import api from "../Util/axiosInstance";



export const UpdateEmployeeBankInfo = async(data)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee-update/update-base-employee-bank-info', data);
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateEmployeeTerminationState = async(data)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee-update/terminate-employee-effective', data);
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const TransferEmployeeToSelectedBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee-update/transfer-employee-to-target-branch', data);
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}