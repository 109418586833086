import classes from './EmployeeProfilePreview.module.css'
import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useAuth } from '../../../../../../../AuthProvider'
import PaperHeader from '../../../../../../../SharedComp/PaperHeader/PaperHeader';
import PaperHash from '../../../../../../../SharedComp/PaperHash/PaperHash';
import { XCircle } from '@phosphor-icons/react';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTargetEmployee } from '../../../SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import { ToEthiopianCalander } from '../../../../../../../SharedComp/Utility';
import { getBaseURL } from '../../../../../../../SharedComp/BaseUrlConfig';

function formatDuration(days) {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const remainingDays = days % 30;

    const final_year =  ` ${years} year${years !== 1 ? 's' : ''} `;
    const final_month = ` ${months} month${months !== 1 ? 's' : ''} `;
    const final_day =  ` ${remainingDays} day${remainingDays !== 1 ? 's' : ''} `
  
    var result = '';
    if(years > 0) 
    {
        result += final_year;
    }
    if(months > 0) 
    {
        result += final_month;
    }
    if(remainingDays > 0) 
    {
        result += final_day;
    }
  
    return result;
}
function CalcServiceDay(day) 
{
    const today = new Date();
    const emp_start_day = new Date(day);
    const timeDifference = today.getTime() - emp_start_day.getTime();
    return formatDuration(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
}
export default function EmployeeProfilePreview({Close_CALLBACK}) 
{
    const auth = useAuth();
    const print_ref = useRef();
    const sharedEmp = useTargetEmployee();
    const CurrentEmployeeSalary = sharedEmp.SalaryArray[sharedEmp.SalaryArray.length - 1].SalaryPoint;

    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "Employee_Profile" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })


    return (
        <div className={`${data_win.main_data_creator_wrapper_emp}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Employee <span className={`${data_win.data_create_name}`}>Document Profile</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><XCircle weight='fill'/></button>
            </div>

        <div className={`${classes.data_preview_wrapper}`}>

            <div className={`${classes.inner_preview_data_wrapper}`}>
                <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                    <PaperHeader/>
                    
                    <div className={`${classes.print_doc_non_repeat_content}`}>

                        <PaperHash text={'AUTHORIZED EMPLOYEE SHEET'}/>
                        <div className={`${classes.meta_data_wrapper}`}> 
                            <h2>Employee Profile</h2>
                            <h2></h2>
                        </div>

                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>

                            <div className={`${classes.employe_personal_info_wrapper_outer}`}>
                                {/* <h2>Personal Information</h2> */}
                                <div className={`${classes.employe_personal_info_wrapper_inner}`}>
                                    <div className={`${classes.employe_personal_info_wrapper}`}>
                                        <div className={`${classes.employe_personal_info_pp_wrapper}`}>
                                            <img src={getBaseURL() + sharedEmp.EmployeeDocumentProfile.EDOCP_ProfilePicture} alt={sharedEmp.Core.JAKSBE_FullName} />
                                        </div>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Full Name</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_FullName}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Phone Number</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_PhoneNumber}</span></li>
                                    </div>
                                    <div className={`${classes.employe_personal_info_wrapper}`}>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Email</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_Email}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Age</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_Age}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Address</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_Address}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Education Status</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_EduStatus}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Emergency Contact Person</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_EmergencyContactName}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Emergency Contact</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_EmergencyPhone}</span></li>
                                    </div>
                                </div>  

                            </div>
                            <hr />
                            <div className={`${classes.employe_personal_info_wrapper_outer}`}>
                                <h2>Job Information</h2>
                                <div className={`${classes.employe_personal_info_wrapper_inner}`}>
                                    <div className={`${classes.employe_personal_info_wrapper}`}>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Work Location</span> <span className={`${classes.employee_personal_info_value}`}>-</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Department</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.CompanyToEmployeeProfile.HRMDT_Name}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Job Position</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.CompanyToEmployeeProfile.HRMJPT_Name}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Work Duration</span> <span className={`${classes.employee_personal_info_value}`}>{CalcServiceDay(sharedEmp.CompanyToEmployeeProfile.CTEP_StartDate)}</span></li>
                                    </div>
                                    <div className={`${classes.employe_personal_info_wrapper}`}>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Start Date</span> <span className={`${classes.employee_personal_info_value}`}>{ToEthiopianCalander(new Date(sharedEmp.CompanyToEmployeeProfile.CTEP_StartDate))}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Bank Account</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.CompanyToEmployeeProfile.CTEP_BankAccount}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Current Salary</span> <span className={`${classes.employee_personal_info_value}`}>{(CurrentEmployeeSalary)?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'ETB',
                                        })}</span></li>
                                        <li><span className={`${classes.employee_personal_info_label}`}>Employee ID</span> <span className={`${classes.employee_personal_info_value}`}>{sharedEmp.Core.JAKSBE_SyntheticID}</span></li>
                                    </div>
                                </div>  

                            </div>
                           
                            
                        </div>

                        {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                        <div className={`${classes.final_note_po_stock_out}`}>
                            {/* <li>This document must have signatures to be considered valid.</li> */}
                        </div>

                    </div>
                </div>
            </div>



            </div> 


            <div className={`${classes.action_button_wrapper}`}>
                <button onClick={()=>{PrintDoc()}} className={`${classes.button_print}`}>Print Document</button>
                <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>
            </div>

            </div>
        </div>
    )
}