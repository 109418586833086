import classes from './MBT_Create.module.css'

import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

 

//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { CreateNewBankType } from '../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I'

export default function MBT_Create({UpdateView, OpenController}) 
{

    const auth = useAuth();
    const notify = useToast();

    const [BankName, SetBankName] = useState();
    const [BankCode, SetBankCode] = useState();
    const [BankLogo, SetBankLogo] = useState();
    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("bank_name", BankName);
            fd.append("bank_code", BankCode);
            fd.append("bank_logo", BankLogo);

            const res = await CreateNewBankType(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            UpdateView();
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    return(
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Create <span className={`${data_win.data_create_name}`}>Bank Type</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetBankName(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Bank Name'
                                                    required />
                                                }
                                            />
                                            <MasterDataRegInput
                                                 input={
                                                    <input  
                                                    onChange={(e)=>{SetBankCode(e.target.value)}}
                                                    type="text" 
                                                    placeholder='eg CBE, BOA'
                                                    required 
                                                    />
                                                }
                                                tip={"Bank Name In Short"}
                                            />
                                            <MasterDataRegInput
                                                LableText={"Bank Logo"}
                                                 input={
                                                    <input  
                                                    onChange={(e)=>{SetBankLogo(e.target.files[0])}}
                                                    type="file" 
                                                    accept="image/*"
                                                    required 
                                                    />
                                                }
                                                tip={"Use images with a 1:1 aspect ratio to achieve optimal results."}
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}