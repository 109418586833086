import classes from './LetterHead.module.css'
import LetterHeagAttachment from '../../asset/images/letter_head.png'


export default function LetterHead()
{


    return (
        <div  className={`${classes.letter_head_main_wrapper} ${classes.print_only}`} 
        style={{
            backgroundImage: `url(${LetterHeagAttachment})`
        }}>
        </div>
    )
}