import React, { useState } from "react";
import { useEffect } from "react";
import { createContext, useContext } from "react";
import { QueryEmployeeByID } from "../../../../../SharedComp/API/HR/API_FE_Employee";
import { useAuth } from "../../../../../AuthProvider";
import { useToast } from "../../../../../ToastContext";
import { QueryBaseEmployeeByID } from "../../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee";

const SingleEmployeeContext = createContext();

export const useTargetEmployee = () => useContext(SingleEmployeeContext);

export const TargetEmployeeProvider = ({children, TargetEmployeeData}) =>
{
    const [Core, SetCore] = useState(false);
    const auth = useAuth();
    const notify = useToast();
    const [Loading, SetLoading] = useState(true);
    const [SalaryArray, SetSalaryArray] = useState([]);
    const [CompanyToEmployeeProfile, SetCompanyToEmployeeProfile] = useState();
    const [EmployeeDocumentProfile, SetEmployeeDocumentProfile] = useState();

    function Update()
    {
        FetchEmployee();
    }

    const FetchEmployee = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", TargetEmployeeData.JAKSBE_ID);
            const main_res = await QueryBaseEmployeeByID(fd);
            
            if(main_res.length > 0) 
            {
                SetCore(main_res[0]);
                SetSalaryArray(main_res[0].JAKSBE_EmployeeContextProfile.CTE_Profile.CTEP_GrossSalary);
                SetCompanyToEmployeeProfile(main_res[0].JAKSBE_EmployeeContextProfile.CTE_Profile);
                SetEmployeeDocumentProfile(main_res[0].JAKSBE_EmployeeContextProfile.EDOC_Profile);
            }
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{

        if(TargetEmployeeData) 
        {
            FetchEmployee();
        }

    }, [TargetEmployeeData])

    return (
        <SingleEmployeeContext.Provider value={{
            SalaryArray,
            CompanyToEmployeeProfile,
            EmployeeDocumentProfile,
            Loading,
            Core,
            Update
        }}>
           
            {children}
        </SingleEmployeeContext.Provider>
    )
}