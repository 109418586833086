import api from "../Util/axiosInstance";




export const CreateNewBaseEmployee = async(data)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee/create-new-base-employee', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const DEBUG_UPDATE_BaseEmployee = async(token)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee/sync-data-employee', {} );
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryBaseEmployeeByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee/query-base-employee-by-branch', data);
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryBaseEmployeeByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee/query-base-employee-by-id', data);
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryBaseEmployeeBySyntID = async(data)=>
{
    try  
    {
        const response = await api.post('api/base-st-employee/query-base-employee-by-synt-id', data);
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}