import classes from './PreviewSmartTransactionSummery.module.css'

import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../../AuthProvider'

import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { useState } from 'react'


const CurrencyFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ETB',
    });
  
    return formatter.format(value);
  };

export default function PreviewSmartTransactionSummery({OpenController, ResponseData}) 
{

    const auth = useAuth();
    const [Loading, setLoading] = useState(false);




    const createMarkup = (htmlString) => {
        return { __html: htmlString };
      };


    


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Stock Smart Summery</span>
                    </h3>
                    <button onClick={()=>{OpenController(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                {!Loading && <div className={`${classes.data_preview_wrapper}`}>

                    <div className={`${classes.inner_preview_data_wrapper}`}>
                        <div className={`${classes.paper_wrapper_dash}`}>
                            <PaperHeader/>
                            
                            <div className={`${classes.print_doc_non_repeat_content}`}>

                                <PaperHash/>
                                <div className={`${classes.meta_data_wrapper}`}>
                                    <h2>[#] {auth.activeWorkShopName} </h2>
                                    <h2>[#] Inventory Smart Stock Summery</h2>
                                </div>


                                <div className={`${classes.main_paper_data_wrapper}`}>

                                    <div className={`${classes.paper_list_wrapper}`}>

                                    <div className={`${classes.single_order_data_view_master_wrapper}`}>
                                            <div dangerouslySetInnerHTML={createMarkup(ResponseData)} className={`${classes.packge_data_wrapper}`}>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>


                            </div>
                        </div>
                    </div>



                    </div>}
                    {
                        Loading &&
                        <LoadingPlaceholder preview_text='Preparing Document'/>
                    }


                        <div className={`${classes.action_button_wrapper}`}>
                            <button onClick={()=>{OpenController(false)}} className={`${classes.button_dismiss}`}>Close</button>
                        </div>

            </div>

            

        </div>
    )
}