import { useEffect } from 'react'
import classes from './PendingPayment.module.css'
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'
import UIButton from '../../../../Components/UIButton/UIButton'
import {  MagnifyingGlass, SpinnerGap, Tray } from '@phosphor-icons/react'

import { useState } from 'react'
import { QueryActivePendingPayments } from '../../../../../SharedComp/API/DeskView/API_FE_DeskView'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import { useAuth } from '../../../../../AuthProvider'

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}


export default function PendingPayment()
{

    const auth = useAuth();
    const [PaymentList, SetPaymentList] = useState([]);
    const [DataCopy, SetDataCopy] = useState([]);
    const [Loading, SetLoading] = useState(true);
    

    useEffect(()=>{
        getPendingPayment();
    }, [])

    const getPendingPayment = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);
            const main_res = await QueryActivePendingPayments(fd);
            SetPaymentList(main_res);
            SetDataCopy(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }


    const FilyerByName = (key_name)=>
    {
        const valid_list = [];
        for(let i = 0; i < PaymentList.length; i++) 
        {
            if(PaymentList[i].PROCI_Name.toLowerCase().includes(key_name.toLowerCase()) || 
            PaymentList[i].PROCI_Phone.includes(key_name)) 
            {
                valid_list.push(PaymentList[i]); 
            }
        }

        SetDataCopy(valid_list);
    }


    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>


        <div className={`${classes.filter_workshop_by_name}`}>
                <div className={`${classes.workshop_filter_input_wrapper}`}>
                    <input onChange={(e)=>{FilyerByName(e.target.value)}} autoComplete='off' placeholder='Name, Telephone' type="text" /> 
                    <span><MagnifyingGlass weight='bold'/></span>
                </div>
        </div>

        <div className={`${classes.list_box_wrapper}`}>
            <div className={`${classes.active_order_main_list_wrapper}`}>
                <li className={`${classes.header_list}`}>
                    <span>Customer Name</span>
                    <span>Phone</span>
                    <span>Expected</span>
                    <span>Cashier</span>
                    <span>Order State</span>
                    <span>Remain Payment</span>
                </li>
                <div className={`${classes.list_data_wrapper}`}>
                    {!Loading &&
                        DataCopy.map((item, idx)=>(
                            <li key={idx}>
                                <span>{item.Customer.POCV_FullName}</span>
                                <span>{item.Customer.POCV_Telephone}</span>
                                <span>{ToEthiopianCalander(new Date(item.OrderDetail.PREO_OrderDetail.ProductExpectedDate))}</span>
                                <span>{item.Cashier.USR_FirstName} {item.Cashier.USR_LastName}</span>
                                <span><span className={`${classes.order_status_pending}`}> <SpinnerGap weight='bold'/> <span className={`${classes.order_stat_text}`}> {item.OrderDetail.PREO_State}</span></span></span>
                            <span className={`${classes.pre_payment_remain_amount}`}>{currency_format(item.OrderDetail.PREO_PaymentInfo.TotalPrice - item.OrderDetail.PREO_PaymentInfo.PrePayment)}</span>
                            </li>
                        ))
                    }
                    {!Loading &&
                        DataCopy.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                            <ul><Tray fontSize={30}/></ul>
                            <ul>No Data</ul>
                        </div>
                    }
                    {
                        Loading && 
                        <LoadingPlaceholder/>
                    }
                </div>
            </div>
        </div>
    </div>
    )
}