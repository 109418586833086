import classes from './CreateNewCredit.module.css'

import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../../SharedComp/Utility'

//lib
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'
import { useTargetEmployee } from '../../SingleEmployeeContextProvider/SingleEmployeeContextProvider'
import { CreateEmployeeCredit } from '../../../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeeCredit'

export default function CreateNewCredit({OpenController}) 
{
    const sharedData = useTargetEmployee(); 
    const auth = useAuth();
    const notify = useToast();

    const _max_credit_amount = (100.0 / sharedData.Core.JAKBECP_MaxCredit) * sharedData.Core.JAKBECP_CurrentGrossSalary;
    const [CreditAmount, SetCreditAmount] = useState();


    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("employee_id", sharedData.Core.JAKSBE_SyntheticID);
            fd.append("credit_init_by", auth.user.USR_UserAccountID);
            fd.append("credit_amount", CreditAmount);
            fd.append("emp_fullname", sharedData.Core.JAKSBE_FullName);
            fd.append("branch_id",  sharedData.Core.JAKSBE_ActiveBranchID);


            const res = await CreateEmployeeCredit(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            sharedData.Update();
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }


    useEffect(()=>{

    }, [])


    return(
        <div className={`${data_win.main_data_creator_wrapper_emp}`}>

        <div className={`${data_win.data_main} ${classes.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Initiate  <span className={`${data_win.data_create_name}`}>New Credit</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetCreditAmount(e.target.value)}}
                                                    type="number"
                                                    step={'any'}
                                                    min={1} 
                                                    max={_max_credit_amount}
                                                    placeholder='Credit Amount'
                                                    required />
                                                }
                                                tip={`The maximum amount of credit for this employee is '${_max_credit_amount}'`}
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Credit'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>)
}