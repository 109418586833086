import api from "../Util/axiosInstance";




export const CreateInitialPayrollDataFeed = async(data)=>
{
    try 
    {
        const response = await api.post('api/payroll/create-intial-payroll-data-feed', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryPreparedPayrollDataFeedEntries = async(token)=>
{
    try 
    {
        const response = await api.post('api/payroll/query-prepared-payroll-data-feed', {} );
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}