import { ToEthiopianCalander } from '../../../../SharedComp/Utility'
import classes from './BudgetAllocationHistoryLocalView.module.css'
import UIButton from '../../../Components/UIButton/UIButton'
import { Checks, Money, Spinner } from '@phosphor-icons/react'
import { useAuth } from '../../../../AuthProvider'
import { useState } from 'react'
import { QueryPettyCashAllocationOmni, QueryPettyCashTransactionDetailByID } from '../../../../SharedComp/API/PettyCash/API_FE_PettyCash'
import { useEffect } from 'react'
import { useToast } from '../../../../ToastContext'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import EmptyProxyDataView from '../../../../SharedComp/DataViewTableProxy/EmptyDataViewTable'


export default function BudgetAllocationHistoryLocalView({RefershData, DataPreviewCallback}) 
{
    const auth = useAuth();
    const notify = useToast();

    const [AllocationHistoryBuffer, SetAllocationHistoryBuffer] = useState([]);
    const [Loading, SetLoading] = useState(false);


    const SortByDate = (data_array)=>
    {
        data_array.sort((a, b)=> new Date(b.PCMM_InitialAllocationDateTime) - new Date(a.PCMM_InitialAllocationDateTime));
        return data_array;
    }
    const QueryAllocationHistory = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const main_res = await QueryPettyCashAllocationOmni(fd);
            const _sorted = SortByDate(main_res);
            SetAllocationHistoryBuffer(_sorted);
            if(_sorted.length > 0) 
            {
                QueryDetailTrasactionLog(_sorted[0]);
            }
            SetLoading(false);
        }catch(err) 
        {
            notify.ShowError(err.message);
            SetLoading(false);
        }
    }

    const QueryDetailTrasactionLog = async(target_data)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", target_data.PCMM_ID);
            const main_res = await QueryPettyCashTransactionDetailByID(fd);
            DataPreviewCallback(main_res);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        QueryAllocationHistory();
    }, [RefershData, auth.activeWorkShop])



    return (
        <div className={`${classes.budget_allocation_history_local_view_master_wrapper}`}>
            <div className={`${classes.budget_allocation_history_local_view_inner_wrapper}`}>

                <div className={`${classes.budget_allocation_history_local_view_header}`}>
                    <h2>Budget Allocation History</h2>
                    {/* <UIButton Variant='Secondary' onClick={()=>{}} label={"Receive Cash Budget"} Icon={<Money weight='fill'/>}/> */}
                </div>

                <div className={`${classes.budget_allocation_history_local_view_body_master_wrapper}`}>
                    {!Loading &&
                        AllocationHistoryBuffer.map((item, idx)=>(
                            <li 
                            key={idx}
                            onClick={()=>{QueryDetailTrasactionLog(item)}}
                            >
                                <span>
                                    {ToEthiopianCalander(new Date(item.PCMM_InitialAllocationDateTime))}
                                </span>
                                <span>
                                    {item.USR_FirstName}  {item.USR_LastName}
                                </span>
                                <span className={`${classes.center_align_span}`}>
                                   {item.PCMM_IsActive === "Yes"  ?  <UIButton onClick={()=>{}} label={"Active Budget"} Icon={<Spinner weight='bold'/>}/> :  <UIButton Variant='Secondary' onClick={()=>{}} label={"Closed Budget"} Icon={<Checks weight='bold'/>}/>}
                                </span>
                                <span className={`${item.PCMM_IsActive === "Yes" ? classes.center_align_span : classes.center_align_span_disable}`}>
                                    {Intl.NumberFormat(
                                        'en', 
                                        {currency: 'ETB', style: 'currency'}).format(
                                        item.PCMM_IsActive === "Yes"  ? item.PCMM_RemainBudget : item.PCMM_InitialAllocationAmount
                                        )}
                                </span>
                                
                            </li>
                        ))
                    }
                    {
                        Loading && 
                        <LoadingPlaceholder placeholder_height={6}/>
                    }
                    {
                        !Loading && AllocationHistoryBuffer.length <= 0 && 
                        <EmptyProxyDataView text='It seems there is no budget allocation. yet!'/>
                    }
                </div>
            
            </div>
        </div>
    )
}