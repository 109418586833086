import { useEffect, useState } from 'react';
import classes from './Warehouse.module.css'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import WarehouseCard from './Component/WarehouseCard/WarehouseCard';
import ViewMappedItem from './Component/ViewMappedItem/ViewMappedItem';
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { FetchWarehouseAll, FetchWarehouseByBranch } from '../../../../SharedComp/API/Warehouse/API_FE_Warehouse';
import { useAuth } from '../../../../AuthProvider';


const Warehouse = () => {


    const auth = useAuth();
    const [WarehouseList, SetWarehouseList] = useState([]);
    const [GroupedList, SetGroupedList] = useState([]);
    const [OpenViewMappedItem, SetOpenViewMappedItem] = useState(false);
    const [ActiveWarehouse, SetActiveWarehouse] = useState();
    const [Loading, SetLoading] = useState(false);


    const generateAnimationDelay = (index) => `${index * 0.15}s`;
    const fetchWarehouse = async () => {
        SetLoading(true);
        try 
        {
            const main_res = await FetchWarehouseAll();
            const workspace_group = new Map();
            main_res.forEach(item => {
                
                if(!workspace_group.has(item.BranchID))
                    workspace_group.set(item.BranchID, []);

                workspace_group.get(item.BranchID).push(item);
            });
            
            const per_workspace_data = [];
            for(const [key, val] of workspace_group) 
            {
                per_workspace_data.push(val);
            }

            SetWarehouseList(per_workspace_data);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }

    const handleViewOpen = (data)=>{
        SetActiveWarehouse(data);
        SetOpenViewMappedItem(true);
    }

    useEffect(()=>{
        fetchWarehouse();
    }, [])


    return (
        <div className={`${classes.warehouse_master_wrapper}`}>

            {OpenViewMappedItem && <ViewMappedItem TargetData={ActiveWarehouse} CloseModal={SetOpenViewMappedItem}/> }

            <div className={`${classes.stock_warehouse_body_wrapper}`}>
                {!Loading &&
                    WarehouseList.map((item, idx)=>(
                        <WarehouseCard 
                            key={idx} 
                            HandleClick={handleViewOpen}
                            AnimDelay={generateAnimationDelay(idx + 1)} 
                            Data={item}/>
                    ))
                }
                {
                    Loading && 
                    <LoadingPlaceholder preview_text='Loading Branch Stores'/>
                }
            </div>
        </div>
    )
};

export default Warehouse;