import api from "../Util/axiosInstance";





export const CreateChefNotifMsg = async(data)=>
{
    try  
    {
        const response = await api.post('api/msg/serve/create-chef-noti-message-pkg', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryUnseenMessage = async(data)=>
    {
        try  
        {
            const response = await api.post('api/msg/serve/query-unseen-messages', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }