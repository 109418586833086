import classes from './StockOut.module.css'

import { useRef, useEffect, useState } from 'react';
import TimeElapsed from '../../SharedComp/TimeElapsed/TimeElapsed';
import { Tooltip } from 'react-tooltip';

import StockAction from './Component/StockAction/StockAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FetchProductionOrderBranchPending} from '../../api'
import { useAuth } from '../../AuthProvider';
import OrderGroupManager from './Component/OrderGroupManager/OrderGroupManager';

import OrderStockProcessor from './Component/OrderStockProcessor/OrderStockProcessor';
import ProductionStockOrderProcessorV2 from './Component/OrderStockProcessor/ProductionStockOrderProcessorV2/ProductionStockOrderProcessorV2';


import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { QueryPendingMasterProductionOrderPackageDetailByBranch, QueryPendingStockReturnRequest } from '../../SharedComp/API/Production/API_MasterProductionV2';
import { useToast } from '../../ToastContext';
import MiniRequestVisualModal from './Component/MiniRequestVisualModal/MiniRequestVisualModal';

import SignableStockReturnDocumentPreview from './Component/MiniRequestVisualModal/SignableStockReturnDocumentPreview/SignableStockReturnDocumentPreview';

export default function StockOut() 
{

    const auth = useAuth();
    const notify = useToast();
    const [ActiveProductionOrders, SetActiveProductionOrders] = useState([]);
    const [OpenPOStockProcessor, SetOpenPOStockProcessor] = useState(false);
    const [FocusedData, SetFocusedData] = useState();
    const [Refersh, SetRefersh] = useState(false);
    const [Loading, SetLoading] = useState(false);
    const [StockReturnRequest, SetStockReturnRequest] = useState([]);
    const [OpenSignableDocPreview, SetOpenSignableDocPreview] = useState(false);
    const [SignDocumentCallbackData, SetSignDocumentCallbackData] = useState();

    const RefershList = ()=>
    {
        SetRefersh(new Date().getMilliseconds());
    }
    const QueryActiveProductionOrders = async()=>{
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_branch_id", auth.activeWorkShop);
            const res = await QueryPendingMasterProductionOrderPackageDetailByBranch(fd);
            SetActiveProductionOrders(res);
            await QueryStockReturnRequest();
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }
    const QueryStockReturnRequest = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_branch_id", auth.activeWorkShop);
            const main_res = await QueryPendingStockReturnRequest(fd);
            SetStockReturnRequest(main_res);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const PoProcessCallback = async(data)=> 
    {
        SetFocusedData(data);
        SetOpenPOStockProcessor(true);
    }

    useEffect(()=>{
        SetActiveProductionOrders([]);
        QueryActiveProductionOrders();

    }, [Refersh, auth.user]);

    const OpenSignableDocumentPreviewCallback = (data)=>
    {
        SetOpenSignableDocPreview(true);
        SetSignDocumentCallbackData(data);
    }
    
    return (
        <div className={`${classes.stock_out_processing_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            {OpenSignableDocPreview && <SignableStockReturnDocumentPreview DataRefersh={RefershList} Close_CALLBACK={SetOpenSignableDocPreview} TargetData={SignDocumentCallbackData}/>}
            {OpenPOStockProcessor && <ProductionStockOrderProcessorV2 DataRefersh={RefershList} TargetData={FocusedData} Close_CALLBACK={SetOpenPOStockProcessor}/> }

            {
                StockReturnRequest.length > 0 &&
                <div className={`${classes.stock_return_request_handle_list_wrapper_outer}`}>
                    <div className={`${classes.stock_return_request_handle_list_header}`}>
                        <h3>Return To Origin</h3>
                    </div>

                    <div className={`${classes.request_list_inner_wrapper}`}>
                        {
                            StockReturnRequest.map((item)=>(
                                <MiniRequestVisualModal 
                                    key={item.MPOSRH_TransactionID} 
                                    CallbackFunction={OpenSignableDocumentPreviewCallback} 
                                    DataPoint={item}
                                    DataRefersh={RefershList}
                                    />
                            ))
                        }
                    </div>

                </div>
            }

            <div className={`${classes.active_order_list}`}>
                {!Loading &&
                    ActiveProductionOrders.map((item, idx)=>(
                        <OrderGroupManager key={item.MPOM_OrderID} DataRefersh={RefershList} DataAcceptCallback={PoProcessCallback} OrderData={item}/>
                    ))
                }
                {Loading && <LoadingPlaceholder/>}
                {
                    ActiveProductionOrders.length === 0 && !Loading && 
                    <div className={`${classes.empty_po_stock_request}`}>
                            <li><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></li>
                            <li>no Active production stock request!</li>
                    </div>
                }
            </div>

        </div>
    );
}