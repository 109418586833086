import classes from './CreateNewEmployee.module.css'
import data_win from '../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'


import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'

import EmployeePersonalData from './EmployeePersonalData/EmployeePersonalData'
import EmployeeCompanyProfile from './EmployeeCompanyProfile/EmployeeCompanyProfile'
import EmployeeDocumentsProfile from './EmployeeDocumentsProfile/EmployeeDocumentsProfile'
import EmployeeFinalPreview from './EmployeeFinalPreview/EmployeeFinalPreview'
import { CreateNewBaseEmployee } from '../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee'

export default function CreateNewEmployee({RefreshDataView, SelectedBranch, OpenController})
{

    const [EmployeeData, SetEmployeeData] = useState({});

    const auth = useAuth();
    const notify = useToast();
    const [Loading, SetLoading] = useState(false);
    const [ActiveProfileIdx, SetActiveProfileIdx] = useState(0);

   
    const NextEmpProfile = async()=>
    {
        if(ActiveProfileIdx < 3) 
        {
            SetActiveProfileIdx(ActiveProfileIdx + 1);
        }
    }
    const PrevEmpProfile = async()=>
    {
        if(ActiveProfileIdx > 0) 
        {
            SetActiveProfileIdx(ActiveProfileIdx - 1);
        }
    }

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        

        if(ActiveProfileIdx !== 3) {

            NextEmpProfile();
            return;
        }
        SetLoading(true);
        notify.ShowLoad("Processing your request.....", 10);
        try 
        {
            const fd = new FormData();
            fd.append('employee_package', JSON.stringify(EmployeeData));
            fd.append('emp_profile_pic', EmployeeData['emp_profile_pic'] ? EmployeeData['emp_profile_pic'] : null);
            fd.append('emp_certification', EmployeeData['emp_certification'] ? EmployeeData['emp_certification'] : null);
            fd.append('emp_identification_card', EmployeeData['emp_identification_card'] ? EmployeeData['emp_identification_card'] : null);
            fd.append('emp_ref_identification_card', EmployeeData['emp_ref_identification_card'] ? EmployeeData['emp_ref_identification_card'] : null);
            fd.append('emp_ref_signed_doc', EmployeeData['emp_ref_signed_doc'] ? EmployeeData['emp_ref_signed_doc'] : null);
            fd.append('CrossBranch', SelectedBranch === -1 ? "Yes" : "No");
            fd.append("dest_branch", SelectedBranch);
            fd.append('action_taken_by', auth.user.USR_UserAccountID);

            const main_res = await CreateNewBaseEmployee(fd);

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetLoading(false);
            CloseModal();
            RefreshDataView(new Date().getUTCMilliseconds());
        }catch(err) 
        {
            SetLoading(false);
            notify.ShowErrorUpdate(err.message, 10);
        }
      
    }


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Create <span className={`${data_win.data_create_name}`}>New Employee Profile</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>

                               {ActiveProfileIdx === 0 && <EmployeePersonalData Employee={EmployeeData} UpdateEmployeeData={SetEmployeeData}/> }  
                               {ActiveProfileIdx === 1 && <EmployeeCompanyProfile  Employee={EmployeeData} UpdateEmployeeData={SetEmployeeData}/>}
                               {ActiveProfileIdx === 2 && <EmployeeDocumentsProfile Employee={EmployeeData} UpdateEmployeeData={SetEmployeeData} />}
                               {ActiveProfileIdx === 3 && <EmployeeFinalPreview Employee={EmployeeData} UpdateEmployeeData={SetEmployeeData}/>}

                                    
                            </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading}  onClick={()=>{PrevEmpProfile()}} className={`${data_win.form_action_button_save}`}  type='button'>Previous</button>
                                <button  disabled={Loading} className={`${data_win.form_action_button_save}`}>{ActiveProfileIdx === 3 ? Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Register' : 'Next'}</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}