import classes from './LocalOrders.module.css'
import TrackSupplyOrder from '../KitchenSupplyOrder/Component/TrackSupplyOrder/TrackSupplyOrder'
import LocalFrontOrder from './LocalFrontOrder/LocalFrontOrder'

export default function LocalOrders() 
{


    return (
        <div>
            <LocalFrontOrder />
        </div>
    )
}