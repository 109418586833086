import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import classes from './Unauthorized.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Detective, RoadHorizon } from '@phosphor-icons/react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Unauthorized()
{
     const navigate = useNavigate();
   
       useEffect(()=>{
           setTimeout(()=>
           {
               navigate("/login");
           }, 15 * 1000)
       }, [])



   
   
       return (
           <div className={`${classes.page_termination_preview_main_wrapper}`}>
               
               <div className={`${classes.content_wrapper}`}>
                   <h1><Detective weight='fill'/></h1>
                   <h2><span className={`${classes.force_terminated}`}>Unauthorized Request</span></h2>

                    
                    <div className={`${classes.page_reason}`}>
                        <h3 style={{fontWeight: 'bold', color: `var(--text_light_white)`}}>This may happen due to one of the following reasons:</h3>
                        <li>Your session has expired (e.g., due to an inactive period).</li>
                        <li>You didn't sign out properly from your last session.</li>
                        <li>Your authentication token is invalid or has been revoked.</li>
                        <li>There was an issue verifying your credentials.</li>
                        <li>You loged out and you are trying to be slick... :)</li>
                    </div>
                    <br />
                   <h3>If this issue persists, please reach out to your <br /><strong> IT support</strong> team for assistance.</h3>
                   <h3>Redirecting you to <strong style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>{navigate("/login");}}>Login</strong> page in a few seconds...</h3> 
               </div>
   
           </div>
       )
}