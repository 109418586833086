import classes from './MiniRequestVisualModal.module.css'
import UIButton from '../../../Components/UIButton/UIButton'
import { ArrowCircleRight } from '@phosphor-icons/react'
import TimeAgo from '../../../../SharedComp/TimeElapsed/TimeAgo'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { useEffect, useState } from 'react'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { QueryPendingStockReturnRequestDetail } from '../../../../SharedComp/API/Production/API_MasterProductionV2'
import { ApproveRTOByIdV2 } from '../../../../SharedComp/API/Production/API_MasterProductionV2'
import { useDAControl } from '../../../../DAControlContext'
export default function MiniRequestVisualModal({DataPoint, CallbackFunction, DataRefersh})  
{

    const auth = useAuth();
    const notify = useToast();
    const DAController = useDAControl();
    const [Loading, SetLoading] = useState(false);
    const [DetailData, SetDetailData] = useState();
    const [DataDetailMeregd, SetDataDetailMeregd] = useState();
    const [OpenSignableDocPreview, SetOpenSignableDocPreview] = useState(false);
    const [MasterDataPackage, SetMasterDataPackage] = useState();


    const QueryDetailRequest = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_item_id", DataPoint.MPOSRH_ID);
            const main_res = await QueryPendingStockReturnRequestDetail(fd);
            SetDetailData(main_res.CoreData);
            SetDataDetailMeregd(main_res.DataMergedDetail);
            SetMasterDataPackage(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
            notify.ShowError(err.message);
        }
    }
     const handleDirectApproval = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("user_id", auth.user.USR_UserAccountID);
            fd.append("target_order_id", DataPoint.MPOSRH_ID);

            const res = await ApproveRTOByIdV2(fd);

            if(res.MyStateCode !== 1)
            {
                throw new Error(res.Msg);
            }

            notify.ShowSuccess(res.Msg);
            DataRefersh();

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        if(DataPoint) 
        {
            QueryDetailRequest();
        }

    }, [DataPoint])


    return (
        <div className={`${classes.mini_req_visual_modal_master_wrapper}`}>
           
            { !Loading &&
                <>
                <div className={`${classes.modeal_requst_info_wrapper}`}>
                    <li>ID: <span>{DataPoint.MPOSRH_TransactionID}</span></li>
                    <li>Request Made By:  <span>{DetailData?.USR_FirstName} {DetailData?.USR_LastName}</span> </li>
                    <li>Time: <span><TimeAgo show_suffix={true} date={new Date(DataPoint.MPOSRH_RequestDateTime)}/></span></li>
                </div>
                <div className={`${classes.datalist_master_wrapper_hint}`}>
                    {DataDetailMeregd &&
                        DataDetailMeregd.map((item, idx)=>(
                            <li>
                                <span>{item.IVItemName}</span>
                                <span>{(item.RT_Quantity).toFixed(2)}</span>
                            </li>
                        ))
                    }
                </div>
                <div className={`${classes.modal_action_button_req}`}>
                    <UIButton 
                        Variant='Secondary' 
                        Tip={"By proceeding, you acknowledge that this approval does not require paper documentation, as it may be deemed an inefficient use of resources. This option is recommended for instances involving minimal stock movement."} 
                        label={"Direct Approve"} 
                        Icon={<ArrowCircleRight weight='fill'/>}
                        onClick={()=>{
                            DAController.Trigger(`Approve RTO Directly`, 
                            `This action will impact the Inventory Balance by adding the specified inventory amount to your store. Please note that this process will not generate any additional documentation prompts.`,
                            true,
                            5, (val)=>{
                                if(val !== null) 
                                {
                                    handleDirectApproval();
                                }
                            }   
                        );}}
                        />
                    <UIButton Tip={"This action will generate a signable document for your approval of the activity."} onClick={()=>{CallbackFunction(MasterDataPackage)}} label={"Signed Apporve"} Icon={<ArrowCircleRight weight='fill'/>}/>
                </div>
                </>
            }
            {
                Loading && 
                <LoadingPlaceholder placeholder_height={5}/>
            }
        </div>
    )
}