import api from "../Util/axiosInstance";




/////////////////////////////////////////////////// MASTER PENALITY

export const CreateNewPenalityType = async(data)=>
{
        try  
        {
            const response = await api.post('api/operation-data-cluster-beta/create-hr-master-penality-type', data);
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdatePenalityType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/update-hr-master-penality-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgePenalityType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/purge-hr-master-penality-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterPenality = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/query-all-hr-master-penality-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

//////////////////////////////////////////////////// MASTER PTO

export const CreateNewPTOType = async(data)=>
{
        try  
        {
            const response = await api.post('api/operation-data-cluster-beta/create-hr-master-pto-type', data);
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdatePTOType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/update-hr-master-pto-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgePTOType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/purge-hr-master-pto-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterPTO = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/query-all-hr-master-pto-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}


//////////////////////////////////////////////////// MASTER SHIFT

export const CreateNewShiftType = async(data)=>
{
        try  
        {
            const response = await api.post('api/operation-data-cluster-beta/create-hr-master-shift-type', data);
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateShiftType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/update-hr-master-shift-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeShiftType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/purge-hr-master-shift-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterShift = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/query-all-hr-master-shift-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}


//////////////////////////////////////////////////// MASTER UTILITY EXPENSE

export const CreateNewUtilityExpenseType = async(data)=>
{
        try  
        {
            const response = await api.post('api/operation-data-cluster-beta/create-hr-master-utility-expense-type', data);
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateUtilityExpenseType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/update-hr-master-utility-expense-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeUtilityExpenseType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/purge-hr-master-utility-expense-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterUtilityExpense = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-beta/query-all-hr-master-utility-expense-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
} 