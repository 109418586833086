import classes from './MainDesktopIcon.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function MainDesktopIcon({AnimationDelay, SubRoute_CALLBACK, Data_CALLBACK, DataPackage, Root = true, Label = "Discuss", Icon})
{
    const HandleClick = ()=>
    {
        console.log(DataPackage);
        if(Root) 
        {
            Data_CALLBACK(DataPackage)
        } else 
        {
            SubRoute_CALLBACK(DataPackage);
        }
    }
    return (
        <div style={{animationDelay: AnimationDelay }} className={`${classes.desktop_main_icon_wrapper}`}>
            <div onClick={()=>{HandleClick()}} className={`${classes.desktop_icon_box_wrapper}`}>
               <FontAwesomeIcon icon={Icon} />
            </div>
            <div className={`${classes.desktop_icon_text}`}>
                <li>{Label}</li>
            </div>
        </div>
    )
}