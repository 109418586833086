import classes from './MCAT_Create.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

 

//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { CreateNewCashAllowanceType } from '../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I'

export default function MCAT_Create({UpdateView, OpenController}) 
{
    const auth = useAuth();
    const notify = useToast();

    const [CashAllowanceName, SetCashAllowanceName] = useState();
    const [CashAllowanceTaxAmount, SetCashAllowanceTaxAmount] = useState(0);
    const [CashAllowanceDesc, SetCashAllowanceDesc] = useState();
    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("allowance_name", CashAllowanceName);
            fd.append("allowance_tax", CashAllowanceTaxAmount);
            fd.append("allownace_desc", CashAllowanceDesc);

            const res = await CreateNewCashAllowanceType(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            UpdateView();
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    return(
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Create <span className={`${data_win.data_create_name}`}>Cash Allowance Type</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetCashAllowanceName(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Cash Allowance Name'
                                                    required />
                                                }
                                            />
                                            <MasterDataRegInput
                                                 input={
                                                    <input  
                                                    onChange={(e)=>{SetCashAllowanceTaxAmount(e.target.value)}}
                                                    type="number"
                                                    step={"any"}
                                                    min={0}
                                                    max={100} 
                                                    placeholder='Tax'
                                                    title='' 
                                                    />
                                                }
                                                tip={"Some cash allowances may be subject to taxation. If the allowance is taxable, please specify the taxable percentage in this field. If it is not taxable, you can leave this field blank"}
                                            />
                                            <MasterDataRegInput
                                                 input={
                                                    <input  
                                                    onChange={(e)=>{SetCashAllowanceDesc(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Description'
                                                    required 
                                                    />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>)
}