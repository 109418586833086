import classes from './CustomerDetail.module.css'
import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'


//lib
import { useAuth } from '../../../../../../../AuthProvider'
import { useToast } from '../../../../../../../ToastContext'


const currency_format = (val)=> 
    {
        return val.toLocaleString('en-US', {
            style: 'currency',
            currency: 'ETB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

export default function CustomerDetail({OpenController, TargetData, Submition_Callback})
{

    const auth = useAuth();
    const notify = useToast();

    const [TotalPrice, SetTotalPrice] = useState(0);
    const [CustomerName, SetCustomerName] = useState('');
    const [CustomerPhone, SetCustomerPhone] = useState('');
    const [ArrivalDate, SetArrivalDate] = useState();
    const [Prepayment, SetPrepayment] = useState('');
    const [PaymentRef, SetPaymentRef] = useState('');
    const [VatAmount, SetVatAmount] = useState(0);

    function CloseModal() 
    {
        OpenController(false);
    }

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();

        Submition_Callback({
            _CustomerName: CustomerName,
            _CustomerPhone: CustomerPhone,
            _ArrivalDate: ArrivalDate,
            _PrePaymentAmount: Prepayment,
            _FullyPaied: Prepayment === TotalPrice,
            _PaymentRef: PaymentRef,
            _OrderReceivedBy: auth.user.USR_UserAccountID,
            _VatAmount: VatAmount,
            _TotalPrice: TotalPrice

        });
        CloseModal();
    }

    useEffect(()=>{

        if(TargetData) 
        {
            let _price = 0;
            let _vat = 0;
            for(let i = 0; i < TargetData.length; i++) 
            {
                _price += TargetData[i]._ProductPrice * TargetData[i]._ProductQuantity;
                if(TargetData[i]._ProductGroupID === 1) 
                {
                    // Calculate VAT
                    _vat += (TargetData[i]._ProductPrice * TargetData[i]._ProductQuantity) * 0.15;
                }
            }

            SetVatAmount(_vat);
            SetTotalPrice(_price);
        }

    }, [TargetData])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    <span className={`${data_win.data_create_name}`}>Finalize Order</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.product_list_with_price}`}>

                                        <div className={`${classes.product_list_impo}`}>
                                            {
                                                TargetData.map((item, idx)=>(
                                                    <li key={idx}>
                                                        <span className={`${classes.name_space}`}>{item._ProductPreviewName}</span>
                                                        <span>{currency_format(item._ProductPrice)}</span>
                                                        <span>{item._ProductQuantity}</span>

                                                        <span className={`${classes.total_single_price}`}>{currency_format(item._ProductPrice * item._ProductQuantity)}</span>
                                                        
                                                    </li>
                                                ))
                                            }
                                        </div>
                                        <div className={`${classes.summery_data_wrapper}`}>
                                            <li>
                                                <span>VAT</span>
                                                <span className={`${classes.total_single_price}`}>{currency_format(VatAmount)}</span>
                                            </li>
                                            <li>
                                                <span>SubTotal</span>
                                                <span className={`${classes.total_single_price}`}>{currency_format(TotalPrice)}</span>
                                            </li>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            
                                            <MasterDataRegInput
                                                    input={
                                                        <input  
                                                        value={CustomerName}
                                                        onChange={(e)=>{SetCustomerName(e.target.value)}}
                                                        type="text" 
                                                        placeholder='Customer Name'
                                                        required
                                                        />
                                                    }
                                                />
                                            <MasterDataRegInput
                                                input={
                                                    <input 
                                                    value={CustomerPhone} 
                                                    onChange={(e)=>{SetCustomerPhone(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Customer Phone'
                                                    required
                                                    />
                                                }
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    value={Prepayment}
                                                    onChange={(e)=>{SetPrepayment(e.target.value)}}
                                                    type="number" 
                                                    min={1}
                                                    max={TotalPrice}
                                                    placeholder='Pre-Payment'
                                                    required
                                                    />
                                                }
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                        value={PaymentRef}
                                                        onChange={(e)=>{SetPaymentRef(e.target.value)}}
                                                        type="text" 
                                                        placeholder='Payment Reference'
                                                    />
                                                }
                                                tip={"If the user uses online payment method"}
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    min={new Date().toISOString().split("T")[0]}
                                                    max={new Date(Date.now() + 15 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]}
                                                    onChange={(e)=>{SetArrivalDate(e.target.value)}}
                                                    type="date" 
                                                    required
                                                     />
                                                }
                                                tip={"Expected Date"}
                                            />

                                           

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button className={`${data_win.form_action_button_save}`}>Finish</button>
                                <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}