import classes from './OrderSubGroup.module.css'

import UIButton from '../../../../../Components/UIButton/UIButton'
import { Stack, Star, Van } from '@phosphor-icons/react'

import TimeAgo from '../../../../../../SharedComp/TimeElapsed/TimeAgo'


import ShippingDialog from '../../ShippingDialog/ShippingDialog'
import { useState } from 'react'
import { useEffect } from 'react'

export default function OrderSubGroup({SubDataList, _UpdateViewToggel, BranchData, _UpdateView})
{

    const [OpenShippingDialog, SetOpenShippingDialog] = useState(false);
    const [SpecialOrderProduct, SetSpecialOrderProduct] = useState(false);

    useEffect(()=>{
        if(SubDataList.length > 0) 
        {
            SetSpecialOrderProduct(SubDataList[0].KPSR_PendingBackUp.KPPO_SpecialOrderDetail !== null);
        }
    }, [])


    return (
        <div className={`${classes.group_body_wrapper}`}>

            {OpenShippingDialog && 
                    <ShippingDialog 
                            UpdateViewToggel={_UpdateViewToggel} 
                            UpdateView={_UpdateView} 
                            DataList={SubDataList} 
                            BranchData={BranchData}
                            Close_CALLBACK={SetOpenShippingDialog}/>
                            }
            
            <div className={`${classes.sub_group_data_list_wrapper}`}>
                {
                    SubDataList?.map((item, idx)=>(
                        <li key={item.KPSHIP_UUID + idx}>
                            <span>{item.CKP_ItemName} {SpecialOrderProduct ? <span style={{padding: `1px 6px`, borderRadius: `2px`, color: `var(--background-dark_plus)`, background: `var(--color-theme)`}}>SPECIAL ORDER</span> : ''}</span>
                            <span className={`${classes.quantity_data_wrapper}`}>
                                <span className={`${classes.total_amount_wrapper}`}>Ordered: {item.KPSR_PendingBackUp.KPPO_OriginalOrderCount} </span>
                                <span>Produced: {item.KPSR_ProductCount} </span>
                            </span>
                            <span className={`${classes.action_button_wrapper_group}`}>
                                <UIButton Variant='Secondary' onClick={()=>{}} Tip={"Response Accuracy"} Icon={<Stack/>} label={((item.KPSR_ProductCount / item.KPSR_PendingBackUp.KPPO_OriginalOrderCount).toFixed(1) * 100).toFixed(1) + '%'} />
                            </span> 
                        </li>
                    ))
                }
            </div>

            <div className={`${classes.sub_group_action_wrapper}`}>
                <div className={`${classes.date_info_action_wrap_sub_group}`}>
                   <li>Ordered Date: <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': `${new Date(SubDataList[0].KPSR_PendingBackUp.KPPO_OrderInitializedDate).toDateString()}`})} className={`${classes.tag_val}`}>{<TimeAgo show_suffix={true} date={SubDataList[0].KPSR_PendingBackUp.KPPO_OrderInitializedDate} />}</span> </li> 
                   <li>Expected Date: <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': `${new Date(SubDataList[0].KPSR_PendingBackUp.KPPO_OrderTargetDeliveryDate).toDateString()}`})} className={`${classes.tag_val}`}>{<TimeAgo show_suffix={true} date={SubDataList[0].KPSR_PendingBackUp.KPPO_OrderTargetDeliveryDate} />}</span></li>
                </div>
                <div>
                    <UIButton onClick={()=>{SetOpenShippingDialog(true)}} label={"Ship Package"} Icon={<Van weight='fill'/>}/>
                </div>
            </div>

        </div>
    )
}