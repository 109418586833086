
import classes from './EmpAllowance.module.css'

import { useEffect, useState } from 'react';

import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo';
import UIButton from '../../../../Components/UIButton/UIButton';
import { Empty } from '@phosphor-icons/react';

import CreateNewEmployeeAllowance from './CreateNewEmployeeAllowance/CreateNewEmployeeAllowance';
import { useTargetEmployee } from '../SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import { PurgeEmployeeAllowance, QueryEmployeeAllowanceByEmployeeID } from '../../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeeAllowance';
import { useAuth } from '../../../../../AuthProvider';
import { useDAControl } from '../../../../../DAControlContext';
import { useToast } from '../../../../../ToastContext';
import EmptyProxyDataView from '../../../../../SharedComp/DataViewTableProxy/EmptyDataViewTable';
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';


const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function EmpAllowance() 
{

    const notify = useToast();
    const DAcontroller = useDAControl();
    const auth = useAuth();
    const sharedData = useTargetEmployee();

    const [RefreshView, SetRefreshView] = useState(false);
    const [AvailAllowanceList, SetAvailAllowanceList] = useState([]);
    const [OpenAllowanceCreator, SetOpenAllowanceCreator] = useState(false);

    const GetAvailAllowance = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", sharedData.Core.JAKSBE_SyntheticID);
            const main_res = await QueryEmployeeAllowanceByEmployeeID(fd);
            SetAvailAllowanceList(main_res);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        GetAvailAllowance();
    }, [OpenAllowanceCreator, RefreshView])
    

 
    return (
        <div className={`${classes.emp_credit_main_wrapper}`}>
            {OpenAllowanceCreator && <CreateNewEmployeeAllowance OpenController={SetOpenAllowanceCreator}/>}

            <div className={`${classes.emp_action_adder}`}>
                <button onClick={() => {SetOpenAllowanceCreator(true)}}>Add Allowance</button>
            </div>
            
            <div className={`${classes.amp_credit_info}`}>

                <div className={`${classes.list_wrapper_inner}`}>
                    { !sharedData.Loading &&
                        AvailAllowanceList.map((item, idx)=>(
                            <li key={item.EMP_ALLOWANCE_Hash}>
                                {/* <span><TimeAgo show_suffix={true} date={item.JAKSEOPALLOW_CreatedDate}/></span> */}
                                <span>{item.HRMCAT_Name}</span>
                                <span>{currency_format(item.EMP_ALLOWANCE_Amount)}</span>
                                <span className={`${classes.action_indicator}`}>
                                        <UIButton Variant='Danger' label={"Purge"} onClick={()=>{
                                            DAcontroller.Trigger(`Remove Allowance`,
                                            `You are about to remove employee allowance, this will affect the salary directly, this amount will be deducted from net pay...,`,
                                            item.EMP_ALLOWANCE_Hash,
                                            7,
                                            async (data)=>
                                            {
                                                if(data) 
                                                {
                                                    try 
                                                    {
                                                        const fd = new FormData();
                                                        fd.append("allowance_id", data);
                                                        fd.append("employee_id",  sharedData.Core.JAKSBE_SyntheticID);
                                                        const main_res = await PurgeEmployeeAllowance(fd);
                                                        notify.ShowSuccess(main_res.Msg);
                                                        SetRefreshView(!RefreshView);
                                                    }catch(err) 
                                                    {
                                                        notify.ShowSuccess(err.message);  
                                                    }

                                                }
                                            })
                                        }} Icon={<Empty weight='bold'/>}/> 
                                </span>
                            </li>
                        ))
                    }
                    {!sharedData.Loading &&
                        AvailAllowanceList.length <= 0 && 
                        <EmptyProxyDataView text='Empty Allowances'/>
                    }
                    {
                        sharedData.Loading && 
                        <LoadingPlaceholder/>
                    }
                </div>
                
                  
            </div>

        </div>
    );
}