import classes from './PastryShelves.module.css'
import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy'
import { useState } from 'react'

import CreatePastryShelves from './Component/CreatePastryShelves/CreatePastryShelves'
import { QueryPastryShelvesProductByBranch } from '../../../SharedComp/API/ShopShelf/API_FE_PastryShelves'
import { useAuth } from '../../../AuthProvider'
import { useEffect } from 'react'
import { useToast } from '../../../ToastContext'


const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function PastryShelves()
{

    const [ShelfItems, SetShelfItems] = useState([]);
    const [OpenCreatePatryShelvsItem, SetOpenCreatePatryShelvsItem] = useState(false);
    const auth = useAuth();
    const notify = useToast();

    const FetchPastryProduct = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop)
           const main_res = await QueryPastryShelvesProductByBranch(fd);
           SetShelfItems(main_res);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        FetchPastryProduct();
    }, [])


    return (
        <div className={`${classes.shelves_master_wrapper}`}>

            {OpenCreatePatryShelvsItem && <CreatePastryShelves OpenController={SetOpenCreatePatryShelvsItem}/>}

            <div className={`${classes.data_inner}`}>
                
                    <div className={`${classes.data_table_wrapper}`}>

                        <DataViewTableProxy 
                            ShowNewButton={false}
                            IsEmpty={ShelfItems.length === 0}
                            OnFilter={()=>{}}
                            OpenDataCreate={SetOpenCreatePatryShelvsItem}
                            HeaderName={
                                <div>
                                    <h2><b>Pastry Shelves</b> Product</h2>
                                </div>
                            }
                            DataArray={
                                <div>
                                    {
                                        ShelfItems.map((item, idx)=>(
                                            <li key={item.CKP_ItemName + idx}>
                                                <span>{item.CKP_ItemName}</span>
                                                <span><span className={`${classes.cash_view_value}`}>{currency_format(item.CKP_UnitPrice)}</span></span>
                                                <span><span className={`${classes.gram_view_amount}`}>{human_readable_format(item.SPP_Quantity)} Slice</span></span>
                                            </li>
                                        ))
                                    }
                                    
                                </div>
                            }
                        />

                    </div>
            </div>

        </div>
    )
}