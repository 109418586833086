import React from "react";
import { createContext, useContext } from "react";

import { ToastWrapper, toast } from "./SharedComp/ToastWrapper/ToastWrapper";


const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({children }) => {

    const Close = async(id = 1) => {toast.dismiss(id)};
    const ShowWarn = (msg) => {toast.warning(msg, {autoClose: 10000}); }
    const ShowSuccess = (msg) => { toast.success(msg, {autoClose: 10000}); }
    const ShowError = (msg) =>  { toast.error(msg, {autoClose: 10000}); }

    const ShowSuccessUpdate = (msg, id) => { toast.update(id, {render: msg, autoClose: 10000, type: 'success', isLoading: false, closeButton: true}); }
    const ShowErrorUpdate = (msg, id) =>  { toast.update(id, {render: msg, autoClose: 10000, type: 'error', isLoading: false, closeButton: true}); }
    const ShowWarnUpdate = (msg, id) =>  { toast.update(id, {render: msg, autoClose: 10000, type: 'warning', isLoading: false, closeButton: true}); }

    const ShowLoad = (msg, id) => {
        toast.loading(msg, {
            toastId: id
        })
    }

    return (
        <ToastContext.Provider value={{
            ShowSuccess, 
            ShowError,
            ShowWarn,
            ShowLoad,
            Close,
            ShowSuccessUpdate,
            ShowErrorUpdate,
            ShowWarnUpdate
        }}>
            <ToastWrapper/>
            {children}
        </ToastContext.Provider>
    )

}