import classes from './DirectStockMovementRequest.module.css'


import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faMinus, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import {CreateNewMerchGroup} from '../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'


import DirectStockRequestInputPackage from './DirectStockRequestInputPackage/DirectStockRequestInputPackage'

//lib
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import UIButton from '../../../Components/UIButton/UIButton'
import { PlusCircle } from '@phosphor-icons/react'
import { CreateDirectStockRequest } from '../../../../SharedComp/API/DirectStockRequest/API_FE_DirectStockRequest'

function getRandomID() 
{
    return Math.floor(Math.random() * 100000000);
}

export default function DirectStockMovementRequest({OpenController}) 
{

    const auth = useAuth();
    const notify = useToast();
    const scrollRef = useRef(null);
    const [Loading, SetLoading] = useState(false);

    const [StockRequestReason, SetStockRequestReason] = useState();
    const [InventoryItemType, SetInventoryItemType] = useState(null);
    const [InventoryItemQuantity, SetInventoryItemQuantity] = useState(null);
    const [StockItems, SetStockItems] = useState(
            [
                <DirectStockRequestInputPackage 
                    HandleInvItemSelect={HandleIvItemSelection} 
                    HandlInvItemQuantity={HandleItemQuantitySelection}
                    idx={getRandomID()}/>
            ]
        );

    function HandleIvItemSelection(data, idx) 
    {
        SetInventoryItemType(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleItemQuantitySelection(data, idx) 
    {
        SetInventoryItemQuantity(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function AddNewInputField() 
    {
        const existing = [...StockItems];
        existing.push(
            <DirectStockRequestInputPackage 
                HandleInvItemSelect={HandleIvItemSelection} 
                HandlInvItemQuantity={HandleItemQuantitySelection}
                idx={getRandomID()}/>
        );
        SetStockItems(existing);
    }
    function RemoveInputField(id_to_remove) 
    {
        if(StockItems.length === 1)
        return;
    
    
        // Clean Inventory Item
        if(InventoryItemType) 
        {
            SetInventoryItemType(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }
        // Clean Item Quantity
        if(InventoryItemQuantity) 
        {
            SetInventoryItemQuantity(prevState => {
                const { [id_to_remove]: _, ...updated } = prevState;
                return updated;
            });
        }
        SetStockItems(prevState => {
            const updated = prevState.filter(item => item.props.idx !== id_to_remove);
            return updated;
        });

    }
    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Request...", 10);
        SetLoading(true);

        try 
        {

            const fd = new FormData();
            fd.append("created_by", auth.user.USR_UserAccountID);
            fd.append("active_workshop", auth.activeWorkShop);
            fd.append("request_reason", StockRequestReason);
            fd.append("payload_IVItem", JSON.stringify(InventoryItemType));
            fd.append("payload_IVItemQuantity", JSON.stringify(InventoryItemQuantity));

            const main_res = await CreateDirectStockRequest(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetLoading(false);
            OpenController(false);
        }catch(err) 
        {
            SetLoading(false);
            notify.ShowErrorUpdate(err.message, 10);
        }

    }
    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    useEffect(()=>{
         scrollToBottom();
    }, [StockItems])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Create <span className={`${data_win.data_create_name}`}>Direct Stock Request</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            <div className={`${classes.reason_to_eject_stock_input_wrapper}`}>
                                                <MasterDataRegInput 
                                                    input={<textarea 
                                                    placeholder='Please provide a detailed reason for requesting this stock.' 
                                                    onChange={(e)=>{SetStockRequestReason(e.target.value)}}
                                                    required />} 
                                                />
                                            </div>
                                        <div className={`${classes.po_mat_list_wrapper}`}>
                                            

                                            <div className={`${classes.po_mat_list}`}>
                                                    {StockItems.map((item) => ( 
                                                        <div 
                                                            className={`${classes.input_fild_shild}`} 
                                                            key={item.props.idx}>{item}{StockItems.length > 1 ? <button type='button' onClick={()=>{RemoveInputField(item.props.idx)}} 
                                                            className={`${classes.remove_button_wrapper}`}>
                                                                <FontAwesomeIcon icon={faMinus}/></button> : <span  className={`${classes.remove_button_wrapper_fake}`}></span> }
                                                            <div ref={scrollRef}/>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    
                                   <div className={`${classes.dx_stock_request_new_add_button_wrapper}`}>
                                        <UIButton Icon={<PlusCircle weight='fill'/>} Type='button' label={"Add More"} onClick={()=>{AddNewInputField()}}/>
                                    </div>     
                                   
                                    </div>

                            </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Create'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}