import classes from '../PayrollConfig/PayrollConfig.module.css'



export default function SystemStockConfig({UpdateReq, ConfigData}) 
{



    return (
        <div className={`${classes.credit_system_wrapper}`}>
        <div className={`${classes.part_of_option}`}>
            <ul>General</ul>
            <div className={`${classes.setting_items_config}`}>
                <li><span>Hide Inventory If Balance is Zero </span> 
                    <span>
                        <select onChange={(e)=>{UpdateReq("GC_HideInventoryIfBalanceIsZero",  e.target.value === 'true');}} value={String(ConfigData.GC_HideInventoryIfBalanceIsZero)}>
                            <option value="true">Hide</option>
                            <option value="false">Show</option>
                        </select>
                    </span>
                </li>
                <li><span>Suppress Stock Issue Notification</span> 
                    <span>
                        <select onChange={(e)=>{UpdateReq("GC_SuppressStockIssueNotification",  e.target.value === 'true');}} value={String(ConfigData.GC_SuppressStockIssueNotification)}>
                            <option value="true">Suppress Notification</option>
                            <option value="false">Show Notification</option>
                        </select>
                    </span>
                </li>
                <li><span>Allow Stock To Be Modified</span> 
                    <span>
                        <select onChange={(e)=>{UpdateReq("GC_AllowStockToBeModified",  e.target.value === 'true');}} value={String(ConfigData.GC_AllowStockToBeModified)}>
                            <option value="true">Allow Stock Modify</option>
                            <option value="false">Block Stock Modify</option>
                        </select>
                    </span>
                </li>
                <li><span>Allow Stock To Be Purged</span> 
                    <span>
                        <select onChange={(e)=>{UpdateReq("GC_AllowStockToBePurged",  e.target.value === 'true');}} value={String(ConfigData.GC_AllowStockToBePurged)}>
                            <option value="true">Allow Stock Purge</option>
                            <option value="false">Block Stock Purge</option>
                        </select>
                    </span>
                </li>
                <li><span>Allow Stock To Be Unmounted</span> 
                    <span>
                        <select onChange={(e)=>{UpdateReq("GC_AllowStockToBeUnmounted",  e.target.value === 'true');}} value={String(ConfigData.GC_AllowStockToBeUnmounted)}>
                            <option value="true">Allow Stock Unmount</option>
                            <option value="false">Block Stock Unmount</option>
                        </select>
                    </span>
                </li>
                
            </div>  
        </div>
    </div>
    )
}