import classes from './OrderGroup.module.css'
import { faCheckDouble, faCircleStop, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {FetchTargetOrderGroupSubProducts, RejectOrderSubProduct} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'

import FilterEmployeeList from '../../../FilterEmployeeList/FilterEmployeeList'
import { useToast } from '../../../../../ToastContext';
import { useAuth } from '../../../../../AuthProvider';

import { useGNP } from '../../../../../GlobalNotificationProvider';
import OrderSubGroupDaily from '../OrderSubGroupDaily/OrderSubGroupDaily';
import OrderSubGroupSpecial from '../OrderSubGroupSpecial/OrderSubGroupSpecial';


export default function OrderGroup({Data, ChefsList}) 
{

    const auth = useAuth();
    const notify = useToast();
    const GNP = useGNP();

    const [GroupDataInfo, SetGroupDataInfo] = useState();
    const [SubProductList, SetSubProductList] = useState([]);
    const [OpenFilterMenu, SetOpenFilterMenu] = useState(false);
    const [RefreshView, SetRefreshView] = useState(false);

    const [ProcessBuffer, SetProcessBuffer] = useState(new Map());
    const [BatchOp, SetBatchOp] = useState(false);
    
    // This is where the chef menu will be apear
    const [FilterBoxPos, SetFilterBoxPos] = useState();

    // Daily Order
    const [SubProductsGroupedList, SetSubProductsGroupedList] = useState([]);

    // Special ORder
    const [SubProductListSpecial, SetSubProductListSpecial] = useState([]);


    const OpenFilterBox = async() =>
    {
        SetOpenFilterMenu(true);
    }
    const FetchProductList = async() =>
    {
        try 
        {
            const fd = new FormData();
            fd.append('order_package_id', Data[0].KCOI_ID);
            const main_res = await FetchTargetOrderGroupSubProducts(fd);

            if(main_res) 
            {
                SetSubProductsGroupedList(main_res.DAILY_ORDER);
                SetSubProductList(main_res.DAILY_ORDER);

                SetSubProductListSpecial(main_res.SPECIAL_ORDER);
            }

            
        }catch(err) 
        {

        }
    }
    const SetFilterBoxPositionManual = async(pos)=>
    {
        SetFilterBoxPos({
            x: pos.x,
            y: pos.y,
            });
    }
    const handleMouseMove = (event) => {
        if(OpenFilterMenu) 
        {
            return;
        }
        SetFilterBoxPos({
            x: event.clientX,
            y: event.clientY,
            });
    };
    const SingleDispatch = async(data, package__uid, _entry_id, _order_type, button_pos)=>
    {
        const _single = new Map();

        if(_order_type === "Daily")  
        {
            _single.set(data.DO_UUID, 
                {
                    DataPkg: data, 
                    Quantity: data.DO_OrderCount - data.DO_DispatchedCount,
                    PackageID: package__uid,
                    EntryID: _entry_id,
                    OrderType: _order_type
                });
        } else 
        {
            _single.set(data.SO_UUID, 
                {
                    DataPkg: data, 
                    Quantity: data.SO_OrderCount - data.SO_DispatchedCount,
                    PackageID: package__uid,
                    EntryID: _entry_id,
                    OrderType: _order_type
                });
        }
       
        SetProcessBuffer(_single);
        SetFilterBoxPositionManual(button_pos);
        OpenFilterBox();
    }
    const OminiDispatch = ()=>
    {
        const _omni = new Map();

        // Collect all the remaining daily product orders
        for(let i = 0; i < SubProductList.length; i++) 
        {
            for(let j = 0; j < SubProductList[i].DOMD_Payload.length; j++) 
            {
                if((SubProductList[i].DOMD_Payload[j].DO_RejectedCount + SubProductList[i].DOMD_Payload[j].DO_DispatchedCount) >= SubProductList[i].DOMD_Payload[j].DO_OrderCount) 
                {
                    continue;
                }
                _omni.set(
                    SubProductList[i].DOMD_Payload[j].DO_UUID, 
                    {
                        DataPkg: SubProductList[i].DOMD_Payload[j], 
                        Quantity: SubProductList[i].DOMD_Payload[j].DO_OrderCount - SubProductList[i].DOMD_Payload[j].DO_DispatchedCount,
                        PackageID: SubProductList[i].DOMD_PackageUUID,
                        EntryID: SubProductList[i].KCOI_ID,
                        OrderType: "Daily"
                    }
                );
            }
            
        }

        // Collect all the remaining special product orders
        for(let i = 0; i < SubProductListSpecial.length; i++) 
        {
            for(let j = 0; j < SubProductListSpecial[i].SOMD_Payload.length; j++) 
            {
                if((SubProductListSpecial[i].SOMD_Payload[j].SO_RejectedCount + SubProductListSpecial[i].SOMD_Payload[j].SO_DispatchedCount) >= SubProductListSpecial[i].SOMD_Payload[j].SO_OrderCount) 
                {
                    continue;
                }
                _omni.set(
                    SubProductListSpecial[i].SOMD_Payload[j].SO_UUID, 
                    {
                        DataPkg: SubProductListSpecial[i].SOMD_Payload[j], 
                        Quantity: SubProductListSpecial[i].SOMD_Payload[j].SO_OrderCount - SubProductListSpecial[i].SOMD_Payload[j].SO_DispatchedCount,
                        PackageID: SubProductListSpecial[i].SOMD_PackageUUID,
                        EntryID: SubProductListSpecial[i].KCOI_ID,
                        OrderType: "Special"
                    }
                );
            }
            
        }

        SetProcessBuffer(_omni);
        SetOpenFilterMenu(true);
    }
    const ToggelItemInOut = async(data, _package_data, _entry_id, _order_type)=>
    {
        if(!BatchOp) 
        {
            return;
        }

        const _exist = ProcessBuffer;
        if(_order_type === "Daily") 
        {
            if((data.DO_RejectedCount + data.DO_DispatchedCount) >= data.DO_OrderCount) 
            {
                notify.ShowError(`You can't select '${data.CKP_ItemName}'! It is closed, Please have some manner.`);
                return;
            }
    
            if(_exist.has(data.DO_UUID)) 
            {
                _exist.delete(data.DO_UUID);
            } else 
            {
                // I know this is Stupid but it serve a puprose letter
                _exist.set(
                        data.DO_UUID, 
                        {
                            DataPkg: data, 
                            Quantity: data.DO_OrderCount - data.DO_DispatchedCount,
                            PackageID: _package_data,
                            EntryID: _entry_id,
                            OrderType: _order_type
                        });
            }
        } else 
        {
            if((data.SO_RejectedCount + data.SO_DispatchedCount) >= data.SO_OrderCount) 
            {
                notify.ShowError(`You can't select '${data.CKP_ItemName}'! It is closed!`);
                return;
            }
    
            
            if(_exist.has(data.SO_UUID)) 
            {
                _exist.delete(data.SO_UUID);
            } else 
            {
                // I know this is Stupid but it serve a puprose letter
                _exist.set(
                        data.SO_UUID, 
                        {
                            DataPkg: data, 
                            Quantity: data.SO_OrderCount - data.SO_DispatchedCount,
                            PackageID: _package_data,
                            EntryID: _entry_id,
                            OrderType: _order_type
                        });
            }
        }

        

        SetProcessBuffer(_exist);
    }
    const CleanCloseMiniModal = async(__val)=>
    {
        SetBatchOp(false);
        SetOpenFilterMenu(false);
        SetProcessBuffer(new Map());
    }
    const IsMarked = (__key)=>
    {
        return ProcessBuffer.has(__key);
    }
    const HandleBatchOp = (__val)=>
    {
        SetBatchOp(__val);
        SetProcessBuffer(new Map());

        if(!__val)
        {
            CleanCloseMiniModal();
        }

    }

    useEffect(()=>
    {
        if(Data.length > 0) 
        {
            FetchProductList();
            SetIntialDatas();
        }
    }, [Data, RefreshView, GNP.EVENT_SpecialOrderArrival, GNP.EVENT_DailyOrderArrival])


    const SetIntialDatas = async() => { SetGroupDataInfo(Data[0]); }


    return (
        <div className={`${classes.order_group_main_wrapper}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.order_group_main_header_wrapper}`}>
                <h2>From #{GroupDataInfo?.BranchName}</h2>
            </div>

           {OpenFilterMenu && 
                    <FilterEmployeeList 
                        ActiveOrderGroup={Data} 
                        UpdateViewCallback={SetRefreshView} 
                        UpdateView={RefreshView} 
                        OrderSourceBranchID={Data.BranchID} 
                        ItemBuffer={ProcessBuffer} 
                        Chefs={ChefsList} 
                        CloseController={CleanCloseMiniModal} 
                        Mpos={FilterBoxPos}/>
            } 

            <div className={`${classes.group_body_wrapper}`}>

                {
                SubProductsGroupedList.map((item, key_idx)=>(
                        <div className={`${classes.data_inner_view}`} key={key_idx}>

                            <OrderSubGroupDaily 
                                SingleItemDispatch={SingleDispatch}
                                SelectItem={ToggelItemInOut} 
                                IsItemSelected={IsMarked} 
                                ItemGroupBase={item} 
                                UpdateView={SetRefreshView}
                            />

                        </div>  
                    ))
                }
                {
                    SubProductListSpecial.map((item, key_idx)=>[
                        <div className={`${classes.data_inner_view}`} key={key_idx}>

                            <OrderSubGroupSpecial 
                                SingleItemDispatch={SingleDispatch}
                                SelectItem={ToggelItemInOut} 
                                IsItemSelected={IsMarked} 
                                ItemGroupBase={item} 
                                UpdateView={SetRefreshView}
                            />

                        </div> 
                    ])
                }


            </div>

            <div className={`${classes.dispatch_group_footer}`}>
                    <div className={`${classes.dispatch_group_action_button_wrapper}`}>
                        <button onClick={()=>{HandleBatchOp(!BatchOp)}}><span><FontAwesomeIcon icon={BatchOp ? faCircleStop : faCopy}></FontAwesomeIcon></span> <span>{BatchOp ? "Void" : "Select"}</span></button>
                        {ProcessBuffer.size > 0 && BatchOp && <button onMouseMove={(e)=>{handleMouseMove(e)}} onClick={()=>{OpenFilterBox()}} style={{background: `var(--color-theme)`, borderColor: `var(--color-theme)`, color: `var(--primary_text_color)`}}><span><FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon></span> <span>Dispatch Marked</span></button>}
                    </div>

                    <div className={`${classes.right_side_dispatch_group_button_wrapper}`}>
                        <button onMouseMove={(e)=>{handleMouseMove(e);}} onClick={()=>{OminiDispatch()}} style={{background: `var(--color-theme)`, borderColor: `var(--color-theme)`, color: `var(--primary_text_color)`}}><span><FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon></span> <span>Dispatch All</span></button>
                    </div>
            </div>

        </div>
    )
}