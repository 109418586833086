import api from "../Util/axiosInstance";




export const CreateKVType = async(data)=>
{
    try  
    {
        const response = await api.post('api/kv/create-kv-type', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateKVType = async(data)=>
    {
        try  
        {
            const response = await api.post('api/kv/update-kv-type', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }
export const FetchKVType = async(data)=>
{
    try  
    {
        const response = await api.post('api/kv/fetch-kv-type', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeKVType = async(_id, token)=>
{
    try  
    {
        const response = await api.post('api/kv/purge-kv-type', {ID: _id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchKVTypeByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/kv/fetch-kv-type-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchKVIngByTypeID = async(data)=>
    {
        try  
        {
            const response = await api.post('api/kv/fetch-kv-ing-by-type-id', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }

