import { faCircle, faCircleDot, faDotCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classes from './StockView.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircleMiniGraph from '../../../../SharedComp/CircleMiniGraph/CircleMiniGraph'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useEffect, useRef, useState } from 'react'
import DotsGif from '../../../../SharedComp/CssGif/DotsGif'
import ApChartMini from '../../../../SharedComp/ApexChartMiniWrapper/ApChartMini'
import {ToEthiopianCalander, mapToObject, objectToMap} from './../../../../SharedComp/Utility'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

import InvoiceView from './Component/InvoiceView/InvoiceView'

import {FetchInventoryStockID, FetchInventoryInvoice, FetchPOLinkedRawMatID} from '../../../../api'
import { getBaseSocket } from '../../../../SocketBase'
import { SingularItemInvoiceData } from '../../../../SharedComp/API/InventoryStock/API_FE_InventoryStock'
import UIButton from '../../../Components/UIButton/UIButton'
import { ArrowCircleDownRight } from '@phosphor-icons/react'
import EmptyProxyDataView from '../../../../SharedComp/DataViewTableProxy/EmptyDataViewTable'

const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
      return str.substring(0, maxLength) + '.....';
  }
  return str;
}
function clampPercentage(value, max) {
  // Assuming 100 as 15%, and linearly interpolate between min and max
  const percentage = (value / max) * 100;

  // Clamp the percentage between 0 and 100
  const clampedPercentage = Math.max(0, Math.min(100, percentage));

  return clampedPercentage;
}
function GetRandomValue()
{
   return Math.floor(Math.random() * (98 - 90 + 1) + 90)
}

export default function StockView({OpenController, TargetID, DataItemID}) 
{
  
  const socket = getBaseSocket();
  const messagesEndRef = useRef(null);
  const auth = useAuth();
  const notify = useToast();

  const [OpenInvoiceView, SetOpenInvoiceView] = useState(false);

  const [Loading, SetLoading] = useState(true);
  const [TargetData, SetTargetData] = useState(null);
  const [DataReady, SetDataReady] = useState(false);
  const [InvoiceList, SetInvoiceList] = useState([]);
  const [AnimateAmountAction, SetAnimateAmountAction] = useState(false);
  const [BranchSpecData, SetBranchSpecData] = useState();
  const [PendingAmount, SetPendingAmount] = useState(0);

  const [ShowMore, SetShowMore] = useState(false);
  const [InvoiceTargetPackage, SetInvoiceTargetPackage] = useState();


  const [InvoiceBaseData, SetInvoiceBaseData] = useState();
  const [InvoiceDataArray, SetInvoiceDataArray] = useState([]);

  // Invoide Data
  const [InvoicePurchase, SetInvoicePurchase] = useState([]);
  const [InvoiceRTO, SetInvoiceRTO] = useState([]);
  const [InvoiceTransfer, SetInvoiceTransfer] = useState([]);

  // Active Preview
  const [InvoiceStream, SetInvoiceStream] = useState([]);
  const [ActiveInvoiceViewType, SetActiveInvoiceViewType] = useState('Purchase');

 

      const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      };


     function ComputePendingStockAmount(data) 
     {
       var amount = 0;
       for(let  i = 0; i < data.length; i++) 
       {
          if(data[i].IsPending) 
          {
            amount += parseInt(data[i].Quantity);
          }
        }
        SetPendingAmount(amount);
     }
     const FetchInvoice = async () => {
        SetLoading(true);
        try 
        {
          const fd = new FormData();
          fd.append("BranchID", auth.activeWorkShop);
          fd.append("StoreID",  TargetData.StoreID);
          fd.append("ItemID", TargetData.ItemID);
          const res = await SingularItemInvoiceData(fd);

          SetInvoiceList(res); 

          // Here we need to filter and add not just add

          const _temp_rto_buffer = [];
          const _temp_purchase_buffer = [];
          const _temp_transfer_buffer = [];
          
          for(let i = 0; i < res.length; i++) 
          {
            if(res[i].Buffer_RTO.length > 0) 
            {
              _temp_rto_buffer.push(res[i]);
            }
            if(res[i].Buffer_Purchase.length > 0)
            {
              _temp_purchase_buffer.push(res[i]);
            }
            if(res[i].Buffer_Transfer.length > 0)
            {
              _temp_transfer_buffer.push(res[i]);
            }
          }

          SetInvoicePurchase(_temp_purchase_buffer);
          SetInvoiceRTO(_temp_rto_buffer);
          SetInvoiceTransfer(_temp_transfer_buffer);

          // By default we gone show the purchase entry
          SetInvoiceStream(_temp_purchase_buffer);


          SetLoading(false);
        }catch(err) 
        {
          notify.ShowError("Failed to Fetch Target Stock Invoice! Try Reloding");
          SetLoading(false);
        }
     }
     const FetchComputePendingAmount = async()=> 
     {
        try 
        {
          // TO-DO here we query all linked production order and show all 
          // request amount here/ it is not really nessary but is is kind of cool

          // And another thing: function FetchPOLinkedRawMatID is fucked!
            const res = [];
            ComputePendingStockAmount(res);
        }catch(err) 
        {
          notify.ShowError("Failed to Fetch Any Linked Production Order! Try Realoding");
        }
     }
     const FetchTargetStockDetail = async()=> 
      {
           try 
           {
             const res = await FetchInventoryStockID(TargetID, auth.token);
             SetTargetData(res[0]);
           }catch(err) 
           {
             notify.ShowError("Failed to Fetch Target Stock Data! Try Realoding");
           }
     }

     function HandleOpneInvoiceView(data_package) 
     {
        SetOpenInvoiceView(true);
        SetInvoiceBaseData(data_package.BaseObject);

        if(ActiveInvoiceViewType === "Purchase") 
        {
          SetInvoiceDataArray(data_package.Buffer_Purchase);
        } else if(ActiveInvoiceViewType === "RTO") 
        {
          SetInvoiceDataArray(data_package.Buffer_RTO);
        } else  
        {
          SetInvoiceDataArray(data_package.Buffer_Transfer);
        }
     }


     useEffect(()=>{
        if(TargetData) 
        {
          const p_res = objectToMap(JSON.parse(TargetData.IVItemWorkspaceInfo)).get(auth.activeWorkShop);
          if(!(auth.GlobalConfig && auth.GlobalConfig?.GC_SuppressStockIssueNotification))  
          {
            if(!p_res.get("MinBalance") || !p_res.get("MaxBalance")) 
            {
              notify.ShowWarn(`No (MINIMUM) and (MAXIMUM) amount is configured for item '${TargetData?.IVItemName}', This will affect the aquirecy measment and the system will not abel to send any critical notification about this inventory item!`)
            }
          }
          
          SetBranchSpecData(p_res);
          FetchInvoice();
          SetDataReady(true);
        }
     }, [TargetData])

     function HandleSocktUpdate() 
     {
        FetchTargetStockDetail(); 
        FetchComputePendingAmount();

        SetAnimateAmountAction(true);
        SetAnimateAmountAction(false);
     }

     useEffect(()=>{

        HandleSocktUpdate();

        socket.on("HANDLE_PO_PROCESS", HandleSocktUpdate);

        return () => {
            socket.off("HANDLE_PO_PROCESS", HandleSocktUpdate);
        }

     }, [socket])

     useEffect(()=>{
      scrollToBottom();
     }, [scrollToBottom])



    return (
      <div className={`${data_win.main_data_creator_wrapper}`}>

          {OpenInvoiceView && <InvoiceView DataBase={InvoiceBaseData} DataArray={InvoiceDataArray} OpenModal={SetOpenInvoiceView}/>}
            <div className={`${classes.stock_data_wrapper_frame}`}>

                <div className={`${classes.stock_data_wrapper_header}`}>
                    <li>Stock Detail  <span className={`${classes.stock_name}`}>({TargetData?.IVItemName})</span></li>
                    <button  onClick={()=>{OpenController(false)}} type='button'><FontAwesomeIcon icon={faTimesCircle} /></button>
                </div>

                {DataReady ? <div className={`${classes.stock_detail_body}`}>

                    <div className={`${classes.stock_db_detail}`}>

                        <div className={`${classes.stock_db_detail_left}`}>
                            <div className={`${classes.stock_db_detail_right_bot_detail_header}`}>
                                  <h2>Incoming Invoices</h2>
                                  <div className={`${classes.right_side_data_wrapp}`}>
                                    <UIButton Variant={ActiveInvoiceViewType === 'Purchase' ? 'Primary' : 'Secondary'} Icon={<ArrowCircleDownRight weight='bold'/>} label={"Purchase"} onClick={()=>{SetInvoiceStream(InvoicePurchase); SetActiveInvoiceViewType('Purchase')}} />
                                    <UIButton Variant={ActiveInvoiceViewType === 'RTO' ? 'Primary' : 'Secondary'} Icon={<ArrowCircleDownRight weight='bold'/>} label={"RTO"} onClick={()=>{SetInvoiceStream(InvoiceRTO);SetActiveInvoiceViewType('RTO')}} />
                                    <UIButton Variant={ActiveInvoiceViewType === 'Transfer' ? 'Primary' : 'Secondary'} Icon={<ArrowCircleDownRight weight='bold'/>} label={"Transfer"} onClick={()=>{SetInvoiceStream(InvoiceTransfer);SetActiveInvoiceViewType('Transfer')}} />
                                  </div>
                              </div>
                                
                              <div className={`${classes.stock_db_detail_right_bot_detail_body_wrapper}`}>
                                {PendingAmount > 0  && <div className={`${classes.pending_stock_out_wapper} ${AnimateAmountAction ? classes.animate_action_stock : ''}`}>
                                        <p> <span className={`${classes.pending_stock_out_indicator}`}><FontAwesomeIcon icon={faCircle} /></span> Waiting Action <span className={`${classes.pending_stock_out_amount}`}>{PendingAmount} {TargetData.UomName}</span></p>
                                </div>}

                                {
                                  InvoiceStream.map((item, idx)=>(
                                      <li key={idx} onClick={()=>{
                                        if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PrintStockEntryInvoice) 
                                        {
                                          HandleOpneInvoiceView(item)
                                        }
                                        }}>
                                        <span>
                                          <span className={`${classes.list_pointer}`}>
                                            <FontAwesomeIcon icon={faCircleDot} />
                                            </span> <span>Invoice - {ToEthiopianCalander(new Date(item.BaseObject.ISIL_Date))}</span>
                                        </span>
                                      </li>
                                  ))
                                }
                                {
                                  InvoiceStream.length <= 0 && 
                                  <EmptyProxyDataView text={`No Incoming ${ActiveInvoiceViewType}`}/>
                                }
                              </div>

                        </div>

                        <div className={`${classes.stock_db_detail_right}`}>
                            <div className={`${classes.stock_circle_Chart_wrapper}`}>
                                <CircleMiniGraph 
                                    Color={TargetData.AvailQuantity < BranchSpecData.get("MinBalance") ? 'var(--red-color)' :'var(--main-color)'} 
                                    rad={1.3} 
                                    value={TargetData.AvailQuantity.toLocaleString('en-US')} 
                                    Text={TargetData.UomDescription}
                                    Percentage={(clampPercentage(TargetData.AvailQuantity, BranchSpecData.get("MaxBalance"))).toFixed(2)} 
                                />
                            </div>
                            <div  className={`${classes.stock_db_detail_right_bot_detail}`}>
                                    <li>
                                        <span>
                                          <span className={`${classes.list_pointer}`}>
                                              <FontAwesomeIcon icon={faCircleDot} />
                                          </span>
                                          <span>
                                              Min Balance 
                                          </span>
                                        </span>
                                        <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>
                                        
                                          {(BranchSpecData.get("MinBalance") ? BranchSpecData.get("MinBalance") : 0).toLocaleString('en-US')}<span className={`${classes.stock_uom}`}>{ TargetData.UomName}

                                          </span>
                                        </span>
                                    </li>
                                    <li> <span><span className={`${classes.list_pointer}`}><FontAwesomeIcon icon={faCircleDot} /></span> <span>Max Balance</span></span> <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>
                                        
                                        {(BranchSpecData.get("MaxBalance") ? BranchSpecData.get("MaxBalance") : 0).toLocaleString('en-US')} <span className={`${classes.stock_uom}`}>{TargetData.UomName}</span>
                                        </span></li>
                                    <li><span><span className={`${classes.list_pointer}`}><FontAwesomeIcon icon={faCircleDot} /></span><span>Edible State</span></span> <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>{TargetData.IVItemTypeEdibleState ? "Ture" : "False"}</span></li>
                                    <li><span><span className={`${classes.list_pointer}`}><FontAwesomeIcon icon={faCircleDot} /></span><span>Wherehouse</span></span> <span className={`${classes.stock_db_detail_right_bot_detail_value}`}>{TargetData.WHName}</span></li>
                            </div>
                        </div>


                    </div>

                    {/* <div className={`${classes.more_button_wrapper}`}>
                      <button onClick={(e)=>{SetShowMore(!ShowMore);}} type='button'>Show {ShowMore ? 'Less' : 'More'}</button>
                    </div> */}
                    

                    {ShowMore && <div className={`${classes.stock_chart_wrapper}`}>


                       {/* <div className={`${classes.chart_whole_wrapper}`}>
                          <div className={`${classes.chart_head}`}>
                              <li>Stock Movment</li>
                          </div>

                          <ApChartMini 
                              Type={"area"} 
                              Data={TestChartDataA} 
                              Height={200} 
                              Tag={"Used kilogram"}/>
                        </div> */}

                        {/* <div className={`${classes.chart_whole_wrapper}`}>
                          <div className={`${classes.chart_head}`}>
                              <li>Price fluctuation</li>
                          </div>
                          

                            <ApChartMini 
                              Type={"bar"} 
                              Data={TestChartDataB} 
                              Height={200} 
                              Tag={"Price Per-KG"}/>
                        </div> */}

                     
                      {/* <div ref={messagesEndRef} /> */}
                    </div>}

                </div>  : 

                <div className={`${classes.asset_loading_placeholder}`}>
                  <DotsGif/>
                </div> }

            </div>

        </div>
    )
}