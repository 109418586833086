import api from "../Util/axiosInstance";




export const CreateUserLoginRequest = async(data)=>
{
    try   
    {
        const response = await api.post('auth/login', data);
        const res = await response.data;

        if(res.LoginState !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const CreateSignOutRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/user-account/sign_out', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const AuthClient = async()=>
{
    try 
    {
        const response = await api.post('api/user-account/auth-client');
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}