import classes from './ReceiptOfFunds.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react'
import { Tooltip } from 'react-tooltip'
import axios from 'axios';
import { useAuth } from '../../../../../AuthProvider'
import { AllocateNewPettyCashBudgetForBranch } from '../../../../../SharedComp/API/PettyCash/API_FE_PettyCash'
import { useToast } from '../../../../../ToastContext'

export default function ReceiptOfFunds({Close_CALLBACK, branchID, RefershCallbackVal}) 
{

    const [Registring, SetRegistring] = useState(false);
    const formRef = useRef();
    const auth = useAuth();
    const notify = useToast();
    const [NewBudget, SetNewBudget] = useState(null);


    const  HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        SetRegistring(true);
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("NewBudget", NewBudget);
            fd.append("branch_id", branchID);
            fd.append("user_id", auth.user.USR_UserAccountID);
     
            const main_res = await AllocateNewPettyCashBudgetForBranch(fd); 
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            RefershCallbackVal(new Date().getMilliseconds());
            SetRegistring(false);
            Close_CALLBACK(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(`Failed to Allocate Budget: ${err.message}`, 10);
            SetRegistring(false);
        }


       
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`} style={{width: '50%' }} >
        <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Budget Alloaction</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_body}`}>

                    <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                            <div className={`${classes.form_wrapper}`}> 
                                    <div  className={`${classes.input_list_wrapper}`}>
                                        
                    
                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input min={0} step="any" onChange={(e)=>{SetNewBudget(e.target.value)}} name='deposit' type="number" placeholder='Deposit Amount' title='' autoComplete='off' required />} />
                                        </div>  

                                    </div>
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button 
                                {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "Please note: Upon allocation of a new budget, the system performs an automatic audit of the prior budget and transmits it to the finance department."})}
                                disabled={Registring} 
                                className={`${data_win.form_action_button_save}`}>
                                    {Registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Allocate'}
                                    </button>
                                
                                
                                <button disabled={Registring}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>

            </div>

        </div>

    </div>
    )
}