import { useEffect, useState } from 'react';
import classes from './StockTransactionDetailWrapper.module.css'
import { useAuth } from '../../../../../AuthProvider';
import { useToast } from '../../../../../ToastContext';
import { ArrowsDownUp, BoxArrowDown, BoxArrowUp, Gear } from '@phosphor-icons/react';
import { AISmartSummerizeStockTransaction, QueryInventoryTransactionByDateExpanded } from '../../../../../SharedComp/API/InventoryStock/API_FE_InventoryStock';
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility';
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import UIButton from '../../../../Components/UIButton/UIButton';
import PreviewSmartTransactionSummery from '../PreviewSmartTransactionSummery/PreviewSmartTransactionSummery';
const human_readable_format = (val)=> 
{
    if(val === undefined || val === null)
    {
        // console.log(val);
        return 0;
    }
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function StockTransactionDetailWrapper({FilterMethod, DataRefresh}) 
{
    const [UpdateView, SetUpdateView] = useState(false);
    const [Loading, SetLoading] = useState(false);
    const [DataPack, SetDataPack] = useState();
    const [MainDataStream, SetMainDataStream] = useState([]);

    const [OpenSmartPreview, SetOpenSmartPreview] = useState(false);
    const [SmartPreviewResponse, SetSmartPreviewResponse] = useState('');

    const notify = useToast();
    const auth = useAuth();
    const [AtDayOf, SetAtDayOf] = useState(new Date());

    const AISmartSummerize = async()=>
    {
        notify.ShowLoad("Thinking...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("worksapce_id", auth.activeWorkShop);
            fd.append("target_date", AtDayOf);
            const main_res = await AISmartSummerizeStockTransaction(fd);
            SetSmartPreviewResponse(main_res.Msg);
            SetOpenSmartPreview(true);
            notify.ShowSuccessUpdate("Preparing for view...", 10);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    const FetchTransactionDaily = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("worksapce_id", auth.activeWorkShop);
            fd.append("target_date", AtDayOf);
            const main_res = await QueryInventoryTransactionByDateExpanded(fd);
            SetDataPack(main_res);
            GroupDataListsInit(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        } 
    }

    const GroupDataLists = ()=>
    {
        const _valid_list = [];
        for(let i = 0; i < DataPack.InputLog.length; i++) 
        {
            _valid_list.push(DataPack.InputLog[i]);
        }
        for(let i = 0; i < DataPack.OutputLog.length; i++) 
        {
            _valid_list.push(DataPack.OutputLog[i]);
        }
        SetMainDataStream(_valid_list);
    }
    const GroupDataListsInit = (data_pack)=>
    {
        const _valid_list = [];
        for(let i = 0; i < data_pack.InputLog.length; i++) 
        {
            _valid_list.push(data_pack.InputLog[i]);
        }
        for(let i = 0; i < data_pack.OutputLog.length; i++) 
        {
            _valid_list.push(data_pack.OutputLog[i]);
        }
        SetMainDataStream(_valid_list);
    }
    const SummerizeItems = ()=>
    {
        const _valid_list_input = new Map();
        const _valud_list_out = new Map();
        for(let i = 0; i < DataPack.InputLog.length; i++) 
        {
            if(_valid_list_input.has(DataPack.InputLog[i].IVItemName)) 
            {
                let _current = _valid_list_input.get(DataPack.InputLog[i].IVItemName);
                _current.INVOICELOG_Quantity += DataPack.InputLog[i].INVOICELOG_Quantity;
                _valid_list_input.set(DataPack.InputLog[i].IVItemName, _current);
            } else 
            {
                _valid_list_input.set(DataPack.InputLog[i].IVItemName, DataPack.InputLog[i]);
            }
        }
        for(let i = 0; i < DataPack.OutputLog.length; i++) 
        {
            if(_valud_list_out.has(DataPack.OutputLog[i].IVItemName)) 
            {
                let _current = _valud_list_out.get(DataPack.OutputLog[i].IVItemName);
                _current.INVOICELOG_Quantity += parseFloat(DataPack.OutputLog[i].INVOICELOG_Quantity);
                _valud_list_out.set(DataPack.OutputLog[i].IVItemName, _current);
            } else 
            {
                _valud_list_out.set(DataPack.OutputLog[i].IVItemName, DataPack.OutputLog[i]);
            }
        }

        // Merge
        const _valid_IN = Array.from(_valid_list_input.values());
        const _valud_OUT = Array.from(_valud_list_out.values());
        SetMainDataStream([..._valid_IN, ..._valud_OUT]);
    }
    const SortByDate = () =>
    {
        const _valid_list = [];
        for(let i = 0; i < DataPack.InputLog.length; i++) 
        {
            _valid_list.push(DataPack.InputLog[i]);
        }
        for(let i = 0; i < DataPack.OutputLog.length; i++) 
        {
            _valid_list.push(DataPack.OutputLog[i]);
        }
        _valid_list.sort((a, b)=> new Date(b.INVOICELOG_DateOfEntry) - new Date(a.INVOICELOG_DateOfEntry))


        SetMainDataStream(_valid_list);
    }

    useEffect(()=>{
        switch(FilterMethod) 
        {
            case "GIO": GroupDataLists(); return;
            case "SUM": SummerizeItems(); return;
            case "LATEST": SortByDate(); return;
            case "AISUM": AISmartSummerize(); return;
        }
    }, [FilterMethod])
    
     
    
    useEffect(()=>{
        FetchTransactionDaily();
    }, [auth.activeWorkShop, UpdateView, AtDayOf, DataRefresh]);

    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>
            {OpenSmartPreview && <PreviewSmartTransactionSummery OpenController={SetOpenSmartPreview} ResponseData={SmartPreviewResponse}/>}

            <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{SetAtDayOf(e.target.value)}} autoComplete='off' type="date" /> 
                        <span>{ToEthiopianCalander(new Date(AtDayOf))}</span>
                    </div>
            </div>
            
            <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.active_order_main_list_wrapper}`}>
                    <li className={`${classes.header_list}`}>
                        <span>Entry Item</span>
                        <span>Date</span>
                        <span>Action Taken By</span>
                        <span>Amount</span>
                        <span>Method</span>
                        <span>Action Type</span>
                    </li>

                    <div className={`${classes.list_data_wrapper}`}>
                        {!Loading &&
                            MainDataStream.map((item, idx)=>(
                                <li key={idx}>
                                    <span>{item.IVItemName}</span>
                                    <span>{ToEthiopianCalander(new Date(item.INVOICELOG_DateOfEntry))} @ {new Date(item.INVOICELOG_DateOfEntry).toLocaleTimeString()}</span>
                                    <span>{item.USR_FirstName} {item.USR_LastName}</span>
                                    <span>{human_readable_format(item.INVOICELOG_Quantity)} {item.UomDescription}</span>
                                    <span className={`${classes.in_transaction_log}`}>
                                         {FilterMethod === "SUM" ? '-' : <UIButton Icon={<Gear weight='bold'/>} Variant='Secondary' label={item.INVOICELOG_Method} onClick={()=>{}}/>}   
                                    </span>
                                    <span className={`${classes.in_transaction_log}`}>
                                            <UIButton 
                                                onClick={()=>{}} 
                                                Icon={item.INVOICELOG_Flag === "Input" ? <BoxArrowDown weight='fill'/> : <BoxArrowUp weight='fill'/>} 
                                                Variant={item.INVOICELOG_Flag === "Input" ? "Primary" : "Danger"} 
                                                label={item.INVOICELOG_Flag === "Input" ? "Incoming Inventory" : "Outgoing Inventory"}/>
                                    </span>
                                </li>
                            ))
                        }

                        { !Loading &&
                            MainDataStream.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                <ul><ArrowsDownUp fontSize={30}/></ul>
                                <ul>No TRANSACTION RECORD</ul>
                            </div>
                        }
                        {
                            Loading && 
                            <LoadingPlaceholder/>
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}