import classes from './PittyCashAuditView.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../../../AuthProvider'
import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef, useState } from 'react'
import DotsGif from '../../../../../../SharedComp/CssGif/DotsGif'
import { getBaseURL } from '../../../../../../SharedComp/BaseUrlConfig'

import { ApprovePettyCashLogReport, QueryPettyCashLogWithApprovedByID, QueryPettyCashTransactionDetailByID } from '../../../../../../SharedComp/API/PettyCash/API_FE_PettyCash'

import PaperHeader from '../../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../../SharedComp/PaperHash/PaperHash'
import LoadingPlaceholder from '../../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { ToEthiopianCalander } from '../../../../../../SharedComp/Utility'
import { useDAControl } from '../../../../../../DAControlContext'
import { useToast } from '../../../../../../ToastContext'

const CurrencyFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ETB',
    });
  
    return formatter.format(value);
  };



const PittyCashAuditView = ({Item, LocalStateBranch, Close_CALLBACK}) => {

    const DAControl = useDAControl();
    const auth = useAuth();
    const notify = useToast();
    const [ReportApprovedBy, SetReportApprovedBy] = useState(null);
    const [Preview, SetPreview] = useState(false);

    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: LocalStateBranch.BranchName + "_CashLog_" + Item.PCMM_AllocationID + "_" + new Date().toDateString(),
        content: () => print_ref.current,
    })


    const [ShowMoreDetail, SetShowMoreDetail] = useState(false);
    const [expenseBook, setExpeseBook] = useState([]);
    const [Loading, setLoading] = useState(true);


    const handleApproval = async () => {

        notify.ShowLoad("Processing your request...", 10);
        try{
            const fd = new FormData();
            fd.append("transaction_id", Item.PCMM_ID);
            fd.append("operator_id", auth.user.USR_UserAccountID);
            const main_res = await ApprovePettyCashLogReport(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            Close_CALLBACK(false);
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    const QueryPettyCashTransactionDetail = async()=>
    {
        setLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", Item.PCMM_ID);
            const main_res = await QueryPettyCashTransactionDetailByID(fd);
            setExpeseBook(main_res.Transaction);
            setLoading(false);
        }catch(err) 
        {
            setLoading(false);
        }
    }
    const QueryAuditHandler = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("transaction_id", Item.PCMM_ID);
            const main_res = await QueryPettyCashLogWithApprovedByID(fd);
            if(main_res.length > 0)
            {
                SetReportApprovedBy(main_res[0]);
            }
        }catch(err) 
        {
            console.log("Failed to query Audit Handler");
        }
    }
    const GetDateDiff = (date_a, date_b)=>
    {
        const diffInMs = Math.abs(date_b - date_a);

        const diffInHours = diffInMs / (1000 * 60 * 60);
        return (diffInHours / 24).toFixed(0);
    }

    useEffect(()=>{
        if(Item) 
        {
            SetPreview(Item.PCMM_IsAudited === "Yes");
            QueryPettyCashTransactionDetail();

            if(Item.PCMM_IsAudited === "Yes")
            {
                QueryAuditHandler();
            }
        }
        

    }, [Item])

    


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Cash Log</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

                {!Loading && <div className={`${classes.data_preview_wrapper}`}>

                    <div className={`${classes.inner_preview_data_wrapper}`}>
                        <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                            <PaperHeader/>
                            
                            <div className={`${classes.print_doc_non_repeat_content}`}>

                                <PaperHash/>
                                <div className={`${classes.meta_data_wrapper}`}>
                                    <h2>[#] {auth.activeWorkShopName} </h2>
                                    <h2>[#] Petty Cash Utilization Summery</h2>
                                </div>

                                <div className={`${classes.transcation_owner_wrapper}`}>
                                    <div className={`${classes.transcation_owner}`}>

                                        <div className={`${classes.transcation_init}`}>
                                            <h2>Cash Initial</h2>
                                            <li><span>Initial Entry</span> <span style={{fontWeight: 'bold'}}>{CurrencyFormatter(Item?.PCMM_InitialAllocationAmount)}</span></li>
                                            <li><span>Transferred In</span>  <span style={{fontWeight: 'bold'}}>{CurrencyFormatter(Item?.PCMM_TransferredIn)}</span></li>
                                            <hr />
                                            <li><span>Total Starting</span> <span style={{fontWeight: 'bold'}}>{CurrencyFormatter(Item?.PCMM_InitialAllocationAmount + Item?.PCMM_TransferredIn)}</span></li>
                                            <li><span>Transferred Out / Remaining Balance</span> <span style={{fontWeight: 'bold'}}>{CurrencyFormatter(Item?.PCMM_TransferredOut)}</span></li>
                                        </div>
                                        <div className={`${classes.transcation_init}`}>
                                            <h2>Utilization Time Frame</h2>
                                            <li><span>Allocated Date</span> <span style={{fontWeight: 'bold'}}>{ToEthiopianCalander(new Date(Item.PCMM_InitialAllocationDateTime))}</span></li>
                                            <li><span>Closed Date</span> <span style={{fontWeight: 'bold'}}>{ToEthiopianCalander(!Preview ? new Date() : new Date(Item.PCMM_LogClosedDateTime))}</span></li>
                                            <li><span>Total Duration </span> <span style={{fontWeight: 'bold'}}>{!Preview ? GetDateDiff(new Date(Item.PCMM_InitialAllocationDateTime), new Date(Item.PCMM_LogClosedDateTime))  : GetDateDiff(new Date(Item.PCMM_InitialAllocationDateTime), new Date())} (Days)</span></li>
                                        </div>

                                    </div>
                                </div>
                                <div className={`${classes.entry_detail}`}>
                                    <li><span className={`${classes.entry_deatil_tag}`}>Cash Log ID</span> <span className={`${classes.entry_deatil_val}`}>{Item?.PCMM_AllocationID}</span></li>
                                </div>  
                                
                                
                                <div className={`${classes.main_paper_data_wrapper}`}>

                                    <div className={`${classes.paper_list_wrapper}`}>

                                    <div className={`${classes.single_order_data_view_master_wrapper}`}>
                                            <div className={`${classes.packge_data_wrapper}`}>

                                                <div className={`${classes.main_prod_info_wrapper}`}>
                                                    <li><span className={`${classes.prod_name_PO}`}>Petty Cash Utilization Summery</span></li>
                                                </div>

                                                <div className={`${classes.ing_list_data_wrapper}`}>
                                                    <div className={`${classes.data_marging_wrapper}`}>
                                                        <li>
                                                            <span>Date {ShowMoreDetail && '/ TIN'}</span>
                                                            <span>Issued By {ShowMoreDetail && '/ Recepit'}</span>
                                                            <span>Expense Amount {ShowMoreDetail && '/ MRC'}</span>
                                                            <span>Balance {ShowMoreDetail && '/ Reason'}</span>
                                                        </li>
                                                        {
                                                            expenseBook.map((item, idx)=>(
                                                                <div className={`${classes.single_transaction_wrapper}`}>
                                                                    <li key={item.IVItemName + idx}>
                                                                        <span>{ToEthiopianCalander(new Date(item.PTYCSH_DateTime))} @ {new Date(item.PTYCSH_DateTime).toLocaleTimeString()}</span>
                                                                        <span>{item.USR_FirstName} {item.USR_LastName}</span>
                                                                        <span>-({CurrencyFormatter(item.PTYCSH_Amount)})</span>
                                                                        <span>{CurrencyFormatter(item.PTYCSH_Balance)}</span>
                                                                    </li>
                                                                 {ShowMoreDetail && <li className={`${classes.additional_info_legal}`} key={item.IVItemName + idx}>
                                                                        <span>TIN:  {item.PTYCSH_TIN}</span>
                                                                        <span>RECEPIT: {item.PTYCSH_Receipt}</span>
                                                                        <span>MRC: {item.PTYCSH_MRC}</span>
                                                                        <span>REASON: {item.PTYCSH_Reason}</span>
                                                                    </li>}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>

                                <div className={`${classes.special_footer_local_wrapper}`}>

                                    <div className={`${classes.footer_user_wrap}`}>
                                        <h2>Audited By</h2>
                                        {Preview ? <li className={`${classes.name_manual}`}>{ReportApprovedBy?.USR_FirstName} {ReportApprovedBy?.USR_LastName}</li> : <li className={`${classes.name_manual}`}>{auth.user?.USR_FirstName} {auth.user?.USR_LastName}</li>}
                                        {Preview && ReportApprovedBy && <li className={`${classes.sign_manual}`}>{ToEthiopianCalander(new Date(ReportApprovedBy.PCMM_LogClosedDateTime))} @ {new Date(ReportApprovedBy.PCMM_LogClosedDateTime).toLocaleTimeString()}</li>}    
                                        <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                    </div>

                                </div>

                                {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                                <div className={`${classes.final_note_po_stock_out}`}>
                                    <li>This document must have all signatures to be considered valid.</li>
                                </div>

                            </div>
                        </div>
                    </div>



                    </div>}
                    {
                        Loading &&
                        <LoadingPlaceholder preview_text='Preparing Document'/>
                    }


                        <div className={`${classes.action_button_wrapper}`}>

                        {Preview &&    <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>}
                        {!Preview && !Loading && <button onClick={()=>{
                                            DAControl.Trigger(`Approve Petty Cash Utilization Report`, 
                                            `You are about to approve petty cash utilization report, meaning you loook through the transaction and found no incoherent data entry.`,
                                            true,
                                            5, (val)=>{
                                                if(val !== null) 
                                                {
                                                    handleApproval();
                                                }
                                            }   
                                        );}} className={`${classes.button_approve}`}>Approve</button> }
                        <button onClick={(e)=>{SetShowMoreDetail(!ShowMoreDetail)}} className={`${classes.button_print}`}>{!ShowMoreDetail ? 'Include Detail' : 'Exclude Detail'}</button>
                        <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                        </div>

            </div>

            

        </div>
    )
}


export default PittyCashAuditView;