import classes from './DataPackageTransferColumnPreview.module.css'
import UIButton from '../../../Components/UIButton/UIButton'
import { ToEthiopianCalander } from '../../../../SharedComp/Utility';
import { ArrowDownRight, FilePdf, Scan } from '@phosphor-icons/react';
import TimeAgo from '../../../../SharedComp/TimeElapsed/TimeAgo';
import { useEffect, useRef, useState } from 'react';


export default function DataPackageTransferColumnPreview({TargetItem, SelectedDataEntry_Callback, QRScan_Callback, inspect_incoming, OpenPreview_Callback, OnFilterKeyInput_Callback}) 
{

    const [ReceiveRequestKey, SetReceiveRequestKey] = useState(false);
    const KeyReceiverRef = useRef();

    useEffect(()=>
    {
        const handleClickOutside = (event) => {
            if (KeyReceiverRef.current && !KeyReceiverRef.current.contains(event.target)) {
                SetReceiveRequestKey(false);
            }
          };
      
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [])

    return (
        <div className={`${classes.product_trasnfer_single_entry_preview_master_wrapper}`}>
            <li>
                <span>{TargetItem.BranchName}</span>
                <span>{TargetItem.USR_FirstName} {TargetItem.USR_LastName}</span>
                <span>{<TimeAgo show_suffix={true} date={new Date(TargetItem.TWFP_RequestDateTIme)}/>}</span>
                <span>{ToEthiopianCalander(new Date(TargetItem.TWFP_TargetArrivalDate))}</span>
                <span>
                    { 
                        TargetItem.TWFP_RequestState === "Completed" ? 
                        <TimeAgo show_suffix={true} date={new Date(TargetItem.TWFP_RequestHandelDateTime)}/> : '--'
                    }
                </span>
                <span>
                    <span className={`${
                        TargetItem.TWFP_RequestState === "Pending" ? 
                        classes.pending_status_View : 
                        TargetItem.TWFP_RequestState === "Completed" || TargetItem.TWFP_RequestState === "Received"  ?  classes.completed_status_View
                        : classes.reject_status_View
                        }`}>
                        {TargetItem.TWFP_RequestState}
                    </span>
                </span>
            
                <span className={`${classes.center_content_button}`}>
                    {
                        TargetItem.TWFP_RequestState === "Completed" || TargetItem.TWFP_RequestState === "Received"?
                        inspect_incoming ? <UIButton Icon={<FilePdf/>} onClick={()=>{OpenPreview_Callback(TargetItem)}} label={"Document Preview"}/> 
                        :
                        <>
                        {
                        TargetItem.TWFP_RequestState !== "Received" &&
                        <UIButton Icon={<ArrowDownRight/>} onClick={()=>{SelectedDataEntry_Callback(TargetItem); SetReceiveRequestKey(true)}} label={"Receive Product"}/>
                        }
                        {
                            ReceiveRequestKey && 
                            <div ref={KeyReceiverRef} className={`${classes.aquaire_request_key}`}>
                                <UIButton Icon={<Scan weight='bold'/>} label={"Scan QR"} onClick={()=>{QRScan_Callback(true);SetReceiveRequestKey(false);}}/>
                                <input onChange={(e)=>{OnFilterKeyInput_Callback(e.target.value, TargetItem)}}  type="text" placeholder='Enter Key' />
                            </div>
                        }
                        </> 
                        : '--'
                    }
                </span>
                
            </li>
        </div>
    )
}