import { faCalendarAlt, faCheckCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import classes from './SalesAndCashSummeryArchive.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useEffect } from 'react';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';
import { SmileyWink } from '@phosphor-icons/react/dist/ssr';

import { useAuth } from '../../../AuthProvider';
import { useToast } from '../../../ToastContext';
import { QueryCashCollectionSummeryByBranch } from '../../../SharedComp/API/DeskView/API_FE_CloseShift';

import UIButton from '../../Components/UIButton/UIButton';
import { ChartLine, Paperclip } from '@phosphor-icons/react';

import CashSalesVisualAnalysis from './CashSalesVisualAnalysis/CashSalesVisualAnalysis';
import CashSalesSummeryPreview from './CashSalesSummeryPreview/CashSalesSummeryPreview';
import { ToEthiopianCalander } from '../../../SharedComp/Utility';
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';


export default function SalesAndCashSummeryArchive() 
{

    const auth = useAuth();
    const notify = useToast();

    const [OpenCashSalesReportPreview, SetOpenCashSalesReportPreview] = useState(false);
    const [SelectedReportPreviewGroup, SetSelectedReportPreviewGroup] = useState(null);

    const [LoadingData, SetLoadingData] = useState(false);
    const [DataCopy, SetDataCopy] = useState([]);
    const [AtDayOf, SetAtDayOf] = useState(new Date()); 
    const [SelectedSummery, SetSelectedSummery] = useState(null);


    
    function SortByDate(data_array)
    {
        data_array.sort((a, b)=> new Date(b.CCMDPV2_CollectionTimeDate) - new Date(a.CCMDPV2_CollectionTimeDate));
        return data_array;
    }
    function truncateString(str, length) 
    {
        if(!str) 
        {
            return str;
        }
        if (str.length > length) {
          return str.slice(0, length) + '...'; 
        } else {
          return str;
        }
      }
    const FetchBranchCashCollection = async()=>
    {
        SetLoadingData(true);
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);

            const main_res = await QueryCashCollectionSummeryByBranch(fd);
            if(main_res.length > 0) 
            {
                const _sorted_data = SortByDate(main_res);
                SetDataCopy(_sorted_data);
                SetSelectedSummery(_sorted_data[0]);
            }

            SetLoadingData(false);
        }catch(err) 
        {
            SetLoadingData(false);
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{

        FetchBranchCashCollection();

    }, [auth.activeWorkShop])

    

    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>


                {OpenCashSalesReportPreview && <CashSalesSummeryPreview Close_CALLBACK={SetOpenCashSalesReportPreview} GroupData={SelectedReportPreviewGroup} />}
                <CashSalesVisualAnalysis GroupData={SelectedSummery} />
   
            
            <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.active_order_main_list_wrapper}`}>
                    <li className={`${classes.header_list}`}>
                        <span>Signature</span>
                        <span>Cashier Name</span>
                        <span>Collection Date</span>
                        <span>Cashier Remark</span>
                        <span>Action</span>
                    </li>

                    <div className={`${classes.list_data_wrapper}`}>
                        {
                            DataCopy.map((item, idx)=>(
                                <li key={item.CCS_CollectionHash}>
                                    <span>{truncateString(item.CCMDPV2_CollectionID, 10)}</span>
                                    <span>{item.USR_FirstName} {item.USR_LastName}</span>
                                    <span>{ToEthiopianCalander(new Date(item.CCMDPV2_CollectionTimeDate))}</span>
                                    <span>{item.CCMDPV2_CashierNote ? item.CCMDPV2_CashierNote : '--'}</span>
                                    <span className={`${classes.action_button_wrapper}`}>
                                            <UIButton Variant='Secondary' onClick={()=>{SetSelectedSummery(item)}} label={"Analysis"} Icon={<ChartLine/>}/>
                                            <UIButton Variant='Secondary' onClick={()=>{SetSelectedReportPreviewGroup(item); SetOpenCashSalesReportPreview(true);}} label={"Preview"} Icon={<Paperclip/>}/>
                                    </span>
                                </li>
                                // <SingleEntryGroup UpdateRefreshView={RefreshView} key={item.PROCI_Name} item={item}/>
                            ))
                        }
                        {
                            DataCopy.length === 0 && !LoadingData && <div className={`${classes.empty_box_wrapper}`}>
                                <ul><SmileyWink fontSize={30}/></ul>
                                <ul>NO CASH FLOW</ul>
                            </div>
                        }
                        {
                            LoadingData && 
                            <LoadingPlaceholder preview_text='Loading Cash Collection'/>
                        }
                    </div>
                    
                </div>
            </div>

            {/* <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{SetAtDayOf(e.target.value)}} autoComplete='off' type="date" /> 
                        <span>{new Date(AtDayOf).toDateString()}</span>
                    </div>
            </div> */}
        </div>
    )
}