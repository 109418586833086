import api from "../Util/axiosInstance";





export const CreateNewBeverageGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/create_baverage_group', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBeverageGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/update_baverage_group', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBeverageGroupAll = async(token)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/query-all-baverage-group', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeBeverageGroupByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/purge_baverage_group_by_id', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////// BAVERAGE PRODUCTS NOT GROUP
export const CreateBeverageProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/create-baverage-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBeverageProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/update-baverage-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBeverageProductAll = async(token)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/fetch-baverage-products-all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeBeverageProductByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/purge-baverage-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBeverageProductByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/baverage-cluster/fetch-baverage-products-by-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}