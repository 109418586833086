import { useState } from 'react'
import classes from './OrderSingluarProd.module.css'
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FetchFinalProductSaved, FetchMixSaved, FetchYamMixIngID} from '../../../../api'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { FetchBakeryProductByID } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';
import { useAuth } from '../../../../AuthProvider';

export default function OrderSingluarProd({Target, Selected_CALLBACK})
{

    return (
        <div className={`${classes.main_product_shelf_card_wrapper}`}>
            <div className={`${classes.prod_item_wrapper}`}>
                <div className={`${classes.prod_name_info_wrapper}`}>
                    <h2>{Target.OP_Name}</h2>
                    <li>{Target.OP_BatchCount} - Batch</li>
                </div>
            </div>
        </div>
    )
}