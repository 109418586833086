import { useEffect, useRef, useState } from 'react';
import classes from './TrackRequest.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faBan, faBinoculars, faCheckCircle, faCircleArrowDown, faCircleArrowUp, faCircleCheck, faDownLong, faDownload, faExpand, faHourglass, faHourglassHalf, faKey, faPrint, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import {FetchTransferRequestDispatched, TerminateStockTransferRequest, UnlockSuspendedStockTransferRequest, FetchTransferRequestIncoming} from '../../../../api'
import { useAuth } from '../../../../AuthProvider';
import { useToast } from '../../../../ToastContext';
import { formatDistanceToNow, formatDistance } from 'date-fns';
import ProcessTRequest from '../../Component/ProcessRequest/ProcessTRequest';
import PrintTransferRequest from '../../Component/PrintTransferRequest/PrintTransferRequest';
import QRCodeReader from '../../../../SharedComp/QRCodeReader/QRCodeReader';

import ReceiveSTRequest from '../../Component/ReceiveSTRequest/ReceiveSTRequest';
import CreateRequest from '../../Component/CreateRequest/CreateRequest';
import TimeAgo from '../../../../SharedComp/TimeElapsed/TimeAgo';

import DispatchedInventoryRequestBundle from './DispatchedInventoryRequestBundle/DispatchedInventoryRequestBundle';
import IncomingInventoryRequestBundle from './IncomingInventoryRequestBundle/IncomingInventoryRequestBundle';
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { ToEthiopianCalander } from '../../../../SharedComp/Utility';
import UIButton from '../../../Components/UIButton/UIButton';
import { Copy, LockOpen, MapPin, Paperclip, Warning } from '@phosphor-icons/react';
import { Clipboard } from 'react-native-web';
import PreviewRequestOrderDetail from './PreviewRequestOrderDetail/PreviewRequestOrderDetail';
import { useDAControl } from '../../../../DAControlContext';


const TrackRequest = () => {
    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();

    const [TragetOrder, SetTragetOrder] = useState(null);
    const [TargetLoading, SetTargetLoading] = useState(false);

    const [TargetRequestHandle, SetTargetRequestHandle] = useState(null);

    const [DispachedRequestList, SetDispachedRequestList] = useState([]);
    const [IncomingRequestList, SetIncomingRequestList] = useState([]);

    const [OpenProcessRequest, SetOpenProcessRequest] = useState(false);

    const [PrintRequest, SetPrintRequest] = useState(false);
    const [RequestID, SetRequestID] = useState();

    const [OpenKeyInputWrapper, SetOpenKeyInputWrapper] = useState(false);
    const ReqKeyInputWrapperRef = useRef(null);
    const [OpenCreateRequest, SetOpenCreateRequest] = useState(false);
    const [OpenRequestPreview, SetOpenRequestPreview] = useState(false);
    const [OpenUnerwayRequestLocationTracker, SetOpenUnerwayRequestLocationTracker] = useState(false);
    // QR
    const [OpenQRCodeReader, SetOpenQRCodeReader] = useState(false);
    const [RequisitionReadbackHashKey, SetRequisitionReadbackHashKey] = useState();

    // Process Receive
    const [OpenProcessReceiveSTRQ, SetOpenProcessReceiveSTRQ] = useState(false);
    const [TargetProcessIncomingRequestKey, SetTargetProcessIncomingRequestKey] = useState('jhsjd-asjdh');
    const [DataViewRefersh, SetDataViewRefersh] = useState(null);
    

    const FetchDetailRequestData = async(data)=>
    {
        SetTargetLoading(true);
        try 
        {
            SetTragetOrder(data);
            SetTargetLoading(false);
        }catch(err) 
        {
            
            notify.ShowError(err.message);
            SetTargetLoading(false);
        }
    }

    const FetchIncomingRequestList = async()=>
    {
        try 
        {
            const res = await FetchTransferRequestIncoming(auth.activeWorkShop, auth.token);
            SetIncomingRequestList(res);
        }catch(err) 
        {
            
            notify.ShowError(err.message);
        }
    }
    const FetchDispachedList = async() =>
    {
        try 
        {
            const res = await FetchTransferRequestDispatched(auth.activeWorkShop, auth.token);
            SetDispachedRequestList(res);
        }catch(err) 
        {
            
            notify.ShowError(err.message);
        }
    }
    const HandleModalDissmis = async()=>
    {
        SetOpenProcessRequest(false);
        SetTargetRequestHandle(null);
    }

    function HandleQRScanOpen() 
    {
        notify.ShowWarn("Please position the requisition paper in front of the camera, ensuring that the QR code area is clearly focused for optimal results.");
        SetOpenQRCodeReader(true);
    }

    useEffect(()=>{
        if(TargetRequestHandle) 
        {        
            SetOpenProcessRequest(true);
        }

    }, [TargetRequestHandle])

    function HandleMouseClick(e) 
    {
        if (ReqKeyInputWrapperRef.current && !ReqKeyInputWrapperRef.current.contains(e.target)) {
            SetOpenKeyInputWrapper(false);
          }
    }

    useEffect(()=>{
        FetchIncomingRequestList();
        FetchDispachedList();

        document.addEventListener('mousedown', HandleMouseClick);
        return () => {
        document.removeEventListener('mousedown', HandleMouseClick);
        };
    }, [auth.activeWorkShop, OpenProcessRequest, OpenProcessReceiveSTRQ])

    
      const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            SetOpenKeyInputWrapper(false);
        }
      };

      function OnQrCodeScan(data) 
      {
        if(data) 
        {
            SetOpenQRCodeReader(false);
            if(data.text !== TragetOrder?.ISTRV2_XchangeID) 
            {
                notify.ShowError("Oops! The key you provided doesn't match with the selected requisition order.")
            } else 
            {
                if(TragetOrder?.ISTRV2_RequestState === "Shipped") 
                {
                    SetTargetProcessIncomingRequestKey(TragetOrder);
                    SetOpenProcessReceiveSTRQ(true);
                } else 
                {
                    notify.ShowWarn("Looks like this requisition order has already been processed.")
                } 
            }
           
        }
      }
      function OnQrFailToRead(data) 
      {

      }

     function HandleTransactionDocPreview(id)
     {
        SetRequestID(id); 
        SetPrintRequest(true)
     }

     function GetTimeDiff(date_1, date_2)
     {

        const diffInMs = Math.abs(date_2 - date_1);

        const diffInHours = diffInMs / (1000 * 60 * 60);
        return diffInHours;
     }

     useEffect(()=>{
        SetTragetOrder(null);
     }, [DataViewRefersh, auth.activeWorkShop])

      function ValidateKey() 
      {
        if(TragetOrder?.ISTRV2_XchangeID !== RequisitionReadbackHashKey)
        {
            notify.ShowError("Oops! The key you provided doesn't match with the selected requisition order.")
        } else 
        {
            if(TragetOrder?.ISTRV2_RequestState === "Shipped") 
            {
                SetTargetProcessIncomingRequestKey(TragetOrder);
                SetOpenProcessReceiveSTRQ(true);
            } else 
            {
                notify.ShowWarn("Looks like this requisition order has already been processed.")
            }  
        }
      }

      const HandleRequestTermination = async(id)=>
      {
        notify.ShowLoad("Procesing your request", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id_to_purge", id);
            const main_res = await TerminateStockTransferRequest(fd);

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetDataViewRefersh(new Date().getMilliseconds());
            SetTragetOrder(null);
        }catch(err) 
        {
            notify.ShowErrorUpdate("Error Operation", 10);
        }
    }
    const UnlockSuspendedTransfer = async(id)=>
      {
        notify.ShowLoad("Procesing your request", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id_to_unlock", id);
            const main_res = await UnlockSuspendedStockTransferRequest(fd);

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetDataViewRefersh(new Date().getMilliseconds());
            SetTragetOrder(null);
        }catch(err) 
        {
            notify.ShowErrorUpdate("Error Operation", 10);
        }
    }

    return (
        <div className={`${classes.request_track_main_wrapper}`}>

            {OpenCreateRequest && <CreateRequest OpenModal={SetOpenCreateRequest}/>}
            {OpenProcessReceiveSTRQ && <ReceiveSTRequest DataRefresh={SetDataViewRefersh} TargetRequest={TargetProcessIncomingRequestKey} OpenModal={SetOpenProcessReceiveSTRQ} />}
            {OpenQRCodeReader && <QRCodeReader onQRRead={OnQrCodeScan} OnError={OnQrFailToRead} Open={OpenQRCodeReader} OpenController={SetOpenQRCodeReader}/>}
            {PrintRequest && <PrintTransferRequest OpenModal={SetPrintRequest} TargetID={RequestID}/>}
            {OpenProcessRequest && <ProcessTRequest DataRefresh={SetDataViewRefersh} TargetRequest={TargetRequestHandle} OpenModal={HandleModalDissmis}/>}
            {OpenRequestPreview && <PreviewRequestOrderDetail TargetData={TragetOrder} OpenController={SetOpenRequestPreview}/>}

            <div className={`${classes.wrapper_master_last_log}`}>

                <div className={`${classes.two_state_wrapper}`}>

                

                    <div className={`${classes.wrapper_inner_last_log}`}>

                        {TragetOrder && 
                        
                        <>
                            <div  className={`${classes.state_log_top_main_wrapper}`}>
                                <div className={`${classes.state_log_wrapper}`}>
                                    <li className={`${classes.tag_header}`}>Actions</li>
                                    <div className={`${classes.ac_state_wrapper}`}>
                                        <UIButton Variant='Secondary' Icon={<Paperclip weight='bold'/>} Type='button' onClick={()=>{SetOpenRequestPreview(true)}} label={"Preview Request"}/>
                                        {/* {TragetOrder?.ISTRV2_RequestState === "Shipped" &&  <UIButton Variant='Secondary' Icon={<MapPin weight='fill'/>} Type='button' onClick={()=>{}} label={"View Location"}/> } */}
                                    </div>
                                    <li className={`${classes.request_transaction_wrapper_id}`}>
                                        RequestID: {TragetOrder?.ISTRV2_TransactionID} 
                                        <span onClick={(e)=>{
                                            Clipboard.setString(TragetOrder?.ISTRV2_TransactionID);
                                            notify.ShowSuccess("Transaction ID copied to clipboard");
                                        }}><Copy weight='bold'/></span> 
                                    </li>
                                </div>
                                <div className={`${classes.date_log_wrapper}`}>
                                    <div className={`${classes.ac_created_date}`}>
                                            <li className={`${classes.tag_header}`}>Creation Date</li>
                                            <li className={`${classes.tag_val}`}>{ToEthiopianCalander(new Date(TragetOrder.ISTRV2_RequestInitDate))}</li>
                                    </div>
                                    <div className={`${classes.ac_required_date}`}>
                                            <li className={`${classes.tag_header}`}>Expected Date</li>
                                            <li className={`${classes.tag_val}`}>{ToEthiopianCalander(new Date(TragetOrder.ISTRV2_RequestExpectedDate))}</li>
                                    </div>
                                </div>
                                <div className={`${classes.approve_date_log_wrapper}`}>
                                    <div className={`${classes.ac_approv_date}`}>
                                            <li className={`${classes.tag_header}`}>Approved Date</li>
                                            <li className={`${classes.tag_val}`}>{TragetOrder?.ISTRV2_RequestState === "Shipped" ? ToEthiopianCalander(new Date(TragetOrder.ISTRV2_ApprovedDate)) : '-'}</li>
                                    </div>
                                    <div className={`${classes.ac_provided_reason}`}>
                                            <li className={`${classes.tag_header}`}>Request Reason</li>
                                            <li className={`${classes.ac_provided_reason_value}`}>{TragetOrder?.ISTRV2_RequestReason ? TragetOrder?.ISTRV2_RequestReason : '-' }</li>
                                    </div>
                                </div>
                            </div>

                            <div className={`${classes.state_requester_main_wrapper}`}>

                                    <div className={`${classes.ac_requester_info}`}>
                                            <li className={`${classes.tag_header}`}>Request Made By</li>
                                            <li className={`${classes.tag_val_bold}`}>{TragetOrder?.FullName}</li>
                                            {/* <li className={`${classes.tag_val}`}>Supervisor</li> */}
                                            <li className={`${classes.tag_val_bold}`}>{TragetOrder?.Email}</li>
                                            <li className={`${classes.tag_val}`}>{auth.activeWorkShopName} Branch</li>
                                    </div>

                                    <div className={`${classes.ac_requester_info}`}>
                                            <li className={`${classes.tag_header}`}>Request Handler</li>
                                            <li className={`${classes.tag_val_bold}`}>{TragetOrder?.BranchName}</li>
                                    </div>
                                    
                            </div>
                          {TragetOrder?.ISTRV2_RequestState === "Shipped" &&  <div className={`${classes.ac_requester_info_recive}`}>
                                    { 
                                        TragetOrder?.ISTRV2_SuspendReceive !== "Yes" ?  <div className={`${classes.ac_requester_info_receive_method}`}> 
                                        <div 
                                            ref={ReqKeyInputWrapperRef}
                                            tabIndex={-1}
                                            onBlur={handleBlur}
                                            onFocus={()=>{SetOpenKeyInputWrapper(true)}}
                                            className={`${OpenKeyInputWrapper ? classes.take_key_order : classes.take_key_order_hide}`}>
                                            <li>Stock Requisition Key</li>
                                            <input onChange={(e)=>{SetRequisitionReadbackHashKey(e.target.value)}} type="text" placeholder='Requestion Code' />
                                            <button onClick={()=>{ValidateKey()}}>Validate</button>
                                        </div>

                                        <button onClick={(e)=>{SetOpenKeyInputWrapper(!OpenKeyInputWrapper)}}> <FontAwesomeIcon icon={faKey}></FontAwesomeIcon> Enter Key</button> 
                                        <button onClick={(e)=>{HandleQRScanOpen()}}><FontAwesomeIcon icon={faExpand}></FontAwesomeIcon> Scan Paper</button>
                                        
                                    </div> : 
                                    <>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CanUnlockTransferRequest && <UIButton Icon={<LockOpen weight='bold'/>}  Variant='Secondary'  
                                        onClick={()=>{
                                            DAControl.Trigger(
                                                'Unlock Suspended Request',
                                                `You are about to unlock suspended reuest, this will allow user to perform stock entry`,
                                                true,
                                                2,
                                                (data)=>{
                                                    if(data)
                                                    {
                                                        UnlockSuspendedTransfer(TragetOrder?.ISTRV2_ID)
                                                    }
                                                }
                                            )
                                            
                                            }}
                                        label={"Unlock"}/> }
                                        <UIButton Icon={<Warning weight='bold'/>} Variant='Danger' onClick={()=>{}} label={"Your Time Expired Please Contact Your Admin"}/>
                                    </>
                                    }
                            </div>}
                        </>}
                        {!TragetOrder && !TargetLoading && <div className={`${classes.place_holder_main}`}>Select to Manage</div> }
                        {TargetLoading && <LoadingPlaceholder preview_text='Processing'/>}

                    </div>

                    <div className={`${classes.action_data_wrapper}`}>
                        
                        <div className={`${classes.action_main_req}`}>
                                <div className={`${classes.action_main_req_dash}`}>
                                  {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateStockRequest && <button onClick={()=>{SetOpenCreateRequest(true)}} className={`${classes.action_new}`}>Create Stock Request</button> }
                                  {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_TerminateTransferRequest && TragetOrder?.ISTRV2_RequestState === "Pending" &&  <button 
                                  onClick={()=>{
                                    DAControl.Trigger(
                                        'Terminate Request',
                                        `You are about to terminate, your inventory request, this only apply if the order state is pending`,
                                        true,
                                        7,
                                        (data)=>{
                                            if(data)
                                            {
                                                HandleRequestTermination(TragetOrder.ISTRV2_ID)
                                            }
                                        }
                                    )
                                    
                                    }} className={`${classes.action_terminate }`}>Terminate Request</button>}
                                </div>
                        </div>
                    </div>
                    
                    


                </div>

            </div> 

            <div className={`${classes.request_track_inner_wrapper}`}>
                    <DispatchedInventoryRequestBundle RefreshView={DataViewRefersh} OrderPreviewCallback={FetchDetailRequestData} />
                    <IncomingInventoryRequestBundle RefreshView={DataViewRefersh} HandleView={SetTargetRequestHandle} HandleTransactionDoc={HandleTransactionDocPreview} />
            </div>

        </div>
    )
}


export default TrackRequest; 