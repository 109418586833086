import classes from './DistributionGroup.module.css'

import { useState } from 'react'
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {QueryShippingReadyProducts} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'


import { useToast } from '../../../../../ToastContext';

import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed';
import CountdownTimer from '../../../../../SharedComp/CountDown/CountDownTimer';
import { useDAControl } from '../../../../../DAControlContext';

import UIButton from '../../../../Components/UIButton/UIButton';
import { Checks, Stack, Star, Van } from '@phosphor-icons/react';

import ShippingDialog from '../ShippingDialog/ShippingDialog';
import { useAuth } from '../../../../../AuthProvider';

import OrderSubGroup from './OrderSubGroup/OrderSubGroup';
import { useGNP } from '../../../../../GlobalNotificationProvider';


function getFormattedDate(dateValue) {
    return new Date(dateValue).toLocaleDateString('en-US', {
      weekday: 'long', 
      month: 'short',  
      day: '2-digit'
    //   year: 'numeric'  
    });
  }
function getFormattedTime(dateValue) {
    return new Date(dateValue).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true 
    });
}
function isDateInThePast(inputDate) {
    const now = new Date();
    const providedDate = new Date(inputDate);
    return now < providedDate;
}

export default function DistributionGroup({Data})
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();
    const GNP = useGNP();

    const [ShippingProductGroupMap, SetShippingProductGroupMap] = useState(new Map());
    const [SubProductList, SetSubProductList] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);
    const [OpenShippingDialog, SetOpenShippingDialog] = useState(false);


    const ParseProductperGroup = async(data_array)=>
    {
        
        const _temp_map = new Map();

        // Here we need to detect special orders and create seprate ship group
        for(let i = 0; i < data_array.length; i++)  
        {
            // So basically if the order has special order detail, it means it is special
            // I know this sound retard but, trust me future me it is fine.
            const _is_special_order = data_array[i].KPSR_PendingBackUp.KPPO_SpecialOrderDetail !== null;

            if(_is_special_order) 
            {
                // in this case we use the dispatch id to seprate them
                if(!_temp_map.has(data_array[i].KPSR_PendingBackUp.KPPO_UID)) 
                {
                    _temp_map.set(data_array[i].KPSR_PendingBackUp.KPPO_UID, []);
                }

                _temp_map.get(data_array[i].KPSR_PendingBackUp.KPPO_UID).push(data_array[i]);

            } else 
            {
                if(!_temp_map.has(data_array[i].KSOBATCH_ShipUUID)) 
                {
                    _temp_map.set(data_array[i].KSOBATCH_ShipUUID, []);
                }

                _temp_map.get(data_array[i].KSOBATCH_ShipUUID).push(data_array[i]);
            }
        }

        SetShippingProductGroupMap(new Map(_temp_map));
    }


    const FetchShipReadyProducts = async() =>
    {
        try 
        {

            const fd = new FormData();
            fd.append("source_workspace_id", auth.activeWorkShop);
            fd.append("destination_workspace_id", Data.BranchID);
            fd.append("targte_date", new Date());

            const main_res = await QueryShippingReadyProducts(fd); 
            ParseProductperGroup(main_res);
            SetSubProductList(main_res);
        }catch(err) 
        {

        }
    }


    useEffect(()=>{

        FetchShipReadyProducts();

    }, [RefreshView, GNP.EVENT_FinishPrepareProduct])


    return (
        <div className={`${classes.order_group_main_wrapper}`}>

            {OpenShippingDialog && <ShippingDialog UpdateViewToggel={RefreshView} UpdateView={SetRefreshView} DataList={SubProductList} BranchData={Data} Close_CALLBACK={SetOpenShippingDialog}/> }

            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />
            <div className={`${classes.order_group_main_header_wrapper}`}>
                <h2>Shipping to #{Data.BranchName} <span className={`${classes.package_info}`}>{ShippingProductGroupMap.size > 0 ?  " - Active Package (" + ShippingProductGroupMap.size + ")" : ''}</span></h2>
            </div>


            <div className={`${classes.group_body_wrapper}`}>

                {
                    Array.from(ShippingProductGroupMap.entries()).map(([map_key, map_items], key_idx)=>(
                        <OrderSubGroup  key={key_idx} BranchData={Data} _UpdateViewToggel={RefreshView} _UpdateView={SetRefreshView} SubDataList={map_items} />
                    ))
                }
                {
                    ShippingProductGroupMap.size === 0 &&
                    <div className={`${classes.expecting_action}`}>
                        <h2>Product will appear here, when they are ready</h2>
                    </div>
                }

            </div>

        </div>
    )
}