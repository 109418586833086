import classes from './ApproveChefStockRequestDocPreview.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {FetchSavedProductionOrderConfig, FetchProductionOrderOWner} from '../../../../../SharedComp/API/Production/API_ProductionOrder'
import { useToast } from '../../../../../ToastContext'

import { FetchProductionOrderReqMaterial, FetchMixIngredientJoined, FetchMixSaved } from '../../../../../api'
import { FetchBakeryProductByID } from '../../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import { useState } from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash'

import { useReactToPrint } from 'react-to-print'
import { useDAControl } from '../../../../../DAControlContext'
import { useRef } from 'react';
import { useEffect } from 'react';
import { ApproveDirectStockRequestItemsByGroup, QueryDirectStockRequestItemsByGroup, QueryDirectStockRequestWithApproverItemsByID, RejectDirectStockRequestRequest } from '../../../../../SharedComp/API/DirectStockRequest/API_FE_DirectStockRequest'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { useAuth } from '../../../../../AuthProvider'
import { AcceptChefsStockRequestItem, RejectChefsStockRequest } from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'

export default function ApproveChefStockRequestDocPreview({DR_request, OpenController, DataRefresh, InventoryList, RequestOwner, TargetData, Preview = false})
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const print_ref = useRef();
    const [UtilizedPackageIvtItem, SetUtilizedPackageIvtItem] = useState([]);
    const [RequestHandler, SetRequestHandler] = useState(null);
    const [ApproveLoading, SetApproveLoading] = useState(false);
    const [IncomingLoading, SetIncomingLoading] = useState(false);

    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_Chef-Requested-Stock" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const HandleRejectChefsStockRequest = async()=>
    {
        const _id = parseInt(Math.random() * 1000);
        notify.ShowLoad("Processing your request...", _id);
        try 
        {
            const fd = new FormData();
            fd.append("target_order_id", DR_request ? TargetData.KCODSI_ID : TargetData.KCOP_ID);
            fd.append("target_request_id", DR_request ? TargetData.RequestUUID : TargetData.KPSR_UID);
            fd.append("is_direct_order", DR_request);

            const main_res = await RejectChefsStockRequest(fd);
            notify.ShowSuccessUpdate(main_res.Msg, _id);
            DataRefresh(new Date().getMilliseconds())
            OpenController(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, _id);
        }
    }

    const HandleAcceptChefStockRequest = async()=>
    {
        const _id = parseInt(Math.random() * 1000);
        notify.ShowLoad("Processing your request...", _id);
        try 
        {
            const fd = new FormData();
            fd.append("target_order_id", DR_request ? TargetData.KCODSI_ID : TargetData.KCOP_ID);
            fd.append("target_request_id", DR_request ? TargetData.RequestUUID : TargetData.KPSR_UID);
            fd.append("is_direct_order", DR_request);
            fd.append("workspace_id", auth.activeWorkShop);
            fd.append("approver_id", auth.user.USR_UserAccountID);

            const main_res = await AcceptChefsStockRequestItem(fd);
            notify.ShowSuccessUpdate(main_res.Msg, _id);
            DataRefresh(new Date().getMilliseconds())
            OpenController(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, _id);
        }

    }



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                    <div className={`${data_win.data_main_header}`}>
                        <h3>
                            Approve <span className={`${data_win.data_create_name}`}>Chef Stock Request</span>
                        </h3>
                        <button onClick={()=>{OpenController(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                    </div>

                    {!IncomingLoading && <div className={`${classes.data_preview_wrapper}`}>

                    <div className={`${classes.inner_preview_data_wrapper}`}>
                        <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                            <PaperHeader/>
                            
                            <div className={`${classes.print_doc_non_repeat_content}`}>

                                <PaperHash text={Preview ? "copy not original" : null}/>
                                <div className={`${classes.meta_data_wrapper}`}>
                                    <h2>[#] {auth.activeWorkShopName} </h2>
                                    <h2>[#] Chef's Stock Request</h2>
                                </div>

                                <div className={`${classes.transcation_owner_wrapper}`}>
                                    <div className={`${classes.transcation_owner}`}>

                                        <div className={`${classes.transcation_init}`}>
                                            <h2>Direct Stock Request Info</h2>
                                            <li><span>Request By</span> <span style={{fontWeight: 'bold'}}>{RequestOwner?.JAKSBE_FullName}</span></li>
                                            <li><span>Request Date</span>  <span style={{fontWeight: 'bold'}}>{ToEthiopianCalander(new Date(DR_request ? TargetData.RequestTime : TargetData.KPSR_Date))}</span></li>
                                            <li><span>Clock</span> <span style={{fontWeight: 'bold'}}>{new Date(DR_request ? TargetData.RequestTime : TargetData.KPSR_Date).toLocaleTimeString()}</span></li>
                                        </div>
                                        {/* <div className={`${classes.transcation_recive}`}>
                                            <h2>Processed By</h2>
                                            <li>{auth.employeeData?.JAKSBE_FullName}</li>
                                        </div> */}

                                    </div>
                                </div>

                                <div className={`${classes.entry_detail}`}>
                                    <li><span className={`${classes.entry_deatil_tag}`}>Process ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetData.KPSR_UID}</span></li>
                                </div>  
                                
                                
                                <div className={`${classes.main_paper_data_wrapper}`}>

                                    <div className={`${classes.paper_list_wrapper}`}>

                                        <div className={`${classes.single_order_data_view_master_wrapper}`}>
                                            <div className={`${classes.packge_data_wrapper}`}>

                                                <div className={`${classes.main_prod_info_wrapper}`}>
                                                    <li><span className={`${classes.prod_name_PO}`}>Requested Items</span></li>
                                                </div>

                                                <div className={`${classes.ing_list_data_wrapper}`}>
                                                    <div className={`${classes.data_marging_wrapper}`}>
                                                        <li>
                                                            <span>Inventory Item Name</span>
                                                            <span className={`${classes.right_align}`}>Quantity</span>
                                                        </li>
                                                        {
                                                            InventoryList.map((item, idx)=>(
                                                                <li style={{fontWeight: 'lighter'}} key={idx}>
                                                                    <span>{item.IVItemName}</span> 
                                                                    <span className={`${classes.right_align}`}>{(item.RequestAmount).toFixed(2)} <strong>{item.UomDescription}</strong></span>
                                                                </li>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            {/* <div className={`${classes.packge_data_wrapper}`}>

                                                <div className={`${classes.main_prod_info_wrapper}`}>
                                                    <li><span className={`${classes.prod_name_PO}`}>Request Reason</span></li>
                                                </div>

                                                <div className={`${classes.ing_list_data_wrapper}`}>
                                                    <div className={`${classes.data_marging_wrapper}`}>
                                                        <li>ll</li>
                                                    </div>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className={`${classes.special_footer_local_wrapper}`}>

                                    <div className={`${classes.footer_user_wrap}`}>
                                        <h2>Digital Authoriziation By</h2>
                                        <li className={`${classes.name_manual}`}>{Preview && RequestHandler ? RequestHandler?.USR_FirstName + ' ' + RequestHandler.USR_LastName : auth.employeeData?.JAKSBE_FullName}</li>
                                    {Preview && RequestHandler && <li className={`${classes.print_date}`}>{ToEthiopianCalander(new Date(RequestHandler.DSRG_ResolveDateTime))} @ {new Date(RequestHandler.DSRG_ResolveDateTime).toLocaleTimeString()}</li>}  
                                        <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                    </div>

                                    <div className={`${classes.footer_user_wrap}`}>
                                        <h2>Physical Authoriziation By</h2>
                                        <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                        <li className={`${classes.print_date}`}>Date:________________________</li>
                                        <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                    </div>

                                    <div className={`${classes.footer_user_wrap}`}>
                                        <h2>Stock checked & approved by</h2>
                                        <li className={`${classes.name_manual}`}>{RequestOwner?.JAKSBE_FullName}</li>
                                        <li className={`${classes.print_date}`}>Date:________________________</li>
                                        <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                    </div>

                                </div>

                                {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                                <div className={`${classes.final_note_po_stock_out}`}>
                                    <li>This document must have all signatures to be considered valid.</li>
                                </div>

                            </div>
                        </div>
                    </div>



                    </div> }
                    {
                        IncomingLoading && 
                        <LoadingPlaceholder preview_text='Preparing Document'/>
                    }


                    <div className={`${classes.action_button_wrapper}`}>

                    {Preview && <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>} 
                    {!Preview && !IncomingLoading &&   <button onClick={()=>{
                                            DAControl.Trigger(`Approve Stock Out`, 
                                            `This action will affect Inventory Balance. In other word the "Grand Total" Amount you see will be deducted form your store.`,
                                            true,
                                            5, (val)=>{
                                                if(val !== null) 
                                                {
                                                    HandleAcceptChefStockRequest();
                                                }
                                            }   
                                        );}} className={`${classes.button_approve}`}>Approve</button> }
                    {!Preview && <button 
                            className={`${classes.form_action_button_dismiss}`}
                            onClick={()=>{
                                DAControl.Trigger(
                                    'Reject User Direct Stock Requesst',
                                    "You are about to reject direct stock requesst from user, if you do this the user need to re-order the request",
                                    true,
                                    7,
                                    (data)=>
                                    {
                                        if(data)
                                        {
                                            HandleRejectChefsStockRequest(TargetData)
                                        }
                                    }
                                )
                            }}>Reject</button>} 
                        <button onClick={()=>{OpenController(false)}} className={`${classes.button_dismiss}`}>Close</button>

                    </div>

                    </div>
        </div> 
    )
}