import { ChefHat, PlusCircle, UserCircleGear, XCircle } from '@phosphor-icons/react'
import classes from './ChefLineUpGroup.module.css'
import { useEffect, useState } from 'react'

import ChefViewCard from '../ChefViewCard/ChefViewCard';
import { useAuth } from '../../../../AuthProvider';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import { useDAControl } from '../../../../DAControlContext';
import { useToast } from '../../../../ToastContext';

import {AssignChefToWorkspace, QueryAssignedChef} from '../../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import { QueryBaseEmployeeByBranch } from '../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee';

export default function ChefLineUpGroup({TargetData}) 
{

    const [EmployeeList, SetEmployeeList] = useState([]);
    const [EmployeeChoiceIsOpen, SetEmployeeChoiceIsOpen] = useState(false);
    const [RefreshView, SetRefreshView] = useState(false);

    const [AssignedChefList, SetAssignedChefList] = useState([]);

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const QueryEmployee = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("branch_id", TargetData.BranchID);
            const main_res = await QueryBaseEmployeeByBranch(fd);
            SetEmployeeList(main_res);
        }catch(err) 
        {

        }
    }

    const FetchAssignedChef = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", TargetData.BranchID);
            const coll = await QueryAssignedChef(fd);
            SetAssignedChefList(coll);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    
    useEffect(() => 
    {
        QueryEmployee();
        FetchAssignedChef();
        
    }, [RefreshView])
    const handleUpdateView = ()=> {SetRefreshView(!RefreshView);}

    const HandleShefAssignment = async(__the_choicen)=>
    {
        notify.ShowLoad("Processing your request....", 10);
        try 
        {
            const fd = new FormData();

            fd.append("emp_id", __the_choicen.JAKSBE_SyntheticID);
            fd.append("user_id", auth.user.USR_UserAccountID);
            fd.append("target_branch", auth.activeWorkShop)

            const main_res = await AssignChefToWorkspace(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshView(!RefreshView);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }



    return (
        <div className={`${classes.chef_lineup_main_workshop_wrapper}`}>

            <div className={`${classes.chef_lineup_header}`}>
                <h3>{TargetData.BranchName} #Assigned Chef <ChefHat weight='bold'/></h3>
                <button onClick={()=>{SetEmployeeChoiceIsOpen(!EmployeeChoiceIsOpen)}}>{EmployeeChoiceIsOpen ? <XCircle weight='bold'/> : <PlusCircle weight='bold'/>}</button>

                <div tabIndex={0} onBlur={()=>{SetEmployeeChoiceIsOpen(false)}} className={`${EmployeeChoiceIsOpen ? classes.workspace_active_employee_list_wraper : classes.workspace_active_employee_list_wraper_hide}`}>

                    <div className={`${classes.employee_assignement_list}`}>
                        {
                            EmployeeList.map((item, idx)=>(
                                <li key={item.JAKSBE_FullName} 
                                onClick={()=>{
                                    DAControl.Trigger(
                                        `Assign Chef`,
                                        `You are about assign ${item.JAKSBE_FullName} as a chef for ${TargetData.BranchName} after this action the chef will receive order form the dipstacher... so with this in mind `,
                                        true,
                                        6,
                                        (data)=>{
                                            if(data) 
                                            {
                                                HandleShefAssignment(item);
                                            }
                                        }
                                    )
                                }}
                                >
                                    <span className={`${classes.chef_image_wrapper}`}>
                                        <img src={getBaseURL() + item.JAKSBE_EmployeeContextProfile.EDOC_Profile.EDOCP_ProfilePicture} alt="" />
                                    </span>
                                    <span className={`${classes.chef_name_wrapper}`}>
                                        {item.JAKSBE_FullName}
                                        <address style={{fontSize: '0.9em', padding: `3px`}}>{item.JAKSBE_EmployeeContextProfile.CTE_Profile.HRMJPT_Name}</address>
                                    </span>
                                </li>
                            ))
                        }
                        {
                            EmployeeList.length === 0 && 

                            <div className={`${classes.emplye_employee_notify}`}>
                                <h2><UserCircleGear/></h2>
                                <h5>There is no Employee list for {TargetData.BranchName}</h5>
                            </div>
                        }
                    </div>

                </div>

            </div>

            <div className={`${classes.chef_line_list_wrapper}`}>
                {
                    AssignedChefList.map((item, idx)=>(
                        <ChefViewCard UpdateView={handleUpdateView} ChefData={item} key={idx}/>
                    ))
                }
                {
                    AssignedChefList.length === 0 &&
                    <div className={`${classes.empty_chef_Collcation}`}>
                        <h1><ChefHat/></h1>
                        <h2>There is no assigned chef</h2>
                    </div>
                }
            </div>

        </div>
    )
}