import api from "../Util/axiosInstance";





export const ProcessShiftClose = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/close-day-shift', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryCashCollectionSummeryByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/query-cash-collection-summery', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryCashCollectionByBranchDate = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/query-cash-collection-summery-by-date-branch', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryBakerySalesByGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/query-bakery-sales-by-group', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryPastrySalesByGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/query-pastry-sales-by-group', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryMerchSalesByGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/query-merch-sales-by-group', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryBaverageSalesByGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/query-baverga-sales-by-group', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryPreorderSalesByDate = async(data)=>
{
    try  
    {
        const response = await api.post('api/close-shift/query-preorder-sales-by-date', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    } 
}

export const QueryFinishedPreorderSalesByDate = async(data)=>
{
    try 
    {
        const response = await api.post('api/close-shift/query-finished-preorder-sales-by-date', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}