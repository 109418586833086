import { toEthiopian, toGregorian } from "ethiopian-date";

export const ETHIOPIAN_MONTHS = [
  "መስከረም",
  "ጥቅምት",
  "ኅዳር",
  "ታኅሣሥ",
  "ጥር",
  "የካቲት",
  "መጋቢት",
  "ሚያዝያ",
  "ግንቦት",
  "ሰኔ",
  "ሐምሌ",
  "ነሐሴ",
  "ጳጉሜ"
];
export const ETHIOPIAN_MONTHS_DROPDOWN = [
  {value: 1, label: "መስከረም"},
  {value: 2, label: "ጥቅምት"},
  {value: 3, label: "ኅዳር"},
  {value: 4, label: "ታኅሣሥ"},
  {value: 5, label: "ጥር"},
  {value: 6, label: "የካቲት"},
  {value: 7, label: "መጋቢት"},
  {value: 8, label: "ሚያዝያ"},
  {value: 9, label: "ግንቦት"},
  {value: 10, label: "ሰኔ"},
  {value: 11, label: "ሐምሌ"},
  {value: 12, label: "ነሐሴ"},
  {value: 13, label: "ጳጉሜ"}
];
export const ACTIVE_REPORT_ETHIOPIAN_YEAR = [
  {value: 2016, label: "2016"},
  {value: 2017, label: "2017"},
  {value: 2018, label: "2018"},
  {value: 2019, label: "2019"},
  {value: 2020, label: "2020"}
];
export const ETHIOPIAN_WEEK_DAY_DROPDOWN = [
  {value: 1, label: "እሁድ"},
  {value: 2, label: "ሰኞ"},
  {value: 3, label: "ማክሰኞ"},
  {value: 4, label: "ዕሮብ"},
  {value: 5, label: "ሀሙስ"},
  {value: 6, label: "አርብ"},
  {value: 7, label: "ቅዳሜ"}
];

const ETHIOPIAN_WEEK_DAY = [
  "እሁድ",
  "ሰኞ",
  "ማክሰኞ",
  "ዕሮብ",
  "ሀሙስ",
  "አርብ",
  "ቅዳሜ"
];
export function GLOBAL_PopupMsgTimeout(callback, state, shouldOpen, msg) 
{
    setTimeout(() => {
        callback(state, false, msg);
      }, 4 * 1000);
}

export function GetOffsetedDateAndTime(val = null) {
  const date_value = val ? new Date(val) : new Date();
  const offsetDate = new Date(date_value.getTime() - date_value.getTimezoneOffset() * 60000);
  
  return offsetDate.toISOString().slice(0, -1);
}
export function GetOffsetedDateAndTimeUnformated(val = null) {
  const date_value = val ? new Date(val) : new Date();
  const offsetDate = new Date(date_value.getTime() - date_value.getTimezoneOffset() * 60000);
  
  return offsetDate;
}

export const Custom_DD_Style = {
  control: (provided, state) => ({
      ...provided,
      width: '100%',
      minHeight: 20,
      height: 35,
      fontWeight: 'bold',
      fontSize: '0.6em',
      background: 'var(--background-light)',
      borderColor: 'var(--background-light)',
      color: 'var(--secondary-color)',
      borderRadius: 1,
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
      borderColor: 'none',
      '&:hover': {
      borderColor: 'none',
      },
  }),
  option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--background-light)' : 'var(--background-dark)', // Change background color on hover
      color:'var(--text_light_white)',
      transition: 'background-color 0.2s ease-in-out',
      cursor: 'pointer',
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
  }),
  singleValue: (provided) => ({
      ...provided,
      color: 'var(--text_light_white)', // Set the text color for the selected option in the dropdown
    }),
    input: (provided) => ({
      ...provided,
      color: 'var(--text_light_white)', //input text color when searching
    }),
  menu: (provided) => ({
      ...provided,
      zIndex: 100, // Increase the z-index if options are not displayed properly
      backgroundColor: 'var(--background-light)',
      fontSize: '0.6em',
      borderRadius: 0,
      fontWeight: 500,
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
  }),
};

export function PrepareDropDownList(data, bucket) {
  const newOptionList = [];

  for (var i = 0; i < data.length; i++) {
    
    let object = data[i];

    let value_key = null;
    let value_lable = null;

   // console.log(object);
    for (const key in object) {

      const active = String(key);
      if (active.toLowerCase().includes('name')) {

        const val = object[key];
        value_lable = val;

      } else if (active.toLowerCase().includes('id')) {

        const val = object[key];
        value_key = val;

      }

      

      if(value_lable !== null && value_key !== null) 
      {
        newOptionList.push({ value: value_key, label: value_lable  });
        break;
      }

    }

    
  }

  bucket(newOptionList);
  return newOptionList;
}
export function PrepareDropDownList_KV(data, bucket, lable_prop, value_prob) {
  const newOptionList = [];

  for (var i = 0; i < data.length; i++) {
    
    let object = data[i];

    let value_key = null;
    let value_lable = null;

   // console.log(object);
    for (const key in object) {

      const active = String(key);
      if (active.toLowerCase().includes(lable_prop.toLowerCase())) {

        const val = object[key];
        value_lable = val;

      } else if (active.toLowerCase().includes(value_prob.toLowerCase())) {

        const val = object[key];
        value_key = val;

      }

      

      if(value_lable !== null && value_key !== null) 
      {
        newOptionList.push({ value: value_key, label: value_lable  });
        break;
      }

    }

    
  }

  bucket(newOptionList);
  return newOptionList;
}
export function PrepareDropDownList_CarID(data, bucket) {
  const newOptionList = [];

  for (var i = 0; i < data.length; i++) {
    
    let object = data[i];

    let value_key = null;
    let value_lable = null;

   // console.log(object);
    for (const key in object) {

      const active = String(key);
      
      if (active.toLowerCase().includes('carid')) {

        const val = object[key];
        value_lable = val;
        value_key = object;
      } 


      if(value_lable !== null && value_key !== null) 
      {
        newOptionList.push({ value: value_key, label: value_lable  });
        break;
      }

    }

    
  }

  
  bucket(newOptionList);
  return newOptionList;
}

export function mapToObject(map) 
{
  const obj = {};
  for (let [key, value] of map) 
  {
      if (value instanceof Map) 
      {
        obj[key] = mapToObject(value);
      } else 
      {
        obj[key] = value;
      }
  }
  return obj;
}
  
function convertToNumber(value) {
  if (!isNaN(value)) { 
      return Number(value);
  } 
  return value;
}
export function objectToMap(obj) 
{
    const map = new Map();

    for (let key in obj) {

        if (obj[key] instanceof Object && !Array.isArray(obj[key])) 
        {
            map.set(convertToNumber(key), objectToMap(obj[key]));
        } else 
        {
            map.set(convertToNumber(key), obj[key]);
        }
    }
    return map;
}

export function ToGregorianCalendar(year, month, day)
{
    const gcDate = toGregorian(year, month, day);
    const ec_year = gcDate[0];
    const ec_month = gcDate[1] - 1;
    const ec_day = gcDate[2];
    
    const rt_date = new Date(ec_year, ec_month, ec_day);
    return rt_date;
}
export function ToEthiopianMonth(month_idx)
{
  return ETHIOPIAN_MONTHS[month_idx - 1];
}
export function ToEthiopianDay(day_index)
{
  return ETHIOPIAN_WEEK_DAY[day_index - 1];
}
export function ToEthiopianCalanderRaw(gc_date)
{
  const year = gc_date.getFullYear();
  const month = gc_date.getMonth() + 1;
  const day = gc_date.getDate();
  
    const etDate = toEthiopian(year, month, day);

    return etDate;
}
export function ToEthiopianCalander(gc_date, payroll_preview = false) {
    const year = gc_date.getFullYear();
    const month = gc_date.getMonth() + 1;
    const day = gc_date.getDate();
    const etDate = toEthiopian(year, month, day); 


    const ec_year = etDate[0];
    const ec_month = etDate[1] - 1;
    const ec_day = etDate[2];
    const day_of_the_week = gc_date.getDay();

    return payroll_preview ? `(${(ec_month + 1)})${ETHIOPIAN_MONTHS[ec_month]}, ${ec_year}` : `${ETHIOPIAN_WEEK_DAY[day_of_the_week]}, ${ETHIOPIAN_MONTHS[ec_month]} ${ec_day}, ${ec_year}`;
}
export function ToEthiopianCalander_MY(gc_date, payroll_preview = false) {
  const year = gc_date.getFullYear();
  const month = gc_date.getMonth() + 1;
  const day = gc_date.getDate();
  const etDate = toEthiopian(year, month, day); 


  const ec_year = etDate[0];
  const ec_month = etDate[1] - 1;
  const ec_day = etDate[2];
  const day_of_the_week = gc_date.getDay();

  return  `${ETHIOPIAN_MONTHS[ec_month]} - ${ec_year}`;
}
