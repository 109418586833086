import classes from './MessageCard.module.css'

import UIButton from '../../../../../pages/Components/UIButton/UIButton'
import { Empty, Envelope, Eye, EyeClosed } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'

export default function MessageCard({MsgPkg})
{

    const [ViewMessage, SetViewMessage] = useState(false);


    useEffect(()=>{
        
    }, [])
    


    return (
        <div className={`${classes.message_card_main_wrapper}`}>
                <li className={`${ViewMessage ? classes.reading : ''}`} onClick={()=>{}} >
                    <span className={`${classes.chef_image_wrapper}`}>
                        <img src="https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659651_640.png" alt="" />
                    </span>
                    <span className={`${classes.chef_name_wrapper}`}>
                        <span className={`${classes.send_name}`}>{MsgPkg.USR_FirstName} {MsgPkg.USR_LastName}</span> Sent you a message
                    </span>
                    <span className={`${classes.view_message_action_button_wrapper}`}>
                        <UIButton onClick={()=>{SetViewMessage(!ViewMessage)}} Icon={ViewMessage ? <EyeClosed/> : <Eye/> } label={ViewMessage ? "Close" : "Read"} Variant='Secondary' />
                        <UIButton onClick={()=>{}} Icon={<Empty/> } label={"Clear"} Variant='Secondary' />
                    </span>
                </li>

                <div className={`${ViewMessage ? classes.main_message : classes.main_message_hide}`}>
                        <p>{MsgPkg.AUM_Message}</p>
                    </div>
        </div>
    )
}