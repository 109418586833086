import classes from './UpdateEmployeeBank.module.css'
import data_win from '../../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../../../../SharedComp/Utility'

//lib
import { useAuth } from '../../../../../../../../AuthProvider'
import { useToast } from '../../../../../../../../ToastContext'
import { QueryAllMasterDataBank } from '../../../../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I';
import { UpdateEmployeeBankInfo } from '../../../../../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployeeUpdator'
import { useTargetEmployee } from '../../../../SingleEmployeeContextProvider/SingleEmployeeContextProvider'

export default function UpdateEmployeeBank({OpenController})  
{

    const sharedData = useTargetEmployee();
    const auth = useAuth();
    const notify = useToast();

    const [BanksList, SetBanksList] = useState([]);

    const [AccountNumber, SetAccountNumber] = useState(sharedData.CompanyToEmployeeProfile.CTEP_BankAccount);
    const [SelectedBank, SetSelectedBank] = useState({ value: sharedData.CompanyToEmployeeProfile.HRMBNKT_ID, label: sharedData.CompanyToEmployeeProfile.HRMBNKT_Name });
    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("account_number", AccountNumber);
            fd.append("seletced_bank", JSON.stringify(SelectedBank));
            fd.append("employee_id", sharedData.Core.JAKSBE_SyntheticID);

            const res = await UpdateEmployeeBankInfo(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            sharedData.Update();
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    const GetBankList = async()=>
    {
        try 
        {
            const main_res = await QueryAllMasterDataBank(auth.token);
            PrepareDropDownList(main_res, SetBanksList);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Bank List");
        }
    }

    useEffect(()=>{
        GetBankList();
    }, [])


    return(
        <div className={`${data_win.main_data_creator_wrapper} ${classes.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main} ${classes.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Update <span className={`${data_win.data_create_name}`}>Bank Information</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput input={
                                                    <Select 
                                                        value={SelectedBank}
                                                        onChange={(data)=>{SetSelectedBank(data)}} 
                                                        options={BanksList} 
                                                        placeholder={"Select Bank"} 
                                                        isSearchable={true} 
                                                        styles={Custom_DD_Style} 
                                                        required ={true} /> } />
                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    value={AccountNumber}
                                                    onChange={(e)=>{SetAccountNumber(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Account Number'
                                                    required />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>)
}