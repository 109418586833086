import api from "../Util/axiosInstance";





export const QueryAllDispatchedTransaction = async(data)=>
{
    try  
    {
        const response = await api.post('api/stock-transfer/fetch-all-dispatched-transaction-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllIncomingTransaction = async(data)=>
{
    try  
    {
        const response = await api.post('api/stock-transfer/fetch-all-incoming-transaction-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestPayloadV2 = async(data)=>
{ 
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-request-payload-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestSenderInfo = async(data)=>
{ 
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-request-sender-info-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestReceiverInfo = async(data)=>
{ 
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-request-receiver-info-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestDriverInfo = async(data)=>
{ 
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-request-driver-info-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessStockRequestV2 = async(data)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/process-stock-tr-request-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessStockRequestShipingState = async(data)=>
{
    try 
    {
        const response = await api.post('api/stock-transfer/process-stock-tr-request-shiping-state-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ReceiveStockRequestV2 = async(data)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/process-request-receive-transfer-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}