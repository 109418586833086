import classes from './StockRequestInputPkg.module.css'

import { useAuth } from '../../../../../../../AuthProvider';

import Select from 'react-select'

import MasterDataRegInput from '../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../../../../../SharedComp/BaseUrlConfig';
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../../../SharedComp/Utility'
import { FetchWarehouseByBranch } from '../../../../../../../SharedComp/API/Warehouse/API_FE_Warehouse';
import { QueryAllInventoryItemsByBranch } from '../../../../../../../SharedComp/API/InventoryItem/API_FE_InventoryItem';


export default function StockRequestInputPkg(
                        {
                        HandleInvItemSelect,  
                        HandlInvItemQuantity, 
                        idx
                      }) 
{

    const auth = useAuth();
    const [PossibleChoiceList, SetPossibleChoiceList] = useState();
    const [LockedItem, SetLockedItem] = useState();
    const [SelectedInventotyItem, SetSelectedInventotyItem] = useState([]);
    const [Quantity, SetQuantity] = useState();

    const [DropDownOption, SetDropDownOption] = useState([]);

    const [storeDropDownOption, setStoreDropDownOption] = useState([]);

    useEffect(() => 
    {
        FetchIvItems();
        fetchWarehouse();
    }, [])

    const LockOnItem = async(data)=>
    {
      SetSelectedInventotyItem(data);
      for(let  i = 0; i < PossibleChoiceList.length; i++) 
      {
        if(PossibleChoiceList[i].IvtID === data.value)
        {
          SetLockedItem(PossibleChoiceList[i]);
          break;
        }
      }
    }
    const fetchWarehouse = async () => {
      try {
        
            const fd = new FormData();
            fd.append("ID", auth.activeWorkShop);
            const main_res = await FetchWarehouseByBranch(fd);

          setStoreDropDownOption([]);
          PrepList(main_res, setStoreDropDownOption, 'WHName', 'WhID');

      }catch(err) 
      {

      }
    }
    const FetchIvItems = async () => 
      {
        try 
          {
            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);
            const main_res = await QueryAllInventoryItemsByBranch(fd);
            PrepList(main_res, SetDropDownOption, 'IVItemName', 'IvtID');
          
            PrepList(main_res, SetDropDownOption, 'IVItemName', 'IvtID');
           SetPossibleChoiceList(main_res);
          }catch(err) 
          {

          }
      } 
    function PrepList(data, bucket, key_name, key_find) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;

          // console.log(object);
          for (const key in object) {

            const active = String(key);
            if (active.toLowerCase() === key_name.toLowerCase()) {

              const val = object[key];
              value_lable = val;

            } else if (active.toLowerCase() === key_find.toLowerCase()) {

              const val = object[key];
              value_key = val;

            }

            

            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }

          }

          
        }
      
        bucket(newOptionList);
      }


    return (
        <div className={`${classes.input_list_wrapper}`}>

                <div className={`${classes.input_fold_wrapper}`}>
                    
                    <div className={`${classes.single_input_wrapper_stock_req}`}>
                      <MasterDataRegInput input={
                        <Select
                            value={SelectedInventotyItem}
                            onChange={(data)=>{LockOnItem(data);HandleInvItemSelect(data, idx)}}
                            name={"dd_data"}
                            options={DropDownOption}
                            placeholder={"Inventory..."}
                            isSearchable={true}
                            styles={Custom_DD_Style}
                            required ={true}
                        /> } /> 
                    </div>

                    <div className={`${classes.single_input_wrapper_stock_req}`}>
                      <MasterDataRegInput 
                          input={<input 
                          value={ Quantity} 
                          onChange={(e)=>{SetQuantity(e.target.value); HandlInvItemQuantity(e.target.value, idx)}} 
                          name='item_quantity' min={0.00001} 
                          type="number" 
                          step="any" 
                          placeholder='Quantity...' 
                          title='' 
                          autoComplete='off' 
                          required />} 
                          tip={LockedItem ? `Use '${LockedItem.UomName}'` : `Select Inventory First to See UOM`} />
                      
                    </div>

                    {/* <div className={`${classes.single_input_wrapper_stock_req}`}>
                      <MasterDataRegInput 
                        input={<input 
                        value={ Brand} 
                        onChange={(e)=>{SetBrand(e.target.value); HandleIvnItemBrand(e.target.value, idx)}} 
                        name='item_brand'
                        type="text"
                        placeholder='Brand...' 
                        title='' 
                        autoComplete='off' 
                        />} 
                        tip={"Optional"}
                        />
                    </div> */}

                  </div>
            
        </div>
    )
}