import classes from './ReceiveOrderedItem.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'


 

//lib
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'
import { QueryProductReadyItemByGroupID } from '../../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import UIButton from '../../../../../Components/UIButton/UIButton'
import { CheckCircle } from '@phosphor-icons/react'
import { useDAControl } from '../../../../../../DAControlContext'
import { AddPastryShelvesProduct } from '../../../../../../SharedComp/API/ShopShelf/API_FE_PastryShelves'
import LoadingPlaceholder from '../../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'

const generateAnimationDelay = (index) => `${index * 0.15}s`;
export default function ReceiveOrderedItem({OpenController, Refresh_CALLBACK, ShipmentCode})
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();
    const [Loading, SetLoading] = useState(true);
    const [TestArray, SetTestArray] = useState([]);

    function CloseModal() 
    {
        OpenController(false);
    }

    const FetchReadyProducts = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append('group_id', ShipmentCode);

            const main_res = await QueryProductReadyItemByGroupID(fd);
            if(main_res.length <= 0) 
            {
                notify.ShowError("The code is invalid or it is already processed!");
                OpenController(false);
                return;
            }
            SetTestArray(main_res[0].KSOBATCH_PackageList);
            SetLoading(false);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const HandleProductReceive = async()=>
    {

        notify.ShowLoad("Processing Your request...", 10)
        try 
        {
            const fd = new FormData();
            fd.append('group_id', ShipmentCode);
            fd.append("source_branch", auth.activeWorkShop);
            const main_res = await AddPastryShelvesProduct(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            Refresh_CALLBACK(Date.now());
            CloseModal();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    useEffect(()=>{
        FetchReadyProducts();
    }, [])

    
    return(
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Accepted <span className={`${data_win.data_create_name}`}>Product Order Response</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

           {!Loading && <div className={`${data_win.data_body}`}>

            <div className={`${classes.list_data_items}`}>
                <div className={`${classes.data_list_inner_wrapper}`}>
                        {
                            TestArray.map((item, idx)=>(
                                <li key={item.CKP_ItemName + idx} style={{'--animation-delay': generateAnimationDelay(idx + 1)}}>
                                    <span>{item.CKP_ItemName}</span>
                                    <span>{item.KPSR_PendingBackUp.KPPO_OriginalOrderCount} Sheet Ordered</span>
                                    <span>{item.KPSR_PendingBackUp.KPPO_OriginalOrderCount} Sheet @ {item.KPSHIP_ProvidedSlice} Slice - Delivered</span>
                                    <span>{parseFloat(item.KPSR_PendingBackUp.KPPO_ProductCount / item.KPSR_PendingBackUp.KPPO_OriginalOrderCount).toFixed(2) * 100}% Accuracy</span>
                                    <span><UIButton onClick={()=>{}} Icon={<CheckCircle/>} label={"Accepted"}/></span>
                                </li>
                            ))
                        }
                        
                    </div>
                </div>    
                <div className={`${data_win.form_action_button_wrapper}`}>
                    <button onClick={()=>{
                        DAControl.Trigger('Approve Product',
                        'You are confirming the receipt of the listed product, which will be placed in your workspace. which you can locate it on the pastry shelf after this action.',
                        true,
                        6,
                        (data)=>{
                            if(data)
                            {
                                HandleProductReceive();
                            }
                        })
                    }} className={`${data_win.form_action_button_save}`}>Approve</button>
                    <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                </div>    
            
            </div>}
            {
                Loading && 
                <LoadingPlaceholder/>
            }
        </div>

    </div>
    )
}