import classes from './WhatsGPS.module.css'
import data_win from '../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useEffect, useState } from 'react'

import VehiclePreviewCard from './VehiclePreviewCard/VehiclePreviewCard';
import TrackRealtimeVhState from './TrackRealtimeVhState/TrackRealtimeVhState';
import { ArrowCircleUpLeft, BoxArrowDown, Link, LinkSimple, MapPin, XCircle } from '@phosphor-icons/react';
import UIButton from '../../Components/UIButton/UIButton';

import { VerifyWhatsgpsAccount,VerifyWhatsgpsCars, FetchWhatsgpsCarInfo, QueryAllVHDriverLink } from '../../../SharedComp/API/GPS/API_FE_WhatsGPS';
import CreateVehicleLink from './CreateVehicleLink/CreateVehicleLink';
import { useAuth } from '../../../AuthProvider';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
export default function WhatsGPS()
{

    const auth = useAuth();
    const [OpenCreateVHLink, SetOpenCreateVHLink] = useState(false)
    const [OpenTrackVhView, SetOpenTrackVhView] = useState(false);
    const [OpenGpsTrackList, SetOpenGpsTrackList] = useState(false);

    const [DeviceList, SetDeviceList] = useState([]);
    const [UserAccountInfo, SetUserAccountInfo] = useState();

    const [AllLinkedDevice, SetAllLinkedDevice] = useState([]);

    const [SelectedVH, SetSelectedVH] = useState();
    const [Loading, SetLoading] = useState(false);


    const SelectVHCard = async(data)=>
    {
        SetSelectedVH(data);
        SetOpenTrackVhView(true);
    }
    const QueryGPSTrackDeviceInfo = async() =>
    {
        try 
        {
            const AccountGPSRes = await VerifyWhatsgpsAccount();
            const CarRes = await VerifyWhatsgpsCars(AccountGPSRes.data.token);
            SetUserAccountInfo(AccountGPSRes.data); 
            // localStorage.setItem('GPS-TOKEN', AccountGPSRes.data.token);
            SetDeviceList(CarRes.data);
            // localStorage.setItem('GPS-ACTIVE-CAR', CarRes.data[0].carId);
        }catch(err) 
        {
            
        }
    }

    const GetAllLinkedDevice = async()=>
    {
        try 
        {
            const main_res = await QueryAllVHDriverLink(auth.token);
            SetAllLinkedDevice(main_res);
        }catch(err) 
        {

        }
    }

    const QueryPrimaryData = async()=>
    {
        SetLoading(true);
        try {
           await GetAllLinkedDevice();
           await QueryGPSTrackDeviceInfo();
           SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }
    

    useEffect(()=>{
        QueryPrimaryData();
    }, [OpenCreateVHLink])

    return (
        <div className={`${classes.whats_gps_main_wrapper}`}>
            {OpenCreateVHLink && <CreateVehicleLink VList={DeviceList} OpenController={SetOpenCreateVHLink}/>}
            {OpenTrackVhView && <TrackRealtimeVhState VHData={SelectedVH} OpenController={SetOpenTrackVhView}/>}

            {!OpenGpsTrackList && <div className={`${classes.left_gpt_target_list}`}>
            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ViewTrackedDevice &&    <UIButton onClick={()=>{SetOpenGpsTrackList(!OpenGpsTrackList)}} Variant='Secondary' Icon={<MapPin weight='fill'/>} label={"Traced Device"}/> }
            </div>}

            {OpenGpsTrackList && <div className={`${classes.side_gps_menu_track_list}`}>
                
                <div className={`${classes.gps_track_list_side_body}`}>
                   {DeviceList.length > 0 && <li style={{color: 'var(--text_light_white)', background: `var(--background-dark_minus)`, textAlign: 'center', border: '1px solid var(--background-light)', fontWeight: 'bold', marginBottom: '10px'}}>
                        <span>Account Owner</span>
                        <span>Vehicle ID</span>
                        <span>Vehicle IMEI</span>
                    </li>}
                    {
                        DeviceList.map((item, idx)=>(
                            <li key={item.imei}>
                                <span>{UserAccountInfo.userName}</span>
                                <span> {item.carId}</span>
                                <span>{item.imei}</span>
                            </li>
                        ))
                    }
                    {
                        DeviceList.length === 0 && 
                        <div className={`${classes.empty_vh_list_noti_wrapper}`}>
                            <ul>Try refresh the page or check WhatsGPs account verification</ul>
                            <ul>If both didn't help, <strong>There might not be any registered device</strong></ul>
                        </div>
                    }
                </div>

                <div className={`${classes.gps_track_list_side_header}`}>
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateDeviceLink &&  <UIButton onClick={()=>{SetOpenCreateVHLink(true)}} Icon={<Link weight='bold'/>} label={"Link Vehicle"}/> }
                    <UIButton Variant='Danger' onClick={()=>{SetOpenGpsTrackList(false)}} Icon={<XCircle weight='fill'/>} label={"Close"}/>
                </div>
                
            </div>}
            
            <div className={`${classes.card_view_wrapper}`}>
                {!Loading &&
                    AllLinkedDevice.map((item, idx)=>
                    (
                        <VehiclePreviewCard Data={item} key={idx} HandleClick={SelectVHCard}/>
                    ))
                }
                {!Loading && AllLinkedDevice.length === 0 &&  

                    <div className={`${classes.empty_box_preview_tdl}`}>
                        <li className={`${classes.link_device_icon_placeholder}`}><LinkSimple weight='bold'/></li>
                        <li>Link Driver With Tracked Vehicle to see them here!</li>
                    </div>

                }
                {
                    Loading && 
                    <LoadingPlaceholder preview_text='Loading Vehicle Information'/>
                }
            </div>

                
                

              

        </div>
    )
}