import api from "../Util/axiosInstance";



 
 
export const AllocateNewPettyCashBudgetForBranch = async(data)=>{
    try 
    {
        const response = await api.post('api/petty-cash-manage/allocate-petty-cash-budget-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const ApproveCashReleasePettyCashV2 = async(data)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/release-petty-cash-exp-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPettyCashAllocationOmni = async(data)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/query-petty-cash-allocation-hist-omni-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPettyCashTransactionDetailByID = async(data)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/query-petty-cash-transaction-detail-by-id-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPettyCashLogWithApprovedByID = async(data)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/query-petty-cash-log-with-approver-by-id-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
} 
export const QueryAllBranchActiveBudgets = async(token)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/query-all-branch-active-cash-budget-v2', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPettyCashTransactionByBranchID = async(data)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/query-petty-cash-transaction-by-branch-id-all-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ApprovePettyCashLogReport = async(data)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/approve-petty-cash-log-report-id-all-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const SuspendPettyCashActivity = async(data)=>
{
    try 
    {
        const response = await api.post('api/petty-cash-manage/suspend-petty-cash-activity-id-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    } 
}