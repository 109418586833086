import api from "../Util/axiosInstance";




export const CreateNewUserAccess = async(data)=>
{
    try  
    {
        const response = await api.post('api/auth/account-access/user-access-create', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateUserAccess = async(data)=>
{
    try  
    {
        const response = await api.post('api/auth/account-access/user-access-update', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateMainMenuPackage = async(data)=>
{
    try  
    {
        const response = await api.post('api/auth/account-access/update-menu-pkg', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const SendVerificationMail = async(data)=>
{
    try  
    {
        const response = await api.post('api/auth/account-access/send-verification-mail', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CheckEmailVerificationCode = async(data)=>
{
    try  
    {
        const response = await api.post('api/auth/account-access/check-email-verification', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}