import api from "../Util/axiosInstance";






export const FetchProductLineMasterGroupAll = async (token)=>
{
    try 
    {
        const response = await api.post('api/product-line/fetch-prod-cat-all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}