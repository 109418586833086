import classes from './DispatchActiveOrders.module.css'
import {FetchTargetOrderGroup} from '../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import {QueryAssignedChef} from '../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext';
import { useState } from 'react';
import { useDAControl } from '../../../DAControlContext';
import { useEffect } from 'react';

import OrderGroup from './Component/OrderGroup/OrderGroup';
import { Empty } from '@phosphor-icons/react';
import { useGNP } from '../../../GlobalNotificationProvider';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';

export default function DispatchActiveOrders() 
{
    const auth = useAuth();
    const GNP = useGNP();

    const [OrderGroupList, SetOrderGroupList] = useState([]);
    const [MousePosition, SetMousePosition] = useState({ x: 0, y: 0 });
    const [LocalChefsList, SetLocalChefsList] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);
    const notify = useToast();

    const [BranchDataMap, SetBranchDataMap] = useState(new Map());

    const handleMouseMove = (event) => {
        SetMousePosition({
        x: event.clientX,
        y: event.clientY,
        });
    };

    const ParseDataForView = async(data_list)=>
    {
        const temp_map = new Map();
        for(let i = 0; i < data_list.length; i++) 
        {
            if(!temp_map.has(data_list[i].BranchID))
            {
                temp_map.set(data_list[i].BranchID, [data_list[i]]);
            } else 
            {
                const _temp_array = temp_map.get(data_list[i].BranchID);

                _temp_array.push(data_list[i]);

                temp_map.set(data_list[i].BranchID, _temp_array);
            }
        }
 
        SetBranchDataMap(new Map(temp_map));
    }
    
    const FetchGroupList = async() =>
    {
        try 
        {
            const main_res = await FetchTargetOrderGroup(auth.activeWorkShop);
            // SetOrderGroupList(main_res);
            ParseDataForView(main_res); 
        } catch(err) 
        {

        }
    }


    const FetchAssignedChef = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);
            const coll = await QueryAssignedChef(fd);
            SetLocalChefsList(coll);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    useEffect(() => 
    {
        FetchAssignedChef();
    }, [auth.activeWorkShop, RefreshView, GNP.EVENT_SpecialOrderArrival, GNP.EVENT_DailyOrderArrival])


    useEffect(()=>{
        FetchGroupList();
    }, [auth.activeWorkShop, RefreshView, GNP.EVENT_SpecialOrderArrival, GNP.EVENT_DailyOrderArrival])

    return (
        <div onMouseMove={handleMouseMove} className={`${classes.dispatch_master_wrapper}`}>
            
            <div className={`${classes.order_group_wrapper_main}`}>

                {
                    Array.from(BranchDataMap.values()).map((item, idx)=>(
                        <OrderGroup ChefsList={LocalChefsList} MGPos={MousePosition} Data={item} key={idx}/>
                    ))
                }
                {
                        BranchDataMap.size === 0 && <div className={`${classes.request_queue_halo}`}>
                                <h1><Empty /></h1>
                                <h2>No Order! No Dispatch!</h2>
                        </div>
                    }

            </div>



        </div>
    )
}