import classes from './InitProductionOrder.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faCircleArrowDown, faCircleArrowUp, faCloud, faMinus, faMoon, faPlusCircle, faSpinner, faSun, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import {SingleProductPackageOrderTemplate} from './ProductOrderUtils'

import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'
import Select from 'react-select'

import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import DynamicPoInput from '../DynamicPoInput/DynamicPoInput'
import { useDAControl } from '../../../../DAControlContext'

import { CreateProductionOrder, FetchBranchWarehouse } from '../../../../api'
import {SaveProductionOrderConfig} from '../../../../SharedComp/API/Production/API_ProductionOrder'

import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'
import { Tooltip } from 'react-tooltip';
import { CreateMasterProductionOrderV2 } from '../../../../SharedComp/API/Production/API_MasterProductionV2'

export default function InitProductionOrder({CloseCleanCALLBACK, CloseModal, Data, DataMix}) 
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const [Loading, SetLoading] = useState(false);
    const [DayShift, SetDayShift] = useState(true);
    const [UnifiedStock, SetUnifiedStock] = useState(false);
    const [MultiplyWithBatch, SetMultiplyWithBatch] = useState(true);
    const [FlourOnly, SetFlourOnly] = useState(true);
    const [HideAdvanceSetting, SetHideAdvanceSetting] = useState(false); // UI
    const [OrderPkgName, SetOrderPkgName] = useState();

    //  util
    const [ShowSaveInput, SetShowSaveInput] = useState(false);
    const [FormActionType, SetFormActionType] = useState("submit");

    // Render Component
    const RawMaterialRenderComponentList = useRef(new Map());

    // Unified Render Component
    const UnifiedRawMaterialRenderComponentList = useRef(new Map());

    // Component Data
    const OrderProductPackage = useRef(new Map());

    const ProdMainBatchCount = useRef(new Map());
    const ProdMixBatchCount = useRef(new Map());
    
    // This guy hold active/selected Product name YES the NAME
    const [ActiveRawMaterialViewID, SetActiveRawMaterialViewID] = useState();
    // This is the temporary list holder like when the suer click order A this guy get filled with A material
    // list and when clicked B this guy hold B raw material pritty fucking easy
    const [DynamicMaterialInputComponentWrapper, SetDynamicMaterialInputComponentWrapper] = useState([]);

    const [WorkspaceWarehouseList, SetWorkspaceWarehouseList] = useState([]);
    const [SelectedWarehouse, SetSelectedWarehouse] = useState();
 
    function AddInitialInput_DATA() 
    {
        if(!Data) { return; }
        // First Proces Raw Product
        for(let i = 0; i < Data.length; i++)
        {
            const key = nanoid();
            // fisrt time
            const list_input = [];
            list_input.push(
                <DynamicPoInput  

                    // For  which product is belong this raw material
                    RootName={Data[i].Data.CMBP_Name}
                    // When ever the user type quantity for raw material this function is invocked
                    HandleMaterialQantity={HandleMaterialQuantity}

                    // and this for the type mostly for flour i guess
                    HandleMaterialType={HandleMaterialType} 

                    // This is unique ID for each mfing input component when the user click add
                    // new Raw Material  this nique key is generated
                    UID={key} 
                />
            );
            RawMaterialRenderComponentList.current.set(Data[i].Data.CMBP_Name.toString(), list_input);
        }
    }
    function AddInitialInput_MIX() 
    {
       // First Proces Raw Product
       for(let i = 0; i < DataMix.length; i++)
        {
            const key = nanoid();
            // fisrt time
            const list_input = [];
            list_input.push(
                <DynamicPoInput  

                    // For  which product is belong this raw material
                    RootName={DataMix[i].Data.MixName}
                    // When ever the user type quantity for raw material this function is invocked
                    HandleMaterialQantity={HandleMaterialQuantity}

                    // and this for the type mostly for flour i guess
                    HandleMaterialType={HandleMaterialType} 

                    // This is unique ID for each mfing input component when the user click add
                    // new Raw Material  this nique key is generated
                    UID={key} 
                />
            );
            RawMaterialRenderComponentList.current.set(DataMix[i].Data.MixName.toString(), list_input);
        } 
    }

    function AddInitialInput() 
    {
        AddInitialInput_DATA();
        AddInitialInput_MIX();
    }
    function CreateProdMainBatch(key, val)
    {
        ProdMainBatchCount.current.set(key, val);
    }
    function CreateProdMixBatch(key, val) 
    {
        ProdMixBatchCount.current.set(key, val);
    }
    function ToggelUnified(val) 
    {

        if(val) // About to be true
        {
            SetActiveRawMaterialViewID("UNIFIED_STOCK");
            RawMaterialRenderComponentList.current.clear();
            
            const key = nanoid();
            const list_input = [];
            list_input.push(
                <DynamicPoInput  
    
                    // For  which product is belong this raw material
                    RootName={"UNIFIED_STOCK"}
                    // When ever the user type quantity for raw material this function is invocked
                    HandleMaterialQantity={HandleMaterialQuantity}
    
                    // and this for the type mostly for flour i guess
                    HandleMaterialType={HandleMaterialType} 
    
                    // This is unique ID for each mfing input component when the user click add
                    // new Raw Material  this nique key is generated
                    UID={key} 
                />
            );
            UnifiedRawMaterialRenderComponentList.current.set("UNIFIED_STOCK", list_input);
            SetDynamicMaterialInputComponentWrapper(list_input);
        } else // About to be false
        {
            UnifiedRawMaterialRenderComponentList.current.clear();
            AddInitialInput();
        }

        SetUnifiedStock(val);
        
    }
    function SetFilterKey(key) 
    {
        if(UnifiedStock) 
        {
            SetActiveRawMaterialViewID("UNIFIED_STOCK");
            const list_ref = UnifiedRawMaterialRenderComponentList.current.get('UNIFIED_STOCK');
            if (list_ref) {
                SetDynamicMaterialInputComponentWrapper(list_ref);
            }
            return;
        }

        SetDynamicMaterialInputComponentWrapper([]);
        SetActiveRawMaterialViewID(key.toString());

        const list_ref = RawMaterialRenderComponentList.current.get(key);
        if (list_ref) {
            SetDynamicMaterialInputComponentWrapper(list_ref);
        }
    }
    function HandleMaterialQuantity(root_name, data, uid) 
    {
        if(!OrderProductPackage.current.has(root_name)) 
        {
            const prod_temp = new SingleProductPackageOrderTemplate();
            prod_temp.RootName = root_name;
            prod_temp.CreateQuantity(uid, data);
            OrderProductPackage.current.set(root_name, prod_temp);
        }else 
        {
            OrderProductPackage.current.get(root_name).CreateQuantity(uid, data);
        }
    }
    function HandleMaterialType(root_name, data, uid) 
    {
        if(!OrderProductPackage.current.has(root_name)) 
            {
                const prod_temp = new SingleProductPackageOrderTemplate();
                prod_temp.RootName = root_name;
                prod_temp.CreateItem(uid, data);
                OrderProductPackage.current.set(root_name, prod_temp);
            }else 
            {
                OrderProductPackage.current.get(root_name).CreateItem(uid, data);
            }
    }
    function AddNewInputField(id_to_add) {

        if(UnifiedStock) 
        {
            for (const [key, value] of UnifiedRawMaterialRenderComponentList.current.entries()) 
            {
                // key new key
                const hash_key = nanoid();

                // Create a new array to avoid mutating the existing state
                const newCompList = [...value, 
                    <DynamicPoInput  
                        RootName={ActiveRawMaterialViewID}
                        HandleMaterialQantity={HandleMaterialQuantity} 
                        HandleMaterialType={HandleMaterialType} 
                        UID={hash_key} 
                    />
                ];

                // Update RawMaterialRenderComponentList
                UnifiedRawMaterialRenderComponentList.current.set(key, newCompList);
    
                // Set the new state
                SetDynamicMaterialInputComponentWrapper(newCompList);
                
                // Get the fuck out #We are done
                break;
            }
        } else 
        {
            // Find the component list for the given key
            for (const [key, value] of RawMaterialRenderComponentList.current.entries()) {
                if (id_to_add === key) {

                    // key new key
                    const hash_key = nanoid();

                    // Create a new array to avoid mutating the existing state
                    const newCompList = [...value, 
                        <DynamicPoInput  
                            RootName={ActiveRawMaterialViewID}
                            HandleMaterialQantity={HandleMaterialQuantity} 
                            HandleMaterialType={HandleMaterialType} 
                            UID={hash_key} 
                        />
                    ];
                    // Update RawMaterialRenderComponentList
                    RawMaterialRenderComponentList.current.set(key, newCompList);

                    // Set the new state
                    SetDynamicMaterialInputComponentWrapper(newCompList);
                    
                    // Get the fuck out #We are done
                    break;
                }
            }
        }
    }
    
    function RemoveInputField(uid) 
    {
        
    }
    const FetchBranchWarehouseClinet = async()=>
    {
        try 
        {
            const res = await FetchBranchWarehouse(auth.activeWorkShop, auth.token);
            PrepareDropDownList(res, SetWorkspaceWarehouseList);

        }catch(err) 
        {
            console.log(err.message);
        }
    }
    const PreValidateData = async()=> 
    {
        // If the order is unified stock auto validte will take car of it
        if(UnifiedStock) 
        {
            return true; 
        }

        // If not
        for(let  i = 0; i < Data.length; i++) 
        {
            const _res = OrderProductPackage.current.has(Data[i].Data.CMBP_Name);
            if(_res) 
            {
                const data_pkg = OrderProductPackage.current.get(Data[i].Data.CMBP_Name);

                if(!data_pkg.ItemData) 
                {
                    SetFilterKey(Data[i].Data.CMBP_Name);
                    notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// Has No Inventory Item Selected! Please fix it!`);
                    return false;
                }
                if(!data_pkg.QuantityData) 
                {
                    SetFilterKey(Data[i].Data.CMBP_Name);
                    notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// No Inventory Item Quantity is not specified! Please fix it!`);
                    return false;
                }

                if(data_pkg.QuantityData.size !== data_pkg.ItemData.size) 
                {
                    SetFilterKey(Data[i].Data.CMBP_Name);
                    notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// Quantity and Inventory Item does not match! Please fix it!`);
                    return false;
                }
            } else 
            {
                SetFilterKey(Data[i].Data.CMBP_Name);
                notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// Has Data Mismatch! Please fix it!`);
                return false;
            }
        }
        for(let  i = 0; i < DataMix.length; i++) 
        {
            const _res = OrderProductPackage.current.has(DataMix[i].Data.MixName);
            if(_res) 
            {
                const data_pkg = OrderProductPackage.current.get(DataMix[i].Data.MixName);

                if(!data_pkg.ItemData) 
                {
                    SetFilterKey(DataMix[i].Data.MixName);
                    notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// Has No Inventory Item Selected! Please fix it!`);
                    return false;
                }
                if(!data_pkg.QuantityData) 
                {
                    SetFilterKey(DataMix[i].Data.MixName);
                    notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// No Inventory Item Quantity is not specified! Please fix it!`);
                    return false;
                }

                if(data_pkg.QuantityData.size !== data_pkg.ItemData.size) 
                {
                    SetFilterKey(DataMix[i].Data.MixName);
                    notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// Quantity and Inventory Item does not match! Please fix it!`);
                    return false;
                }
            } else 
            {
                SetFilterKey(DataMix[i].Data.MixName);
                notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// Has Data Mismatch! Please fix it!`);
                return false;
            }
            
        }

        return true;
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();

        const _valid_data = await PreValidateData();

        if(!_valid_data) { return; }

        if (FormActionType === 'save') 
        {
            SetShowSaveInput(true);
            return;
        }

        notify.ShowLoad("Processing order", 10);

        try 
        {
            const fd = new FormData();
            fd.append("is_day_shift", DayShift);
            fd.append("is_unified_stock_access", UnifiedStock);
            fd.append("batch_based", MultiplyWithBatch);
            fd.append("data_mix", JSON.stringify(DataMix));
            fd.append("data_prod", JSON.stringify(Data));
            fd.append("target_warehouse", JSON.stringify(SelectedWarehouse));
            fd.append("active_branch", auth.activeWorkShop);
            fd.append("ordered_by", auth.user.USR_UserAccountID);

            const prodmain_jsonstring = JSON.stringify(Array.from(ProdMainBatchCount.current.entries()));
            const prodmix_jsonstring = JSON.stringify(Array.from(ProdMixBatchCount.current.entries()));

            fd.append("prod_main_batch", prodmain_jsonstring);
            fd.append("prod_mix_batch", prodmix_jsonstring);

            const serializedPriceData = JSON.stringify(
                Array.from(OrderProductPackage.current, ([key, value]) => [
                  key,
                  {
                    ...value,
                    QuantityData: Array.from(value.QuantityData),
                    ItemData: Array.from(value.ItemData)
                  }
                ])
              );
            fd.append("Inventory", serializedPriceData);

            // const res = await CreateProductionOrder(fd);
            const res = await CreateMasterProductionOrderV2(fd);

            if(res.MyStateCode !== -1) 
            {
                notify.ShowSuccessUpdate(res.Msg, 10);
                CloseCleanCALLBACK();
            }else 
            {
                notify.ShowErrorUpdate(<div dangerouslySetInnerHTML={{ __html: res.Msg }} />, 10);
            }

        }catch(err) 
        {
            notify.ShowErrorUpdate(err, 10);
        }


    }
    const SaveOrderConfig = async(e)=>{
        e.preventDefault();
        SetShowSaveInput(false);
        notify.ShowLoad("Saving order", 10);

        try 
        {
            const fd = new FormData();
            fd.append("is_day_shift", DayShift);
            fd.append("is_unified_stock_access", UnifiedStock);
            fd.append("batch_based", MultiplyWithBatch);
            fd.append("data_mix", JSON.stringify(DataMix));
            fd.append("data_prod", JSON.stringify(Data));
            fd.append("target_warehouse", JSON.stringify(SelectedWarehouse));
            fd.append("active_branch", auth.activeWorkShop);
            fd.append("ordered_by", auth.user.USR_UserAccountID);
            fd.append("po_name", OrderPkgName);

            const prodmain_jsonstring = JSON.stringify(Array.from(ProdMainBatchCount.current.entries()));
            const prodmix_jsonstring = JSON.stringify(Array.from(ProdMixBatchCount.current.entries()));

            fd.append("prod_main_batch", prodmain_jsonstring);
            fd.append("prod_mix_batch", prodmix_jsonstring);

            const serializedPriceData = JSON.stringify(
                Array.from(OrderProductPackage.current, ([key, value]) => [
                  key,
                  {
                    ...value,
                    QuantityData: Array.from(value.QuantityData),
                    ItemData: Array.from(value.ItemData)
                  }
                ])
              );
            fd.append("Inventory", serializedPriceData);

            const res = await SaveProductionOrderConfig(fd);

            if(res.MyStateCode !== -1) 
            {
                notify.ShowSuccessUpdate(res.Msg, 10);
                CloseCleanCALLBACK();
            }else 
            {
                notify.ShowErrorUpdate(<div dangerouslySetInnerHTML={{ __html: res.Msg }} />, 10);
            }

        }catch(err) 
        {
            notify.ShowErrorUpdate(err, 10);
        }
    }

    useEffect(()=>{
        AddInitialInput();
        FetchBranchWarehouseClinet();
    }, []);

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            <div className={`${data_win.data_main} ${classes.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Creating <span className={`${data_win.data_create_name}`}>Production Order</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={SaveOrderConfig} method="post">
                                <div className={`${ShowSaveInput ? classes.config_name_setup_wrapper : classes.config_name_setup_wrapper_hide}`}>
                                    <input onChange={(e)=>{SetOrderPkgName(e.target.value)}} name='order_pkg_name' type="text" placeholder='Order Package Name' autoComplete='off' required />
                                    <button type='submit'>Save Config</button>
                                </div>
                            </form>
                            <form onSubmit={HandleSubmition} method="POST">
                                <div className={`${classes.form_wrapper}`}> 

                                    <div className={`${HideAdvanceSetting ? classes.batch_operation_action_hide :  classes.batch_operation_action}`}>
                                        
                                        <div className={`${classes.order_mark_action}`}>
                                            <li className={`${classes.shift_controller} ${DayShift ? classes.day_shift : classes.night_shift}`} onClick={()=>{SetDayShift(!DayShift)}}><FontAwesomeIcon icon={DayShift ? faSun : faMoon}></FontAwesomeIcon> {DayShift ? 'Day Shift' : 'Night Shift'}</li>
                                            
                                            {/* <li onClick={()=>{SetMultiplyWithBatch(!MultiplyWithBatch)}}>
                                                <input onChange={(e)=>{SetMultiplyWithBatch(e.target.value)}} checked={MultiplyWithBatch} type="checkbox" />
                                                <span>Batch-Based</span>
                                            </li>
                                            <li onClick={()=>{SetFlourOnly(!FlourOnly)}}>
                                                <input onChange={(e)=>{SetFlourOnly(e.target.value)}} checked={FlourOnly} type="checkbox" />
                                                <span>Flour Only</span>
                                            </li> */}
                                       
                                        </div>


                                        <div className={`${classes.left_size_manipulate_wrapper}`}>
                                        
                                            <button name="action" type='submit' onClick={(e)=>{SetFormActionType("save")}} className={`${classes.order_save}`}><span><FontAwesomeIcon icon={faCloud}></FontAwesomeIcon></span> <span>Save</span></button>
                                            { (DataMix.length + Data.length > 1) && <li onClick={()=>{
                                                        DAControl.Trigger(`${UnifiedStock ? 'Disable Unified Stock Access' : 'Enable Unified Stock Access'}`, 
                                                            `You are about to ${UnifiedStock ? 'Disable' : 'Enable'} unified stock inventory access. 
                                                                This action will affect your current entries, if any.`, 
                                                            !UnifiedStock,
                                                            7, (val)=>{
                                                                if(val !== null) 
                                                                {
                                                                    ToggelUnified(val)
                                                                }
                                                            }   
                                                        );
                                                        }}>
                                                    <input checked={UnifiedStock} onChange={(e)=>{}} type="checkbox" />
                                                    <span>Unified Stock</span>
                                                </li> }
                                            {/* <div className={`${classes.store_choice}`}>
                                                <MasterDataRegInput input={
                                                        <Select
                                                            onChange={(data)=>{SetSelectedWarehouse(data)}}
                                                            name={"dd_data"}
                                                            value={SelectedWarehouse}
                                                            options={WorkspaceWarehouseList}
                                                            placeholder={"Warehouse..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                    />
                                                    }  />
                                            </div> */}
                                        </div>
                                        
                                    </div>
                                    <div className={`${classes.toggle_advance_setting_wrapper}`}>
                                        <button type='button' onClick={()=>{SetHideAdvanceSetting(!HideAdvanceSetting)}}><FontAwesomeIcon icon={HideAdvanceSetting ? faCircleArrowDown : faCircleArrowUp}></FontAwesomeIcon></button>
                                    </div>

                                    <div className={`${classes.dat_wrap_flex}`}>
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <div className={`${classes.input_adder}`}>
                                                <li>Target Product</li>
                                            </div>
                                            <div className={`${classes.po_mat_list}`}>
                                                {
                                                    Data.map((item, idx)=>(
                                                        <div 
                                                            key={idx}  
                                                            onClick={()=>{SetFilterKey(item.Data.CMBP_Name)}} 
                                                            className={`${classes.order_prod_list} 
                                                            ${ActiveRawMaterialViewID === item.Data.CMBP_Name ? classes.order_prod_list_active : ''}`}>
                                                                <div className={`${classes.order_item_spec}`}><MasterDataRegInput input={<input value={item.Data.CMBP_Name} type="text" title={item.Data.CMBP_Name} autoComplete='off' readOnly />} /></div>
                                                                <div className={`${classes.order_item_spec}`}><MasterDataRegInput input={<input onChange={(e)=>{CreateProdMainBatch(item.Data.CMBP_Name, e.target.value)}} name='po_batch' type="number" min={1} placeholder='Batch' title='' autoComplete='off' required />} /></div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    DataMix.map((item, idx)=>(
                                                        <div 
                                                            key={item.Data.MixName + idx} 
                                                            onClick={()=>{SetFilterKey(item.Data.MixName)}}
                                                            className={`${classes.order_prod_list}
                                                            ${ActiveRawMaterialViewID === item.Data.MixName ? classes.order_prod_list_active : ''}`}>
                                                            <div className={`${classes.order_item_spec}`}><MasterDataRegInput input={<input value={item.Data.MixName} type="text" title={item.Data.MixName} autoComplete='off' readOnly />} /></div>
                                                            <div className={`${classes.order_item_spec}`}><MasterDataRegInput input={<input onChange={(e)=>{CreateProdMixBatch(item.Data.MixName, e.target.value)}} name='po_batch' type="number" min={1} placeholder='Batch' title='' autoComplete='off' required />} /></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        <div className={`${classes.raw_material}`}>
                                            <div className={`${classes.input_adder}`}>
                                                <li className={`${UnifiedStock ? classes.unified_stock_name_head : ''}`}>[{ActiveRawMaterialViewID}] #Complementary Stock</li>
                                                <button onClick={()=>{AddNewInputField(ActiveRawMaterialViewID)}} type='button'><span><FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></span> <span>Extra Stock</span></button>
                                            </div>
                                            <div className={`${classes.po_mat_list}`}>
                                                    {DynamicMaterialInputComponentWrapper.map((item, idx) =>  ( 
                                                        <div className={`${classes.input_fild_shild}`} key={item.props.RootName + idx}>
                                                            {React.cloneElement(item, 
                                                                {ReadBack_Quantity: OrderProductPackage.current.has(ActiveRawMaterialViewID) 
                                                                    ?  OrderProductPackage.current.get(ActiveRawMaterialViewID).QuantityData 
                                                                        : null, 
                                                                ReadBack_Item: OrderProductPackage.current.has(ActiveRawMaterialViewID) 
                                                                    ? OrderProductPackage.current.get(ActiveRawMaterialViewID).ItemData 
                                                                        : null})}

                                                            {/* {POMatItems.length > 1 ? <button type='button' onClick={()=>{RemoveInputField(item.props.idx)}} className={`${classes.remove_button_wrapper}`}>
                                                                <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
                                                                </button> : <span  className={`${classes.remove_button_wrapper_fake}`}></span> } */}
                                                        </div>
                                                    ))}
                                            </div>
                                            
                                        </div> 
                                        
                                    </div>
                                            
                                </div>

                                

                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} type="submit"   name="action" value="submit" onClick={(e)=>{SetFormActionType("submit")}}   className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Order'}</button>
                                    <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}