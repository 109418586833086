import classes from './DynamicStockInput.module.css'

import Select from 'react-select'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../../../../AuthProvider';
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'
import { objectToMap } from '../../../../../SharedComp/Utility';
import { FetchWarehouseByBranch } from '../../../../../SharedComp/API/Warehouse/API_FE_Warehouse';
import { QueryAllInventoryItemsByBranch } from '../../../../../SharedComp/API/InventoryItem/API_FE_InventoryItem';

export default function DynamicStockInput({
  HandleInvItemSelect, 
  HandleInvItemStore, 
  HandlInvItemQuantity, 
  HandleIvnItemBrand, 
  HandleUnitPrice, 
  HandleExDate, 
  idx}) 
{

    const auth = useAuth();
    const [PossibleChoiceList, SetPossibleChoiceList] = useState();
    const [LockedItem, SetLockedItem] = useState();
    const [SelectedInventotyItem, SetSelectedInventotyItem] = useState([]);
    const [SelectedStore, SetSelectedStore] = useState([]);
    const [Quantity, SetQuantity] = useState();
    const [UnitPrice, SetUnitPrice] = useState();
    const [Brand, SetBrand] = useState();
    const [ExDate, SetExDate] = useState();
    const [StoreUseEnforced, SetStoreUseEnforced] = useState(false);

    const [DropDownOption, SetDropDownOption] = useState([]);

    const [storeDropDownOption, setStoreDropDownOption] = useState([]);

    useEffect(() => 
    {
        FetchIvItems();
        fetchWarehouse();
    }, [])

    const LockOnItem = async(data)=>
    {
      SetSelectedInventotyItem(data);
      for(let  i = 0; i < PossibleChoiceList.length; i++) 
      {
        if(PossibleChoiceList[i].IvtID === data.value)
        {
          SetLockedItem(PossibleChoiceList[i]);
          break;
        }
      }
    }
    const fetchWarehouse = async () => {
        try {
            const fd = new FormData();
            fd.append("ID", auth.activeWorkShop);
            const main_res = await FetchWarehouseByBranch(fd);

            setStoreDropDownOption([]);
            PrepList(main_res, setStoreDropDownOption, 'WHName', 'WhID');

        }catch(err) 
        {

        }
    }
    const FetchIvItems = async () => 
    {

      try 
      {
        const fd = new FormData();
        fd.append("workspace_id", auth.activeWorkShop);
        const main_res = await QueryAllInventoryItemsByBranch(fd);
        PrepList(main_res, SetDropDownOption, 'IVItemName', 'IvtID');
         
        SetPossibleChoiceList(main_res);
      }catch(err) 
      {

      }

    } 
    function PrepList(data, bucket, key_name, key_find) {
        const newOptionList = [];
      
        for (var i = 0; i < data.length; i++) {
          
          let object = data[i];
      
          let value_key = null;
          let value_lable = null;

         // console.log(object);
          for (const key in object) {

            const active = String(key);
            if (active.toLowerCase() === key_name.toLowerCase()) {

              const val = object[key];
              value_lable = val;

            } else if (active.toLowerCase() === key_find.toLowerCase()) {

              const val = object[key];
              value_key = val;

            }

            

            if(value_lable !== null && value_key !== null) 
            {
              newOptionList.push({ value: value_key, label: value_lable  });
              break;
            }

          }

          
        }
      
        bucket(newOptionList);
      }

      function HandleInventoryItemSelection(data)
      {
        LockOnItem(data);
        HandleInvItemSelect(data, idx)

        
        const _target_item = PossibleChoiceList.find((item)=>item.IvtID === data.value);

        const all_rules = objectToMap(JSON.parse(_target_item.IVItemWorkspaceInfo));
        const my_rules = all_rules.get(auth.activeWorkShop);
        const my_store_config = my_rules.get('PrimaryStore');
        const _store_obj = {value: my_store_config.get('value'), label: my_store_config.get('label')};

        SetSelectedStore(_store_obj);
        HandleInvItemStore(_store_obj, idx)
        SetStoreUseEnforced(_target_item.IVItemEnforceStore === "Yes");
        
      }

    return (
        <div className={`${classes.input_list_wrapper}`}>

                  <div className={`${classes.input_fold_wrapper}`}>
                    <MasterDataRegInput input={
                      <Select
                          value={SelectedInventotyItem}
                          onChange={(data)=>{HandleInventoryItemSelection(data);}}
                          name={"dd_data"}
                          options={DropDownOption}
                          placeholder={"Inventory..."}
                          isSearchable={true}
                          styles={Custom_DD_Style}
                          required ={true}
                      /> } /> 

                  <MasterDataRegInput input={
                      <Select
                          isDisabled={StoreUseEnforced}
                          value={SelectedStore}
                          onChange={(data)=>{SetSelectedStore(data);HandleInvItemStore(data, idx)}}
                          name={"dd_data"}
                          options={storeDropDownOption}
                          placeholder={"To Store..."}
                          isSearchable={true}
                          styles={Custom_DD_Style}
                          required ={true}
                      /> } tip={StoreUseEnforced ? "Warehouse choice for this item is locked!" : null}  /> 
                  </div>
                    <div className={`${classes.input_fold_wrapper}`}>
                      <MasterDataRegInput 
                        input={<input 
                        value={ Brand} 
                        onChange={(e)=>{SetBrand(e.target.value); HandleIvnItemBrand(e.target.value, idx)}} 
                        name='item_brand'
                        type="text"
                        placeholder='Brand...' 
                        title='' 
                        autoComplete='off' 
                        />} 
                        />

                  <MasterDataRegInput 
                      input={<input 
                      value={ Quantity} 
                      onChange={(e)=>{SetQuantity(e.target.value); HandlInvItemQuantity(e.target.value, idx)}} 
                      name='item_quantity' min={0.00001} 
                      type="number" 
                      step="any" 
                      placeholder='Quantity...' 
                      title='' 
                      autoComplete='off' 
                      required />} 
                      tip={LockedItem ? `Use '${LockedItem.UomName}'` : `Select Inventory First to See UOM`} />
                    </div>

               <div className={`${classes.input_fold_wrapper}`}>
                <MasterDataRegInput 
                      input={<input 
                      value={ UnitPrice} 
                      onChange={(e)=>{SetUnitPrice(e.target.value); HandleUnitPrice(e.target.value, idx)}} 
                      name='item_unit_price' min={0.00001} 
                      type="number" 
                      step="any" 
                      placeholder='Unit Price...' 
                      title='' 
                      autoComplete='off' 
                      required />} />

                  <MasterDataRegInput 
                      input={<input 
                      onChange={(e)=>{SetExDate(e.target.value); HandleExDate(e.target.value, idx)}} 
                      name='expire_date' 
                      min={new Date().toISOString().split('T')[0]}
                      type="date" 
                      title='' 
                      autoComplete='off' 
                    /> }  tip={"Expire Date, You can find it on the lable, or you skip this."} />
               </div>
            
        </div>
    )
}