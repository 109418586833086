import classes from './MerchBatchInputUnit.module.css'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'

import { QueryAvialMerchMasterDataByBranch } from '../../../../../SharedComp/API/ShopShelf/API_FE_MerchShelves'
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { TrashSimple } from '@phosphor-icons/react'

import UIButton from '../../../../Components/UIButton/UIButton'

export default function MerchBatchInputUnit({ID, EntryPurge, UpdateActiveMerch, UpdateUnitPrice, UpdateQuantity}) 
{

    const [MerchList, SetMerchList] = useState([]);

    const [SelectedMerch, SetSelectedMerch] = useState('');
    const [Quantity, SetQuantity] = useState('');
    const [UnitPrice, SetUnitPrice] = useState('');

    const auth = useAuth();
    const notify = useToast();

    const QueryMerchItems = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const main_res = await QueryAvialMerchMasterDataByBranch(fd);

            PrepareDropDownList(main_res, SetMerchList);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{

        QueryMerchItems();

    }, [])



    return (
        <div className={`${classes.batch_unit_input_wrapper}`}>

            <div className={`${classes.even_input_wrap}`}>
                <MasterDataRegInput input={
                    <Select
                        onChange={(data)=>{SetSelectedMerch(data); UpdateActiveMerch(ID, data)}}
                        name={"dd_data"}
                        options={MerchList}
                        placeholder={"Merch..."}
                        isSearchable={true}
                        styles={Custom_DD_Style}
                        required ={true}
                    /> } /> 
            </div>

            <div className={`${classes.even_input_wrap}`}>

                <MasterDataRegInput
                    input={
                        <input  
                        onChange={(e)=>{SetUnitPrice(e.target.value); UpdateUnitPrice(ID, e.target.value)}}
                        type="number"
                        min={0} 
                        step={'any'}
                        placeholder='Unit Price'
                        required />
                    }
                />
            </div>

            <div className={`${classes.even_input_wrap}`}>

                <MasterDataRegInput
                    input={
                        <input  
                        onChange={(e)=>{SetQuantity(e.target.value); UpdateQuantity(ID, e.target.value)}}
                        type="number"
                        min={0} 
                        placeholder='Quantity'
                        required />
                    }
                />
            </div>

            <div className={`${classes.even_input_wrap} ${classes.action_button_wrapper}`}>

                <UIButton Type='button' onClick={()=>{EntryPurge(ID)}} Icon={<TrashSimple weight='fill' />} Variant='Danger' label={'Purge'} />

            </div>
                 
        </div>
    )
}