import api from "../Util/axiosInstance";





export const VerifyWhatsgpsAccount = async()=>
{
    try 
    {
        const response = await api.post('api/gps-trace/whatsgps-account', {});
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const VerifyWhatsgpsCars = async(gps_token)=>
{
    try 
    {
        const response = await api.post('api/gps-trace/whatsgps-cars', {token: gps_token});
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const FetchWhatsgpsCarInfo = async(car_id, gps_token)=>
{
    try 
    {
        const response = await api.post('api/gps-trace/whatsgps-car-info',  {ID: car_id, token: gps_token});
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const BreakDriverDeviceLinkInfo = async(data)=>
{
    try 
    {
        const response = await api.post('api/gps-trace/break-driver-device-link',  data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CreateVHDriverLink = async(data)=>
{
    try 
    {
        const response = await api.post('api/gps-trace/create-driver-vh-link',  data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllVHDriverLink = async()=>
{
    try 
    {
        const response = await api.post('api/gps-trace/query-all-driver-vh-link',  {});
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}