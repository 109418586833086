import api from "../Util/axiosInstance";





export const API_POSaved_Save = async(data)=>{
    try 
    {
        const response = await api.post('api/production/serialized-production/process-po-save', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchSavedProductionOrderConfig = async(data)=>{
    try 
    {
        const response = await api.post('api/production/serialized-production/fetch-saved-po-config', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchProductionOrderOWner = async(data)=>{
    try 
    {
        const response = await api.post('api/production/serialized-production/fetch-production-order-owner', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const SaveProductionOrderConfig = async(data)=>
{
        try 
        {
            const response = await api.post('api/production/serialized-production/process-po-save', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const FetchSavedProductionProductOrderItem = async(hash_id, token)=>{
    try 
    {
        const response = await api.post('api/production/serialized-production/fetch-saved-products-item', {ID: hash_id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchProductionOrderReqMaterialSaved = async(id, token)=>{
    try 
    {
        const response = await api.post('api/production/serialized-production/fetch-prod-order-saved-mat', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateSavedPOShareState = async(data)=>
{
    try 
    {
        const response = await api.post('api/production/serialized-production/update-production-order-share-state', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeSavedPO = async(data)=>
{
    try 
    {
        const response = await api.post('api/production/serialized-production/purge-saved-production-order', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}