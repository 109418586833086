import classes from './InvoiceView.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import PaperHeader from '../../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../../SharedComp/PaperHash/PaperHash'
import PaperFooter from '../../../../../../SharedComp/PaperFooter/PaperFooter'

import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef, useState } from 'react'
import {FetchInventoryInvoiceUID} from '../../../../../../api'
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'
import { ToEthiopianCalander } from '../../../../../../SharedComp/Utility'
import LoadingPlaceholder from '../../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'

export default function InvoiceView({OpenModal, DataBase, DataArray}) 
{

    const auth = useAuth();
    const notify = useToast();
    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_Invoice_" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const [Loading, SetLoading] = useState(true);
    const [InvoiceList, SetInvoiceList] = useState([]);
    const formatter = new Intl.NumberFormat('en-US');

    useEffect(()=>{
        if(InvoiceList.length > 0) 
        {
            SetLoading(false);
        }

    }, [InvoiceList])

    useEffect(()=>{
        if(DataBase) 
        {
            SetInvoiceList(DataArray);
        }
    }, [DataBase])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Preview <span className={`${data_win.data_create_name}`}> Daily Invoices</span>
                    </h3>
                    <button onClick={()=>{OpenModal(false)}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body} ${classes.data_body}`}>

                {!Loading ? <div className={`${classes.inner_preview_data_wrapper}`}>
                        <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                            <PaperHeader/>
                            <PaperHash/>
                            <div className={`${classes.meta_data_wrapper}`}>
                                <h2>{auth.activeWorkShopName}</h2>
                                <h2>Invoice Entry</h2>
                                {/* <h4>{new Date().toDateString()}</h4> */}
                            </div>
                            
                            {/* <div className={`${classes.entry_detail}`}>
                                <li><span className={`${classes.entry_deatil_tag}`}>TIN</span> <span className={`${classes.entry_deatil_val}`}>{InvoiceList[0].IvInvoiceSellerTin ? InvoiceList[0].IvInvoiceSellerTin : 'NULL'}</span></li>
                                <li><span className={`${classes.entry_deatil_tag}`}>MRC</span> <span className={`${classes.entry_deatil_val}`}>{InvoiceList[0].IvInvoiceMRC ? InvoiceList[0].IvInvoiceMRC : 'NULL'}</span></li>
                                <li><span className={`${classes.entry_deatil_tag}`}>Receipt</span> <span className={`${classes.entry_deatil_val}`}>{InvoiceList[0].IvInvoiceReceipt ? InvoiceList[0].IvInvoiceReceipt : 'NULL'}</span></li>
                                <li><span className={`${classes.entry_deatil_tag}`}>Transaction ID</span> <span className={`${classes.entry_deatil_val}`}>{InvoiceList[0].IvInvoiceUID}</span></li>
                            </div> */}
                            <div className={`${classes.main_paper_data_wrapper}`}>
                                <div className={`${classes.paper_list_wrapper}`}>

                                    <li className={`${classes.fake_tabke_header}`}>
                                        <span>Action Taken By</span>
                                        <span>Inventory</span>
                                        <span>Quantity</span>
                                        <span>Balance</span>
                                        <span>Unit Price</span>
                                        <span>Method</span>
                                        <span>Date</span>
                                    </li>


                                    {
                                        InvoiceList.map((item, idx)=>(
                                            <li key={idx}>
                                                <span>
                                                {item.USR_FirstName} {item.USR_LastName}
                                                </span>
                                                <span>
                                                    {item.IVItemName}
                                                </span>
                                                <span>
                                                    {formatter.format(item.INVOICELOG_Quantity)} {item.UomDescription}
                                                </span>
                                                <span>
                                                    <strong>{formatter.format(item.INVOICELOG_Balance)} {item.UomDescription}</strong>
                                                </span>
                                                <span>
                                                    {item.INVOICELOG_UnitPrice ? "ETB " + formatter.format(item.INVOICELOG_UnitPrice) : 'Unknown'}
                                                </span>
                                                <span>
                                                {item.INVOICELOG_Method}
                                                </span>
                                                <span>
                                                    {ToEthiopianCalander(new Date(item.INVOICELOG_DateOfEntry))} @ {new Date(item.INVOICELOG_DateOfEntry).toLocaleTimeString()}
                                                </span>
                                            </li>
                                        ))  
                                    }

                                

                                </div>
                            </div>

                            <PaperFooter Name={auth.employeeData?.JAKSBE_FullName} Role={auth.employeeData?.HRMJPT_Name} Phone={auth.employeeData?.JAKSBE_PhoneNumber}/>

                        </div>
                    </div> :  
                    <LoadingPlaceholder/>
                    }
                        

                </div> 

                    <div className={`${classes.form_action_button_wrapper}`}>
                    <button onClick={()=>{PrintDoc()}} className={`${classes.form_action_button_print}`}>Print Attachment</button>
                    <button  onClick={()=>{OpenModal(false)}} className={`${classes.form_action_button_dismiss}`} type='button'>Close</button>
                </div> 
            </div>

    </div>
    )
}