import { useEffect, useState } from 'react';

import Login from './pages/Login/Login';
import Dashboard from                   './pages/Dashboard/Dashboard'
import UserManagment from               './pages/USerManagment/UserManagment'
import CompanyBranch from               './pages/CompanyBranch/CompanyBranch';
import ProductionOrder from             './pages/ProductionOrder/ProductionOrder';
import StockCard from                   './pages/StockCard/StockCard';
import StockOut from                    './pages/StockOut/StockOut'
import StockManager from                './pages/StockManager/StockManager';
import EmployeeManager from             './pages/Employee/EmployeeManager';
import InventoryMasterData from         './pages/Inventory/EdibleGoods/InventoryMasterData';
import ProductionMix from               './pages/Production/ProductionMix/ProductionMix';

import FrontDeskCashier from                    './pages/FrontDesk/FrontDeskCashier/FrontDeskCashier';

import Merchandise from                 './pages/Production/Merchandise/Merchandise';
import DomesticProduction from          './pages/Production/DomesticProduction/DomesticProduction';
import ProductionStack from             './pages/ProductionStack/ProductionStack';
import SalesAndCashSummeryArchive from './pages/FrontDesk/CashCollection/SalesAndCashSummeryArchive';


import Vendors from                     './pages/Archive/Vendors/Vendors';
import CKProduction from                './pages/Production/CKProduction/CKProduction';
import PittyCashBranchLevel from        './pages/PittyCashManagment/PittyCashBranch/PittyCashBranch';
import PittyCashFinanceLevel from       './pages/PittyCashManagment/PittyCashFinance/PittyCashFinance';
import RoleManagment from               './pages/AccountManagment/RolesManagment/RoleManagment';
import Warehouse from                   './pages/StockManager/Pages/Warehouse/Warehouse'
import TrackRequest from                './pages/StockManager/Pages/TrackRequest/TrackRequest';
import Layout from                       './Layout/Layout'
import Products from                    './pages/Production/Products/Products';
import UnknownRoute from                './pages/404/UnknownRoute';
import WhatsGPS from                    './pages/DeviceIntegration/Vehicle/WhatsGPS'
import KitchenVariants from             './pages/Production/KitchenVariants/KitchenVariants';
import OfflinePage from                 './pages/Offline/Offline';

import KitchenSupplyOrder from          './pages/ManageOrders/KitchenSupplyOrder/KitchenSupplyOrder';
import LocalOrders from                 './pages/ManageOrders/LocalOrder/LocalOrders';
import ProductionControlCenter from     './pages/Kitchen/ProductControlCenter/ProductionControlCenter';


import PastryShelves from               './pages/EssentialsShelf/PastryShelves/PastryShelves';
import MerchandiseShelves from          './pages/EssentialsShelf/MerchandiseShelves/MerchandiseShelves';
import ManageMerchInvoices from './pages/EssentialsShelf/ManageMerchInvoices/ManageMerchInvoices';


import SessionTerminationPreview from './SharedComp/SessionTerminationPreview/SessionTerminationPreview';

import ChefsDashboard from              './pages/Chefs/ChefsDashboard/ChefsDashboard';
import ChefsPerformance from            './pages/Chefs/ChefsPerformance/ChefsPerformance';
import ChefsStockRequest from './pages/Kitchen/ChefsStockRequest/ChefsStockRequest';

import ChefLineup from './pages/Kitchen/ChefLineup/ChefLineup';

import OnProductionLine from './pages/Kitchen/OnProductionLine/OnProductionLine';
import ManageDistribution from './pages/Kitchen/ManageDistribution/ManageDistribution';

import DistributionArchive from './pages/Kitchen/DistributionArchive/DistributionArchive';

import BaverageCluster from './pages/Production/BaverageCluster/BaverageCluster';

import OperationMDCluster_I from './pages/CoreMasterDataCluster/OperationMDCluster_I/OperationMDCluster_I';
import OperationMDCluster_II from './pages/CoreMasterDataCluster/OperationMDCluster_II/OperationMDCluster_II';
import OperationMDCluster_III from './pages/CoreMasterDataCluster/OperationMDCluster_III/OperationMDCluster_III';

// import Dispa from            './pages/Kitchen/AwaitingDispatch/AwaitingDispatch';
import DispatchActiveOrders from './pages/AwaitingDispatch/DispatchActiveOrders/DispatchActiveOrders';

// HR
import CrossBranchEmployee from './pages/Employee/CrossBranchEmployee/CrossBranchEmployee';
import TerminationArchive from './pages/Employee/TerminationArchive/TerminationArchive';
import HRMasterReport from './pages/Employee/HRMasterReport/HRMasterReport';

// Payroll
import PayrollAccountingPass from './pages/PayrollPipline/AccoutingPass/PayrollAccountingPass';
import PayrollHrPass from './pages/PayrollPipline/HRPass/PayrollHrPass';
import PayrollDataFeedPass from './pages/PayrollPipline/DataFeedPass/PayrollDataFeedPass';
import PayrollScheduler from './pages/PayrollPipline/PayrollScheduler/PayrollScheduler';
import PayrollArchive from './pages/PayrollPipline/PayrollArchive/PayrollArchive';

import classes from './App.module.css'

import AuthProvider from './AuthProvider';
import { ToastProvider } from './ToastContext';
import { GlobalNotificationProvider } from './GlobalNotification';
import PrivateRoute from './ProtectedRoute';
import { DAControlProvider } from './DAControlContext';
import {GNProvider} from './GlobalNotificationProvider'

import StockApprovedArchive from './pages/StockApprovedArchive/StockApprovedArchive';
import StockDirectRequestManage from './pages/StockDirectRequestManage/StockDirectRequestManage';

import ProductionTransferArchive from './pages/ProductionTransferArchive/ProductionTransferArchive';
import StockInventoryTransaction from './pages/StockManager/Pages/StockInventoryTransaction/StockInventoryTransaction';


// Router
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Unauthorized from './pages/Unauthorized/Unauthorized';


function App() {


  

  return (

    <BrowserRouter>
    <GlobalNotificationProvider>
      <ToastProvider>
        <DAControlProvider>
          <AuthProvider>
            <GNProvider>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route exact path='/login' element={<Login />} />
              <Route exact path='/force-termination' element={<SessionTerminationPreview />} />
              <Route exact path='/unauthorized' element={<Unauthorized />} />
                  <Route element={<PrivateRoute />} >
                    <Route path='/dashboard_master'                         element={<Layout> <Dashboard />                         </Layout>} />
                    <Route path='/user-account'                             element={<Layout> <UserManagment />                     </Layout>} />
                    <Route path='/inventory-master-data-entry'              element={<Layout> <InventoryMasterData />               </Layout>} />
                
                    <Route path='/production-master-data-entry'             element={<Layout> <DomesticProduction />                </Layout>} />
                    <Route path='/yam-mix-master-data-entry'                element={<Layout> <ProductionMix />                     </Layout>} />
                    <Route path='/company-branch-manager'                   element={<Layout> <CompanyBranch />                     </Layout>} />
                    <Route path='/production-order'                         element={<Layout> <ProductionOrder />                   </Layout>} />
                    <Route path='/stock-card'                               element={<Layout> <StockCard />                         </Layout>} />
                    <Route path='/stock-out'                                element={<Layout> <StockOut />                          </Layout>} />
                    <Route path='/stock-in'                                 element={<Layout> <StockManager />                      </Layout>} />
                    <Route path='/inventory-merchandise'                    element={<Layout> <Merchandise />                       </Layout>} />
                    <Route path='/prod-stack'                               element={<Layout> <ProductionStack />                   </Layout>} />
                    <Route path='/front-desk'                               element={<Layout> <FrontDeskCashier />                 </Layout>} />

                    {/*HR  */}
                    <Route path='/standard-employee'                        element={<Layout> <EmployeeManager/>           </Layout>} />
                    <Route path='/cross-branch-employee'                    element={<Layout> <CrossBranchEmployee/>                </Layout>} />
                    <Route path='/employee-termination-archive'             element={<Layout> <TerminationArchive/>                 </Layout>} />
                    <Route path='/employee-master-report'                   element={<Layout> <HRMasterReport/>                     </Layout>} />

                   {/* Payroll */}
                   <Route path='/master-payroll-accounting-pass'            element={<Layout> <PayrollAccountingPass/>              </Layout>} />
                   <Route path='/master-payroll-hr-pass'                    element={<Layout> <PayrollHrPass/>                      </Layout>} />
                   <Route path='/master-payroll-data-feed-pass'             element={<Layout> <PayrollDataFeedPass/>                </Layout>} />
                   <Route path='/payroll-scheduler'                         element={<Layout> <PayrollScheduler/>                   </Layout>} />
                   <Route path='/master-payroll-archive'                    element={<Layout> <PayrollArchive/>                     </Layout>} />
                    
                    <Route path='/sales-and-cash-summary-archive'           element={<Layout> <SalesAndCashSummeryArchive />        </Layout>} />
                    <Route path='/vendors'                                  element={<Layout> <Vendors />                           </Layout>} />
                    <Route path='/ck-production'                            element={<Layout> <CKProduction />                      </Layout>} />
                    <Route path='/pitty-cash-branch-level'                  element={<Layout> <PittyCashBranchLevel />              </Layout>} />
                    <Route path='/pitty-cash-finance-level'                 element={<Layout> <PittyCashFinanceLevel />             </Layout>} />
                    <Route path='/user-role'                                element={<Layout> <RoleManagment />                     </Layout>} />
                    <Route path='/kitchen-variants-master-data-entry'       element={<Layout> <KitchenVariants />                   </Layout>} />

                    <Route path='/stock-warehouse'                          element={<Layout> <Warehouse />                         </Layout>} />
                    <Route path='/stock-track-request'                      element={<Layout> <TrackRequest />                      </Layout>} />
                    
                    <Route path='/master-product-line'                      element={<Layout> <Products/>                           </Layout>}/>

                    <Route path='/production-transfer-archive'              element={<Layout> <ProductionTransferArchive/>                           </Layout>}/>

                    {/* MD */}
                    <Route path='/operation-master-data-cluster-I'                      element={<Layout> <OperationMDCluster_I/>   </Layout>}/>
                    <Route path='/operation-master-data-cluster-II'                      element={<Layout> <OperationMDCluster_II/>   </Layout>}/>
                    <Route path='/operation-master-data-cluster-III'                      element={<Layout> <OperationMDCluster_III/>   </Layout>}/>


                    {/* Shelves  */}
                    <Route path='/pastry-shop-shelfs'                      element={<Layout> <PastryShelves/>                           </Layout>}/>
                    <Route path='/merch-shop-shelfs'                      element={<Layout> <MerchandiseShelves/>                           </Layout>}/>
                    <Route path='/manage-merch-invoice'                      element={<Layout> <ManageMerchInvoices/>                           </Layout>}/>
                    


                    {/* Manage Orders */}
                    <Route path='/kitchen-supply-order'                      element={<Layout> <KitchenSupplyOrder/>                 </Layout>}/>
                    <Route path='/local-front-order'                         element={<Layout> <LocalOrders/>                        </Layout>}/>
                    

                    {/* Kitchen */}
                    <Route path='/production-control-center'                  element={<Layout> <ProductionControlCenter/>              </Layout>}/>
                   
                    <Route path='/chefs-stock-request'                        element={<Layout> <ChefsStockRequest/>                    </Layout>}/>
                    <Route path='/on-production-line'                         element={<Layout> <OnProductionLine/>                     </Layout>}/>
                    <Route path='/manage-workspace-order-distribution'        element={<Layout> <ManageDistribution/>                   </Layout>}/>
                    <Route path='/chef-lineup'                                element={<Layout> <ChefLineup/>                           </Layout>}/>
                    <Route path='/distribution-archive'                       element={<Layout> <DistributionArchive/>                  </Layout>}/>

                    {/* Chefs */}
                    {/* ChefsStockRequest */}
                    <Route path='/chefs_dashboard'                           element={<Layout> <ChefsDashboard/>                     </Layout>}/>
                    <Route path='/chefs_performance'                         element={<Layout> <ChefsPerformance/>                   </Layout>}/>

                    <Route path='/manage-hot-drink'                         element={<Layout> <BaverageCluster/>                     </Layout>}/>


                    {/* Dispatch  */}
                    <Route path='/production-awaiting-dispatch'               element={<Layout> <DispatchActiveOrders/>                     </Layout>}/>


                    {/* Stock */}
                    <Route path='/manage-direct-stock-request'               element={<Layout> <StockDirectRequestManage/>                     </Layout>}/>
                    <Route path='/approved-stock-archive'                   element={<Layout> <StockApprovedArchive/>                     </Layout>}/>
                    <Route path='/inventory-stock-transaction'               element={<Layout> <StockInventoryTransaction/>                     </Layout>}/>

                    <Route path='/whats-gps-vehicle-integration'             element={<Layout> <WhatsGPS/>                           </Layout>}/>
                  </Route>
                  <Route path='*'                                         element={<UnknownRoute />} />
            </Routes>
            </GNProvider>
          </AuthProvider>
        </DAControlProvider>
      </ToastProvider>
    </GlobalNotificationProvider>
    </BrowserRouter>

  );
}

export default App;
