import api from "../Util/axiosInstance";




export const CreateMasterProductionOrderV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/process-po-reg-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPendingMasterProductionOrderPackageDetailByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-pending-branch-order-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterProductionOrderDetailByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-order-detail-by-id-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterProductionOrderForRequestOwnerByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-order-for-request-owner-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterProductionOrderForRequestHandlerByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-order-for-request-handler-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const RejectMasterProductionOrderV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/reject-master-production-order-by-id-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ApproveMasterProductionOrderStockRequestV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/approve-master-production-stock-request', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterProductionStockApprovedEntryByIDV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-stock-approved-by-id-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
} 
export const QueryMasterProductionStockApprovedEntryOmniByBranchV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-stock-approved-omni-by-branch-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterProcutionOrderByIdWithOwnerV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-with-owner-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterProcutionOrderByIdWithApproverV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-with-approver-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterProductionOrderRemainingBatchProductsByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/query-master-production-remaining-branch-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ReturnToInventoryPartialMasterProductionOrder = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-order-v2/return-inventory-partial-master-production-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPendingStockReturnRequest =async(data, token)=>
{
    try 
    {
        const response = await api.post('api/production-order-v2/query-active-stock-return-requests-by-branch-id-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPendingStockReturnRequestDetail = async(data)=>
{
    try 
    {
        const response = await api.post('api/production-order-v2/query-active-stock-return-requests-detail-by-id-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryActiveStockReturnRequestDetailOmni = async(data)=>
{
    try 
    {
        const response = await api.post('api/production-order-v2/query-active-stock-return-requests-detail-omni-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ApproveRTOByIdV2 = async(data)=>
{
    try 
    {
        const response = await api.post('api/production-order-v2/approve-rto-by-id-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryProductionStockApprovedDetailsForArchiveByBranchV2 = async(data)=>
{
    try 
    {
        const response = await api.post('api/production-order-v2/query-production-stock-aprroved-detail-for-archive-by-branch-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryMasterStockReturnWithHandlerByID = async(data)=>
{
    try 
    {
        const response = await api.post('api/production-order-v2/query-master-production-return-with-handler-v2', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
