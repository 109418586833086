import classes from './PasswordVerfiyPage.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import UIButton from '../../../../Components/UIButton/UIButton'
import { SealCheck } from '@phosphor-icons/react'
import { CheckEmailVerificationCode, SendVerificationMail } from '../../../../../SharedComp/API/UserAccess/API_FE_UserAccess'
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { useState } from 'react'


export default function PasswordVerfiyPage({CloseModal, RequestOTP_Callback, XUser})
{

    const auth = useAuth();
    const notify = useToast();
    const [OtpCode, SetOtpCode] = useState();
    const _time_out = 1000 * (2 * 60);

    const [EmailTimeOut, setEmailTimeOut] = useState(_time_out / 1000);
    const [CanSendAnother, setCanSendAnother] = useState(false);
    const [Reinit, SetReinit] = useState(Date.now());

    useEffect(() => {
        const _interval = setInterval(() => {
            setEmailTimeOut(prevTime => {
                if (prevTime >= 1) {
                    return prevTime - 1;  
                } else {
                    setCanSendAnother(true);  
                    clearInterval(_interval);  
                    return prevTime; 
                }
            });
        }, 1000);
    
        return () => {
            clearInterval(_interval);
        };
    }, [Reinit]);

    const CheckOTP = async(code)=>
    {
        try 
        {
            if(!OtpCode || OtpCode.length <= 0) 
            {
                throw new Error("Empty Verification Code")
            }
            const fd = new FormData();
            fd.append("otp", OtpCode.toUpperCase());
            fd.append("user_id", XUser.USR_UserAccountID);
            
            const main_res = await CheckEmailVerificationCode(fd);
            notify.ShowSuccess(main_res.Msg);
            CloseModal();

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const _send_otp = ()=>
    {
        if(CanSendAnother) 
        {
            SetReinit(Date.now());
            setCanSendAnother(false);
            setEmailTimeOut(_time_out / 1000);
            RequestOTP_Callback()
        }
    }


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${classes.data_main}`}>
            <div className={`${classes.data_main_header}`}>
                <h3>
                    <span className={`${classes.data_create_name}`}>Confirm Email</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${classes.data_body}`}>
                    <input onChange={(e)=>{SetOtpCode(e.target.value)}} type="text" autoComplete='off' placeholder='verfication code' required />
                    <li onClick={()=>{_send_otp()}}>{CanSendAnother ? "Send Code" : `Try again in ${EmailTimeOut} seconds`}</li>
                    <UIButton onClick={()=>{CheckOTP()}} label={"Verify Email"} Icon={<SealCheck/> }/>
            </div>
        </div>

    </div>
    )
}