import classes from './OrderGroupManager.module.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrderSingluarProd from '../OrderSingluarProd/OrderSingluarProd'
import { faBan, faCircleCheck } from '@fortawesome/free-solid-svg-icons'



import {FetchSavedProductionProductOrder, RejectPoRequest} from '../../../../api'
import { useDAControl } from '../../../../DAControlContext'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { QueryMasterProductionOrderDetailByID, RejectMasterProductionOrderV2 } from '../../../../SharedComp/API/Production/API_MasterProductionV2'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'

export default function OrderGroupManager({OrderData, DataAcceptCallback, DataRefersh}) 
{
    const [OrderProducts, SetOrderProducts] = useState([]);
    const DAControl = useDAControl();
    const auth = useAuth();
    const notify = useToast();
    const [Loading, SetLoading] = useState(false);
    const [ExtractedData, SetExtractedData] = useState();

    function TerminateString(input) {
        if(!input)
        {
            return "##";
        }
        if (input.length > 15) {
            return input.slice(0, 15) + '...';
        }
        return input;
    }

    const FetchDetailProductOrder = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_order_id", OrderData.MPOM_ID);
            const main_res = await QueryMasterProductionOrderDetailByID(fd);
            SetOrderProducts(main_res.OrderProductDetail);
            SetExtractedData(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }
    const HandleRejection = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", OrderData.MPOM_ID);
            const res = await RejectMasterProductionOrderV2(fd);
            notify.ShowSuccess(res.Msg);
            DataRefersh();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    useEffect(()=>{
        FetchDetailProductOrder();
    }, [OrderData, auth.user])
    

    return (
        <div className={`${classes.group_batch_manager_wrapper}`}>

            <div className={`${classes.group_batch_header_wrapper}`}>
                    <div className={`${classes.order_has_wrapper}`}>
                        <h2>POSR-Hash <span className={`${classes.order_hash_value}`}>{TerminateString(OrderData.MPOM_OrderID)}</span></h2>
                    </div>
                    <div className={`${classes.order_action_wrapper}`}>
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_RejectStockRequest && <button onClick={()=>{
                                        DAControl.Trigger(`Reject PO Stock Request`, 
                                        `You are about to reject stock item request for "Production Order", If you do this the Production Manager need to Re-order. And it gone be all you fault so with that in mind...`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                HandleRejection();
                                            }
                                        }   
                                    );}} className={`${classes.reject_request}`}><span><FontAwesomeIcon icon={faBan}/></span> <span>Reject</span></button> }
                       {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ApproveStockRequest && <button onClick={()=>{DataAcceptCallback(OrderData)}} className={`${classes.accept_request}`}><span><FontAwesomeIcon icon={faCircleCheck}/></span> <span>Accept</span></button>}
                    </div>
            </div>

            <div className={`${classes.group_manager_list_wrapper}`}>
                { !Loading &&
                    OrderProducts.map((item, idx)=>(
                        <OrderSingluarProd key={idx} Target={item}/>
                    ))
                }
                {
                    Loading && 
                    <LoadingPlaceholder/>
                }
            </div>

            <div className={`${classes.batch_group_footer_wrapper}`}>
                <div className={`${classes.footer_action_wrapper}`}>
                    <li>{OrderData.MPOM_Shift} Shift</li>
                    <li>Total {OrderProducts.length} Products Type</li>
                </div>
            </div>
        </div>
    )
}