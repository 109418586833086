import classes from './DirectProductionOrder.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faCircleArrowDown, faCircleArrowUp, faCloud, faMinus, faMoon, faPlusCircle, faSpinner, faSun, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import {SingleProductPackageOrderTemplate} from '../InitProductionOrder/ProductOrderUtils'

import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'
import Select from 'react-select'

import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import DynamicPoInput from '../DynamicPoInput/DynamicPoInput'
import { useDAControl } from '../../../../DAControlContext'

import { FetchBakeryProductByID } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'


import {FetchYamMixOrderableBranch} from '../../../../api';

import {FetchOrderableBakeryProduct} from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery'
import { 
        CreateProductionOrder, 
        FetchBranchWarehouse, 
        FetchFinalProductSaved, 
        FetchMixSaved, 
        } from '../../../../api'

import {
        UpdateSavedPOShareState, 
        FetchProductionOrderReqMaterialSaved, 
        SaveProductionOrderConfig, 
        FetchSavedProductionProductOrderItem,
        PurgeSavedPO} from '../../../../SharedComp/API/Production/API_ProductionOrder'


import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'
import { Tooltip } from 'react-tooltip';

import UIButton from '../../../Components/UIButton/UIButton'
import { XCircle } from '@phosphor-icons/react'
import { CreateMasterProductionOrderV2 } from '../../../../SharedComp/API/Production/API_MasterProductionV2'
import { QueryInventoryItemByID } from '../../../../SharedComp/API/InventoryItem/API_FE_InventoryItem'

export default function DirectProductionOrder({CloseCleanCALLBACK, CloseModal, Target}) 
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();
    
    const [AdditionalEntryData, SetAdditionalEntryData] = useState([]);
    const [Data, SetData] = useState([]);
    const [DataMix, SetDataMix] = useState([]);
    // const DataMix = useRef(new Map());

    const [Loading, SetLoading] = useState(false);
    const [DayShift, SetDayShift] = useState(true);
    const [UnifiedStock, SetUnifiedStock] = useState(false);
    const [MultiplyWithBatch, SetMultiplyWithBatch] = useState(true);
    const [FlourOnly, SetFlourOnly] = useState(true);
    const [HideAdvanceSetting, SetHideAdvanceSetting] = useState(false); // UI
    const [OrderPkgName, SetOrderPkgName] = useState();
    const [InputBatchUpdate, SetInputBatchUpdate] = useState(false);
    const [LoadingData, SetLoadingData] = useState(false);

    //  util
    const [ShowSaveInput, SetShowSaveInput] = useState(false);
    const [FormActionType, SetFormActionType] = useState("submit");

    // Render Component
    const RawMaterialRenderComponentList = useRef(new Map());

    // Unified Render Component
    const UnifiedRawMaterialRenderComponentList = useRef(new Map());

    // Component Data
    const OrderProductPackage = useRef(new Map());
    const isFetching = useRef(false);

    const ProdMainBatchCount = useRef(new Map());
    const ProdMixBatchCount = useRef(new Map());
    
    // This guy hold active/selected Product name YES the NAME
    const [ActiveRawMaterialViewID, SetActiveRawMaterialViewID] = useState();
    // This is the temporary list holder like when the suer click order A this guy get filled with A material
    // list and when clicked B this guy hold B raw material pritty fucking easy
    const [DynamicMaterialInputComponentWrapper, SetDynamicMaterialInputComponentWrapper] = useState([]);

    const [WorkspaceWarehouseList, SetWorkspaceWarehouseList] = useState([]);
    const [SelectedWarehouse, SetSelectedWarehouse] = useState();

    // Share Option
    const [GlobalShare, SetGlobalShare] = useState(false);
    const [LocalShare, SetLocalShare] = useState(false);

    // Additional Item preparation
    const [ValidProductList, SetValidProductList] = useState([]);
    const [ValidMixList, SetValidMixList] = useState([]);
    const [AdditionalEntryMasterOption, SetAdditionalEntryMasterOption] = useState([]);
    const [GenericDataOrigin, SetGenericDataOrigin] = useState([]);
    
 
    function AddInitialInput_DATA() 
    {
        if(!Data) { return; }
        // First Proces Raw Product
        for(let i = 0; i < Data.length; i++)
        {
            const key = nanoid();
            // fisrt time
            const list_input = [];
            list_input.push(
                <DynamicPoInput  

                    // For  which product is belong this raw material
                    RootName={Data[i].Data.CMBP_Name}
                    // When ever the user type quantity for raw material this function is invocked
                    HandleMaterialQantity={HandleMaterialQuantity}

                    // and this for the type mostly for flour i guess
                    HandleMaterialType={HandleMaterialType} 

                    // This is unique ID for each mfing input component when the user click add
                    // new Raw Material  this nique key is generated
                    UID={key} 
                />
            );
            RawMaterialRenderComponentList.current.set(Data[i].Data.CMBP_Name.toString(), list_input);
        }
    }
    function AddInitialInput_MIX() 
    {
       // First Proces Raw Product
       for(let i = 0; i < DataMix.length; i++)
        {
            const key = nanoid();
            // fisrt time
            const list_input = [];
            list_input.push(
                <DynamicPoInput  

                    // For  which product is belong this raw material
                    RootName={DataMix[i].Data.MixName}
                    // When ever the user type quantity for raw material this function is invocked
                    HandleMaterialQantity={HandleMaterialQuantity}

                    // and this for the type mostly for flour i guess
                    HandleMaterialType={HandleMaterialType} 

                    // This is unique ID for each mfing input component when the user click add
                    // new Raw Material  this nique key is generated
                    UID={key} 
                />
            );
            RawMaterialRenderComponentList.current.set(DataMix[i].Data.MixName.toString(), list_input);
        } 
    }

    function AddInitialInput() 
    {
        AddInitialInput_DATA();
        AddInitialInput_MIX();
    }
    function CreateProdMainBatch(key, val)
    {
        ProdMainBatchCount.current.set(key, val);
        SetInputBatchUpdate(!InputBatchUpdate);
    }
    function CreateProdMixBatch(key, val) 
    {
        ProdMixBatchCount.current.set(key, val);
        SetInputBatchUpdate(!InputBatchUpdate);
    }
    function ToggelUnified(val) 
    {

        if(val) // About to be true
        {
            SetActiveRawMaterialViewID("UNIFIED_STOCK");
            RawMaterialRenderComponentList.current.clear();
            
            const key = nanoid();
            const list_input = [];
            list_input.push(
                <DynamicPoInput  
    
                    // For  which product is belong this raw material
                    RootName={"UNIFIED_STOCK"}
                    // When ever the user type quantity for raw material this function is invocked
                    HandleMaterialQantity={HandleMaterialQuantity}
    
                    // and this for the type mostly for flour i guess
                    HandleMaterialType={HandleMaterialType} 
    
                    // This is unique ID for each mfing input component when the user click add
                    // new Raw Material  this nique key is generated
                    UID={key} 
                />
            );
            UnifiedRawMaterialRenderComponentList.current.set("UNIFIED_STOCK", list_input);
            SetDynamicMaterialInputComponentWrapper(list_input);
        } else // About to be false
        {
            UnifiedRawMaterialRenderComponentList.current.clear();
            AddInitialInput();
        }

        SetUnifiedStock(val);
        
    }
    function SetFilterKey(key) 
    {
        if(UnifiedStock) 
        {
            SetActiveRawMaterialViewID("UNIFIED_STOCK");
            const list_ref = UnifiedRawMaterialRenderComponentList.current.get('UNIFIED_STOCK');

            if (list_ref) {
                SetDynamicMaterialInputComponentWrapper(list_ref);
            }
            return;
        }

        SetDynamicMaterialInputComponentWrapper([]);
        SetActiveRawMaterialViewID(key.toString());

        const list_ref = RawMaterialRenderComponentList.current.get(key);
        if (list_ref) {
            SetDynamicMaterialInputComponentWrapper(list_ref);
        }
    }
    function HandleMaterialQuantity(root_name, data, uid) 
    {
        if(!OrderProductPackage.current.has(root_name)) 
        {
            const prod_temp = new SingleProductPackageOrderTemplate();
            prod_temp.RootName = root_name;
            prod_temp.CreateQuantity(uid, data);
            OrderProductPackage.current.set(root_name, prod_temp);
        }else 
        {
            OrderProductPackage.current.get(root_name).CreateQuantity(uid, data);
        }

    }
    function HandleMaterialType(root_name, data, uid) 
    {
        if(!OrderProductPackage.current.has(root_name)) 
            {
                const prod_temp = new SingleProductPackageOrderTemplate();
                prod_temp.RootName = root_name;
                prod_temp.CreateItem(uid, data);
                OrderProductPackage.current.set(root_name, prod_temp);
            }else 
            {
                OrderProductPackage.current.get(root_name).CreateItem(uid, data);
            }
    }
    function AddNewInputField(id_to_add) {

        if(UnifiedStock) 
        {
            for (const [key, value] of UnifiedRawMaterialRenderComponentList.current.entries()) 
            {
                // key new key
                const hash_key = nanoid();

                // Create a new array to avoid mutating the existing state
                const newCompList = [...value, 
                    <DynamicPoInput  
                        RootName={ActiveRawMaterialViewID}
                        HandleMaterialQantity={HandleMaterialQuantity} 
                        HandleMaterialType={HandleMaterialType} 
                        UID={hash_key} 
                    />
                ];

                // Update RawMaterialRenderComponentList
                UnifiedRawMaterialRenderComponentList.current.set(key, newCompList);
    
                // Set the new state
                SetDynamicMaterialInputComponentWrapper(newCompList);

                HandleMaterialQuantity(ActiveRawMaterialViewID, null, hash_key); // FOR TRIGGER-ERROR
                
                // Get the fuck out #We are done
                break;
            }
        } else 
        {
            // Find the component list for the given key
            for (const [key, value] of RawMaterialRenderComponentList.current.entries()) {
                if (id_to_add === key) {

                    // key new key
                    const hash_key = nanoid();

                    // Create a new array to avoid mutating the existing state
                    const newCompList = [...value, 
                        <DynamicPoInput  
                            RootName={ActiveRawMaterialViewID}
                            HandleMaterialQantity={HandleMaterialQuantity} 
                            HandleMaterialType={HandleMaterialType} 
                            UID={hash_key} 
                        />
                    ];
                    // Update RawMaterialRenderComponentList
                    RawMaterialRenderComponentList.current.set(key, newCompList);

                    // This is really fucked up fix to trigger error check
                    // Since we absolutely don't trust the user at all, he might change his mind,
                    // He might say ohh I want to change the batch, ooohh, I want to change the amount or any other shit. In short
                    // The user may not stick with to what he initially saved, so this call make sure all entry are re-tested before sending it to
                    // the server so the server don't bitch about it.
                    HandleMaterialQuantity(ActiveRawMaterialViewID, null, hash_key); // FOR TRIGGER-ERROR

                    // Set the new state
                    SetDynamicMaterialInputComponentWrapper(newCompList);
                    
                    // Get the fuck out #We are done
                    break;
                }
            }
        }
    }
    
    function RemoveInputField(uid) 
    {
        
    }
    const FetchBranchWarehouseClinet = async()=>
    {
        try 
        {
            const res = await FetchBranchWarehouse(auth.activeWorkShop, auth.token);
            PrepareDropDownList(res, SetWorkspaceWarehouseList);

        }catch(err) 
        {
            console.log(err.message);
        }
    }
    const PreValidateData = async()=> 
    {
        // If the order is unified stock auto validte will take car of it
        if(UnifiedStock) 
        {
            return true; 
        }

        // If not
        for(let  i = 0; i < AdditionalEntryData.length; i++) 
        {
            const _res = OrderProductPackage.current.has(AdditionalEntryData[i].Data.ItemName);
            if(_res) 
            {
                const data_pkg = OrderProductPackage.current.get(AdditionalEntryData[i].Data.ItemName);

                if(!data_pkg.ItemData) 
                {
                    SetFilterKey(AdditionalEntryData[i].Data.ItemName);
                    notify.ShowError(`DATA_GUARD: Order //${AdditionalEntryData[i].Data.ItemName}// Has No Inventory Item Selected! Please fix it!`);
                    return false;
                }
                if(!data_pkg.QuantityData) 
                {
                    SetFilterKey(AdditionalEntryData[i].Data.ItemName);
                    notify.ShowError(`DATA_GUARD: Order //${AdditionalEntryData[i].Data.ItemName}// No Inventory Item Quantity is not specified! Please fix it!`);
                    return false;
                }

                if(data_pkg.QuantityData.size !== data_pkg.ItemData.size) 
                {
                    SetFilterKey(AdditionalEntryData[i].Data.ItemName);
                    notify.ShowError(`DATA_GUARD: Order //${AdditionalEntryData[i].Data.ItemName}// Quantity and Inventory Item does not match! Please fix it!`);
                    return false;
                }
            } else 
            {
                SetFilterKey(AdditionalEntryData[i].Data.ItemName);
                notify.ShowError(`DATA_GUARD: Order //${AdditionalEntryData[i].Data.ItemName}// Has Data Mismatch! Please fix it!`);
                return false;
            }
        }
        for(let  i = 0; i < Data.length; i++) 
        {
            const _res = OrderProductPackage.current.has(Data[i].Data.CMBP_Name);
            if(_res) 
            {
                const data_pkg = OrderProductPackage.current.get(Data[i].Data.CMBP_Name);

                if(!data_pkg.ItemData) 
                {
                    SetFilterKey(Data[i].Data.CMBP_Name);
                    notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// Has No Inventory Item Selected! Please fix it!`);
                    return false;
                }
                if(!data_pkg.QuantityData) 
                {
                    SetFilterKey(Data[i].Data.CMBP_Name);
                    notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// No Inventory Item Quantity is not specified! Please fix it!`);
                    return false;
                }

                if(data_pkg.QuantityData.size !== data_pkg.ItemData.size) 
                {
                    SetFilterKey(Data[i].Data.CMBP_Name);
                    notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// Quantity and Inventory Item does not match! Please fix it!`);
                    return false;
                }
            } else 
            {
                SetFilterKey(Data[i].Data.CMBP_Name);
                notify.ShowError(`DATA_GUARD: Order //${Data[i].Data.CMBP_Name}// Has Data Mismatch! Please fix it!`);
                return false;
            }
        }
        for(let  i = 0; i < DataMix.length; i++) 
        {
            const _res = OrderProductPackage.current.has(DataMix[i].Data.MixName);
            if(_res) 
            {
                const data_pkg = OrderProductPackage.current.get(DataMix[i].Data.MixName);

                if(!data_pkg.ItemData) 
                {
                    SetFilterKey(DataMix[i].Data.MixName);
                    notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// Has No Inventory Item Selected! Please fix it!`);
                    return false;
                }
                if(!data_pkg.QuantityData) 
                {
                    SetFilterKey(DataMix[i].Data.MixName);
                    notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// No Inventory Item Quantity is not specified! Please fix it!`);
                    return false;
                }

                if(data_pkg.QuantityData.size !== data_pkg.ItemData.size) 
                {
                    SetFilterKey(DataMix[i].Data.MixName);
                    notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// Quantity and Inventory Item does not match! Please fix it!`);
                    return false;
                }
            } else 
            {
                SetFilterKey(DataMix[i].Data.MixName);
                notify.ShowError(`DATA_GUARD: Order //${DataMix[i].Data.MixName}// Has Data Mismatch! Please fix it!`);
                return false;
            }
            
        }

        return true;
    }
    const PurgeSavedPoConfig = async(_name)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("hash_name", _name);
            fd.append("pos_hash", Target.POS_Hash);
            fd.append("branch_id", Target.POS_SourceBranch);

            const main_res = await PurgeSavedPO(fd);

            if(main_res.MyStateCode !== 1) 
            {
                notify.ShowError(main_res.Msg);
            } else 
            {
                notify.ShowSuccess(main_res.Msg);
                CloseCleanCALLBACK();
            }
        }catch(err) 
        {

        }
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();

        const _valid_data = await PreValidateData();

        if(!_valid_data) { return; }

        notify.ShowLoad("Processing order", 10);

        try 
        {
            const fd = new FormData();
            fd.append("is_day_shift", DayShift);
            fd.append("is_unified_stock_access", UnifiedStock);
            fd.append("batch_based", MultiplyWithBatch);
            fd.append("data_mix", JSON.stringify(DataMix));
            fd.append("data_prod", JSON.stringify(Data));
            fd.append("data_prod_additional_order", JSON.stringify(AdditionalEntryData));
            fd.append("target_warehouse", JSON.stringify(SelectedWarehouse));
            fd.append("active_branch", auth.activeWorkShop);
            fd.append("ordered_by", auth.user.USR_UserAccountID);

            const prodmain_jsonstring = JSON.stringify(Array.from(ProdMainBatchCount.current.entries()));
            const prodmix_jsonstring = JSON.stringify(Array.from(ProdMixBatchCount.current.entries()));

            fd.append("prod_main_batch", prodmain_jsonstring);
            fd.append("prod_mix_batch", prodmix_jsonstring);

            const serializedPriceData = JSON.stringify(
                Array.from(OrderProductPackage.current, ([key, value]) => [
                  key,
                  {
                    ...value,
                    QuantityData: Array.from(value.QuantityData),
                    ItemData: Array.from(value.ItemData)
                  }
                ])
              );
            fd.append("Inventory", serializedPriceData);

            const res = await CreateMasterProductionOrderV2(fd);

            if(res.MyStateCode !== -1) 
            {
                notify.ShowSuccessUpdate(res.Msg, 10);
                CloseCleanCALLBACK();
            }else 
            {
                notify.ShowErrorUpdate(<div dangerouslySetInnerHTML={{ __html: res.Msg }} />, 10);
            }

        }catch(err) 
        {
            notify.ShowErrorUpdate(err, 10);
        }


    }
    const SaveOrderConfig = async(e)=>{
        e.preventDefault();
        SetShowSaveInput(false);
        notify.ShowLoad("Saving order", 10);

        try 
        {
            const fd = new FormData();
            fd.append("is_day_shift", DayShift);
            fd.append("is_unified_stock_access", UnifiedStock);
            fd.append("batch_based", MultiplyWithBatch);
            fd.append("data_mix", JSON.stringify(DataMix));
            fd.append("data_prod", JSON.stringify(Data));
            fd.append("target_warehouse", JSON.stringify(SelectedWarehouse));
            fd.append("active_branch", auth.activeWorkShop);
            fd.append("ordered_by", auth.user.USR_UserAccountID);
            fd.append("po_name", OrderPkgName);

            const prodmain_jsonstring = JSON.stringify(Array.from(ProdMainBatchCount.current.entries()));
            const prodmix_jsonstring = JSON.stringify(Array.from(ProdMixBatchCount.current.entries()));

            fd.append("prod_main_batch", prodmain_jsonstring);
            fd.append("prod_mix_batch", prodmix_jsonstring);

            const serializedPriceData = JSON.stringify(
                Array.from(OrderProductPackage.current, ([key, value]) => [
                  key,
                  {
                    ...value,
                    QuantityData: Array.from(value.QuantityData),
                    ItemData: Array.from(value.ItemData)
                  }
                ])
              );
            fd.append("Inventory", serializedPriceData);

            const res = await SaveProductionOrderConfig(fd);

            if(res.MyStateCode !== -1) 
            {
                notify.ShowSuccessUpdate(res.Msg, 10);
                CloseCleanCALLBACK();
            }else 
            {
                notify.ShowErrorUpdate(<div dangerouslySetInnerHTML={{ __html: res.Msg }} />, 10);
            }

        }catch(err) 
        {
            notify.ShowErrorUpdate(err, 10);
        }
    }
    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }
    const ReFeedRawMaterial = async(hash_id, root_name)=>
    {
        // Query all the Inventory List basd on the hash
        const inventory_list = await FetchProductionOrderReqMaterialSaved(hash_id, auth.token);
        
        const list_input = [];
        
        // For Each Inventory Item we received
        for(let i = 0; i < inventory_list.length; i++) 
        {
            // We will have new hash ID For Each MF
            const hash_key = nanoid();

            // We create Dynamic Input Renderbale Component: REMEMBER my dude this is for only the UI
            list_input.push(
                <DynamicPoInput  

                    // For  which product is belong this raw material
                    RootName={root_name}
                    // When ever the user type quantity for raw material this function is invocked
                    HandleMaterialQantity={HandleMaterialQuantity}

                    // and this for the type mostly for flour i guess
                    HandleMaterialType={HandleMaterialType} 

                    // This is unique ID for each mfing input component when the user click add
                    // new Raw Material  this nique key is generated
                    UID={hash_key} 
                />
            );

            // After we created the Renderable Component
            // We create the Data That will be feeded to this UI

            // Get the Target inventory Item this is usefull to read the name
            const fd = new FormData();
            fd.append("target_id", inventory_list[i].POM_IvID);
            const main_res = await QueryInventoryItemByID(fd);
            const __iv_data = main_res[0];

            // If we receive Valid Inventory Item Data
            if(__iv_data) 
            {
                // Register Inventory Item Quantity
                HandleMaterialQuantity(root_name, inventory_list[i].POM_IvQuantity, hash_key); 
                
                // Make the Data An Object so the dropdown won't start bitching
                const __item_data = {value: inventory_list[i].POM_IvID, label: __iv_data.IVItemName};
    
                //  Write the Material/Inventory Data so the UI can use it 
                HandleMaterialType(root_name, __item_data, hash_key);

            }
            
            
        }
        
        // And Fill the buffer Finally
        if(Target.POS_UnifiedStock) 
        {
            UnifiedRawMaterialRenderComponentList.current.set(root_name, list_input);
        }else 
        {
            RawMaterialRenderComponentList.current.set(root_name, list_input);
        }   
    }
    const HandleAdditionalDataEntry = (data)=>
    {
        const _entry = GenericDataOrigin.find((item)=>item.ItemID === data.value && item.ItemName === data.label);

        if(_entry) 
        {
            SetAdditionalEntryData((prev)=>
            {
                const __update = [...prev];
                let _clone_found = false;
                for(let i = 0; i < __update.length; i++) 
                {
                    if(__update[i].Data.ItemID === _entry.ItemID && __update[i].Data.ItemName === _entry.ItemName)
                    {
                        _clone_found = true;
                        break;
                    }
                }
                if(_clone_found) 
                {
                    notify.ShowWarn("You Already added this product, Please modify the batch count if you want");
                    return __update;
                }
                __update.push({
                    ID: getRandomID(),
                    Data: _entry
                })

                const _complmetary_stock = [];
                _complmetary_stock.push(<DynamicPoInput  
                    RootName={_entry.ItemName}
                    HandleMaterialQantity={HandleMaterialQuantity}
                    HandleMaterialType={HandleMaterialType} 
                    UID={nanoid()} 
                />);

                RawMaterialRenderComponentList.current.set(_entry.ItemName, _complmetary_stock);

                return __update;
            })
        }

        
    }
    const PurgeAdditionalDataEntry = (uid, name)=>
    {
        SetAdditionalEntryData((prev)=>
        {
            const __update = [...prev];
            
            const _target_item = __update.findIndex((item)=>item.Data.UID === uid);
            if(_target_item !== -1) 
            {
                __update.splice(_target_item, 1);
                RawMaterialRenderComponentList.current.delete(name);
            }
            return __update;
        })
    }
    const PurgeMixAsOrderDataEntry = (name)=>
    {
        SetDataMix((prev)=>
        {
            const __update = [...prev];
            const _target_item = __update.findIndex((item)=>item.Data.MixName === name);
            if(_target_item !== -1) 
            {
                __update.splice(_target_item, 1);
                RawMaterialRenderComponentList.current.delete(name);
            }
            return __update;
        })
    }
    const PurgeProdOrderDataEntry = (name)=>
    {
        SetData((prev)=>
        {
            const __update = [...prev];
            const _target_item = __update.findIndex((item)=>item.Data.CMBP_Name === name);
            if(_target_item !== -1) 
            {
                __update.splice(_target_item, 1);
                RawMaterialRenderComponentList.current.delete(name);
            }
            return __update;
        })
    }
    const FetchProduct = async() => 
    {
        try 
        {
            const fd = new FormData();
            fd.append('workspace_id', auth.activeWorkShop);
            const res = await FetchOrderableBakeryProduct(fd);

            SetValidProductList(res);

            return res;

        }catch(err) 
        {
            console.log(err);
        }
    }
    const FetchMix = async()=> 
        {
            try 
            {
                const res = await FetchYamMixOrderableBranch(auth.activeWorkShop, auth.token);
                if(res.length > 0)
                {
                    SetValidMixList(res);
                }

                return res;
            }catch(err) 
            {
                console.log(err);
            }
    }
    const FetchData = async()=> 
        {
            try 
            {
                const avail_data = await FetchTragetSavedOrder();
               const _raw_product_list =  await FetchProduct();
               const _orderable_mix =  await FetchMix();

            //    Here we need to create generic data for mix and product 


            const __data_generic = [];

            for(let i = 0; i < _raw_product_list.length; i++) 
            {
                if(!_raw_product_list[i].YamMix_Orderable) 
                {

                    // Before anything
                    // If the item already exist we should ignore it
                    if(avail_data) 
                    {

                        // WE need to to manual linear search here
                        let _clone_found = false;
                        for(let j = 0; j < avail_data.AvailProduct.length; j++) 
                        {
                            if(avail_data.AvailProduct[j].Data.CMBPID === _raw_product_list[i].CMBPID) 
                            {
                                _clone_found = true;
                                break;
                            }
                        }

                        if(_clone_found)  { continue; }
                        
                    }


                    // Before  Adding make sure it is available to the current branch
                    const _branch_spec_data = JSON.parse(_raw_product_list[i].CMBP_WorkspaceSpec);

                    if(_branch_spec_data[auth.activeWorkShop])
                    {
                        const _branch_specific_data = _branch_spec_data[auth.activeWorkShop];
                        if(_branch_specific_data.Active)
                        {
                            const __obj_1 = {
                                ItemName: _branch_specific_data.Alias ? _branch_specific_data.Alias : _raw_product_list[i].CMBP_Name,
                                ItemID: _raw_product_list[i].CMBPID,
                                IsItemMix: false,
                                RecipeID: _raw_product_list[i].CMBP_RecipeID,
                                UID: nanoid()
                            }
                            __data_generic.push({...__obj_1, ..._raw_product_list[i]})
                        }
                        
                    }

                    
                }
            }

            for(let i = 0; i < _orderable_mix.length; i++) 
            {
                if(_orderable_mix[i].YamMix_Orderable) 
                {

                    if(avail_data) 
                    {

                        // WE need to to manual linear search here
                        let _clone_found = false;
                        for(let j = 0; j < avail_data.AvailMix.length; j++) 
                        {
                            if(avail_data.AvailMix[j].Data.MID === _orderable_mix[i].MID) 
                            {
                                _clone_found = true;
                                break;
                            }
                        }

                        if(_clone_found)  { continue; }
                        
                    }

                    const _branch_spec_data = JSON.parse(_orderable_mix[i].MixAvailWorkspace);

                    if(_branch_spec_data[auth.activeWorkShop]) 
                    {
                        const _obj_temp_1 = {
                            ItemName: _orderable_mix[i].MixName,
                            ItemID: _orderable_mix[i].MID,
                            IsItemMix: true,
                            UID: nanoid()
                        }
                        __data_generic.push({..._obj_temp_1, ..._orderable_mix[i]})
                    }
                    
                }
            }

            // Now Create Drop Down Menu
            PrepareDropDownList(__data_generic, SetAdditionalEntryMasterOption);
            SetGenericDataOrigin(__data_generic);

            }catch(err) 
            {
                console.log(err);
            }
    }
    const FetchTragetSavedOrder = async()=>
    {
        SetLoadingData(true);
        isFetching.current = true;
       try 
       {
         // Buffers for Product List and Prod Mix
         const __prod_main = [];
         const __prod_mix = [];
 
         // Get Common Value
             SetDayShift(Target.POS_TargetShift === "Day");
             SetMultiplyWithBatch(Target.POS_BatchBased);
             SetUnifiedStock(Target.POS_UnifiedStock === 1);
 
             SetGlobalShare(Target.POS_GlobalShare === 1);
             SetLocalShare(Target.POS_LocalShare === 1);
 
            // Now we a have Hash value which represent The saved Product
            // Based on that We query all PRODUCT tied with this hash value
            const TargetHash = Target.POS_Hash;
             const order_prod_list  = await FetchSavedProductionProductOrderItem(TargetHash, auth.token);
         
 
             // We iterate each one
             for(let i = 0; i < order_prod_list.length; i++) 
             {
                 // If The selected product is a Mix
                 if(order_prod_list[i].POP_IsMix) 
                 {
                     // Query the Mix Data - Here our goal is to make it look like the original
                     // data so get the Mix Data using the POP_ProdID
                     const __mix_res = await FetchMixSaved(order_prod_list[i].POP_ProdID, auth.token);
 
                     // We make sure we have the Data
                     if(__mix_res.length > 0) 
                     {
                         // If we have correct Data Push an object inot the buffer with RandomID Which will be help full later
                         __prod_mix.push({ID: getRandomID(), Data: __mix_res[0]});
 
                         // We also Read the Registered Batch Count - Since we have the name this is much easier
                         CreateProdMixBatch(__mix_res[0].MixName, order_prod_list[i].POP_Batch);
 
                         // Select the first By Default
                         if((i === 0) && (DynamicMaterialInputComponentWrapper.length === 0)) 
                          {
                              SetFilterKey(__mix_res[0].MixName);
                          }
 
                         // Now Based on the Pop_Hash (Which is Special Hash Value which represent specific Product in the order)
                         // Query all the Material // InventoryItem Related to this Product-Order
                         if(Target.POS_UnifiedStock === 0)
                         {
                             await ReFeedRawMaterial(order_prod_list[i].POP_Hash, __mix_res[0].MixName);
                         }
                     }
                     
 
                 } else // If the Selected Product is Raw-Product
                 {
                     const fd = new FormData();
                     fd.append("targte_id", order_prod_list[i].POP_ProdID);
                     // This is exactly the same fking Proces as discribed above only for Raw-Product
                     const __prod_res = await FetchBakeryProductByID(fd);
                     if(__prod_res.length > 0) 
                     {
                          if((i === 0) && (DynamicMaterialInputComponentWrapper.length === 0)) 
                          {
                              SetFilterKey(__prod_res[0].CMBP_Name);
                          }
                         __prod_main.push({ID: getRandomID(), Data: __prod_res[0]});
                         CreateProdMainBatch(__prod_res[0].CMBP_Name, order_prod_list[i].POP_Batch);
 
                         // console.log(__prod_res[0].CMBP_Name);
                         if(Target.POS_UnifiedStock === 0) 
                         {
                             await ReFeedRawMaterial(order_prod_list[i].POP_Hash, __prod_res[0].CMBP_Name);
                         }
                     }
                     
 
                 }
             }
 
             if(Target.POS_UnifiedStock === 1)  
             {
                 await ReFeedRawMaterial(Target.POS_Hash, "UNIFIED_STOCK");
             }
 
             SetDataMix(__prod_mix);
             SetData(__prod_main);
             isFetching.current = false;
             SetLoadingData(false);

             return {
                AvailProduct: __prod_main,
                AvailMix: __prod_mix
             };
       } catch(err) 
       {
            SetLoadingData(false);
            notify.ShowError("Failed to get your saved config, please try again");
            return {};
       }
    }
    const UpdateShareStateLocal = async(val)=>{

        if(!val && GlobalShare) 
        {
            notify.ShowWarn("Huh! you think you really slick? Huh? Please Respectfully Disable [Global Share] First!");
            return;
        }

        SetLocalShare(val);
        notify.ShowLoad("Updating Sharing Stat....", 1)
        const fd = new FormData();
        fd.append("LocalShare", val);
        fd.append("GlobalShare", GlobalShare);
        fd.append("POS_Hash", Target.POS_Hash);
        const update_res = await UpdateSavedPOShareState(fd);

        if(update_res.MyStateCode !== 1) 
        {
            notify.ShowErrorUpdate(update_res.Msg, 1);
        }else 
        {
            notify.ShowSuccessUpdate(update_res.Msg, 1);
        }
    }
    const UpdateShareStateGlobal = async(val)=>{

        // If the user is trying to set global true while local is false say no
        if(val && !LocalShare) 
        {
            notify.ShowWarn("Please Share it locally First! It is like help your family before helping friend!");
            return;
        } else 
        {
            SetGlobalShare(val);
            notify.ShowLoad("Updating Sharing Stat....", 1)
            const fd = new FormData();
            fd.append("LocalShare", LocalShare);
            fd.append("GlobalShare", val);
            fd.append("POS_Hash", Target.POS_Hash);
            const update_res = await UpdateSavedPOShareState(fd);

            if(update_res.MyStateCode !== 1) 
            {
                notify.ShowErrorUpdate(update_res.Msg, 1);
            }else 
            {
                notify.ShowSuccessUpdate(update_res.Msg, 1);
            }
        }

        
    } 
    useEffect(()=>{
        if(!isFetching.current) 
        {
            FetchData();
        }
        FetchBranchWarehouseClinet();
    }, []);
    useEffect(()=>
    {
        if(Target.POS_UnifiedStock === 1) 
        {
            SetFilterKey('UNIFIED_STOCK');
        }
    }, [Data, DataMix])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            <div className={`${data_win.data_main} ${classes.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Creating <span className={`${data_win.data_create_name}`}>Production Order //{Target.POS_Name}</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            {/* <form onSubmit={SaveOrderConfig} method="post">
                                <div className={`${ShowSaveInput ? classes.config_name_setup_wrapper : classes.config_name_setup_wrapper_hide}`}>
                                    <input onChange={(e)=>{SetOrderPkgName(e.target.value)}} name='order_pkg_name' type="text" placeholder='Order Package Name' required />
                                    <button type='submit'>Save Config</button>
                                </div>
                            </form> */}
                            <form onSubmit={HandleSubmition} method="POST">
                                <div className={`${classes.form_wrapper}`}> 

                                    <div className={`${HideAdvanceSetting ? classes.batch_operation_action_hide :  classes.batch_operation_action}`}>
                                        
                                        <div className={`${classes.order_mark_action}`}>
                                            <li className={`${classes.shift_controller} ${DayShift ? classes.day_shift : classes.night_shift}`} onClick={()=>{SetDayShift(!DayShift)}}><FontAwesomeIcon icon={DayShift ? faSun : faMoon}></FontAwesomeIcon> {DayShift ? 'Day Shift' : 'Night Shift'}</li>
                                            
                                            {/* <li onClick={()=>{SetMultiplyWithBatch(!MultiplyWithBatch)}}>
                                                <input onChange={(e)=>{SetMultiplyWithBatch(e.target.value)}} checked={MultiplyWithBatch} type="checkbox" />
                                                <span>Batch-Based</span>
                                            </li>
                                            <li onClick={()=>{SetFlourOnly(!FlourOnly)}}>
                                                <input onChange={(e)=>{SetFlourOnly(e.target.value)}} checked={FlourOnly} type="checkbox" />
                                                <span>Flour Only</span>
                                            </li> */}
                                           {UnifiedStock &&  <li {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "This is readonly, And it means when you save this thing you check Unified Stock."})} onClick={()=>{}}>
                                                <input readOnly checked={UnifiedStock} type="checkbox" />
                                                <span>Unified Stock</span>
                                            </li>}
                                       
                                        </div>


                                        <div className={`${classes.left_size_manipulate_wrapper}`}>
                                        
                                            {/* <button name="action" type='submit' onClick={(e)=>{SetFormActionType("save")}} className={`${classes.order_save}`}><span><FontAwesomeIcon icon={faCloud}></FontAwesomeIcon></span> <span>Save</span></button> */}
                                            {/* { (Data.length + DataMix.length > 1) && <li onClick={()=>{
                                                        DAControl.Trigger(`${UnifiedStock ? 'Disable Unified Stock Access' : 'Enable Unified Stock Access'}`, 
                                                            `You are about to ${UnifiedStock ? 'Disable' : 'Enable'} unified stock inventory access. 
                                                                This action will affect your current entries, if any.`, 
                                                            !UnifiedStock,
                                                            7, (val)=>{
                                                                if(val !== null) 
                                                                {
                                                                    ToggelUnified(val)
                                                                }
                                                            }   
                                                        );
                                                        }}>
                                                    <input checked={UnifiedStock} onChange={(e)=>{}} type="checkbox" />
                                                    <span>Unified Stock</span>
                                                </li> } */}
                                                {/* <div className={`${classes.store_choice}`}>
                                                    <MasterDataRegInput input={
                                                            <Select
                                                                onChange={(data)=>{SetSelectedWarehouse(data)}}
                                                                name={"dd_data"}
                                                                value={SelectedWarehouse}
                                                                options={WorkspaceWarehouseList}
                                                                placeholder={"Warehouse..."}
                                                                isSearchable={true}
                                                                styles={Custom_DD_Style}
                                                                required ={true}
                                                        />
                                                        }  />
                                                </div> */}
                                              {auth.user.USR_UserAccountID === Target.USR_UserAccountID &&  
                                                <>
                                                    <li onClick={()=>{
                                                            DAControl.Trigger(`${LocalShare ? 'Revoke' : 'Allow'} Local Access`, 
                                                            `You are about to ${LocalShare ? 'Revoke' : 'Allow'} other user in your vicinity ${LocalShare ? 'from using' : 'to use'} this Production-Order configuration!`,
                                                            !LocalShare,
                                                            5, (val)=>{
                                                                if(val !== null) 
                                                                {
                                                                    UpdateShareStateLocal(val)
                                                                }
                                                            }   
                                                        );}}>
                                                        <input onChange={(e)=>{}} checked={LocalShare} type="checkbox" />
                                                        <span>Share Local</span>
                                                    </li>
                                                    <li onClick={()=>{
                                                            DAControl.Trigger(`${GlobalShare ? 'Revoke' : 'Allow'} Global Access`, 
                                                            `You are about to ${GlobalShare ? 'Revoke' : 'Allow'} other user from diffrent branch ${GlobalShare ? 'from using' : 'to use'} this Production-Order configuration!`,
                                                            !GlobalShare,
                                                            5, (val)=>{
                                                                if(val !== null) 
                                                                {
                                                                    UpdateShareStateGlobal(val)
                                                                }
                                                            }   
                                                        );}}>
                                                        <input onChange={(e)=>{}} checked={GlobalShare} type="checkbox" />
                                                        <span>Share Global</span>
                                                    </li>
                                                    <li 
                                                        className={`${classes.shift_controller} ${classes.purge_saved_po_config}`} 
                                                        onClick={()=>{
                                                            DAControl.Trigger('Remove Production Order Configuration', 
                                                                `You are about to PURGE production order configuration named [${Target.POS_Name}]. 
                                                                If you shared this PO config to other user they LOSS ACCESS IMMEDIATELY. soo.. with that in mind `, 
                                                                true,
                                                                7, (val)=>{
                                                                    if(val !== null) 
                                                                    {
                                                                        PurgeSavedPoConfig(Target.POS_Name);
                                                                    }
                                                                }   
                                                            )
                                                            }}>
                                                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> Purge
                                                    </li>
                                                </>
                                              }
                                            
                                        </div>
                                        
                                    </div>
                                    <div className={`${classes.toggle_advance_setting_wrapper}`}>
                                        <button type='button' onClick={()=>{SetHideAdvanceSetting(!HideAdvanceSetting)}}><FontAwesomeIcon icon={HideAdvanceSetting ? faCircleArrowDown : faCircleArrowUp}></FontAwesomeIcon></button>
                                    </div>

                                    <div className={`${classes.dat_wrap_flex}`}>
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            <div className={`${classes.input_adder}`}>
                                                <li>Target Product</li>
                                               <div style={{width: '15em'}}>
                                                <MasterDataRegInput 
                                                        input={
                                                            <Select
                                                                    onChange={(data)=>{HandleAdditionalDataEntry(data)}}
                                                                    name={"dd_data"}
                                                                    options={AdditionalEntryMasterOption}
                                                                    placeholder={"Add New Product.."}
                                                                    isSearchable={true}
                                                                    styles={Custom_DD_Style}
                                                            />
                                                            }
                                                    />
                                               </div>
                                            </div>
                                            <div className={`${classes.po_mat_list}`}>
                                                {
                                                    AdditionalEntryData.map((item, idx)=>(
                                                        <div 
                                                            key={item.UID + idx}  
                                                            onClick={()=>{SetFilterKey(item.Data.ItemName)}} 
                                                            className={`${classes.order_prod_list} 
                                                            ${ActiveRawMaterialViewID === item.Data.ItemName ? classes.order_prod_list_active : ''}`}>
                                                                <div className={`${classes.order_item_spec}`}><MasterDataRegInput input={<input value={item.Data.ItemName} type="text" title={item.Data.CMBP_Name} autoComplete='off' readOnly />} /></div>
                                                                <div className={`${classes.order_item_spec}`}>
                                                                    <MasterDataRegInput 
                                                                        input={
                                                                            <input 
                                                                                // value={ProdMainBatchCount.current.get(item.Data.ItemName) || ''} 
                                                                                onChange={(e)=>{
                                                                                    if(item.Data.IsItemMix) 
                                                                                    {
                                                                                        CreateProdMixBatch(item.Data.ItemName, e.target.value)
                                                                                    } else 
                                                                                    {
                                                                                        CreateProdMainBatch(item.Data.ItemName, e.target.value)
                                                                                    }
                                                                                }} 
                                                                                name='po_batch' 
                                                                                type="number" 
                                                                                min={1} 
                                                                                placeholder='Batch' 
                                                                                title='' 
                                                                                autoComplete='off' 
                                                                                required />}
                                                                    />
                                                                </div>
                                                                <div className={`${classes.order_item_action_button_wrapper}`}>
                                                                    <UIButton Type={'button'} Variant='Danger' Icon={<XCircle weight='fill'/>} onClick={()=>{PurgeAdditionalDataEntry(item.Data.UID, item.Data.ItemName)}} label={"Removes"}/>
                                                                </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    Data.map((item, idx)=>(
                                                        <div 
                                                            key={idx}  
                                                            onClick={()=>{SetFilterKey(item.Data.CMBP_Name)}} 
                                                            className={`${classes.order_prod_list} 
                                                            ${ActiveRawMaterialViewID === item.Data.CMBP_Name ? classes.order_prod_list_active : ''}`}>
                                                                <div className={`${classes.order_item_spec}`}><MasterDataRegInput input={<input value={item.Data.CMBP_Name} type="text" title={item.Data.CMBP_Name} autoComplete='off' readOnly />} /></div>
                                                                <div className={`${classes.order_item_spec}`}>
                                                                    <MasterDataRegInput 
                                                                        input={
                                                                            <input 
                                                                                value={ProdMainBatchCount.current.get(item.Data.CMBP_Name) || ''} 
                                                                                onChange={(e)=>{CreateProdMainBatch(item.Data.CMBP_Name, e.target.value)}} 
                                                                                name='po_batch' 
                                                                                type="number" 
                                                                                min={1} 
                                                                                placeholder='Batch' 
                                                                                title='' 
                                                                                autoComplete='off' 
                                                                                required />}
                                                                    />
                                                                </div>
                                                                <div className={`${classes.order_item_action_button_wrapper}`}>
                                                                    <UIButton Type={'button'} Variant='Danger' Icon={<XCircle weight='fill'/>} onClick={()=>{PurgeProdOrderDataEntry(item.Data.CMBP_Name)}} label={"Removes"}/>
                                                                </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    LoadingData && 
                                                    <LoadingPlaceholder preview_text='Loading Your Data'/>
                                                }
                                                {
                                                    DataMix.map((item, idx)=>(
                                                        <div 
                                                            key={item.Data.MixName + idx} 
                                                            onClick={()=>{SetFilterKey(item.Data.MixName)}}
                                                            className={`${classes.order_prod_list}
                                                            ${ActiveRawMaterialViewID === item.Data.MixName ? classes.order_prod_list_active : ''}`}>
                                                            <div className={`${classes.order_item_spec}`}><MasterDataRegInput input={<input value={item.Data.MixName} type="text" title={item.Data.MixName} autoComplete='off' readOnly />} /></div>
                                                            <div className={`${classes.order_item_spec}`}>
                                                                    <MasterDataRegInput 
                                                                        input={
                                                                            <input 
                                                                                value={ProdMixBatchCount.current.get(item.Data.MixName) || ''}
                                                                                onChange={(e) => CreateProdMixBatch(item.Data.MixName, e.target.value)} 
                                                                                name='po_batch_mix' 
                                                                                type="number" 
                                                                                min={1} 
                                                                                placeholder='Batch' 
                                                                                title='' 
                                                                                autoComplete='off' 
                                                                                required />} 
                                                                    />
                                                            </div>
                                                            <div className={`${classes.order_item_action_button_wrapper}`}>
                                                                    <UIButton Type={'button'} Variant='Danger' Icon={<XCircle weight='fill'/>} onClick={()=>{PurgeMixAsOrderDataEntry(item.Data.MixName)}} label={"Removes"}/>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        <div className={`${classes.raw_material}`}>
                                            <div className={`${classes.input_adder}`}>
                                                <li className={`${UnifiedStock ? classes.unified_stock_name_head : ''}`}>[{ActiveRawMaterialViewID}] #Complementary Stock</li>
                                                <button onClick={()=>{AddNewInputField(ActiveRawMaterialViewID)}} type='button'><span><FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></span> <span>Extra Stock</span></button>
                                            </div>
                                            <div className={`${classes.po_mat_list}`}>
                                                    {DynamicMaterialInputComponentWrapper.map((item, idx) =>  ( 
                                                        <div className={`${classes.input_fild_shild}`} key={item.props.RootName + idx}>
                                                            {React.cloneElement(item, 
                                                                {ReadBack_Quantity: OrderProductPackage.current.has(ActiveRawMaterialViewID) 
                                                                    ?  OrderProductPackage.current.get(ActiveRawMaterialViewID).QuantityData 
                                                                        : null, 
                                                                ReadBack_Item: OrderProductPackage.current.has(ActiveRawMaterialViewID) 
                                                                    ? OrderProductPackage.current.get(ActiveRawMaterialViewID).ItemData 
                                                                        : null})}

                                                            {/* {POMatItems.length > 1 ? <button type='button' onClick={()=>{RemoveInputField(item.props.idx)}} className={`${classes.remove_button_wrapper}`}>
                                                                <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
                                                                </button> : <span  className={`${classes.remove_button_wrapper_fake}`}></span> } */}
                                                        </div>
                                                    ))}
                                            </div>
                                            
                                        </div> 
                                        
                                    </div>
                                            
                                </div>

                                

                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} type="submit"   name="action" value="submit" onClick={(e)=>{SetFormActionType("submit")}}   className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Order'}</button>
                                    <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}