import classes from './ChefsProductionStackCard.module.css'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed'
import { useEffect, useState } from 'react'
import { formatDistanceToNow } from 'date-fns';

import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo';

import {FinishChefsItemProduction, StartChefsItemProduction} from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'
import { useToast } from '../../../../../ToastContext';
import { ArrowCircleLeft, ArrowCircleRight, ArrowCircleUpRight, MinusCircle, PlusCircle } from '@phosphor-icons/react';

import SpecialOrderDetailView from '../RecipeCard/SpecialOrderDetailView/SpecialOrderDetailView';
import { useDAControl } from '../../../../../DAControlContext';
import { useAuth } from '../../../../../AuthProvider';

export default function ChefsProductionStackCard({TargetData, DataRef}) 
{
    const [IsSolo, SetIsSolo] = useState(TargetData?.KV_IsSolo === "1");
    const [IsStarted, SetIsStarted] = useState(false);
    const [IsSpecialOrder, SetIsSpecialOrder] = useState(TargetData?.KPSR_PendingBackUp.KPPO_SpecialOrderDetail !== null)
    const [OpenSpecialOrderDetailPreview, SetOpenSpecialOrderDetailPreview] = useState(false);
    const notify = useToast();

    const auth = useAuth();
    const DAcontrol = useDAControl();

    const SliceToleration = useState(10);
    const BaseSliceCount = TargetData.KV_SliceCount * TargetData.KPSR_ProductCount;
    const [ExpectedSliceCount, SetExpectedSliceCount] = useState(TargetData.KV_SliceCount * TargetData.KPSR_ProductCount);

    const PluseSliceCount = ()=> 
    {
        SetExpectedSliceCount(ExpectedSliceCount + 1); 
    }
    const MinusSliceCount = ()=> 
    {
        SetExpectedSliceCount(ExpectedSliceCount - 1);
    }


    useEffect(()=>{
        SetIsStarted(TargetData?.KPPR_StartTime !== null);
        SetIsSpecialOrder(TargetData?.KPSR_PendingBackUp.KPPO_SpecialOrderDetail !== null);
    }, [TargetData])


    const HandleFinishProduct = async()=>
    {
        try 
        {
            const _expected_slice_count = TargetData.KV_SliceCount * TargetData.KPSR_ProductCount;
            const fd = new FormData();
            fd.append("production_ready_uuid", TargetData.KPPR_UUID);
            fd.append("process_entry_id", TargetData.KCOP_ID);
            fd.append("active_workspace", auth.activeWorkShop);
            fd.append("expected_slice_count", _expected_slice_count);
            fd.append("provided_slice_count", ExpectedSliceCount);
            
            const main_res = await FinishChefsItemProduction(fd);
            notify.ShowSuccess(main_res.Msg);
            DataRef();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const HandleStartProduct = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("production_ready_uuid", TargetData.KPPR_UUID);
            fd.append("process_entry_id", TargetData.KCOP_ID);
            fd.append("product_id", TargetData.KPSR_ProductID);
            fd.append("product_variance_id", TargetData.CKP_ItemVarianceID);
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("product_count", TargetData.KPSR_ProductCount);
            
            const main_res = await StartChefsItemProduction(fd); 
            notify.ShowSuccess(main_res.Msg);
            DataRef();
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }



    return (
        <>
        {OpenSpecialOrderDetailPreview && <SpecialOrderDetailView IsProdCard={true} DV_TargetData={TargetData?.KPSR_PendingBackUp} OpenController={SetOpenSpecialOrderDetailPreview}/>}
        <div className={`${classes.chefs_production_stack_card} ${IsStarted ? classes.chefs_production_stack_card_started : ''}`}>

            
            <div className={`${classes.chef_prod_name}`}>
                <div className={`${classes.card_name_right_info}`}>
                    <h3>{TargetData.CKP_ItemName}</h3>
                    <li>{TargetData.KV_TypeName}</li>
                </div>
                <div className={`${classes.card_name_more_right_special_info}`}>
                    {IsSpecialOrder && <h3 onClick={()=>{SetOpenSpecialOrderDetailPreview(true)}}><span><ArrowCircleUpRight weight='bold'/></span></h3>}
                </div>
            </div>
{/* Complete  */} 
            <div className={`${classes.prod_detail}`}>
                <li className={`${classes.expectation_tag}`}>Expected</li>
                <li>
                        <span className={`${classes.expenct_amount}`}>{TargetData.KPSR_ProductCount} Sheet</span>
                         {!IsSolo ? 
                                IsStarted ? <span className={`${classes.expenct_amount} ${classes.mod_slice_wrap}`}> <MinusCircle onClick={()=>{MinusSliceCount()}} className={`${classes.decrease_slice_count}`} size={15} weight='fill'/> {ExpectedSliceCount} Slice <PlusCircle onClick={()=>{PluseSliceCount()}} className={`${classes.increase_slice_count}`} size={15} weight='fill'/></span>  :
                                <span className={`${classes.expenct_amount}`}> - {ExpectedSliceCount} Slice </span> 

                        : ''}  </li> 
            </div>

            <div className={`${classes.card_right_data}`}>
                {/* <li>Sheet Count: {TargetData.KPSR_ProductCount}</li> */}
                <li>{IsStarted ? "Time: " : '' } {IsStarted ?  <TimeAgo date={TargetData.KPPR_StartTime}/> : "Waiting action..."}</li>
            </div>

            
            <div className={`${classes.action_button}`}>
                <button onClick={()=>{IsStarted ? HandleFinishProduct() : HandleStartProduct()}} className={`${IsStarted ? classes.button_action_started : ''}`}>{IsStarted ? "Finish" : "Get started"}</button>
               {/* {IsStarted && <button onClick={()=>{
                DAcontrol.Trigger(`Report Product Damage`,
                `This action will consider the entire product as a damage, and remove this from the production stack.`,
                true,
                6,
                (data)=>{
                    if(data) 
                    {

                    }
                })
               }} className={`${classes.button_action_report_damage}`}>Report Damage</button>}  */}
            </div>
        </div>
        </>
    )
}