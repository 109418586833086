import api from "../Util/axiosInstance";





export const QueryChefsOrderDashboard = async(data)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/query-chefs-order-dashboard', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const QueryLocalStockBuffer = async(data)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/query-local-stock-buffers', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const QueryProductionReadyItems = async(data)=> 
    {
        try  
        {
            const response = await api.post('api/ck/chefs/query-prod-ready-items', data);
            const res = await response.data;
    
            return res;
        }catch(err) 
        {
            throw err; 
        }
    }
export const QueryVariantIng = async(__id, token)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/query-variant-ing', {ID: __id} );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const CreateChefsStockRequest = async(data)=>
{
    try  
    {
        const response = await api.post('api/ck/chefs/create-chefs-stock-request', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const CreateChefsStockRequestFromRecipe = async(data)=>
    {
        try  
        {
            const response = await api.post('api/ck/chefs/create-chefs-stock-request-fomr-recipe-card', data);
            const res = await response.data;

            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
    
            return res;
        }catch(err) 
        {
            throw err; 
        }
    }

export const QueryChefsStockRequestGroup = async(data)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/query-chefs-sr-group', data);
        const res = await response.data;


        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const QueryChefDirectStockRequest = async(data)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/query-chefs-direct-stock-request', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err; 
    }
}

export const AcceptChefsStockRequestItem = async(data)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/accept-chefs-sr-prod-request', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const RejectChefsStockRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/ck/chefs/reject-chefs-prod-stock-request', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FinishChefsItemProduction = async(data)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/finish-chefs-prod-item', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const StartChefsItemProduction = async(data)=> 
{
    try  
    {
        const response = await api.post('api/ck/chefs/start-chefs-prod-item', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err; 
    }
}