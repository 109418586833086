import classes from './TransferFinishedProduct.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'

import { v4 as uuidv4 } from 'uuid'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import {CreateNewMerchGroup} from '../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'
import {FetchBranchExcludedCurrent} from '../../../../api'
 
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'

//lib
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'

import FinalProductChoiceInputPackage from './FinalProductChoiceInputPackage/FinalProductChoiceInputPackage'
import UIButton from '../../../Components/UIButton/UIButton'
import { CirclesThreePlus, TrashSimple } from '@phosphor-icons/react'
import { CreateFinalBakeryProductRequest } from '../../../../SharedComp/API/FinalBakeryTransfer/FinalBakeryTransfer'
import { FetchBakeryProductAll } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery'



export default function TransferFinishedProduct({OpenController}) 
{

        const auth = useAuth();
        const notify = useToast();
    
        const [SelectedDestDate, SetSelectedDestDate] = useState();
        const [Loading, SetLoading] = useState(false);
        const [SummerizedProductionList, SetSummerizedProductionList] = useState([]);

        const [ValidBranchList, SetValidBranchList] = useState([]);
        const [SelectedBranch, SetSelectedBranch] = useState();

        const [DynamicInputList, SetDynamicInputList] = useState([uuidv4()]);

        const [DynamicInputListMainData, SetDynamicInputListMainData] = useState(new Map());



        const AddNewDynamicInput = ()=>
        {
            const __id = uuidv4();

            SetDynamicInputList((prev)=>{
                const __current = [...prev];
                __current.push(__id);

                return __current;
            })

        }
        const RemoveDynamicInputEntry = (__id)=>
        {
            const _idx_to_purge = DynamicInputList.indexOf(__id);
            if(_idx_to_purge !== -1) 
            {
                SetDynamicInputList((prev)=>{
                    const __current = [...prev];
                    __current.splice(_idx_to_purge, 1)
                    return __current;
                })
            }

            // Remove the data also
            SetDynamicInputListMainData((prev)=>
            {
                const __update = new Map(prev);
                if(__update.has(__id)) 
                {
                    __update.delete(__id);
                }

                return __update;
            })
            
        }
        const DyanamicInputListUpdateCallback = (update_key, update_value_ref, update_value)=>
        {
            SetDynamicInputListMainData((prev)=>{
                const __update = new Map(prev);
                if(__update.has(update_key)) 
                {
                    const _target_data ={... __update.get(update_key)};
                    _target_data[update_value_ref] = update_value;

                    __update.set(update_key, _target_data);
                }else 
                {
                    __update.set(update_key, {
                        [update_value_ref]: update_value
                    })
                }
                
                return __update;
            })
        }
        const FetchValidBranchList = async() =>
        {
            try 
            {
                const res = await FetchBranchExcludedCurrent(auth.activeWorkShop, auth.token);
                PrepareDropDownList(res, SetValidBranchList);
            }catch(err) 
            {
                notify.ShowError("Falied to Fetch valid Workspace");
            }
        }
        function CloseModal() 
        {
            OpenController(false);
        }
        const HandleSubmition = async(e)=> 
        {
            e.preventDefault();
            notify.ShowLoad("Processing Your Request...", 10);
            SetLoading(true);
            try 
            {
                const fd = new FormData();

                fd.append("data_request_package", JSON.stringify(Array.from(DynamicInputListMainData.entries())));
                fd.append("target_branch", SelectedBranch.value);
                fd.append("sender_branch", auth.activeWorkShop);
                fd.append("sender_branch_name", auth.activeWorkShopName);
                fd.append("sender_user_id", auth.user.USR_UserAccountID);
                fd.append("target_arrival_date", SelectedDestDate);
                const main_res = await CreateFinalBakeryProductRequest(fd);
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                SetLoading(false);
                OpenController(false);
            } catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
                SetLoading(false);
            }
    
        }

        const QueryAllBakeryItem = async()=>
        {
            try 
            {
                const main_res = await FetchBakeryProductAll(auth.token);
                SetSummerizedProductionList(main_res);
            }catch(err) 
            {
                notify.ShowError(err.message);
            }
        }

        useEffect(()=>{
            FetchValidBranchList();
            QueryAllBakeryItem();
        }, [])
    


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Create <span className={`${data_win.data_create_name}`}>Finished Product Request</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                        <div  className={`${classes.input_list_wrapper}`}>
                                                
                                                <div className={`${classes.input_header_single_wrapper_st_req}`}>
                                                    <MasterDataRegInput input={
                                                        <Select
                                                            onChange={(data)=>{SetSelectedBranch(data);}}
                                                            name={"dd_data"}
                                                            options={ValidBranchList}
                                                            placeholder={"Recipient..."}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                        /> } 
                                                      />
                                                </div>
                                                
                                                <div className={`${classes.input_header_single_wrapper_st_req}`}>
                                                    <MasterDataRegInput input={<input onChange={(e)=>{SetSelectedDestDate(e.target.value)}} name='need_date' type="date" required />} />
                                                </div>

                                        </div>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <div className={`${classes.final_product_ship_wrapper}`}>
                                                
                                                    {
                                                        DynamicInputList.map((item_key, idx)=>(
                                                            <li key={item_key}>
                                                                <span className={`${classes.main_input_final_product_ship_wrapper}`}>
                                                                    <FinalProductChoiceInputPackage
                                                                        Sender={false}
                                                                        ID={item_key}
                                                                        DropDownProductList={SummerizedProductionList}
                                                                        Update_CALLBACK={DyanamicInputListUpdateCallback}
                                                                    />
                                                                </span>
                                                                <span><UIButton Type='button' Icon={<TrashSimple/>} Variant='Danger' onClick={()=>{RemoveDynamicInputEntry(item_key)}} label={"Remove"}/></span>
                                                            </li>
                                                        ))
                                                    }
                                            </div>

                                    </div>

                                    

                                    
                                </div>
                                <div className={`${classes.finsla_product_trasnfer_package_input_action_button}`}>
                                        <UIButton Type='button' onClick={()=>{AddNewDynamicInput()}} label={"Add More"} Icon={<CirclesThreePlus/>}/>
                                </div>
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Create Request'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}