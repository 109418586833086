import { ArrowBendRightUp, CurrencyDollar } from '@phosphor-icons/react'
import classes from './BudgetExpenditureLocalView.module.css'
import UIButton from '../../../Components/UIButton/UIButton'
import { ToEthiopianCalander } from '../../../../SharedComp/Utility'
import { useState } from 'react'
import { useEffect } from 'react'
import EmptyProxyDataView from '../../../../SharedComp/DataViewTableProxy/EmptyDataViewTable'

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function BudgetExpenditureLocalView({ReleaseCash_Callback, ExpList})
{
    const [DetailPreviewMap, SetDetailPreviewMap] = useState(new Map());

    useEffect(()=>{

        const _temp_map = new Map();
        for(let i = 0; i < ExpList.length; i++) 
        {
            _temp_map.set(ExpList[i].PTYCSH_UID, false);
        }
        SetDetailPreviewMap(new Map(_temp_map))

    }, [ExpList])


    const OpenDetail = (uid)=>
    {
        SetDetailPreviewMap((prev)=>
        {
            const __update = new Map(prev);
            for(const [key, value] of __update)
            {
                if(key === uid) 
                {
                    if(__update.get(key)) 
                    {
                        __update.set(key, false);
                    } else 
                    {
                        __update.set(key, true);
                    }
                    
                } else 
                {
                    __update.set(key, false);
                }
            }
            return __update;
        })
    }


    return (
    <div className={`${classes.budget_allocation_history_local_view_master_wrapper}`}>
        <div className={`${classes.budget_allocation_history_local_view_inner_wrapper}`}>

            <div className={`${classes.budget_allocation_history_local_view_header}`}>
                <h2>Cash Expenditure</h2>
                <UIButton Variant='Secondary' onClick={()=>{ReleaseCash_Callback(true)}} label={"Release Cash"} Icon={<ArrowBendRightUp weight='bold'/>}/>
            </div>

            <div className={`${classes.budget_allocation_history_local_view_body_master_wrapper}`}>
               {
                    ExpList.map((item, idx)=>(
                        <div key={idx} className={`${classes.single_transaction_preview}`}>
                            <li 
                                key={idx}
                                onClick={()=>{OpenDetail(item.PTYCSH_UID)}}
                                >
                                <span>
                                    {ToEthiopianCalander(new Date(item.PTYCSH_DateTime))} @ {new Date(item.PTYCSH_DateTime).toLocaleTimeString()}
                                </span>
                                <span>
                                    {item.USR_FirstName} {item.USR_LastName}
                                </span>
                                <span>
                                    {item.HRMUET_Name}
                                </span>
                                <span className={`${classes.cash_view_value}`}>
                                    -{currency_format(item.PTYCSH_Amount)}
                                </span>
                            </li>
                            <div className={`${DetailPreviewMap.get(item.PTYCSH_UID) ? classes.transaction_detail_inner : classes.transaction_detail_hide}`}>
                                <li>MRC: <span>{item.PTYCSH_MRC}</span></li>
                                <li>TIN: <span>{item.PTYCSH_TIN}</span></li>
                                <li>Receipt: <span>{item.PTYCSH_Receipt}</span></li>
                                <li>Reason: <span>{item.PTYCSH_Reason}</span></li>
                            </div>
                        </div>
                        
                    ))
               }
               {
                ExpList.length <= 0 && 
                    <EmptyProxyDataView text='Seems No Expenditure Yet!'/>
               }
            </div>
        
        </div>
    </div>
    )
}