import classes from './StockCard.module.css'
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDown, faChartArea, faChevronRight, faEllipsisVertical, faPrint } from '@fortawesome/free-solid-svg-icons';

import {FetchProductionLogDaily} from '../../SharedComp/API/Production/API_ProductionLog'

import { useToast } from '../../ToastContext'
import { useAuth } from '../../AuthProvider';

import ProductionLogGroup from './Component/ProductionLogGroup/ProductionLogGroup';
import ProductionLogGroupTransfer from './Component/ProductionLogGroupTransfer/ProductionLogGroupTransfer';

import PrintProductionLogView from './Component/PrintProductionLogView/PrintProductionLogView';
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';


import TransferFinishedProduct from './Component/TransferFinishedProduct/TransferFinishedProduct';
import TransferFinishedBakeryProductActionTake from './Component/TransferFinishedBakeryProductActionTake/TransferFinishedBakeryProductActionTake';


import { BellRinging, ChatText, Minus, TelegramLogo, WarningDiamond } from '@phosphor-icons/react';
import UIButton from '../Components/UIButton/UIButton';
import { QueryPendingFinalBakeryRequest } from '../../SharedComp/API/FinalBakeryTransfer/FinalBakeryTransfer';
import { ToEthiopianCalander } from '../../SharedComp/Utility';

import TimeAgo from '../../SharedComp/TimeElapsed/TimeAgo';

export default function StockCard() 
{
    const auth = useAuth();

    // This is the all daily production log
    // I mean all day and night
    // we gone preform post processing after the feach is compltated and we 
    // gone fill the Day and Night Shift Array
    const [DailyProductionLog, SetDailyProductionLog] = useState([]);

    // Day Shift Prodution Log Buffer
    const [DayShiftProductionLog, SetDayShiftProductionLog] = useState([]);

    // Night Shift Production Log Buffer
    const [NightShiftProductionLog, SetNightShiftProductionLog] = useState([]);

    const [TransferredInProducts, SetTransferredInProducts] = useState([]);
    const [TransferredIOutProducts, SetTransferredIOutProducts] = useState([]);

    const [ShowSummary, SetShowSummary] = useState(false);
    const [ShowTransferredInProducts, SetShowTransferredInProducts] = useState(false);

    const [TargetDate, SetTargetDate] = useState(new Date());

    const [OpenPrintView, SetOpenPrintView] = useState(false);

    const [DataIncomeLoading, setDataIncomeLoading] = useState(false);

    const [CanSendFinishedGoods, SetCanSendFinishedGoods] = useState(true);

    const [PendingProductRequest, SetPendingProductRequest] = useState([]);

    const [ProcessTargetBakeryRequest, SetProcessTargetBakeryRequest] = useState(false);
    const [TargetBakeryRequestPackage, SetTargetBakeryRequestPackage] = useState();

    const notify = useToast();

    const HandlePrintOpen = async(val)=>
    {
        if(val) 
        {
            if(DailyProductionLog.length > 0) 
            {
                SetOpenPrintView(val);
                return;
            } else 
            {
                notify.ShowWarn("Print What? Is it empty!");
                return;
            }
        }

        SetOpenPrintView(val);
    }
    const SeprateDayNightShiftLog = async(log_buffer)=>
    {
        const __day_shift = [];
        const __night_shift = [];

        for(let i = 0; i < log_buffer.length; i++) 
        {
            if(log_buffer[i].SCM_Shift === 'Day') 
            {
                __day_shift.push(log_buffer[i]);  
            } else 
            {
                __night_shift.push(log_buffer[i]); 
            }
        }

        SetDayShiftProductionLog(__day_shift);
        SetNightShiftProductionLog(__night_shift);
    }
    const QueryDailyProductLog = async()=> 
    {
        setDataIncomeLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("target_day", TargetDate);
            const res = await FetchProductionLogDaily(fd);
            if(res.length > 0) 
            {
                //SeprateDayNightShiftLog(res);
               
                SetDailyProductionLog([...res[0].DayShiftProduction, ...res[0].NightShiftProduction]);
                SetDayShiftProductionLog(res[0].DayShiftProduction);
                SetNightShiftProductionLog(res[0].NightShiftProduction);
                SetTransferredInProducts(res[0].TransferredInProducs);
                SetTransferredIOutProducts(res[0].TransferredOutProducts);
            } else 
            {
                SeprateDayNightShiftLog([]);
                SetDailyProductionLog([]);
            }
            setDataIncomeLoading(false);
        }catch(err) 
        {
            setDataIncomeLoading(false);
            notify.ShowError(err.message);
        }
    }
    const QueryPendingProductRequest = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", auth.activeWorkShop);
            const main_res = await QueryPendingFinalBakeryRequest(fd);
            SetPendingProductRequest(main_res);
        }catch(err) 
        {

        }
    }
    useEffect(()=>{
        QueryDailyProductLog();
        QueryPendingProductRequest();

    }, [TargetDate, auth.user, auth.activeWorkShop])

    const ProcessBranchBakeryProductRequest = (data)=>
    {
        SetTargetBakeryRequestPackage(data);
        SetProcessTargetBakeryRequest(true);
    }

    

    

    return (
        <div className={`${classes.stock_card_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />

            {ProcessTargetBakeryRequest && <TransferFinishedBakeryProductActionTake SelectedDate={TargetDate} RequestPackage={TargetBakeryRequestPackage} ProductList={DailyProductionLog} OpenController={SetProcessTargetBakeryRequest} />}
           {OpenPrintView && <PrintProductionLogView DataList={DailyProductionLog} TargetDate={TargetDate} Close_CALLBACK={SetOpenPrintView}/>} 


           {PendingProductRequest.length > 0 && CanSendFinishedGoods &&  <div className={`${classes.side_ncoming_production_request_notify}`}>

                <div className={`${classes.notification_main_message_wrapper_final_product_notify}`}>

                    <div className={`${classes.message_header_notification_main_message_wrapper_final_product_notify}`}>
                        <li><BellRinging weight='fill' size={16}/></li>
                        <li>Product Request <sup><TimeAgo show_suffix={true} date={new Date(PendingProductRequest[0].TWFP_RequestDateTIme)}/></sup> </li>
                    </div>

                    <div className={`${classes.message_body_notification_main_message_wrapper_final_product_notify}`}>
                        <p>
                            Hello There! A new final product request has been submitted from <strong style={{textDecoration: 'underline'}}>{PendingProductRequest[0].BranchName}</strong>, 
                            with a target date of <strong style={{textDecoration: 'underline'}}>{ToEthiopianCalander(new Date(PendingProductRequest[0].TWFP_TargetArrivalDate))}</strong>. 
                            This request was initiated by <strong style={{textDecoration: 'underline'}}>{PendingProductRequest[0].USR_FirstName} {PendingProductRequest[0].USR_LastName}</strong>. Please take a look and take the next steps.
                        </p>
                    </div>

                </div> 
                <div className={`${classes.notification_main_message_action_take_wrapper_final_product_notify}`}>
                    <UIButton onClick={()=>{ProcessBranchBakeryProductRequest(PendingProductRequest[0])}} Icon={<Minus/>} label={"take a look"}/>
                    {/* <UIButton Variant='Secondary' onClick={()=>{}} Icon={<Minus/>} label={"Minimize"}/> */}
                    {/* <UIButton Variant='Danger' onClick={()=>{}} Icon={<Minus/>} label={"Reject"}/> */}
                </div>

            </div>}

            <div className={`${classes.prodution_log_header_wrapper}`}>
                <div className={`${classes.prodution_log_header_wrapper_inner}`}>

                    <div className={`${classes.prodcution_log_head_inner}`}>
                        <h2>Log -[{new Date(TargetDate).toDateString()}]</h2>
                    </div>

                    <div className={`${classes.prodcution_log_head_inner_right}`}>
                        
                        <div className={`${classes.production_log_header_right_action_wrapper}`}>
                            <input  
                            onChange={(e) => {
                                const selectedDate = new Date(e.target.value);
                                if (!isNaN(selectedDate.getTime())) { 
                                  SetTargetDate(selectedDate);

                                  const today = new Date();
                                //   istoday
                                if(selectedDate.getFullYear() === today.getFullYear() &&
                                    selectedDate.getMonth() === today.getMonth() &&
                                    selectedDate.getDate() === today.getDate()) 
                                {
                                    SetCanSendFinishedGoods(true);
                                } else 
                                {
                                    SetCanSendFinishedGoods(false);
                                }


                                } else {
                                  console.error("Invalid date selected");
                                }
                              }}
                            type="date" />
                        </div>

                        <div onClick={(e)=>{
                            SetShowSummary(!ShowSummary);
                            if(!ShowSummary) 
                            {
                                SetShowTransferredInProducts(false);
                            }
                            }} className={`${classes.production_log_header_right_action_wrapper} ${classes.production_log_header_right_action_wrapper_summerize}`}>
                            <input checked={ShowSummary} onChange={()=>{}} type="checkbox" />
                            <span>Summary</span>
                        </div>
                        <div onClick={(e)=>{
                            SetShowTransferredInProducts(!ShowTransferredInProducts);
                            if(!ShowTransferredInProducts)
                            {
                                SetShowSummary(false);
                            }
                            }} className={`${classes.production_log_header_right_action_wrapper} ${classes.production_log_header_right_action_wrapper_summerize}`}>
                            <input checked={ShowTransferredInProducts} onChange={()=>{}} type="checkbox" />
                            <span>Xchange</span>
                        </div>
                        
                        {/* <div className={`${classes.production_log_header_right_action_wrapper}`}>
                            <button><FontAwesomeIcon icon={faChartArea}></FontAwesomeIcon></button>
                        </div> */}
                        <div className={`${classes.production_log_header_right_action_wrapper}`}>
                            <button onClick={()=>{HandlePrintOpen(true)}}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon></button>
                        </div>
                    </div>
                </div>
            </div>



            {!ShowSummary && !ShowTransferredInProducts && <div className={`${classes.production_log_group_wrapper}`}>

               {/* we will have two group for the night and day shift */}
                <div className={`${classes.day_shift_production_log_wrapper}`}>
                    <ProductionLogGroup isLoading={DataIncomeLoading} ShiftName={"Day Time"} DataList={DayShiftProductionLog} />
                </div>

                {/* Night shift */}

                <div className={`${classes.day_shift_production_log_wrapper}`}>
                    <ProductionLogGroup isLoading={DataIncomeLoading} ShiftName={"Night Time"} DataList={NightShiftProductionLog} />
                </div>


            </div>}
            {ShowSummary && !ShowTransferredInProducts && <div className={`${classes.production_log_group_wrapper}`}>

                {/* Summary */}

                <div className={`${classes.day_shift_production_log_wrapper_summary}`}>
                    <ProductionLogGroup isLoading={DataIncomeLoading} ShiftName={"Summary"} DataList={DailyProductionLog} />
                </div>


            </div>} 

            {!ShowSummary && ShowTransferredInProducts && <div className={`${classes.production_log_group_wrapper}`}>

                {/* Summary */}

                <div className={`${classes.day_shift_production_log_wrapper}`}>
                    <ProductionLogGroupTransfer isLoading={DataIncomeLoading} ShiftName={"Incoming"} DataList={TransferredInProducts} />
                </div>
                <div className={`${classes.day_shift_production_log_wrapper}`}>
                    <ProductionLogGroupTransfer isLoading={DataIncomeLoading} ShiftName={"Outgoing"} DataList={TransferredIOutProducts} />
                </div>
                


            </div>} 



        </div>
    );
}