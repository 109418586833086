import api from "../Util/axiosInstance";

export const QueryAllYamBakeryMix = async(token)=>
{
    try 
    {
        const response = await api.post('api/yam-bakery-mix/query-all-entry', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}