import classes from './MerchandiseShelves.module.css'
import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy'
import { useEffect, useState } from 'react'

import { QueryAvialMerchByBranch } from '../../../SharedComp/API/ShopShelf/API_FE_MerchShelves'

import CreateMerchShelves from './Component/CreateMerchShelves/CreateMerchShelves'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}


export default function MerchandiseShelves()
{

    const auth = useAuth();
    const notify = useToast();

    const [ShelfItems, SetShelfItems] = useState([]);
    const [ShelfItemsOriginal, SetShelfItemsOriginal] = useState([]);
    const [OpenCreateMerchShelvesItem, SetOpenCreateMerchShelvesItem] = useState(false);




    const FetchAvialMerch = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);

            const main_res = await QueryAvialMerchByBranch(fd);
            SetShelfItems(main_res);
            SetShelfItemsOriginal(main_res);
        }catch(err) 
        {
            notify.ShowError("Unable to fetch Merch!");
        }
    }

    const HandleFilterByHash = async(key)=>
    {
        const valid_list = [];
        for(let i = 0; i < ShelfItemsOriginal.length; i++) 
        {
            if(ShelfItemsOriginal[i].CMMP_Name.toLowerCase().includes(key.toLowerCase()))
            {
                valid_list.push(ShelfItemsOriginal[i]);
            }
        }
        SetShelfItems(valid_list);
    }
    

    useEffect(()=>{
        FetchAvialMerch();
    }, [OpenCreateMerchShelvesItem])


    return (
        <div className={`${classes.shelves_master_wrapper}`}>

            {OpenCreateMerchShelvesItem && <CreateMerchShelves OpenController={SetOpenCreateMerchShelvesItem}/>}

            <div className={`${classes.data_inner}`}>
                
                    <div className={`${classes.data_table_wrapper}`}>

                        <DataViewTableProxy
                            ShowNewButton={auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PerformMerchandiseShelfEntry} 
                            IsEmpty={ShelfItems.length === 0}
                            OnFilter={HandleFilterByHash}
                            OpenDataCreate={SetOpenCreateMerchShelvesItem}
                            HeaderName={
                                <div>
                                    <h2><b>Merchandise Shelves</b> Product</h2>
                                </div>
                            }
                            DataArray={
                                <div>
                                    {
                                        ShelfItems.map((item, idx)=>(
                                            <li key={item.CMMP_Name + idx}>
                                                <span>{item.CMMP_Name}</span>
                                                <span><span className={`${classes.gram_view_amount}`}>{human_readable_format(item.SPMP_Quantity)} Pcs</span></span>
                                                <span><span className={`${classes.cash_view_value}`}>{currency_format(parseFloat(item.CMMP_Price))}</span></span>
                                                <span>{item.SPMP_LastInvoiceHash}</span>
                                            </li>
                                        ))
                                    }
                                    
                                </div>
                            }
                        />

                    </div>
            </div>

        </div>
    )
}