import classes from './UpdateCKProduct.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useEffect, useRef, useState } from 'react';

import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'

import {QueryProdTypeAll, UpdateCKProduct_API_ED, QueryCKProductID} from '../../../../../SharedComp/API/CK/AP_CenteraKitchen'
import {FetchKVType} from '../../../../../SharedComp/API/KV/API_kv'

import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import axios from 'axios'
import { useAuth } from '../../../../../AuthProvider';
import { useToast } from '../../../../../ToastContext';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';

export default function UpdateCKProduct({OpenController, TargetID}) 
{

    const notify = useToast();
    const formRef = useRef();
    const auth = useAuth();
    const [registring, SetRegistring] = useState(false);


    const [ReadStaticServerImage, SetReadStaticServerImage] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [ImageUpdated, SetImageUpdated] = useState(false);
    const PurgeCurrentImage = async()=>
    {
        SetCKProductIcon(null);
        setPreviewUrl(null);
        SetReadStaticServerImage(false);
        SetImageUpdated(true);
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            SetImageUpdated(true);
         SetReadStaticServerImage(false);
          SetCKProductIcon(file); // Save the file
          const url = URL.createObjectURL(file); // Create a preview URL
          setPreviewUrl(url); // Set the preview URL
        }
      };
      const fileInputRef = useRef(null);
      const handleImageClick = () => {
        // Trigger the file input click when the image box is clicked
        fileInputRef.current.click();
      };

    const [CkProductTypeList, SetCkProductTypeList] = useState([]);
    const [CK_KV_TypeLlist, SetCK_KV_TypeLlist] = useState([]);


    const [CK_KV_Choice, SetCK_KV_Choice] = useState();
    const [CKProductTypeChoice, SetCKProductTypeChoice] = useState();
    const [CKProductName, SetCKProductName] = useState();
    const [CKProductUnitPrice, SetCKProductUnitPrice] = useState();
    const [CKProductIcon, SetCKProductIcon] = useState();

    

    function CloseModal() 
    {
        formRef.current.reset();
        OpenController(false);
    }

    const QueryCKProductType = async()=> 
    {
        try 
        {
            const res = await QueryProdTypeAll(auth.token);
            PrepareDropDownList(res, SetCkProductTypeList)
        }catch(err) 
        {
        }
    }
    const QueryKVType = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const res = await FetchKVType(fd);
            PrepareDropDownList(res, SetCK_KV_TypeLlist);
        }catch(err) 
        {

        }
    }

    const QueryTargetProduct = async()=>
    {
        try 
        {
            const res = await QueryCKProductID(TargetID, auth.token);
            
            if(res.length > 0) 
            {
                SetCKProductTypeChoice({value: res[0].CKPTID, label: res[0].CK_PCAT_Name});
                SetCK_KV_Choice({value: res[0].KVTID, label: res[0].KV_TypeName});
                SetCKProductName(res[0].CKP_ItemName);
                SetCKProductUnitPrice(res[0].CKP_UnitPrice);

                SetReadStaticServerImage(res[0].CKP_IconPath !== null);
                setPreviewUrl(res[0].CKP_IconPath);
                SetCKProductIcon(res[0].CKP_IconPath);

            }

        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        QueryCKProductType();
        QueryKVType();
        QueryTargetProduct();
    }, [])

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        SetRegistring(true);
        notify.ShowLoad("Processing Your Request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("product_type", JSON.stringify(CKProductTypeChoice));
            fd.append("product_name", CKProductName);
            fd.append("product_variance", JSON.stringify(CK_KV_Choice));
            fd.append("unit_price", CKProductUnitPrice);
            fd.append("icon", CKProductIcon);
            fd.append("target_id", TargetID);
            fd.append("image_updated", ImageUpdated);

            const res = await UpdateCKProduct_API_ED(fd);
            SetRegistring(false);
            notify.ShowSuccessUpdate(res.Msg, 10);
            CloseModal();
            
        }catch(err) 
        {
            SetRegistring(false);
            notify.ShowErrorUpdate(err.message, 10);
            CloseModal();
        }
        
    }
    

    return (
            <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Registring <span className={`${data_win.data_create_name}`}>Centeral Kicthen Product</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}> 
                        
                

                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                        <div  className={`${classes.input_list_wrapper}`}>

                                            <div className={`${classes.single_input}`}>
                                                <MasterDataRegInput input={
                                                        <Select
                                                            value={CKProductTypeChoice}
                                                            onChange={(data)=>{SetCKProductTypeChoice(data)}}
                                                            name={"dd_data"}
                                                            options={CkProductTypeList}
                                                            placeholder={"Product Type"}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                    />
                                                    } tip={"Product Type"} forceInput={true} /> 
                                            </div>
                                            <div className={`${classes.single_input}`}>
                                                <MasterDataRegInput input={
                                                        <Select
                                                            value={CK_KV_Choice}
                                                            onChange={(data)=>{SetCK_KV_Choice(data)}}
                                                            name={"dd_data"}
                                                            options={CK_KV_TypeLlist}
                                                            placeholder={"Kitchen Variance Type"}
                                                            isSearchable={true}
                                                            styles={Custom_DD_Style}
                                                            required ={true}
                                                    />
                                                    } tip={"Product Type"} forceInput={true} /> 
                                            </div>

                                            <div className={`${classes.single_input}`}>
                                                <MasterDataRegInput input={<input value={CKProductName} onChange={(e)=>{SetCKProductName(e.target.value)}}  name='ck_prod_name' type="text" placeholder='CK Product Name' title='' autoComplete='off' required />} forceInput={true} />
                                            </div>
                                            
                                            <div className={`${classes.single_input}`}>
                                                <MasterDataRegInput input={<input value={CKProductUnitPrice} onChange={(e)=>{SetCKProductUnitPrice(e.target.value)}} name='ck_prod_unit_price' min={0} step="any" type="number" placeholder='Unit Price' title='' autoComplete='off' required />} forceInput={true} />
                                            </div>
                                            
                                            
                                            
                                        
                                        </div>

                                        <div className={`${classes.prod_preview_wrpper}`}>
                                                <div onClick={() => fileInputRef.current.click()}  className={`${classes.prod_image_input_wrapper}`}>
                                                {previewUrl ? <img src={ReadStaticServerImage ? getBaseURL() + previewUrl : previewUrl} alt="Product Preview" /> : 
                                                        <div className={`${classes.click_upload_helper}`}>
                                                        <li> <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon></li>
                                                        <li>Click to Upload</li>
                                                        </div> } 
                                                </div>
                                                <div className={`${classes.remove_image}`}>
                                                        <button type='button' onClick={()=>{PurgeCurrentImage()}}>Purge Image</button>
                                                </div>
                                                <div style={{ 
                                                            position: 'absolute', // Ensure it's out of the flow
                                                            width: '0',
                                                            height: '0',
                                                            opacity: '0',
                                                            overflow: 'hidden',
                                                            }}
                                                        className={`${classes.single_input}`}>
                                                    
                                                    <MasterDataRegInput input={<input ref={fileInputRef} onChange={(e)=>{handleFileChange(e)}} name='ck_prod_icon' min={0}  type="file" accept=".jpg,.jpeg,.png" placeholder='Icon' />} />
                                                </div>
                                                
                                        </div>
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

            </div>
    )
}