import classes from './PrintTransferRequest.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faCheckCircle, faTimesCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import PaperHeader from '../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../SharedComp/PaperHash/PaperHash'
import PaperFooter from '../../../../SharedComp/PaperFooter/PaperFooter'

import QRCode from 'react-qr-code'

import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef, useState } from 'react'
import {FetchStockTransferRequestByID, FetchTransferRequestPayload, FetchRequestSenderUser, FetchRequestHandlerUser} from '../../../../api'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { FetchTransferRequestDriverInfo, FetchTransferRequestPayloadV2, FetchTransferRequestReceiverInfo, FetchTransferRequestSenderInfo } from '../../../../SharedComp/API/InventoryStockTransfer/API_FE_InventoryStockTransfer'
import { ToEthiopianCalander } from '../../../../SharedComp/Utility'

export default function PrintTransferRequest({OpenModal, TargetID}) 
{

    const auth = useAuth();
    const notify = useToast();
    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_Requisition_Order_" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const [Loading, SetLoading] = useState(true);
    const [TargetRequest, SetTargetRequest] = useState([]);
    const [RequestPayload, SetRequestPayload] = useState([]);
    const formatter = new Intl.NumberFormat('en-US');

    const [RequestSender, SetRequestSender] = useState();
    const [RequestReciver, SetRequestReciver] = useState();
    const [Driver, SetDriver] = useState(null);


    const FetchRequestPayload = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_request_id", TargetID);
            const res = await FetchTransferRequestPayloadV2(fd);
            if(res.length > 0) 
            {

            }
            SetRequestPayload(res.REQUEST_DETAIL_ARRAY);
            SetTargetRequest(res.INIT_REQUEST);
            return res.INIT_REQUEST;
        }catch(err) 
        {
            throw err;
        }
    }
    

    const FetchTagetRequest = async()=>{
        SetLoading(true);
        try 
        {
           const _reqeust_entry = await FetchRequestPayload();


           const fd_xchnage = new FormData();
           fd_xchnage.append("target_request_id", _reqeust_entry.ISTRV2_ID);
           const __sender_info = await FetchTransferRequestSenderInfo(fd_xchnage, auth.token);
           const __receiver_info = await FetchTransferRequestReceiverInfo(fd_xchnage, auth.token);
           
        // we query if we have driver
           if(_reqeust_entry.ISTRV2_DriverID) 
           {
                const fd_diver = new FormData();
                fd_diver.append("driver_id", _reqeust_entry.ISTRV2_DriverID);
                const __driver_info = await FetchTransferRequestDriverInfo(fd_diver, auth.token);
                if(__driver_info.length > 0) 
                {
                    SetDriver(__driver_info[0]);
                }
           }
           
            SetRequestSender(__sender_info[0]);
            SetRequestReciver(__receiver_info[0]);


            SetLoading(false);
        }catch(err) 
        {
            notify.ShowError("Falied to Fetch Invoice!")
        } 
    }
    // useEffect(()=>{
    //     if(InvoiceList.length > 0)
    //     {
    //         SetLoading(false);
    //     }
    // }, [InvoiceList])

    useEffect(()=>{
        
        FetchTagetRequest();
    }, [])

    useEffect(()=>{

    }, [TargetRequest])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        <div className={`${data_win.data_main}`}>
          <div className={`${data_win.data_main_header}`}>
                <h3>
                    Stock <span className={`${data_win.data_create_name}`}> Requisition Order</span>
                </h3>
                <button onClick={()=>{OpenModal(false)}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body} ${classes.data_body}`}>

            {Loading === false ? <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        <PaperHash/>
                        <div className={`${classes.meta_data_wrapper}`}>
                            <h2>//{RequestReciver?.BranchName} <FontAwesomeIcon icon={faArrowRightLong}></FontAwesomeIcon> //{RequestSender?.BranchName}</h2>
                            <h2>Stock Requisition</h2>
                        </div>

                        <div className={`${classes.transcation_owner_wrapper}`}>
                            <div className={`${classes.transcation_owner}`}>

                                <div className={`${classes.transcation_init}`}>
                                    <h2>Requisition Intialized By</h2>
                                    <li>{RequestSender?.USR_FirstName} {RequestSender?.USR_LastName}</li>
                                    <li>{RequestSender?.Name}</li>
                                    <li>{RequestSender?.BranchName} Branch</li>
                                    <li>{ToEthiopianCalander(new Date(TargetRequest.ISTRV2_RequestInitDate))}</li>
                                </div>
                                <div className={`${classes.transcation_recive}`}>
                                    <h2>Requisition Handled By</h2>
                                    <li>{RequestReciver?.USR_FirstName} {RequestReciver?.USR_LastName}</li>
                                    <li>{RequestReciver?.Name}</li>
                                    <li>{RequestReciver?.BranchName} Branch</li>
                                    <li>{ToEthiopianCalander(new Date(TargetRequest.ISTRV2_ApprovedDate))}</li>
                                </div>

                            </div>
                        </div>

                        <div className={`${classes.entry_detail}`}>
                            <li><span className={`${classes.entry_deatil_tag}`}>Transaction ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetRequest.ISTRV2_TransactionID}</span></li>
                        </div>  
                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>
                            <div className={`${classes.paper_list_wrapper}`}>

                            <li className={`${classes.fake_tabke_header}`}>
                                <span>
                                   Name
                                </span>
                                <span>
                                    Amount
                                </span>
                                <span>
                                    Brand
                                </span>
                                {/* <span>
                                    Wherehouse
                                </span> */}
                                <span>
                                    Action
                                </span>
                            </li>


                                {
                                    RequestPayload.map((item, idx)=>(
                                        <li className={`${item.IVSTRP_TR_Value === 'Transferred' ? '' : classes.ban_view}`} key={idx}>
                                            <span>
                                            {item.IVItemName}
                                            </span>
                                            <span>
                                              {formatter.format(item.SR_ApprovedQuantity)} {item.UomDescription}
                                            </span>
                                            <span>
                                            {item.SR_RequstedBrand ? item.SR_RequstedBrand : '-' }
                                            </span>
                                            {/* <span>
                                                {item.WHName ? item.WHName : '-'}
                                            </span> */}
                                            <span className={`${item.SR_ApprovedQuantity > 0 ? classes.action_wrapper_text_good : classes.action_wrapper_text_bad}`}>
                                               <span>{item.SR_ApprovedQuantity > 0 ? "Approved" : "Rejected"}</span> 
                                               <span className={`${item.SR_ApprovedQuantity > 0 ? classes.check_valid : classes.check_invalid}`}><FontAwesomeIcon icon={item.SR_ApprovedQuantity > 0 ? faCheckCircle : faXmarkCircle}></FontAwesomeIcon></span>
                                            </span>
                                        </li>
                                    ))  
                                }
                                 

                            </div>
                            
                        </div>

                        <div className={`${classes.entry_detail_code}`}>
                            <div className={`${classes.scan_data}`}>
                               {TargetRequest.ISTRV2_XchangeID && <QRCode
                                    size={128}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    fgColor={'var(--background-dark)'}
                                    value={TargetRequest.ISTRV2_XchangeID}
                                    viewBox={`0 0 256 256`}
                                    /> }
                            </div>
                            <div className={`${classes.scan_data_connect}`}>

                            </div>
                            <div className={`${classes.scan_data_text}`}>
                                <li>KEY: {TargetRequest.ISTRV2_XchangeID}</li>
                            </div>
                        </div>

                        <div className={`${classes.special_footer_local_wrapper}`}>

                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Printed By</h2>
                                <li className={`${classes.name_manual}`}>{auth.employeeData.JAKSBE_FullName}</li>
                                <br />
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>
                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Driver</h2>
                                <li className={`${classes.name_manual}`}>{Driver ? Driver.JAKSBE_FullName : 'Full Name: ______________'}</li>
                                {Driver?.VINTKD_PlateNumber && <li className={`${classes.name_manual}`}>Licence Plates: {Driver.VINTKD_PlateNumber}</li>} 
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>
                            <div className={`${classes.footer_user_wrap}`}>
                                <h2>Received By</h2>
                                <li className={`${classes.name_manual}`}>Full Name: ______________</li>
                                <br />
                                <li className={`${classes.sign_manual}`}>Sign: __________________</li>
                            </div>

                        </div>


                    </div>
                </div> : <LoadingPlaceholder preview_text='Preparing Your Document'/>}
                    

            </div> 

                 <div className={`${classes.form_action_button_wrapper}`}>
                {/* <button className={`${classes.form_action_button_approve}`}>Request Approval</button> */}
                {/* <button className={`${classes.form_action_button_req_dismiss}`}>Request Dismissal </button> */}
               {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PrintTransferDocument && <button onClick={()=>{PrintDoc()}} className={`${classes.form_action_button_print}`}>Print Attachment</button> }
                <button  onClick={()=>{OpenModal(false)}} className={`${classes.form_action_button_dismiss}`} type='button'>Close</button>
            </div> 
        </div>

    </div>
    )
}