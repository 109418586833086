import api from "../Util/axiosInstance";




///////////////////////////////////////////////  BANK BEGIN

export const CreateNewBankType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/create-hr-master-bank-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBankType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/update-hr-master-bank-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeBankType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/purge-hr-master-bank-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterDataBank = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/query-all-hr-master-bank-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

////////////////////////////////////////////// CASH ALLOWANCE BEGIN

export const CreateNewCashAllowanceType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/create-hr-master-cash-allowance-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateCashAllowanceType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/update-hr-master-cash-allowance-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeCashAllowanceType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/purge-hr-master-cash-allowance-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterCashAllowance = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/query-all-hr-master-cash-allowance-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}


////////////////////////////////////////////// DEPARTMENT

export const CreateNewDepartmentType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/create-hr-master-department-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateDepartmentType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/update-hr-master-department-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeDepartmentType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/purge-hr-master-department-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterDepartment = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/query-all-hr-master-department-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}


///////////////////////////////////////////////// JOB POSITION

export const CreateNewJobPositionType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/create-hr-master-job-position-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateJobPositionType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/update-hr-master-job-position-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeJobPositionType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/purge-hr-master-job-position-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterJobPosition = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-alpha/query-all-hr-master-job-position-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}


