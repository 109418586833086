import api from "../Util/axiosInstance";




export const QueryVendorsOmni = async(token)=>
{
    try   
    {
        const response = await api.post('api/vendors/get-vendor', {} );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeVendorSingle = async(data)=>
{
    try   
    {
        const response = await api.post('api/vendors/purge-vendor', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CreateNewVendor = async(data)=>
{
    try   
    {
        const response = await api.post('api/vendors/add-vendor', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}