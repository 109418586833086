import api from "../Util/axiosInstance";

export const FetchWorkspaceAllOutlet = async(token)=>
{
    try 
    {
        const response = await api.post('api/workspace/fetch-workspace-outlet-all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchWorkspaceAllExcept = async(data)=>
{
    try 
    {
        const response = await api.post('api/workspace/fetch-workspace-all-except', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const FetchWorkspaceAllNotOffice = async(token)=>
{
    try 
    {
        const response = await api.post('api/workspace/get-workspaces-not-office', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchWorkspaceBranchAll = async()=>
{
    try 
    {
        const response = await api.post('api/workspace/fetch-workspace-all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchWorkspaceBranchByID = async(data)=>
    {
        try 
        {
            const response = await api.post('api/workspace/fetch-workspace-by-id', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }
export const CreateNewWorkspace = async(data)=>
{
    try 
    {
        const response = await api.post('api/master-data/add-company-branch', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const UpdateWorkspaceData = async(data)=>
{
    try 
    {
        const response = await api.post('api/master-data/update-company-branch', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const UpdateWorkspaceSuspendState = async(data)=>
{
    try 
    {
        const response = await api.post('api/master-data/update-company-branch-suspend-state', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    } 
}
export const QueryWorkspaceByID = async(data)=>
{
    try 
    {
        const response = await api.post('api/workspace/get-workspaces-by-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}