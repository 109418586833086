import classes from './MasterWarehouseReportPreview.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import PaperHeader from '../../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../../SharedComp/PaperHash/PaperHash'
import PaperFooter from '../../../../../../SharedComp/PaperFooter/PaperFooter'

import { useReactToPrint } from 'react-to-print'
import { useEffect, useRef, useState } from 'react'
import {FetchInventoryInvoiceUID} from '../../../../../../api'
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'
import { ToEthiopianCalander, ToEthiopianCalanderRaw, ToEthiopianCalander_MY, ToGregorianCalendar } from '../../../../../../SharedComp/Utility'
import LoadingPlaceholder from '../../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'

import ReportTransactionPreviewRC from './ReportTransactionPreviewRC/ReportTransactionPreviewRC'

export default function MasterWarehouseReportPreview({OpenModal,CoreBranchData, DataSet, ReportFlag}) 
{


    const auth = useAuth();
    const notify = useToast();
    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_master_report_" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })

    const [Loading, SetLoading] = useState(true);
    const [InvoiceList, SetInvoiceList] = useState([]);
    const formatter = new Intl.NumberFormat('en-US');


    useEffect(()=>{
        if(DataSet) 
        {
            SetLoading(false);
        }
    }, [DataSet])

    function getFirstDayOfMonth(yearMonth) {
        const [year, month] = yearMonth.split('-').map(Number);
        return new Date(year, month - 1, 1 + 7);
    }
    function getLastDayOfMonth(startDate) 
    {
        const _date_array = ToEthiopianCalanderRaw(startDate);
        const _geord = ToGregorianCalendar(_date_array[0],_date_array[1], 30);
        return ToEthiopianCalander(_geord);
    }




    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                        <h3>
                            Preview <span className={`${data_win.data_create_name}`}> Master Report</span>
                        </h3>
                        <button onClick={()=>{OpenModal(false)}}>
                            <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                        </button>
                    </div>

                    <div className={`${data_win.data_body} ${classes.data_body}`}>

                    {!Loading ? <div className={`${classes.inner_preview_data_wrapper}`}>
                            <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                                <PaperHeader/>
                                <PaperHash/>
                                <div className={`${classes.meta_data_wrapper}`}>
                                    <h2>{CoreBranchData?.BranchName} {DataSet.length > 0 ? ' @ ' + ToEthiopianCalander_MY(getFirstDayOfMonth(DataSet[0].Month)) :''}</h2>
                                    <h2>( {CoreBranchData.WHName} Warehouse ) Report</h2>
                                </div>

                                <div className={`${classes.main_paper_data_wrapper}`}>
                                    <div className={`${classes.paper_list_wrapper}`}>


                                        {
                                            DataSet.map((month_data, month_data_idx)=>(

                                                <div key={month_data_idx} className={`${classes.month_single_inventory_wrapper}`}>

                                                   <div className={`${classes.inventory_time_frame}`}>
                                                        <li>{ToEthiopianCalander(getFirstDayOfMonth(month_data.Month))} {`-->`} {getLastDayOfMonth(getFirstDayOfMonth(month_data.Month))}</li>
                                                   </div>
                                                    <div className={`${classes.monthly_inventory_transaction_data_wrapper}`}>
                                                        {
                                                            month_data.DataSet.map((inventory_data, inventory_data_idx)=>(

                                                                <ReportTransactionPreviewRC SummeryMethod={ReportFlag} CloseDate={getLastDayOfMonth(getFirstDayOfMonth(month_data.Month))} DataArray={inventory_data}/>

                                                            ))
                                                        }
                                                    </div>
                                                    
                                                </div>
                                                
                                            ))  
                                        }

                                    

                                    </div>
                                </div>
                            </div>
                        </div> :  
                        <LoadingPlaceholder/>
                        }
                            

                    </div> 

                        <div className={`${classes.form_action_button_wrapper}`}>
                        <button onClick={()=>{PrintDoc()}} className={`${classes.form_action_button_print}`}>Print Report</button>
                        <button  onClick={()=>{OpenModal(false)}} className={`${classes.form_action_button_dismiss}`} type='button'>Close</button>
                    </div> 
                </div>
        </div>

    )
}