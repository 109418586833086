import classes from './MJPT_Edit.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useEffect, useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../../SharedComp/Utility'

//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { QueryAllMasterDepartment, UpdateJobPositionType } from '../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I';

export default function MJPT_Edit({UpdateView, OpenController, TargetData})
{

    const auth = useAuth();
    const notify = useToast();

    const [DepartmentList, SetDepartmentList] = useState([]);

    const [JobPositionTypeName, SetJobPositionTypeName] = useState(TargetData.HRMJPT_Name);
    const [SelectedDepartment, SetSelectedDepartment] = useState();
    const [JobPositionTypeDesc, SetJobPositionTypeDesc] = useState(TargetData.HRMJPT_Description);
    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("job_position_name", JobPositionTypeName);
            fd.append("job_position_desc", JobPositionTypeDesc);
            fd.append("job_position_department", JSON.stringify(SelectedDepartment));
            fd.append("target_id", TargetData.HRMJPT_ID);

            const res = await UpdateJobPositionType(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            UpdateView();
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    const GetDepartmentList  =async()=>
    {
        try 
        {
            const main_res = await QueryAllMasterDepartment(auth.token);
            PrepareDropDownList(main_res, SetDepartmentList);

            const q_res = main_res.find((item)=>item.HRMDT_ID === parseInt(TargetData.HRMJPT_DepartmentID));
            if(q_res) 
            {
                SetSelectedDepartment({ value: q_res.HRMDT_ID, label: q_res.HRMDT_Name  });
            }

        }catch(err) 
        {
            notify.ShowError("Failed to Query Department List" + err.message);
        }
    }

    useEffect(()=>{
        GetDepartmentList();
    }, [])


    return(
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Update <span className={`${data_win.data_create_name}`}>Job Position Type</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                    <MasterDataRegInput input={
                                                    <Select 
                                                        value={SelectedDepartment}
                                                        onChange={(data)=>{SetSelectedDepartment(data)}} 
                                                        options={DepartmentList} 
                                                        placeholder={"Department"} 
                                                        isSearchable={true} 
                                                        styles={Custom_DD_Style} 
                                                        required ={true} /> } />
                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    value={JobPositionTypeName}
                                                    onChange={(e)=>{SetJobPositionTypeName(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Job Position Name'
                                                    required />
                                                }
                                            />
                                            <MasterDataRegInput
                                                 input={
                                                    <input  
                                                    value={JobPositionTypeDesc}
                                                    onChange={(e)=>{SetJobPositionTypeDesc(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Description'
                                                    required 
                                                    />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>)
}