import classes from './ActiveOrder.module.css'
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'
import UIButton from '../../../../Components/UIButton/UIButton'
import { BoxArrowDown, CheckCircle, Empty, MagnifyingGlass, SealCheck, SpinnerGap, Tray } from '@phosphor-icons/react'

import CloseActiveOrder from './Component/CloseActiveOrder/CloseActiveOrder'
import { useEffect, useState } from 'react'
import { QueryActiveOrderByBranch } from '../../../../../SharedComp/API/DeskView/API_FE_DeskView'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { useAuth } from '../../../../../AuthProvider'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'


export default function ActiveOrder({RefreshView_State, RefreshView_CALLBACK}) 
{
 
    const auth = useAuth();
    const [OpenCloseActiveOrderDiag, SetOpenCloseActiveOrderDiag] = useState(false);
    const [SelectedCustomerInfo, SetSelectedCustomerInfo] = useState(null);
    const [Loading, SetLoading] = useState(true);
    const [DataEntry, SetDataEntry] = useState([]);

    const [DataCopy, SetDataCopy] = useState([]);

    const HandleOpenActiveOrderDiag = async(data)=>
    {
        SetSelectedCustomerInfo(data);
        SetOpenCloseActiveOrderDiag(true);
    }

    const FilyerByName = (key_name)=>
    {
        const valid_list = [];
        for(let i = 0; i < DataEntry.length; i++) 
        {
            if(
                DataEntry[i].Customer.POCV_FullName.toLowerCase().includes(key_name.toLowerCase()) || 
                DataEntry[i].Customer.POCV_Telephone.includes(key_name)) 
            {
                valid_list.push(DataEntry[i]); 
            }
        }

        SetDataCopy(valid_list);
    }

    useEffect(()=>{
        getActiveOrders();
    }, [RefreshView_State])

    const getActiveOrders = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);
            const main_res = await QueryActiveOrderByBranch(fd);
            SetDataEntry(main_res);
            SetDataCopy(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }

    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>

            {OpenCloseActiveOrderDiag && <CloseActiveOrder RefreshView={RefreshView_CALLBACK} CustomerInfo={SelectedCustomerInfo} OpenController={SetOpenCloseActiveOrderDiag}/>}


            <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{FilyerByName(e.target.value)}} autoComplete='off' placeholder='Name, Telephone' type="text" /> 
                        <span><MagnifyingGlass weight='bold'/></span>
                    </div>
            </div>
            
            <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.active_order_main_list_wrapper}`}>
                    <li className={`${classes.header_list}`}>
                        <span>Customer Name</span>
                        <span>Ordered Date</span>
                        <span>Expected</span>
                        <span>Cashier</span>
                        <span>Order State</span>
                        <span>Action</span>
                    </li>

                    <div className={`${classes.list_data_wrapper}`}>
                        {!Loading &&
                            DataCopy.map((item, idx)=>(
                                <li key={idx + item.Customer.POCV_FullName}>
                                    <span>{item.Customer.POCV_FullName}</span>
                                    <span>{ToEthiopianCalander(new Date(item.OrderDetail.PREO_OrderDetail.OrderIntiailizedDate))}</span>
                                    <span>{ToEthiopianCalander(new Date(item.OrderDetail.PREO_OrderDetail.ProductExpectedDate))}</span>
                                    <span>{item.Cashier.USR_FirstName} {item.Cashier.USR_LastName}</span>
                                    <span className={`${classes.action_button_wrapper}`}>
                                        <UIButton 
                                            onClick={()=>{}} 
                                            Variant={item.OrderDetail.PREO_State === "Ready" ? "Primary" : "Secondary"} 
                                            label={item.OrderDetail.PREO_State} 
                                            Icon={item.OrderDetail.PREO_State === "Ready" ? <SealCheck weight='bold'/> : <SpinnerGap weight='bold'/> } />
                                    </span>
                                    
                                    <span className={`${classes.action_button_wrapper}`}>
                                        {/* <UIButton onClick={()=>{}} Icon={<Empty weight='fill'/>} Variant='Danger'  label={"Abort"}/> */}
                                     {item.OrderDetail.PREO_State === "Ready" ?  <UIButton onClick={()=>{HandleOpenActiveOrderDiag(item)}} Icon={<CheckCircle weight='fill'/>} label={"Process"}/> : '--'}  
                                    </span>
                                </li>
                            ))
                        }
                        {!Loading &&
                            DataCopy.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                <ul><Tray fontSize={30}/></ul>
                                <ul>No Data</ul>
                            </div>
                        }
                        {
                            Loading &&
                            <LoadingPlaceholder/>
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}