import { useEffect, useState } from 'react';
import classes from './FilterEmployeeList.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../../../ToastContext';
import { useAuth } from '../../../AuthProvider';

import {CreateOrderDispatch} from '../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';



export default function FilterEmployeeList({ IsSpecial = false, ActiveOrderGroup, UpdateViewCallback, UpdateView, Mpos, Chefs, ItemBuffer, OrderSourceBranchID, CloseController}) 
{

    const [LocalChefArray, SetLocalChefArray] = useState([]);
    const [ActiveOpView, SetActiveOpView] = useState(0);
    const [DataList, SetDataLlist] = useState([]);

    const [QuantityBuffer, SetQuantityBuffer] = useState(new Map());

    const notify = useToast();
    const auth = useAuth();

    const NextMiniContent = ()=>
    {

        let valid_return = true;
        // If it is the first then make sure everythig is okay
        if(ActiveOpView === 0) 
        {
            for(const [key, value] of QuantityBuffer.entries()) 
            {
                if(value === '') 
                {
                    notify.ShowError(`Invalid Quantity for ${key}.`);
                    valid_return = false;
                    break;
                }
            }
        }

        if(!valid_return) 
        {
            return;
        }
        if(ActiveOpView === 1) 
        {
            SetActiveOpView(ActiveOpView - 1);
        }else 
        {
            SetActiveOpView(ActiveOpView + 1);
        }
    }

    useEffect(()=>{
        SetLocalChefArray(Chefs);
    }, [Chefs])

    useEffect(()=>{

        if(ItemBuffer) 
        {
            SetDataLlist(Array.from(ItemBuffer.entries()));

            const _quant_buffer = new Map();
            for(const [key, value] of ItemBuffer.entries()) 
            {
                _quant_buffer.set(key, parseInt(value.Quantity));
            }

            SetQuantityBuffer(_quant_buffer);
        }

    }, [ItemBuffer])

    const UpdateQuantityDistribution = async(__name, __val, __ref)=>
    {
        if(__val > __ref) 
        {
            notify.ShowWarn(`The Ordered Amount is ${__ref}`);
            return;
        }
        if(__val <= 0 && __val !== '') 
        {
            notify.ShowWarn(`There is no such amount as ${__val}`);
            return;
        }

        const _exist = QuantityBuffer;
        _exist.set(__name, __val);
        SetQuantityBuffer(new Map(_exist));
    }

    const FilterByname = async(__value) =>
    {
        const _filter = [];

        for(let i = 0; i < Chefs.length; i++) 
        {
            if(Chefs[i].JAKSBE_FullName.toLowerCase().includes(__value.toLowerCase())) 
            {
                _filter.push(Chefs[i]);
            }
        }

        SetLocalChefArray(_filter);
    }

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            CloseController(false); 
        }
      };

      const HandleOrderDispatch = async(_chef)=>
      {
        const  _id = parseInt(Math.random() * 1000);
        notify.ShowLoad("Processing Your Request...", _id);

        try 
        {
            const fd = new FormData();

            fd.append("quantity_buffer", JSON.stringify(Array.from(QuantityBuffer.entries())));
            fd.append("order_prod_data", JSON.stringify(DataList));
            fd.append("dispatcher", auth.user.USR_UserAccountID);
            fd.append("target_chef", _chef.USR_EmployeeID);
            fd.append("request_branch", OrderSourceBranchID);
            fd.append("handler_branch", auth.activeWorkShop);


            const main_res = await CreateOrderDispatch(fd);
            
            notify.ShowSuccessUpdate(main_res.Msg, _id);
            UpdateViewCallback(!UpdateView);
            CloseController(false);

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, _id);
        }
      }

    return (
        <div tabIndex={0}  onBlur={handleBlur} style={{position: 'absolute', left: (Mpos.x - 200), top: (Mpos.y + 15), }} className={`${classes.box_wrapper_filter}`}>
            <div className={`${classes.mini_win_header}`}>
                <h4>Dispatcher</h4>
                <button onClick={()=>{CloseController(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.mini_navigator}`}>
                <button onClick={()=>{NextMiniContent()}}><FontAwesomeIcon icon={ActiveOpView ? faChevronCircleLeft : faChevronCircleRight}></FontAwesomeIcon> {ActiveOpView ? "Prev" : "Next"}</button>
            </div>
        
            <div className={`${classes.chef_list_wrapper}`}>
                { ActiveOpView === 1 && 
                    LocalChefArray?.map((item, idx)=>(
                        <li onClick={()=>{HandleOrderDispatch(item)}} key={idx}>
                            <span className={`${classes.chef_image_wrapper}`}>
                                <img src={getBaseURL() + item.EDOCP_ProfilePicture} alt="" />
                            </span>
                            <span className={`${classes.chef_name_wrapper}`}>
                                {item.JAKSBE_FullName}
                            </span>
                        </li>
                    ))
                }

                {
                    ActiveOpView === 0 && 
                    
                    DataList.map((item, idx)=>(
                        <div className={`${classes.data_input_filter}`} key={idx}>
                                <li>
                                    <span>{item[1].DataPkg.CKP_ItemName}</span>
                                    <span>
                                            <input 
                                                onChange={(e)=>{UpdateQuantityDistribution(item[0], e.target.value, item[1].Quantity)}} 
                                                value={QuantityBuffer.get(item[0])} 
                                                type="number" 
                                                min={1} 
                                                max={item[1].Quantity} />
                                    </span>
                                </li>
                        </div>
                    ))
                }
            </div>

           {ActiveOpView === 1 && <div className={`${classes.mini_box_dash_filter}`}>
                <input onChange={(e)=>{FilterByname(e.target.value)}} type="text" placeholder={`Search Chef's`} />
            </div>}
        </div>
    )
}