import classes from './CloseActiveOrder.module.css'


import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import LoadingPlaceholder from '../../../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'

import { QueryActiveOrderBakeryByCustomerID, QueryActiveOrderPastryByCustomerID, WrapUpPreorder } from '../../../../../../../SharedComp/API/DeskView/API_FE_DeskView';

//lib
import { useAuth } from '../../../../../../../AuthProvider'
import { useToast } from '../../../../../../../ToastContext'
import { CheckCircle } from '@phosphor-icons/react'


const currency_format = (val)=> 
    {
        return val.toLocaleString('en-US', {
            style: 'currency',
            currency: 'ETB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

export default function CloseActiveOrder({OpenController, CustomerInfo, RefreshView})
{
    const auth = useAuth();
    const notify = useToast();

    const [Loading, SetLoading] = useState(true);
    const [PastryOrderList, SetPastryOrderList] = useState([]);
    const [BakeryOrderList, SetBakeryOrderList] = useState([]);

    const [GenericDataViewList, SetGenericDataViewList] = useState([]);
    const [TotalPrice, SetTotalPrice] = useState(0);
    const [RemainAmount, SetRemainAmount] = useState(1);


    const CreateGenericView = async()=>
    {
        const generic_view = [];
        let _total_price = 0;

        for(let i = 0; i < PastryOrderList.length; i++) 
        {
            const _obj = {
                _ProductName: PastryOrderList[i].CKP_ItemName,
                _ProductPrice: PastryOrderList[i].PROPPI_Price,
                _PoductQuantity: PastryOrderList[i].PROPPI_Quantity
            }
            _total_price += (PastryOrderList[i].PROPPI_Price * PastryOrderList[i].PROPPI_Quantity);
            generic_view.push(_obj);
        }

        for(let i = 0; i < BakeryOrderList.length; i++)  
        {
            const _obj = {
                _ProductName: BakeryOrderList[i].CMBP_Name,
                _ProductPrice: BakeryOrderList[i].CMBP_UnitPrice,
                _PoductQuantity: BakeryOrderList[i].PROPBI_Quantity
            }
            _total_price += (BakeryOrderList[i].CMBP_UnitPrice * BakeryOrderList[i].PROPBI_Quantity);
            generic_view.push(_obj);
        }

        SetGenericDataViewList(generic_view);
        SetTotalPrice(_total_price);
    }
    useEffect(()=>{ 
        if(PastryOrderList) 
        {
            CreateGenericView(); 
        }
    }, [PastryOrderList])


    
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();

        notify.ShowLoad("Processing Your Request...", 10);

        try 
        {
            const fd = new FormData();
            fd.append('order_entry_id', CustomerInfo.OrderDetail.PREM_ID);
            fd.append('order_package_id', CustomerInfo.OrderDetail.PREO_UID);
            fd.append('received_by', auth.user.USR_UserAccountID);
            fd.append('source_workspace', auth.activeWorkShop);
            const main_res = await WrapUpPreorder(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            RefreshView(Date.now());
            OpenController(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
      
    }

    useEffect(()=>
    {
        if(CustomerInfo) 
        {
            SetRemainAmount(CustomerInfo.OrderDetail.PREO_PaymentInfo.TotalPrice - CustomerInfo.OrderDetail.PREO_PaymentInfo.PrePayment);
            SetLoading(false);
        }

    }, [CustomerInfo])


    
    function CloseModal() { OpenController(false); }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

      {!Loading &&   <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    <span className={`${data_win.data_create_name}`}>Process Order</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>

                            <div className={`${classes.product_list_with_price}`}>

                                    <div className={`${classes.product_list_impo}`}>
                                        {
                                            CustomerInfo.OrderPackage.map((item, idx)=>(
                                                <li key={idx}>
                                                    <span className={`${classes.name_space}`}>{item.CKP_ItemName}</span>
                                                    <span>{currency_format(item.CKP_UnitPrice)}</span>
                                                    <span>{parseInt(item.ProductQuantity)}</span>

                                                    <span className={`${classes.total_single_price}`}>{currency_format(item.CKP_UnitPrice * parseInt(item.ProductQuantity))}</span>
                                                    
                                                </li>
                                            ))
                                        }
                                    </div>
                                    <div className={`${classes.summery_data_wrapper}`}>
                                        <li>
                                            <span>Pre-Paid</span>
                                            <span className={`${classes.total_single_price}`}>{currency_format(parseFloat(CustomerInfo.OrderDetail.PREO_PaymentInfo.PrePayment))}</span>
                                        </li>
                                        <li>
                                            <span>Remain Payment</span>
                                            <span className={`${classes.total_single_price}`}>{currency_format(parseInt(RemainAmount))}</span>
                                        </li>
                                        <li>
                                            <span>Grand Total</span>
                                            <span className={`${classes.total_single_price}`}>{currency_format(parseFloat(CustomerInfo.OrderDetail.PREO_PaymentInfo.TotalPrice))}</span>
                                        </li>
                                    </div>


                                    </div>

                                     <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            
                                            {
                                                RemainAmount === 0 ? 
                                                <div className={`${classes.clear_customer_to_ship}`}>
                                                    <h1><CheckCircle/></h1>
                                                    <h2>Cleared for shipping</h2>
                                                </div>
                                                : 

                                                    <MasterDataRegInput
                                                    input={
                                                        <input  
                                                            value={RemainAmount}
                                                            onChange={(e)=>{}}
                                                            type="text" 
                                                            placeholder='Remianed Payment'
                                                            readOnly
                                                        />
                                                    }
                                                    
                                            />
                                            }
                                            
                                           

                                           

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button className={`${data_win.form_action_button_save}`}>Process</button>
                                <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div> }

        {
            Loading && 
            <LoadingPlaceholder/>
        }

    </div>
    )
}