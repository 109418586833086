import api from "../Util/axiosInstance";

export const CreateEmployeeAllowance = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/create-new-allowance', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryEmployeeAllowanceByEmployeeID = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/query-all-allowance-by-employee-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeEmployeeAllowance = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/purge-allowance-by-employee-id', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}