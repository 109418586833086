import api from "../Util/axiosInstance";




export const FetchProductionLogDaily = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-log/query-production-order-daily', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchProductionLogDailyMerged = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-log/query-production-order-daily-merged', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}