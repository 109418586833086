import classes from './ProductionStack.module.css'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {CloseInDateProductionLog, FetchProductionOrderBranchStockedOut} from '../../api'
import { useAuth } from '../../AuthProvider'
import ProductionStackOrderGroupWrapper from './Component/ProductionStackOrderGroupWrapper/ProductionStackOrderGroupWrapper'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import UIButton from '../Components/UIButton/UIButton'
import { Checks, SignOut } from '@phosphor-icons/react'
import { QueryMasterProductionStockApprovedEntryOmniByBranchV2} from '../../SharedComp/API/Production/API_MasterProductionV2'
import ProductionStackCheckOutPreview from './Component/ProductionStackCheckOutPreview/ProductionStackCheckOutPreview'
import { useToast } from '../../ToastContext'
import { useDAControl } from '../../DAControlContext'
import { ToEthiopianCalander } from '../../SharedComp/Utility'
import { FetchProductionLogDaily } from '../../SharedComp/API/Production/API_ProductionLog'

export default function ProductionStack() 
{
    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl();

    const [PendningOrder, SetPendningOrder] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);
    const [Loading, SetLoading] = useState(false);
    const [OpenCheckOutPreview, SetOpenCheckOutPreview] = useState(false);
    const [ActiveWorkingDate, SetActiveWorkingDate] = useState(new Date());
    const [CanCloseShift, SetCanCloseShift] = useState(true);


    


    const FetchProdReadyOrders = async()=>
    {
        SetLoading(true);
        try 
        {

            // const res = await FetchProductionOrderBranchStockedOut(auth.activeWorkShop, auth.token);
            const fd = new FormData();
            fd.append("target_branch_id", auth.activeWorkShop);
            const main_res = await QueryMasterProductionStockApprovedEntryOmniByBranchV2(fd);
            SetPendningOrder(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
            notify.ShowError(err.message);
        }
    }
    const FetchDailyProduction = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("target_day", ActiveWorkingDate);
            const main_res = await FetchProductionLogDaily(fd);
            if(main_res.length > 0) 
            {
                if(main_res[0].Core.SCMV2_IsClosed === "Yes")
                {
                    const _today = new Date();
                    const _tommorow = new Date();
                    _tommorow.setDate(_today.getDate() + 1);
                    SetActiveWorkingDate(new Date(_tommorow));
                    SetCanCloseShift(false);
                }
            } 
        }catch(err) 
        {

        }
    }

    const DataRefresh = async()=>
    {
        SetRefreshView(new Date().getMilliseconds());
    }
    useEffect(()=>{
        FetchDailyProduction();
        FetchProdReadyOrders();
    }, [RefreshView, auth.user])

    const FinishIndateShift = async()=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            const main_res = await CloseInDateProductionLog(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            DataRefresh();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }


    return (
        <div className={`${classes.production_stack_main_wrapper}`}>

            {OpenCheckOutPreview && <ProductionStackCheckOutPreview PendingOrderPackage={PendningOrder} OpenController={SetOpenCheckOutPreview}/>}
            
            
            <div className={`${classes.production_stack_action_Wrapper}`}>
                <li>{ToEthiopianCalander(ActiveWorkingDate)}</li>
                <UIButton 
                    Variant={CanCloseShift ? 'Secondary' : 'Disabled'}
                    Icon={<Checks weight='bold'/>} 
                    label={"Finish Day Shift Production"}
                    onClick={()=>{
                        DAControl.Trigger('Finish Day Shift Production',
                        `You are about to finish, day shift production of '${ToEthiopianCalander(new Date())}'. This means you finished the day shift leftovers and any production that will be register after this action will be considered in the next date production log.`,
                        true,
                        7,
                        (data)=>
                        {
                            if(data) 
                            {
                                FinishIndateShift();
                            }
                        })
                    }}
                />
            </div>
            
            <div className={`${classes.prodution_stack_group_wrapper}`}>
                {
                    PendningOrder.map((item, idx)=>(
                        <ProductionStackOrderGroupWrapper 
                                key={item.MPOM_OrderID + idx} 
                                DataPoint={item} 
                                DataRefresh_Callback={DataRefresh}
                            /> 
                    ))
                    
                }

                { Loading && <LoadingPlaceholder/>}

                { PendningOrder.length === 0 && !Loading &&
                    <div className={`${classes.empty_stack}`}>
                        <li><FontAwesomeIcon icon={faBoxOpen}></FontAwesomeIcon></li>
                        <li>No Production Stack</li>
                    </div>
                }
            </div>
            
        </div>
    )
}