import api from "../Util/axiosInstance";





export const AddMerchShelvesProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/shelves-merch/add-merch-into-shelves', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAvialMerchMasterDataByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/shelves-merch/query-merch-master-data-by-branch', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAvialMerchByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/shelves-merch/query-merch-by-branch', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAvialMerchInvoiceByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/shelves-merch/query-merch-invoice-by-branch', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ReverseProductEntry = async(data)=>
{
    try  
    {
        const response = await api.post('api/shelves-merch/reverse-merch-invoice-entry-by-branch', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ReverseMerchInvoice = async(data)=>
{
    try  
    {
        const response = await api.post('api/shelves-merch/reverse-merch-invoice-omni-by-branch', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}