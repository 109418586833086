import classes from './SingleEntryGroup.module.css'
import UIButton from '../../../../../Components/UIButton/UIButton'
import { CheckCircle, Checks, ListDashes, SpinnerGap, Truck } from '@phosphor-icons/react'
import TimeAgo from '../../../../../../SharedComp/TimeElapsed/TimeAgo'

import InspectOriginalOrder from './InspectOriginalOrder/InspectOriginalOrder'
import { useState } from 'react'
import { ToEthiopianCalander } from '../../../../../../SharedComp/Utility'

export default function SingleEntryGroup({item}) 
{
    const [OpenOrderPreview, SetOpenOrderPreview] = useState(false);

    return (
        <>
        {OpenOrderPreview && <InspectOriginalOrder TargetData={item} OpenController={SetOpenOrderPreview}/> }
        <li className={`${classes.single_entry_li_dash}`} key={item.PROCI_Name}>


            <span>{item.USR_FirstName} {item.USR_LastName}</span>
            <span>{ToEthiopianCalander(new Date(item.DOMD_OrderedDate))} @ {new Date(item.DOMD_OrderedDate).toLocaleTimeString()}</span>
            <span>{ToEthiopianCalander(new Date(item.DOMD_ExpectedDate))} @ {new Date(item.DOMD_ExpectedDate).toLocaleTimeString()}</span>

            <span className={`${classes.order_state_button_wrap}`}>

                <UIButton 
                    Variant={'Secondary'} 
                    onClick={()=>{SetOpenOrderPreview(true)}} Icon={<ListDashes weight='bold'/>} 
                    label={"Inspect Orders"}/> 
            {!item.DOMD_LoopBackState &&
                <UIButton 
                    Variant={item.DOMD_LoopBackState ? 'Primary' : 'Secondary'} 
                    onClick={()=>{}} Icon={item.DOMD_Closed ? <CheckCircle weight='fill'/> : <SpinnerGap weight='bold'/>} 
                    label={"Pending"}/> }

                {item.DOMD_LoopBackState && 
                        <UIButton 
                            Variant={'Primary'} 
                            onClick={()=>{}} 
                            Icon={<Checks weight='fill'/>} 
                            label={"Arrived"}/>}
                
            </span>
        </li>
    </>
    )
}