import classes from './BakeryDataPackageRC.module.css'
import { FetchBakeryProductAll } from '../../../../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';
import { useAuth } from '../../../../../../../AuthProvider';
import { useToast } from '../../../../../../../ToastContext';
import { useEffect, useState } from 'react';
import { ArrowBendUpRight, Lock, LockOpen, Warning } from '@phosphor-icons/react';
import UIButton from '../../../../../../Components/UIButton/UIButton';

import { FetchProductionLogDailyMerged } from '../../../../../../../SharedComp/API/Production/API_ProductionLog';

export default function BakeryDataPackageRC({Interaction, BakeryDataPackage, SetBakeryDataPackage}) 
{

    const auth = useAuth();
    const notify = useToast();

    // ///////////////////////////// BAKERY START 
    const [LockBakeryInteraction, SetLockBakeryInteraction] = useState(false);
    const [BakeryProductList, SetBakeryProductList] = useState([]);
    const [BakeryProductListOriginal, SetBakeryProductListOriginal] = useState([]);

    const InitBakeryDataPackage = async(data)=>
    {
        const _init_list = new Map();
        for(let i = 0; i < data.length; i++) 
        {
            _init_list.set(data[i].CMBP_Name,
                {
                    Name: data[i].CMBP_Name,
                    ProductID: data[i].CMBPID,
                    Price: data[i].CMBP_UnitPrice,
                    Damage: 0,
                    Unsold: 0,
                    ProductionVolume: data[i].Quantity
                }
            )
        }

        SetBakeryDataPackage(new Map(_init_list));
    }
    const UpdateBakeryUnsoldAmount = async(name, amount)=>
    {
        SetBakeryDataPackage((prevList) => {
            const newList = new Map(prevList);
            if (newList.has(name)) {
                newList.set(name, { ...newList.get(name), Unsold: amount });  
            }
            return newList; 
        });
    }
    const UpdateBakeryDamageAmount = async(name, amount)=>
    {
        SetBakeryDataPackage((prevList) => {
            const newList = new Map(prevList); 
            if (newList.has(name)) {
                newList.set(name, { ...newList.get(name), Damage: amount });  
            }
            return newList;  
        });
    }
    const HandleBakeryFilter = async(_key)=>
        {
            const valid_list = [];
    
            for(let  i = 0; i < BakeryProductListOriginal.length; i++) 
            {
                const _seach_val = BakeryProductListOriginal[i].CMBP_Name;
                if(_seach_val.toLowerCase().includes(_key.toLowerCase())) 
                {
                    valid_list.push(BakeryProductListOriginal[i]);
                }
            }
    
            SortAndSetBakertList(valid_list);
    }
    const SortAndSetBakertList = async(data)=>
    {
        const sortedProducts = data.sort((a, b) => {
            if (a.CMBP_Name < b.CMBP_Name) return -1; 
            if (a.CMBP_Name > b.CMBP_Name) return 1; 
            return 0;                         
        });

        SetBakeryProductList(sortedProducts);
    }
    const QueryBakeryProductList = async()=>
    {
        try 
        { 
            const fd = new FormData();
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("target_day", new Date());
            const main_res = await FetchProductionLogDailyMerged(fd);
            // const main_res = await FetchBakeryProductAll(auth.token);
            InitBakeryDataPackage(main_res);
            SortAndSetBakertList(main_res);
            SetBakeryProductListOriginal(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Bakery Product List: " + err.message);
        }
    }

    // /////////////////////////////// BAKERY END
    

    useEffect(()=>{
        QueryBakeryProductList();
    }, [])


    return (
        <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.heder_type_main_wrapper}`}>
                    <h3>Bakery</h3>
                    <div className={`${classes.group_filter_and_action}`}>
                        <input onChange={(e)=>{HandleBakeryFilter(e.target.value)}} type="text" placeholder='Filter' autoComplete='off' />
                    </div>
                </div>

                <div className={`${classes.list_content_section_wrapper}`}>
                    <div style={{pointerEvents: LockBakeryInteraction ? 'none' : '', filter: LockBakeryInteraction ? 'brightness(50%)' : 'brightness(100%)' }} className={`${classes.items_list_wrapper}`}>
                        {
                            BakeryProductList.map((item, idx)=>(
                                <div key={item.CMBP_Name + idx} className={`${classes.list_single_item}`}>
                                    <div className={`${classes.produc_singluar_name} ${classes.data_inner_wrapper}`}>
                                        {item.CMBP_Name} <span>#{item.Quantity} Pcs {item.YUnsold &&  item.YUnsold  > 0 && <span>(U-{item.YUnsold} Pcs)</span> }</span>
                                    </div>

                                    <div className={`${classes.data_left_side_input_wrapper}`}>
                                        <div className={`${classes.product_input_data_wrapper} ${classes.data_inner_wrapper}`}>
                                            <li className={`${classes.damage_prod_report}`}> <span><Warning weight='fill'/></span> Damage</li>
                                            <input 
                                                value={BakeryDataPackage.get(item.CMBP_Name)?.Damage || ''}
                                                onChange={(e) => UpdateBakeryDamageAmount(item.CMBP_Name, e.target.value)}
                                                type="number" 
                                                name={item.CMBP_Name + '_'+'damage'}
                                                min={1} />
                                        </div>
                                        <div className={`${classes.product_input_data_wrapper} ${classes.data_inner_wrapper}`}>
                                            <li className={`${classes.unsold_prod_report}`}> <span><ArrowBendUpRight weight='fill'/></span> Unsold</li>
                                            <input
                                                key={item.CMBP_Name + "_unsold"}
                                                value={BakeryDataPackage.get(item.CMBP_Name)?.Unsold || ''}
                                                onChange={(e) => UpdateBakeryUnsoldAmount(item.CMBP_Name, e.target.value)}
                                                name={item.CMBP_Name + '_'+'unsold'}
                                                type="number" 
                                                min={1} />
                                        </div>
                                    </div>
                                    
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={`${classes.bottom_action_wrapper}`}>
                    <UIButton 
                        Variant={LockBakeryInteraction ? "Danger" : "Primary"} 
                        Icon={LockBakeryInteraction ? <Lock weight='fill'/> : <LockOpen weight='fill' />} 
                        label={LockBakeryInteraction ? "Unlock Interaction" : "Lock Interaction"} 
                        onClick={()=>{
                            SetLockBakeryInteraction(!LockBakeryInteraction);
                            Interaction(!LockBakeryInteraction);
                            }}/>
                </div>
            </div>
    )
}