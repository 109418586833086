import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider"

import LoadingPlaceholder from "./SharedComp/LoadingPlaceholder/LoadingPlaceholder";

const PrivateRoute = () => {

    const { user, loading, CheckingUserAuth } = useAuth();

    if(loading || CheckingUserAuth)
    {
        return (
            <div style={{background: `#000000`, height: `100vh`}}>
                <LoadingPlaceholder preview_text="Loading Please Wait..."/>
            </div>
        )
    }

    if (!user) {
        console.log("Unknown User");
        return <Navigate to="/login" />;
    }
    

    return <Outlet />;
}

export default PrivateRoute;