import classes from './ManageUser.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNodes, faCircleNotch, faEye, faEyeSlash, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import { PrepareDropDownList, ToEthiopianCalander } from '../../../../SharedComp/Utility';
import LocalNotification from '../../../../SharedComp/LocalNotification/LocalNotification'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import axios from 'axios';
import man_placeholder from '../../../../asset/images/placeholder-man.jpg'
import chick_placeholder from '../../../../asset/images/placeholder-chick.jpg'
import { useEffect, useRef, useState } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import DotsGif from '../../../../SharedComp/CssGif/DotsGif'

import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import DecisionAssurance from '../../../../SharedComp/DecisionAssurance/DecisionAssurance'
import { useDAControl } from '../../../../DAControlContext'
import { useAuth } from '../../../../AuthProvider'

import {QueryAllUserRoles, QueryUserByID, TerminateUserSession, UpdateUserAccountState, UpdateUserPassword, UpdateUserRole, UpdateUserWorkspaceAccessControl} from '../../../../SharedComp/API/UserAccess/API_FE_UserManage'
import { useToast } from '../../../../ToastContext'
import UIButton from '../../../Components/UIButton/UIButton'
import { Prohibit, SealCheck, Skull, StopCircle } from '@phosphor-icons/react'
import PasswordVerfiyPage from './PasswordVerfiyPage/PasswordVerfiyPage'
import { FetchWorkspaceBranchAll } from '../../../../SharedComp/API/Workspace/API_FE_Workspace'
import { Custom_DD_Style } from '../../../../SharedComp/Utility'
import { QueryBaseEmployeeByID, QueryBaseEmployeeBySyntID } from '../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee'
import { SendVerificationMail } from '../../../../SharedComp/API/UserAccess/API_FE_UserAccess'

// let DefaultIcon = L.icon({
//     iconUrl: icon,
//     iconSize: [20, 20],
//     className: `${classes.map_marker}`
// });

// L.Marker.prototype.options.icon = DefaultIcon;


const mapStyle = { height: "200px", width: "100%", borderRadius: '10px' };

export default function ManageUser({UsrData, Close_CALLBACK})
{
    let position = [1.024307, 1.866125];
    const [Placeholder, SetPlaceholder] = useState(chick_placeholder);
    const [SiteList, SetSiteList] = useState([]);
    const [WorkspaceAccessList, setWorkspaceAccessList] = useState(new Map());
    const [WorkspaceAccessListBK, SetWorkspaceAccessListBK] = useState(null);
    const [NewUserKey, SetNewUserKey] = useState();
    const [HideKey, SetHideKey] = useState(false);
    const [RoleList, SetRoleList] = useState([]);
    const [ActiveRole, SetActiveRole] = useState();
    const [LeafletReady, SetLeafletReady] = useState(false);
    const [UsrLoc, SetUsrLoc] = useState({latitude: 0.0, longitude: 0.0});
    const [verifiyingEmail, SetverifiyingEmail] = useState(false);
    const [TargetUser, SetTargetUser] = useState();
    
    const [EmployeeDetail, SetEmployeeDetail] = useState(null);
    const [EmployeeDocProfile, SetEmployeeDocProfile] = useState(false);

    const [LocalUserData, SetLocalUserData] = useState(null);
    const [InitialLoaded, SetInitialLoaded] = useState(false);
    const [UserConfiguration, SetUserConfiguration] = useState(null);
    const [LatestSessionInfo, SetLatestSessionInfo] = useState(null);

    // Ref
    const NewKeyInputFormRef = useRef();
    const DAControl = useDAControl();
    const auth = useAuth();
    const notify = useToast();


    const RequestVerfication = async()=>
    {
        try 
        {
            const _target_email = LocalUserData.USR_Email;
            const _user_full_name = (LocalUserData.USR_FirstName + " " + LocalUserData.USR_LastName);

            const fd = new FormData();
            fd.append("user_email", _target_email);
            fd.append("user_full_name", _user_full_name);
            fd.append("user_id", LocalUserData.USR_UserAccountID);

            const main_res = await SendVerificationMail(fd);

        } catch(err) 
        {

        }
    } 
    const HandleEmailVerification = async(_item)=>
    {
        RequestVerfication();
        SetverifiyingEmail(true);
        SetTargetUser(_item);
    }

    const getTargetUser = async() =>
    {
        try 
        {
            const fd = new FormData();
            fd.append("ID", UsrData.USR_UserAccountID);
            const main_res = await QueryUserByID(fd);

            SetLocalUserData(main_res[0]);
            const _parsed_json = JSON.parse(main_res[0].USR_UserConfig);
            SetUserConfiguration(_parsed_json);

            if(_parsed_json.USR_Sessions.SessionHistory.length > 0) 
            {
                // get the last push
                const _new_session = _parsed_json.USR_Sessions.SessionHistory[_parsed_json.USR_Sessions.SessionHistory.length - 1];
                SetLatestSessionInfo(_new_session);
                const loc_array = _new_session.SessionRequest_AproxLocation;
                SetUsrLoc({latitude: parseFloat(loc_array.latitude), longitude: parseFloat(loc_array.longitude)})
            }

            position[0] = 0.0;
            position[1] = 0.0;

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    // fetch core
    useEffect(()=>{ 
        if(UsrData)
        {
            getTargetUser();
        }
        QueryUserRole();

    }, [UsrData]);

    function GenerateNewRandomKey(pass_leng = 10) 
    {
        const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
        const allchars = upperCase + lowerCase + numbers + specialChars;
        const char_count = allchars.length;

        let pass = '';
        for(let  i = 0; i < pass_leng; i++)
            pass += (allchars[Math.floor(Math.random() * char_count)]);

        SetNewUserKey(pass);
    }
    function HandleWorkshopeSelection(branch_data) 
    {
        setWorkspaceAccessList((prev)=>
        {
            const _data = new Map(prev);
            if(_data.has(branch_data.BranchName))
            {
                _data.delete(branch_data.BranchName);
            } else 
            {
                _data.set(branch_data.BranchName, branch_data.BranchID);
            }

            return _data;
        });    
    }

    const getEmployeeProfileForUser = async()=> 
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", LocalUserData.USR_EmployeeID)
            const main_res = await QueryBaseEmployeeBySyntID(fd);
            SetEmployeeDetail(main_res[0]);
            SetEmployeeDocProfile(main_res[0].JAKSBE_EmployeeContextProfile.EDOC_Profile);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const QueryUserRole = async()=> 
    {
        try 
        {
            const main_res = await QueryAllUserRoles();
            PrepareDropDownList(main_res, SetRoleList)
        }catch(err) 
        {

        }
    }

    function PrepAvailAccess() 
    {
        const _current_state = UserConfiguration.USR_WorkspacePrivilege;
        const _temp_map = new Map();
        for(let i = 0; i < SiteList.length; i++) 
        {
             const __id = parseInt(SiteList[i].BranchID);
             if(_current_state[SiteList[i].BranchName]) 
             {
                _temp_map.set(SiteList[i].BranchName, __id);
             }             
        }
        setWorkspaceAccessList(_temp_map);
    }


    useEffect(()=>{
        
        if(LocalUserData) 
        {
            if(LocalUserData.USR_Type === 'Standard') 
            {
                getEmployeeProfileForUser();
            }
            PrepAvailAccess();
        }

    }, [LocalUserData, SiteList])


    const WorkspaceAccessUpdateRequest = async()=>
    {
        const _tab_id = Math.floor(Math.random() * 1000);
        notify.ShowLoad("Processing your request...", _tab_id);
        try 
        {
            const fd = new FormData();
            fd.append("UsrID", LocalUserData.USR_UserAccountID);
            fd.append("AccessPack", JSON.stringify(Array.from(WorkspaceAccessList.entries())));

            const main_res = await UpdateUserWorkspaceAccessControl(fd);

            notify.ShowSuccessUpdate(main_res.Msg, _tab_id);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, _tab_id);
        }
    }
    function HandleAccessUpdate(e) 
    {
        e.preventDefault();
        WorkspaceAccessUpdateRequest();
    }
    const PasswordUpdateRequest = async()=>
    {
        const _tab_id = Math.floor(Math.random() * 1000);
        notify.ShowLoad("Processing your request...", _tab_id);
        try 
        {
            const fd = new FormData();
            fd.append("NewUserKey", NewUserKey);
            fd.append("UsrID", LocalUserData.USR_UserAccountID);

            const main_res = await UpdateUserPassword(fd);
            notify.ShowSuccessUpdate(main_res.Msg, _tab_id);
            SetNewUserKey('');
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, _tab_id);
        }
    }
    function HandlePassKeyUpdate(e) 
    {
        e.preventDefault();
        PasswordUpdateRequest();       
    }
    const UpdateRoleRequest = async()=>
    {
        const _tab_id = Math.floor(Math.random() * 1000);
        notify.ShowLoad("Processing your request...", _tab_id);
        try 
        {
            const fd = new FormData();
            fd.append("NewRole", JSON.stringify(ActiveRole));
            fd.append("UsrID", LocalUserData.USR_UserAccountID);

            const main_res = await UpdateUserRole(fd);

            notify.ShowSuccessUpdate(main_res.Msg, _tab_id);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, _tab_id);
        }
    }
    function HandleUpdateUserRole(e) 
    {
        e.preventDefault();
        UpdateRoleRequest();
    }
    const UpdateAccountState = async () =>
    {
        const _tab_id = Math.floor(Math.random() * 1000);
        notify.ShowLoad("Processing your request...", _tab_id);
        try 
        {
            const new_state = LocalUserData.USR_AccountState === 'Active' ? 'Suspended' : 'Active';
            const fd = new FormData();
            fd.append("NewState", new_state);
            fd.append("UsrID", LocalUserData.USR_UserAccountID);

            const main_res = await UpdateUserAccountState(fd);
            notify.ShowSuccessUpdate(main_res.Msg, _tab_id);
        }catch(err) 
        {
            notify.ShowSuccessUpdate(err.message, _tab_id);
        }
    }

    const getAllCompanyBranch = async()=>
    {
        try 
        {
            const main_res = await FetchWorkspaceBranchAll(auth.token);
            SetSiteList(main_res);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(() => 
    {
        getAllCompanyBranch();
    }, []) 


    const HandleUserSessionTermination = async (user_data)=>
    {

        if(!auth.user) 
        {
            notify.ShowError("Failed to determine current user, please try again!");
            return;
        }

        if(auth.user.USR_UserAccountID === user_data.USR_UserAccountID) 
        {
            notify.ShowError("You cannot terminate your own session. Please sign out if you want to end your session.");
            return;
        }

        if(!UserConfiguration.USR_IsOnline) 
        {
            notify.ShowError("You cannot terminate an inactive session.");
            return;
        }

        notify.ShowLoad("Processing your request....", 10);
        try 
        {

            const fd = new FormData();
            fd.append("user_id", user_data.USR_UserAccountID);
            const main_res = await TerminateUserSession(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

// Add this (ValidEmail) to user account after Email
    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


            {verifiyingEmail && <PasswordVerfiyPage RequestOTP_Callback={RequestVerfication} XUser={TargetUser} CloseModal={SetverifiyingEmail} />}

        <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Manage User</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>


            {LocalUserData && 
            <div className={`${classes.data_body}`}>

            <div className={`${classes.inner_wrapper}`}>

                <div className={`${classes.header_wrap}`}>

                    <div className={`${classes.top_level_user_detail}`}>
                        <div className={`${classes.user_pic_wrapper}`}>
                            <img src={getBaseURL() + EmployeeDocProfile?.EDOCP_ProfilePicture} alt="" />
                        </div>
                        <div className={`${classes.user_name_wrapper}`}>
                            <li>{LocalUserData.USR_Username} / {LocalUserData.UARI_Name}</li>
                        </div>
                    </div>

                    <div className={`${classes.session_detail}`}>
                        {/* <div className={`${classes.state_wrapper}`}>
                            <button className={`${LocalUserData.USR_AccountState ===  'Active' ? classes.account_state_active : classes.account_state_suspend}`}>{LocalUserData.USR_AccountState}</button>
                            <button className={`${UserConfiguration.USR_IsOnline ? classes.online_state : classes.offline_state}`}>{UserConfiguration.USR_IsOnline ? 'Online' : 'Offline'}</button>
                        </div> */}

                        <div className={`${classes.info_wrap}`}>

                            <div className={`${classes.raw_info_wrap}`}>

                                <li>
                                {LocalUserData.USR_FirstName} {LocalUserData.USR_LastName}
                                </li>
                                <li className={`${classes.user_email_preview_check}`}>
                                    {LocalUserData.USR_Email} {LocalUserData.USR_ValidEmail ? <span className={`${classes.verified_email}`}><SealCheck size={16} weight='bold'/></span> : <span onClick={()=>{HandleEmailVerification(LocalUserData)}} className={`${classes.unverified_email}`}>Unverified Email</span> }
                                </li> 
                                <li>
                                    {LocalUserData.USR_Type} - User
                                </li>

                                    <form ref={()=>{return NewKeyInputFormRef}} onSubmit={HandlePassKeyUpdate} method="post">
                                        <div className={`${classes.pass_update_wrapper}`}>
                                            <input value={NewUserKey} onChange={(e)=>{SetNewUserKey(e.target.value)}} type={HideKey ? 'password' : 'text'} placeholder='New Password' required />
                                            <div className={`${classes.pass_field_action}`}>
                                                    <button type='button' onClick={(e)=>{SetHideKey(!HideKey)}}><FontAwesomeIcon icon={HideKey ? faEye : faEyeSlash}></FontAwesomeIcon></button>
                                                    <button type='button' onClick={(e)=>{GenerateNewRandomKey()}}><FontAwesomeIcon icon={faCircleNodes}></FontAwesomeIcon></button>
                                                    <button type='submit'>Save</button>
                                            </div>
                                        </div>
                                    </form>
                                    <form onSubmit={HandleUpdateUserRole} method="post">
                                        <div className={`${classes.role_update_wrapper}`}>
                                            <div className={`${classes.input_box_dp_role_data_wrapper}`}>
                                                <Select
                                                    onChange={(data)=>{SetActiveRole(data)}}
                                                    name={"dd_data"}
                                                    options={RoleList}
                                                    placeholder={"Update Role ..."}
                                                    isSearchable={true}
                                                    styles={Custom_DD_Style}
                                                    required ={true}
                                                />
                                            </div>
                                            <button type='submit'>Save</button>
                                        </div>
                                    </form>

                            </div>
                            <div className={`${classes.access_wrap}`}>
                                <form onSubmit={HandleAccessUpdate} method="post">
                                    <div className={`${classes.batch_input_wrap}`}>


                                        {
                                           SiteList.map((item, idx)=>(
                                                <div onClick={()=>{HandleWorkshopeSelection(item)}} key={idx} className={`${classes.single_input_wrap}`}>
                                                    <input 
                                                        onChange={(e)=>{HandleWorkshopeSelection(item)}} 
                                                        name={item.BranchName} 
                                                        checked={WorkspaceAccessList.has(item.BranchName)} 
                                                        type="checkbox" /> 
                                                    <span className={`${classes.workspace_access_tag}`}>[Access]-{item.BranchName}</span>

                                                </div>
                                            ))
                                        }

                                    </div>
                                    
                                    <div className={`${classes.action_wrap}`}>
                                        <UIButton onClick={()=>{}} Variant='Secondary' label={"Update Workspace Access"}/>
                                    </div>
                                    
                                </form>
                            </div>

                        </div>
                        
                    </div>

                </div>

                <div className={`${classes.map_frame_wrapper}`}>

                    <div className={`${classes.leaftlet_map}`}>
                        <MapContainer 
                                zoomControl={false} 
                                attributionControl={false} 
                                center={[UsrLoc.latitude, UsrLoc.longitude]} 
                                zoom={12} 
                                style={mapStyle}
                                whenReady={()=>{SetLeafletReady(true)}}
                                >
                                    {
                                        LeafletReady && 
                                        <>
                                         <TileLayer
                                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                                attribution='&copy; <a href="https://www.esri.com">Esri</a>'/>
                                        <TileLayer
                                                url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                                                attribution='&copy; <a href="https://www.esri.com">Esri</a>'
                                                opacity={1}/>
                                            <Marker position={[UsrLoc.latitude, UsrLoc.longitude]}>
                                                <Popup>
                                                    <div className={`${classes.user_location_info_tip}`}>
                                                        <li>{LatestSessionInfo?.SessionRequest_Region} / {LatestSessionInfo?.SessionRequest_Country}</li>
                                                        <li>{LatestSessionInfo?.SessionRequest_ISP}</li>
                                                        <li>{LatestSessionInfo?.SessionRequest_City ? 'This is an approximation based on the IP address and may not reflect the exact location.' : 'The user may not be logged in yet, or we were unable to determine their location'}</li>
                                                    </div>
                                                </Popup>
                                            </Marker>
                                        </>
                                    }
                           


                        </MapContainer>
                    </div>

                    <div className={`${classes.usr_info_pers}`}>
                        <li><span className={`${classes.ac_tag}`}>[Total Session Count]</span> <span className={`${classes.ac_val}`}>{UserConfiguration.USR_Sessions.SessionCount}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[IP Address]</span> <span className={`${classes.ac_val}`}>{LatestSessionInfo?.SessionRequest_IPAddress}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Request Origin City]</span> <span className={`${classes.ac_val}`}>{LatestSessionInfo?.SessionRequest_City}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Request Origin Region]</span> <span className={`${classes.ac_val}`}>{LatestSessionInfo?.SessionRequest_Region}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Request Origin Country]</span> <span className={`${classes.ac_val}`}>{LatestSessionInfo?.SessionRequest_Country}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[ISP]</span> <span className={`${classes.ac_val}`}>{LatestSessionInfo?.SessionRequest_ISP}</span></li>
                        <li><span className={`${classes.ac_tag}`}>[Location]</span> <span className={`${classes.ac_val}`}>{LatestSessionInfo ? `${UsrLoc.latitude}, ${UsrLoc.longitude} `: '--'}</span></li>
                        {LatestSessionInfo && <li><span className={`${classes.ac_tag}`}>[Initial Login Requested]</span> <span className={`${classes.ac_val}`}>{ToEthiopianCalander(new Date(LatestSessionInfo?.SessionRequest_IntialTime))} @ {new Date(LatestSessionInfo?.SessionRequest_IntialTime).toTimeString()}</span></li>}
                    </div>

                </div>

                <div className={`${classes.user_action}`}>
                    <UIButton onClick={(e)=>{UpdateAccountState()}} Variant='Secondary' Icon={<StopCircle  weight='bold'/>} label={LocalUserData.USR_AccountState === 'Active'? 'Restrict' : 'Reactivate'} />
                    <UIButton onClick={(e)=>{
                            DAControl.Trigger(`Terminate User Session`, 
                                `You are about to terminate a user session. This action will immediately affect the user's current activity.`,
                                true,
                                8,
                                async (data)=>
                                {
                                    if(data) 
                                    {
                                        await HandleUserSessionTermination(LocalUserData);
                                    }
                                }
                            )
                        }} Variant='Secondary' Icon={<Skull/>} label={"Terminate Session"} />
                        
                </div>

            </div>

        </div>
            }

            {!LocalUserData && <div className={`${classes.loading_placeholder}`}>
                  <LoadingPlaceholder/>
             </div>}

        </div>

    </div>
    )
}