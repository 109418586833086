import api from "../Util/axiosInstance";




export const CreateFinalBakeryProductRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/create-final-bakery-product-request', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const ApproveFinalBakeryProductRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/approve-final-bakery-product-request', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const RejectFinalBakeryProductRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/reject-final-bakery-product-request', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const QueryPendingFinalBakeryRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/query-pending-final-bakery-product-request', data);
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}

export const QueryFinalBakeryProductRequestIncomingAll = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/query-final-bakery-product-incoming-all-request', data);
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}
export const QueryFinalBakeryProductRequestOutgoingAll = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/query-final-bakery-product-outgoing-all-request', data);
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}
export const QueryTransferByApprover = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/query-transfer-entry-by-approver', data);
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}
export const QueryTransferByInitializer = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/query-transfer-entry-by-initializer', data);
        const res = await response.data;
        return res; 
    }catch(err) 
    {
        throw err;
    }
}


export const ReceiveAndFinishFinalProductTransfer = async(data)=>
{
    try 
    {
        const response = await api.post('api/final-bakery-transfer/receive-and-finalize-final-bakery-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}