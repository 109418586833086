import api from "../Util/axiosInstance";





export const AddPastryShelvesProduct = async(data)=>
{
    try 
    {
        const response = await api.post('api/shelves-pastry/add-pastry-products-to-branch', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err)
    {
        throw err;
    }
} 

export const QueryPastryShelvesProductByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/shelves-pastry/query-pastry-products-by-branch', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}