import classes from './BudgetCashUtilizationGraphView.module.css'
import Chart from 'react-apexcharts'

export default function BudgetCashUtilizationGraphView({GraphData}) 
{

    const chart_option = {
        
        labels: ['Available Budget', 'Utilized Budget'],
        chart: {
            
            type: 'pie',
        //   background: 'var(--background-dark_plus)', // Set the background color
            },
        
          dataLabels: {
            enabled: true, // Hide data labels inside the graph
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex].toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'ETB',
                  });
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val; // Display actual values in tooltip
              }
            }
        },
          stroke: {
            show: true,
            width: 5, // Stroke width
            colors: ['var(--background-dark_minus)'] // Stroke color (black in this example)
          },
          tooltip: {
            theme: 'dark', // Set the theme to dark
          },
          colors: ['var(--color-theme)', 'var(--background-light)'], // Set the text color
      };

    return (
      <div className={`${classes.budget_allocation_history_local_view_master_wrapper}`}>
        <div className={`${classes.budget_allocation_history_local_view_inner_wrapper}`}>

            <div className={`${classes.budget_allocation_history_local_view_header}`}>
                <h2>Cash Utilization</h2>
            </div>

            <div className={`${classes.budget_allocation_history_local_view_body_master_wrapper}`}>
                <Chart
                    options={chart_option}
                    series={GraphData}
                    type="pie"
                    height={180}
                />
            </div>
        
        </div>
    </div>
    )
}