import classes from './PittyCashFinance.module.css'

import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';

import { useEffect, useState } from 'react';
import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';

import MiniPittyCashBranchCard from './Component/MiniPittyCashBranchCard';
import PittyCashBranchAnalysis from './Component/PittyCashBranchAnalysis/PittyCashBranchAnalysis';
import { useAuth } from '../../../AuthProvider';
import { QueryAllBranchActiveBudgets } from '../../../SharedComp/API/PettyCash/API_FE_PettyCash';
import { FetchWorkspaceBranchAll } from '../../../SharedComp/API/Workspace/API_FE_Workspace';
const getCssVariable = (variableName) =>
  getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
export default function PittyCashFinanceLevel() 
{

    const auth = useAuth();

    const colorTheme = getCssVariable('--color-theme');
    const [workspaceList, setWorkspaceList] = useState([]);
    const [targetWorkspace, setTargetWorkspace] = useState();
    const [refereshData, setRefershData] = useState(false);
    

    // windows
    const [OpenPittyCashAnalysis, SetOpenPittyCashAnalysis] = useState(false);
    const [ChartDistroView, SetChartDistroView] = useState([]);
    const [ChartDataViewRemain, SetChartDataViewRemain] = useState([]);
    const series = [
        {
          name: 'Initial Budget',
          data: ChartDistroView,
        },
        {
            name: 'Available Balance',
            data: ChartDataViewRemain
        }
      ];

    function OpenPittyCashAnalysisCallback(data) 
    {
        setTargetWorkspace(data);
        SetOpenPittyCashAnalysis(true);
    }

    const QueryAllActiveBranchPettyCashBudget = async()=>
    {
        try 
        {
            const main_res = await QueryAllBranchActiveBudgets(auth.token);

            const _val = [];
            const _val_remain = [];
            for(let i = 0; i < main_res.length; i++) 
            {
                _val.push(
                    {
                        x: main_res[i].BranchName,
                        y: parseFloat(main_res[i].PCMM_InitialAllocationAmount),
                    });
                _val_remain.push(
                    {
                        x: main_res[i].BranchName,
                        y: parseFloat(main_res[i].PCMM_RemainBudget),
                    });
            }
            SetChartDistroView(_val);
            SetChartDataViewRemain(_val_remain);
        }catch(err) 
        {

        }
    }

    const fetchBranch = async () => {
        try 
        {
          const main_res = await FetchWorkspaceBranchAll(auth.token);
          setWorkspaceList(main_res);

        }catch(err)  
        {
            throw new Error("Falied to fetch Workspace data!");
        }
    }

    useEffect(()=>{
        QueryAllActiveBranchPettyCashBudget();
        setWorkspaceList([]);
        fetchBranch();
    }, [auth.activeWorkShop, refereshData])


    const chart_option = {
        
        chart: {
          background: 'var(--background-dark_plus)', // Set the background color
      },
      theme: !auth.DarkMode ? { 
        monochrome: {
          enabled: true,
          color: colorTheme,
          shadeTo: 'light',
          shadeIntensity: 0.65
        } 
      } : {},
        xaxis: {
            type: 'category',
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set x-axis text color
              },
            },
          },
          yaxis: {
            show: true, // Hide y-axis
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set y-axis text color
              },
            },
          },
          grid: {
            show: false, // Hide grid lines
            
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return val.toLocaleString('en-US', {
                style: 'currency',
                currency: 'ETB',
                });
            },
            style: {
                fontSize: '10px', // Adjust the font size here
            },
          },
          tooltip: {
            theme: auth.DarkMode ? "dark" : 'light', // Set the theme to dark
          },
        colors: ['var(--color-theme)', 'var(--red-color)'], 
      };

    return (
        <div className={`${classes.pitty_cash_finance_level_master_wrapper}`}>

            
            {OpenPittyCashAnalysis && <PittyCashBranchAnalysis RefershData={setRefershData} Item={targetWorkspace} Close_CALLBACK={SetOpenPittyCashAnalysis}/>}

                <div className={`${classes.charts_wrapper}`}>
                        <div className={`${classes.single_chart_wrapper}`}>
                            <div className={`${classes.char_head_name_Wrapper}`}>
                                <h1>Budget Distribution</h1>
                            </div>
                            <div className={`${classes.char_body_wrapper}`}>
                                <Chart
                                    options={chart_option}
                                    series={series}
                                    type="area"
                                    height={250}
                                />
                            </div>
                        </div>
                </div>

                <div className={`${classes.main_report_body_wrapper}`}>

                    <div className={`${classes.main_report_body_inner_wrapper}`}>

                            <div className={`${classes.branch_card_list_wrapper}`}>
                                {
                                    workspaceList.map((item, idx)=>(
                                        auth.userAccessWorkspaceIDList.includes(item.BranchID) &&
                                        <MiniPittyCashBranchCard key={idx} Item={item} OpenAnalysis={OpenPittyCashAnalysisCallback} />
                                    ))
                                }
                        
                            </div>

                    </div>

                </div>
        </div>
    )
}