import classes from './ProductionStackOrderSingleCardWrapper.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FetchFinalProductSaved, FetchMixSaved} from '../../../../api'

import { FetchBakeryProductByID } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';

import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../../../AuthProvider';


export default function ProductionStackOrderSingleCardWrapper({Target, PackageList, SingleCard, OrderGroupID}) 
{

    const [TargetData, SetTargetData] = useState();
    const [IsMix, SetIsMix] = useState(Target.OP_IsMix);
    const [Selected, SetSelected] = useState();
    const auth = useAuth();


    const FetchTargetData = async()=>{
        try 
        {
            const fd = new FormData();
            fd.append("targte_id", Target.OP_ItemID);
            const res = IsMix ? await FetchMixSaved(Target.OP_ItemID) : await FetchBakeryProductByID(fd);
            SetTargetData(res[0]);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        FetchTargetData();
    }, [])

    useEffect(()=>{
        if(TargetData) 
        {
            SetSelected(PackageList.has(IsMix ? TargetData?.MixName: TargetData?.CMBP_Name));
        }
    }, [PackageList])


    return (
        <div onClick={()=>{
            if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PerformProductionLogEntry) 
            {
                if(!Target.OP_Returned)
                {
                    SingleCard(TargetData, IsMix, Target.OP_BatchRemain, OrderGroupID, Target.OP_UniqueID);
                }
            }
            }} className={`${classes.main_product_shelf_card_wrapper} ${Selected && classes.main_product_shelf_card_wrapper_selected} ${Target.OP_Returned && classes.product_is_returned}`}>
                {Target.OP_Returned && 
                    <div className={`${classes.rot_approve_data_wrapper}`}>
                        <li>RTO Approved</li>
                    </div>
                }
            <div className={`${classes.prod_name_info_wrapper}`}>
                <h2><sub className={`${classes.item_type}`}> {IsMix ? ["MIX"] : ["FP"]}</sub>{IsMix ? TargetData?.MixName: TargetData?.CMBP_Name} <sup className={`${classes.batch_preview}`}>RM x{Target.OP_BatchRemain}</sup> <sup className={`${classes.batch_preview_TL}`}>TL x{Target.OP_BatchCount}</sup></h2>
                
            </div>
        </div>
    )
}