import classes from './CKProduction.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import CreateCKProductType from './Component/CreateCKProductType/CreateCKProductType';
import UpdateCKProductType from './Component/UpdateCKProductType/UpdateCKProductType';


import CreateCKProducts from './Component/CreateCKProduct/CreateCKProduct';
import UpdateCKProduct from './Component/UpdateCKProduct/UpdateCKProduct';

import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy';

import {QueryProdTypeAll, QueryCKProductALL, PurgeProdTypeID, PurgeCKProduct_API_ED} from '../../../SharedComp/API/CK/AP_CenteraKitchen'
import { faBan, faPenNib } from '@fortawesome/free-solid-svg-icons';
import { useDAControl } from '../../../DAControlContext';
import { useToast } from '../../../ToastContext';
import { useAuth } from '../../../AuthProvider';
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';

const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function CKProduction() 
{

    const DAControl = useDAControl();
    const notify = useToast();
    const auth = useAuth();
    // CK Product Type
    const [OpenCKProductType, SetOpenCKProductType] = useState(false);
    const [ReloadQueryCKType, SetReloadQueryCKType] = useState(false);
    const [OpenCKProductTypeUpdate, SetOpenCKProductTypeUpdate] = useState(false);
    const [UpdateProdTypeID, SetUpdateProdTypeID] = useState();
    const HandleCKProdType = async(id)=>
    {
        SetUpdateProdTypeID(id);
        SetOpenCKProductTypeUpdate(true);
    }

    // Product Item
    const [OpenCKProduct, SetOpenCKProduct] = useState(false);
    const [ReloadQueryCKItem, SetReloadQueryCKItem] = useState(false);
    const [OpenCKProductTypeItem, SetOpenCKProductItemUpdate] = useState(false);
    const [UpdateProdItemID, SetUpdateProdItemID] = useState();
    const HandleCKProdItem = async(id)=>
    {
        SetUpdateProdItemID(id);
        SetOpenCKProductItemUpdate(true);
    }

    const [RefreshView, SetRefreshView] = useState(false);

    const [CKProductTypeList, SetCKProductTypeList] = useState([]);
    const [CKProductItemList, SetCKProductItemList] = useState([]);

    // Loading
    const [LoadingPastryGroup, SetLoadingPastryGroup] = useState(false);
    const [LoadingPastryItems, SetLoadingPastryItems] = useState(false);

    const QueryProductTypeList = async() =>
    {
        SetLoadingPastryGroup(true);
        try 
        {
            const res = await QueryProdTypeAll(auth.token);
            SetCKProductTypeList(res);
            SetLoadingPastryGroup(false);
        }catch(err) 
        {
            SetLoadingPastryGroup(false);
        }
    }
    const QueryProductItemList = async() =>
    {
        SetLoadingPastryItems(true);
        try 
        {
            const res = await QueryCKProductALL(auth.token);
            SetCKProductItemList(res);
            SetLoadingPastryItems(false);
        }catch(err) 
        {
            SetLoadingPastryItems(false);
        }
    }


    useEffect(()=>{
        QueryProductTypeList();
        QueryProductItemList();
    }, [RefreshView, OpenCKProductType, OpenCKProduct, OpenCKProductTypeUpdate, ReloadQueryCKType]);



    return (
        <div className={`${classes.ck_production_main_wrapper}`}>

        {OpenCKProductType && <CreateCKProductType OpenController={SetOpenCKProductType} />}
        {OpenCKProduct && <CreateCKProducts OpenController={SetOpenCKProduct}  />}

        {/* Update */}
        {OpenCKProductTypeUpdate && <UpdateCKProductType TargetID={UpdateProdTypeID} OpenController={SetOpenCKProductTypeUpdate} />}
        {OpenCKProductTypeItem && <UpdateCKProduct TargetID={UpdateProdItemID} OpenController={SetOpenCKProductItemUpdate} />}

        <div className={`${classes.table_wrapper}`}>
            <DataViewTableProxy 
                IsEmpty={CKProductTypeList.length === 0 && !LoadingPastryGroup} 
                OpenDataCreate={SetOpenCKProductType} DataArray={
                <div>
                    {
                        CKProductTypeList.map((item, idx)=>(
                            <li key={idx}>
                                <span>{item.CK_PCAT_Name}</span>
                                <span>{item.CK_PCAT_Remark}</span>
                                <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                    onClick={()=>{DAControl.Trigger("Purge Master Kitchen Products Group", 
                                        `You are about to purge Kitchen Products Group '${item.CK_PCAT_Name}'. (MAKE SURE THERE ARE NO LINKED SUB-PRODUCT TO THIS GROUP)
                                        Any active Product That are linked with this Group will be Hidden! This action is IRREVERSIBLE!`, 
                                        item.CKPTID,
                                        10,
                                        async (data)=>{
                                            if(!data)
                                                return;

                                                notify.ShowLoad(`Purging Item ${item.CK_PCAT_Name}.....`, 10);

                                                setTimeout(async()=>{

                                                    try 
                                                    {
                                                        const res = await PurgeProdTypeID(data, auth.token);
                                                        notify.ShowSuccessUpdate(res.Msg, 10);
                                                        SetReloadQueryCKType(!ReloadQueryCKType);
                                                    }catch(err) 
                                                    {
                                                        notify.ShowErrorUpdate(err.message, 10);
                                                    }

                                                }, 500)

                                        })}}
                                    >
                                        <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                        <span>Purge</span>
                                    </button> }
                                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData &&  <button onClick={()=>{HandleCKProdType(item.CKPTID)}}>
                                        <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                        <span>Manage</span>
                                    </button>}
                                </span>
                            </li>
                        ))
                    }
                    {
                        LoadingPastryGroup && 
                        <LoadingPlaceholder/>
                    }
                </div>
            }
                HeaderName={
                    <div>
                        <h2>Master <b>Kitchen Products</b> Group</h2>
                    </div>
                }
             />

            <DataViewTableProxy 
                    IsEmpty={CKProductItemList.length === 0 && !LoadingPastryItems} 
                    OpenDataCreate={SetOpenCKProduct} DataArray={
                <div>
                    {
                        CKProductItemList.map((item, idx)=>(
                            <li key={idx}>
                                <span>{item.CKP_ItemName}</span>
                                <span><span className={`${classes.cash_view_value}`}>{currency_format(item.CKP_UnitPrice)}</span></span>
                                <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                    onClick={()=>{DAControl.Trigger("Purge Master Kitchen Products Main Item", 
                                        `You are about to purge Kitchen Products  '${item.CKP_ItemName}'. If there is any active order it will be REMOVED. This action is IRREVERSIBLE!`, 
                                        item.CKPID,
                                        10,
                                        async (data)=>{
                                            if(!data)
                                                return;

                                                notify.ShowLoad(`Purging Item ${item.CKP_ItemName}.....`, 10);

                                                setTimeout(async()=>{

                                                    try 
                                                    {
                                                        const res = await PurgeCKProduct_API_ED(data, auth.token);
                                                        notify.ShowSuccessUpdate(res.Msg, 10);
                                                        SetReloadQueryCKType(!ReloadQueryCKType);
                                                    }catch(err) 
                                                    {
                                                        notify.ShowErrorUpdate(err.message, 10);
                                                    }

                                                }, 500)

                                        })}}
                                    >
                                        <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                        <span>Purge</span>
                                    </button>}
                                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleCKProdItem(item.CKPID)}}>
                                        <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                        <span>Manage</span>
                                    </button>}
                                </span>
                            </li>
                        ))
                    }
                    {
                        LoadingPastryItems && 
                        <LoadingPlaceholder/>
                    }
                </div>
            }
                HeaderName={
                    <div>
                        <h2>Master <b>Kitchen Products</b> Main Item</h2>
                    </div>
                }
             />

            </div>

                
        </div>
    )
}