import classes from '../PayrollConfig/PayrollConfig.module.css'
import { Tooltip } from 'react-tooltip';
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'

export default function GeneralConfig({UpdateReq, ConfigData}) 
{


    return (
        <div className={`${classes.payroll_config_wrapper}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="general_config_tip" place="bottom" />
            <div className={`${classes.part_of_option}`}>
                <ul>General</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span
                    {...({'data-tooltip-id': 'general_config_tip', 'data-tooltip-content': 
                    `If you enable this option only user with verifyed email can login- (If you want to apply this terminate all active session first)`})}
                    >Require Email Verification Before Account Activation</span> <span><input onChange={(e)=>{UpdateReq("GC_RequireEmailVerificationBeforeAccountActivation", e.target.checked)}} checked={ConfigData.GC_RequireEmailVerificationBeforeAccountActivation} type="checkbox" /></span></li>
                    <li><span>Shipped Stock Can Be Received Before</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_ShippedStockCanBeReceivedBefore",  parseInt(e.target.value)); }} value={String(ConfigData.GC_ShippedStockCanBeReceivedBefore)}>
                                <option value="1">1 Hour</option>
                                <option value="3">3 Hour</option>
                                <option value="6">6 Hour</option>
                                <option value="12">12 Hour</option>
                                <option value="24">1 Day</option>
                            </select>
                        </span>
                    </li>
                    <li><span
                    {...({'data-tooltip-id': 'general_config_tip', 'data-tooltip-content': 
                    `This will help the system to clean user session automatically, by providing cyclic session termination. I originaly thought people will sign-out when they finish, but they don't including me so this guy will take care of that mess.`})}
                    >Terminate All Active Session Every</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_TerminateAllActiveSession",  parseInt(e.target.value)); }} value={String(ConfigData.GC_TerminateAllActiveSession)}>
                                <option value="6">6 Hour</option>
                                <option value="12">12 Hour</option>
                                <option value="24">1 Day</option>
                            </select>
                        </span>
                    </li>
                </div>  
            </div>
            {/* <div className={`${classes.part_of_option}`}>
                <ul>Petty Cash</ul>
                <div className={`${classes.setting_items_config}`}>
                    <li><span>Notify Branch When New Budget Is Allocated (Slack)</span> <span><input onChange={(e)=>{UpdateReq("GC_RequireEmailVerificationBeforeAccountActivation", e.target.checked)}} checked={ConfigData.GC_RequireEmailVerificationBeforeAccountActivation} type="checkbox" /></span></li>
                    <li><span>Minimum Petty Cash</span> 
                        <span>
                            <select onChange={(e)=>{UpdateReq("GC_ShippedStockCanBeReceivedBefore",  parseInt(e.target.value)); }} value={String(ConfigData.GC_ShippedStockCanBeReceivedBefore)}>
                                <option value="500">500 Birr</option>
                                <option value="1000">1,000 Birr</option>
                                <option value="2000">2,000 Birr</option>
                                <option value="3000">3,000 Birr</option>
                                <option value="4000">4,000 Birr</option>
                            </select>
                        </span>
                    </li>
                    <li><span>Notify Branch With Slack When New Budget Is Allocated</span> <span><input onChange={(e)=>{UpdateReq("GC_RequireEmailVerificationBeforeAccountActivation", e.target.checked)}} checked={ConfigData.GC_RequireEmailVerificationBeforeAccountActivation} type="checkbox" /></span></li>
                </div>  
            </div> */}
        </div>
    )
}