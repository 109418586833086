import classes from './InventoryMasterData.module.css'
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenNib, faTrash, faCopy, faPlusCircle, faSort } from '@fortawesome/free-solid-svg-icons';

// Include Inventory Group Component
import CreateInventoryGoodType from './Component/CreateInventoryGoodType/CreateInventoryGoodType';
import EditInventoryGroup from './Component/EditInventoryGoodType/EditInventoryGroup';

// Include Inventory Item component
import CreateInventoryItem from './Component/CreateInventoryItem/CreateInventoryItem';
import EditInventoryItem from './Component/EditInventoryItem/EditInventoryItem';

// API
import {FetchIVGroupAll,FetchIVItemAll, PurgeIVGroupID, PurgeFetchIVItemID} from '../../../api'


import { useAuth } from '../../../AuthProvider';
import { useToast } from '../../../ToastContext';
import { useDAControl } from '../../../DAControlContext';
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';


export default function InventoryMasterData() 
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();
    
    // Filter
    const [EdibleOnly, SetEdibleOnly] = useState(false);

    // Reload the page
    const[ReloadPage, SetReloadPage] = useState(false);

    // Inventory Group
    const [OpenEditInventoryGoodTypeComp, SetOpenEditInventoryGoodTypeComp] = useState(false);
    const [OpenCreateIVGroupTypeComp, SetOpenCreateIVGroupTypeComp] = useState(false);
    const [InventoryGoodEditID, SetInventoryGoodEditID] = useState();

    //  Inventory Item
    const [OpenEdibleGoodDataCreator, SetOpenNonEdibleGoodDataCreator] = useState(false);
    const [OpenEditEventoryItem, SetOpenEditEventoryItem] = useState(false);
    const [InventoryItemEditID, SetInventoryItemEditID] = useState();

    const [InventoryItemGroupList, SetInventoryItemGroupList] = useState([]);
    const [InventoryItemList, SetInventoryItemList] = useState([]);
    const [InventoryItemViewList, SetInventoryItemViewList] = useState([]);


    const [MutiSelectSubProduct, SetMutiSelectSubProduct] = useState(false);
    const [LoadingItem, SetLoadingItem] = useState(false);
    const [LoadingGroup, SetLoadingGroup] = useState(false);

    const FetchIvItemGroup = async()=>{

        SetLoadingGroup(true);
        try 
        {
            const res = await FetchIVGroupAll(auth.token);
            SetInventoryItemGroupList(res);
            SetLoadingGroup(false);
        }catch(err) 
        {
            SetLoadingGroup(false);
            notify.ShowError("Falied to Fetch Inventory Item Group! Try Reloading the Page!");
        }

    }
    const HandleEditInventoryGroup = (id)=> 
    {
        SetInventoryGoodEditID(id);
        SetOpenEditInventoryGoodTypeComp(true);

    }
    const HandleEditInventoryItem = (id) => 
    {
        SetInventoryItemEditID(id);
        SetOpenEditEventoryItem(true);
    }
    const FetchIvItemData = async()=>{
        SetLoadingItem(true);
        try 
        {
            const res = await FetchIVItemAll(auth.token);
            SetInventoryItemList(res);
            const _sorted_data = SortByName(res);
            SetInventoryItemViewList(_sorted_data);
            SetLoadingItem(false);
        }catch(err) 
        {
            SetLoadingItem(false);
            notify.ShowError("Falied to Fetch Inventory Item! Try Reloading the Page!");
        }
    }

    const FilterIvItemByName = async(text)=>{

        const temp_list = [];
        for(let i = 0; i < InventoryItemList.length; i++) 
        {
            const check = InventoryItemList[i].IVItemName.toLowerCase();
            if(check.includes(text.toLowerCase()))
                temp_list.push(InventoryItemList[i])
        }

        SetInventoryItemViewList(temp_list);
    }
    const FilterIVItemSpecial = async(id)=>{
        const temp_list = [];
        for(let i = 0; i < InventoryItemList.length; i++) 
        {
            const check = InventoryItemList[i].IVItemTypeID;
            if(id === check)
                temp_list.push(InventoryItemList[i])
        }

        SetInventoryItemViewList(temp_list);
    }
    const SortByName = (data_array)=>
    {
        const temp_list = [...data_array].sort((a, b) => {
            if (a.IVItemName < b.IVItemName) return -1; 
            if (a.IVItemName > b.IVItemName) return 1; 
            return 0;                         
        });
        return temp_list;
    }
    const select_process = async(filter_key)=>{

        const temp_list = [];
        if(filter_key === 'all') 
        {
            SetInventoryItemViewList(InventoryItemList)
        }else if(filter_key === 'edible')
        {
            for(let i = 0; i < InventoryItemList.length; i++) 
            {
                if(InventoryItemList[i].IVItemTypeEdibleState === 1)
                    temp_list.push(InventoryItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        } else if(filter_key === 'raw') 
        {
            for(let i = 0; i < InventoryItemList.length; i++) 
            {
                if(InventoryItemList[i].IVItemTypeEdibleState === 0)
                    temp_list.push(InventoryItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        }else if(filter_key === 'hasParent') 
        {
            for(let i = 0; i < InventoryItemList.length; i++) 
            {
                if(InventoryItemList[i].IVItemFollowParent === 1)
                    temp_list.push(InventoryItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        }else if(filter_key === 'noParent') 
        {
            for(let i = 0; i < InventoryItemList.length; i++) 
            {
                if(InventoryItemList[i].IVItemFollowParent === 0)
                    temp_list.push(InventoryItemList[i])
            }
            SetInventoryItemViewList(temp_list)
        }
    }


    useEffect(()=>{
        FetchIvItemData();
    }, [OpenEdibleGoodDataCreator, OpenEditEventoryItem]);
    
    useEffect(()=>{
        FetchIvItemGroup();
    }, [OpenEditInventoryGoodTypeComp, OpenCreateIVGroupTypeComp])




    return (
        <div className={`${classes.edible_in_main_wrapper}`}>


            {/* Inventory Group */}
            {OpenCreateIVGroupTypeComp && <CreateInventoryGoodType OpenModal={SetOpenCreateIVGroupTypeComp} />}
            {OpenEditInventoryGoodTypeComp && <EditInventoryGroup TargetID={InventoryGoodEditID} OpenModal={SetOpenEditInventoryGoodTypeComp}/>}

            {/* Inventory Item */}
            {OpenEdibleGoodDataCreator && <CreateInventoryItem OpenModal={SetOpenNonEdibleGoodDataCreator} />}
            {OpenEditEventoryItem && <EditInventoryItem TargetID={InventoryItemEditID} OpenModal={SetOpenEditEventoryItem}/>}

              
            

            <div className={`${classes.inv_type_wrapper}`}>

                    <div className={`${classes.list_item_product_class}`}>

                        <div className={`${classes.list_item_product_class_list_wrapper_header}`}>
                            <h2>Master<span> Inventory</span> Main Group</h2>
                        </div>

                        <div className={`${classes.list_item_product_class_list_wrapper}`}>
                            {!LoadingGroup &&
                                InventoryItemGroupList.map((item, idx)=>(
                                    <li key={item.IVItemTypeName} onDoubleClick={()=>{FilterIVItemSpecial(item.IvtItemTypeID)}}>
                                        {MutiSelectSubProduct && <input type="checkbox" /> }
                                        <span className={`${classes.list_item_product_class_subcat_name}`}>{item.IVItemTypeName}</span>
                                        <span className={`${classes.list_item_product_class_name}`}>{item.IVItemTypeEdibleState ? 'Edible' : 'Raw Material'}</span>
                                        <span className={`${classes.list_item_product_class_detail}`}>{item.IVItemTypeDescription}</span>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <span onClick={()=>{DAControl.Trigger("Purging Inventory Group", 
                                        `You are about to purge the Inventory Group '${item.IVItemTypeName}'. This action will have significant consequences:
                                        Any active orders or stocked Item will be earsed or aborted!
                                        Remeber "Wharehouse Mapping" !!ALL WILL BE LOST!! This action is IRREVERSIBLE! 
                                        Point is if you don't know what you doing don't f**ing touch this. You still want to`, 
                                        item.IvtItemTypeID,
                                        10,
                                         async (data)=>{
                                            if(!data)
                                                return;

                                                notify.ShowLoad(`Purging Item ${item.IVItemTypeName}.....`, 10);

                                                setTimeout(async()=>{

                                                    try 
                                                    {
                                                        const res = await PurgeIVGroupID(data, auth.token);
                                                        notify.ShowSuccessUpdate(res.Msg, 10);
                                                        SetReloadPage(!ReloadPage);
                                                    }catch(err) 
                                                    {
                                                        notify.ShowErrorUpdate(err.message, 10);
                                                    }

                                                }, 500)


                                         })}} className={`${classes.list_item_product_class_purge}`}> <FontAwesomeIcon icon={faTrash}/> Purge</span>}
                                        
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <span onClick={()=>{HandleEditInventoryGroup(item.IvtItemTypeID)}} className={`${classes.list_item_product_class_more}`}><FontAwesomeIcon icon={faPenNib}/> Manage</span>}
                                    </li>
                                ))
                            }
                            {
                                LoadingGroup && 
                                <LoadingPlaceholder preview_text='Loading Inventory Group'/>
                            }
                                

                        </div>
                        <div className={`${classes.action_button_wrapper_product_class}`}>
                            <button onClick={()=>{SetOpenCreateIVGroupTypeComp(true)}}><FontAwesomeIcon icon={faPlusCircle}/> New</button>
                            <button onClick={()=>{}}><FontAwesomeIcon icon={faCopy}/></button>
                        </div>

                    </div>
            
            </div>

            <div className={`${classes.inv_type_wrapper}`}>

                    <div className={`${classes.list_item_product_class}`}>

                        <div className={`${classes.list_item_product_class_list_wrapper_header}`}>
                            <h2>Master<span> Inventory</span> Main Item</h2>
                        </div>

                        <div className={`${classes.list_item_product_class_list_wrapper}`}>
                            {!LoadingItem &&
                                InventoryItemViewList.map((item, idx)=>(
                                    <li key={item.IVItemName + idx}>
                                        {MutiSelectSubProduct && <input type="checkbox" /> }
                                        <span className={`${classes.list_item_product_class_subcat_name}`}>{item.IVItemName}</span>
                                        <span className={`${classes.list_item_product_class_name}`}>{item.IVItemTypeName }</span>
                                        <span className={`${classes.list_item_product_class_detail}`}>{item.IVItemTypeEdibleState ? 'Edible' : 'Raw Material'}</span>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <span onClick={()=>{DAControl.Trigger("Purging Inventory Item", 
                                        `You are about to purge the Inventory Item '${item.IVItemName}'. This action will have significant consequences:
                                        Any ACTIVE-ORDER, Any LIVE-STOCK Linked with Item will be ERASED and ABORTED! This action is IRREVERSIBLE! 
                                        Point is if you don't know what you doing! Don't f**ing touch this. You still want to`, 
                                        item.IvtID,
                                        10,
                                         async (data)=>{
                                            if(!data)
                                                return;

                                                notify.ShowLoad(`Purging Item ${item.IVItemName}.....`, 10);

                                                setTimeout(async()=>{

                                                    try 
                                                    {
                                                        const res = await PurgeFetchIVItemID(data, auth.token);
                                                        notify.ShowSuccessUpdate(res.Msg, 10);
                                                        SetReloadPage(!ReloadPage);
                                                    }catch(err) 
                                                    {
                                                        notify.ShowErrorUpdate(err.message, 10);
                                                    }

                                                }, 500)


                                         })}} className={`${classes.list_item_product_class_purge}`}> <FontAwesomeIcon icon={faTrash}/> Purge</span>}
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <span onClick={()=>{HandleEditInventoryItem(item.IvtID)}} className={`${classes.list_item_product_class_more}`}><FontAwesomeIcon icon={faPenNib}/> Manage</span>}
                                    </li>
                                ))
                            }
                            {
                                LoadingItem && 
                                <LoadingPlaceholder preview_text='Loading Inventory Core Data'/>
                            }
                                

                        </div>
                        <div className={`${classes.action_button_wrapper_product_class}`}>
                            <button onClick={()=>{SetOpenNonEdibleGoodDataCreator(true)}}><FontAwesomeIcon icon={faPlusCircle}/> New</button>
                            <button onClick={()=>{SetInventoryItemViewList(SortByName(InventoryItemViewList))}}><FontAwesomeIcon icon={faSort}/> Sort</button>
                            {/* <button onClick={()=>{}}><FontAwesomeIcon icon={faCopy}/></button> */}
                            <input className={`${classes.action_button_wrapper_product_class_filter_name_edibale}`} onChange={(e)=>{FilterIvItemByName(e.target.value)}} type="text" placeholder='Filter...' autoComplete='off' />
                            <span className={`${classes.action_button_wrapper_product_class_filter_edibale}`}>
                                <select onChange={(e)=>{select_process(e.target.value)}} id="filterCat" name="filterCat">
                                    <option value="all">All Items</option>
                                    <option  value="edible">Edible Only</option>
                                    <option value="raw">Raw Material Only</option>
                                    <option value="hasParent">With Parent Link</option>
                                    <option value="noParent">With No Parent Link</option>
                                </select>
                            </span>
                            
                        </div>
                        

                    </div>
            </div>

        </div>
    )
}