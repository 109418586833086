import api from "../Util/axiosInstance";



export const CreateEmployeeBenefit = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/create-employee-operation-benefit', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllEmployeeBenefitByEmployeeID = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/query-employee-benefit-by-id', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}