import { faEnvelope, faPhone, faUserTie } from '@fortawesome/free-solid-svg-icons';
import classes from './EmployeeMiniCardMaster.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Et, Us } from 'react-flags-select';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../../../AuthProvider';

export default function EmployeeMiniCardMaster({EmpData, EmpViewController}) 
{

    const auth = useAuth();

    return (
        <div onClick={() => {
            if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectEmployeeProfile) 
            {
                EmpViewController(true, EmpData)
            }
            }} className={`${classes.employee_master_card_main_wrapper}`}>
            <div className={`${classes.employee_master_card_header}`}>
                <div className={`${classes.employee_master_card_header_inner}`}> 
                    <img className={`${classes.employee_master_card_header_image}`} src={getBaseURL() + EmpData.JAKSBE_EmployeeContextProfile.EDOC_Profile.EDOCP_ProfilePicture} alt="" />

                </div>
                <div className={`${classes.employee_master_card_header_inner}`}>
                    <li className={`${classes.employee_name_in_master_Card}`}>{ EmpData.JAKSBE_FullName}  <sub className={`${classes.employee_workspace}`}>[{EmpData.JAKSBE_SyntheticID}]</sub></li>
                    <li><FontAwesomeIcon icon={faUserTie}></FontAwesomeIcon> {EmpData.JAKSBE_EmployeeContextProfile.CTE_Profile?.HRMJPT_Name}</li>
                    <li><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> {EmpData.JAKSBE_PhoneNumber}</li>
                </div> 
            </div>
        </div>
    );
}