import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import classes from './JobPay.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip';
import { useTargetEmployee } from '../../../SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import { ToEthiopianCalander } from '../../../../../../../SharedComp/Utility';
import UIButton from '../../../../../../Components/UIButton/UIButton';
import { ArrowArcRight, ArrowRight, Paperclip, UserCircleMinus, Warning } from '@phosphor-icons/react';
import EmployeeProfilePreview from '../EmployeeProfilePreview/EmployeeProfilePreview';
import { useDAControl } from '../../../../../../../DAControlContext';
import { TransferEmployeeToSelectedBranch, UpdateEmployeeTerminationState } from '../../../../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployeeUpdator';
import { useToast } from '../../../../../../../ToastContext';
import { useAuth } from '../../../../../../../AuthProvider';
import { FetchBranchExcludedCurrent } from '../../../../../../../api';
import { FetchWorkspaceAllExcept } from '../../../../../../../SharedComp/API/Workspace/API_FE_Workspace';
import ExperienceLetterDocument from '../ExperienceLetterDocument/ExperienceLetterDocument';


function formatDuration(days) {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const remainingDays = days % 30;

    const final_year =  ` ${years} year${years !== 1 ? 's' : ''} `;
    const final_month = ` ${months} month${months !== 1 ? 's' : ''} `;
    const final_day =  ` ${remainingDays} day${remainingDays !== 1 ? 's' : ''} `
  
    var result = '';
    if(years > 0) 
    {
        result += final_year;
    }
    if(months > 0) 
    {
        result += final_month;
    }
    if(remainingDays > 0) 
    {
        result += final_day;
    }
  
    return result;
}

export default function JobPay({EmpTerminationRefresh, EmployeeMasterView}) 
{
    const [OpenEmployeeProfile, SetOpenEmployeeProfile] = useState(false);
    const [OpernBranchListForTransfer, SetOpernBranchListForTransfer] = useState(false);
    const [OpenEmployeeXPDoc, SetOpenEmployeeXPDoc] = useState(false);
    const [PerformaceBoostXP, SetPerformaceBoostXP] = useState(false);
    const [OpenXPChoice, SetOpenXPChoice] = useState(false);
    const DAControl = useDAControl();
    const notify = useToast();
    const auth = useAuth();
    const [TransferAvailBranchList, SetTransferAvailBranchList] = useState([]);

    const AvialBranchListRef = useRef();
    const XPChoiceRef = useRef();

    const EmpData = useTargetEmployee();

    function CalcServiceDay(day) 
    {
        const today = new Date();
        const emp_start_day = new Date(day);
        const timeDifference = today.getTime() - emp_start_day.getTime();
        return formatDuration(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
    }

    const getAllBranch = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", EmpData.Core.JAKSBE_ActiveBranchID);
            const main_res = await FetchWorkspaceAllExcept(fd);
            SetTransferAvailBranchList(main_res);
        }catch(err) 
        {
            console.log("Failed to getAllBranch: " + err.message);
        }
    }

    useEffect(()=>{
        getAllBranch();
    })
    function HandleMouseClick(e) 
    {
        if (XPChoiceRef.current && !XPChoiceRef.current.contains(e.target)) {
            SetOpenXPChoice(false);
        }
        if (AvialBranchListRef.current && !AvialBranchListRef.current.contains(e.target)) {
            SetOpernBranchListForTransfer(false);
        }
        
    }

    useEffect(()=>{

        document.addEventListener('mousedown', HandleMouseClick);

        return () => {
            document.removeEventListener('mousedown', HandleMouseClick);
        }; 
    }, [])

    const TerminateEmployee = async()=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", EmpData.Core.JAKSBE_SyntheticID);
            fd.append("approved_by", auth.user.USR_UserAccountID);
            const main_res = await UpdateEmployeeTerminationState(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            EmployeeMasterView(false);
            EmpTerminationRefresh(new Date().getMilliseconds());
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }
    const TransferEmployee = async(target_branch)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", EmpData.Core.JAKSBE_SyntheticID);
            fd.append("transfer_destination", target_branch);
            fd.append("approved_by", auth.user.USR_UserAccountID);
            const main_res = await TransferEmployeeToSelectedBranch(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            EmployeeMasterView(false);
            EmpTerminationRefresh(new Date().getMilliseconds());
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }


    return (
        <div className={`${classes.job_and_pay_master_wrapper}`}>

            {OpenEmployeeXPDoc && <ExperienceLetterDocument Performance={PerformaceBoostXP} Close_CALLBACK={SetOpenEmployeeXPDoc}/>}
            {OpenEmployeeProfile && <EmployeeProfilePreview Close_CALLBACK={SetOpenEmployeeProfile}/>}

            <div className={`${classes.job_and_pay_main_wrapper}`}>
                <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
                <div className={`${classes.job_and_pay_inner_wrapper}`}>

                <li><span className={`${classes.value_tag}`}>Job Department</span> <span className={`${classes.value_value_emp_date}`}>{EmpData.Core.JAKSBE_EmployeeContextProfile.CTE_Profile?.HRMDT_Name}</span></li>
                <li><span className={`${classes.value_tag}`}>Job Position</span> <span className={`${classes.value_value_emp_date}`}>{EmpData.Core.JAKSBE_EmployeeContextProfile.CTE_Profile?.HRMJPT_Name}</span></li>
                <li><span className={`${classes.value_tag}`}>Employement Start Date</span> <span className={`${classes.value_value_emp_date}`}>{ToEthiopianCalander(new Date(EmpData.Core.JAKSBE_EmployeeContextProfile.CTE_Profile.CTEP_StartDate))}</span></li>
                <li><span className={`${classes.value_tag}`}>Service Duration</span> <span className={`${classes.value_value_emp_date}`}>{CalcServiceDay(EmpData.Core.JAKSBE_EmployeeContextProfile.CTE_Profile.CTEP_StartDate)}</span></li>
                <li><span className={`${classes.value_tag}`}>Employee ID</span> <span className={`${classes.value_value_emp_date}`}>{EmpData.Core.JAKSBE_SyntheticID}</span></li>

                </div>

                <div className={`${classes.job_and_pay_inner_wrapper}`}>
                    
                            <li><span className={`${classes.value_tag}`}>Starting Salary </span> 
                                <span className={`${classes.value_value_emp_date}`}>
                                    {EmpData.SalaryArray.length > 0 &&  
                                        (EmpData.SalaryArray[0].SalaryPoint)?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'ETB',
                                        })
                                    }
                                </span>
                            </li>

                            <li><span className={`${classes.value_tag}`}>Current Salary </span>
                                <span className={`${classes.value_value_emp_date}`}>
                                    {EmpData.SalaryArray.length > 0 &&
                                        (EmpData.SalaryArray[EmpData.SalaryArray.length - 1].SalaryPoint)?.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'ETB',
                                        })
                                    }
                                </span>
                            </li>

                    {EmpData.SalaryArray.length > 1 &&  <li><span className={`${classes.value_tag}`}>Avg. Salary Increase %</span> 
                            <span className={`${classes.value_value_emp_date} ${classes.job_and_pay_value_prc}`}>
                                {((EmpData.SalaryArray[EmpData.SalaryArray.length - 1].SalaryPoint / EmpData.SalaryArray[0].SalaryPoint) - 1.0).toFixed(3) > 0.01 ? <span className={`${classes.up_indicate}`}><FontAwesomeIcon icon={faArrowUp}/></span> : ''}{(((EmpData.SalaryArray[EmpData.SalaryArray.length - 1].SalaryPoint / EmpData.SalaryArray[0].SalaryPoint) - 1.0) * 100).toFixed(3)}%
                            </span>
                        </li>}
    
                </div>
            </div>

          {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CriticalEmployeeOperation && <div className={`${classes.employee_termination_resign_transfer_action_wrapper_master}`}>
                <div className={`${classes.employee_termination_resign_transfer_action_wrapper}`}>
                    <UIButton Variant='Primary' Icon={<Paperclip weight='bold'/>} label={`Profile`} onClick={()=>{SetOpenEmployeeProfile(true)}} />
                    <UIButton Variant='Primary' Icon={<Paperclip weight='bold'/>} label={`Experience Letter`} onClick={()=>{SetOpenXPChoice(!OpenXPChoice)}} />
                     <UIButton Variant='Secondary' Icon={<ArrowArcRight weight='bold'/>} label={`Transfer`} onClick={()=>{SetOpernBranchListForTransfer(!OpernBranchListForTransfer)}} />
                    {/*<UIButton Variant='Secondary' Icon={<UserCircleMinus weight='fill'/>} label={`Resign`} onClick={()=>{}} /> */}
                    <UIButton Variant='Danger' Icon={<Warning weight='fill'/>} label={`Terminate`} onClick={()=>{
                        DAControl.Trigger(
                            `Terminate Employee`,
                            `You are about to terminate employee '${EmpData.Core.JAKSBE_FullName}', this will effectively remove employee for payroll sheet. and will suspend user account if any.`,
                            true,
                            8,
                            (data)=>
                            {
                                if(data)
                                {
                                    TerminateEmployee();
                                }
                            }
                        )
                    }} />

                   {OpernBranchListForTransfer && <div ref={AvialBranchListRef} className={`${classes.transfer_employee_branch_list}`}>
                        {
                            TransferAvailBranchList.map((item, idx)=>(
                                <UIButton FillSpace={true} key={idx} Icon={<ArrowRight weight='fill'/>} Variant='Secondary' label={`Transfer to ${item.BranchName}`} onClick={()=>{
                                    DAControl.Trigger(
                                        `Transfer Employee`,
                                        `You are about to trasnfer employee '${EmpData.Core.JAKSBE_FullName}' to '$${item.BranchName}'`,
                                        true,
                                        8,
                                        (data)=>
                                        {
                                            if(data)
                                            {
                                                TransferEmployee(item.BranchID);
                                            }
                                        }
                                    )
                                }}/>
                            ))
                        }
                    </div> }
                    {OpenXPChoice && <div ref={XPChoiceRef} className={`${classes.transfer_employee_branch_list}`}>
                        <UIButton FillSpace={true} Icon={<ArrowRight weight='fill'/>} Variant='Secondary' label={`Typical XP`} onClick={()=>{SetOpenEmployeeXPDoc(true); SetPerformaceBoostXP(false)}} />
                        <UIButton FillSpace={true} Icon={<ArrowRight weight='fill'/>} Variant='Secondary' label={`Boost XP`} onClick={()=>{SetOpenEmployeeXPDoc(true); SetPerformaceBoostXP(true)}} />
                   </div> }
                </div>
            </div>}
        </div>
    )
}