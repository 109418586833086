import classes from './OnProductionLine.module.css'
import XChefProductionStackGroup from './Component/XChefProductionStackGroup/XChefProductionStackGroup'
import { useState } from 'react'
import { useToast } from '../../../ToastContext';
import { useAuth } from '../../../AuthProvider';


import {QueryAssignedChef} from '../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import { useEffect } from 'react';

export default function OnProductionLine()
{


    const [ActiveChefList, SetActiveChefList] = useState([]);
    const notify = useToast();
    const auth = useAuth();


    const QueryActiveChefs = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);

            const main_res = await QueryAssignedChef(fd);
            SetActiveChefList(main_res);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    useEffect(()=>{
        QueryActiveChefs()
    }, [])


    return (
        <div className={`${classes.on_prodcution_line_master_wrapper}`}>

            <div className={`${classes.on_production_main_body_wrapper}`}>

                <div className={`${classes.x_chef_workgroup_wrapper}`}>
                    {/* {
                        ActiveChefList.map((item, idx)=>
                        (
                            <XChefProductionStackGroup TargetData={item} key={idx}/>
                        ))
                    } */}
                </div>

            </div>


            
        </div>
    )
}