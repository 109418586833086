import api from "../Util/axiosInstance";




export const QueryHRPreparedData = async(token)=>
{
    try 
    {
        const response = await api.post('api/payroll/query-hr-prepared-data-for-accounting-package', {} );
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const FinishPayrollPreparation = async(token)=>
{
    try 
    {
        const response = await api.post('api/payroll/finish-payroll-accouting-data-post-entry', {} );
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}