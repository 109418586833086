import classes from './CreateVehicleLink.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import { CreateVHDriverLink } from '../../../../SharedComp/API/GPS/API_FE_WhatsGPS'
 
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList, PrepareDropDownList_CarID} from '../../../../SharedComp/Utility'

//lib
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { Info } from '@phosphor-icons/react'
import { QueryBaseEmployeeByBranch } from '../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee'
import { QueryAllMasterVehicle } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_III'

export default function CreateVehicleLink({VList, OpenController}) 
{

    const auth = useAuth();
    const notify = useToast();

    const [SelectedDriver, SetSelectedDriver] = useState();
    const [CrossBranchEmployeeList, SetCrossBranchEmployeeList] = useState();

    const [SelectedVH, SetSelectedVH] = useState();
    const [ValidVHList, SetValidVHList] = useState([]);

    const [SelectedVHType, SetSelectedVHType] = useState();
    const [AvailVHTypeList, SetAvailVHTypeList] = useState([]);

    const [PlateCode, SetPlateCode] = useState();

    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }

    const QueryAllCrossBranchEmployee = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", -1)
            const main_res = await QueryBaseEmployeeByBranch(fd);
            PrepareDropDownList(main_res, SetCrossBranchEmployeeList);
        }catch(err) 
        {

        }
    }
    const QueryAllVehicleType = async()=>
    {
        try
        {
            const main_res = await QueryAllMasterVehicle(auth.token);
            PrepareDropDownList(main_res, SetAvailVHTypeList);
        }catch(err) 
        {

        }
        
    }

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            
            fd.append("driver_id", SelectedDriver.value);
            fd.append("vh_info", JSON.stringify(SelectedVH.value));
            fd.append("vh_type", SelectedVHType.value);
            fd.append("plate_code", PlateCode);
            fd.append("user_id", auth.user.USR_UserAccountID);

            const res = await CreateVHDriverLink(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    useEffect(()=>{
        PrepareDropDownList_CarID(VList, SetValidVHList);
        QueryAllCrossBranchEmployee();
        QueryAllVehicleType();
    }, [])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Create <span className={`${data_win.data_create_name}`}>Vehicle-Driver Link <span {...({'data-tooltip-id': 'offical_doc2', 'data-tooltip-content': 'This is where you create a link between Employee/Driver and a GPS tracked vehicle, and remember only the vehicle id show up in the drop-down option'})}><Info/></span></span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                                <MasterDataRegInput input={
                                                    <Select 
                                                        value={SelectedDriver}
                                                        onChange={(data)=>{SetSelectedDriver(data)}} 
                                                        options={CrossBranchEmployeeList} 
                                                        placeholder={"Select Driver"} 
                                                        isSearchable={true} 
                                                        styles={Custom_DD_Style} 
                                                        required ={true} /> } />

                                                <MasterDataRegInput input={
                                                    <Select 
                                                        value={SelectedVH}
                                                        onChange={(data)=>{SetSelectedVH(data)}} 
                                                        options={ValidVHList} 
                                                        placeholder={"Select Vehicle"} 
                                                        isSearchable={true} 
                                                        styles={Custom_DD_Style} 
                                                        required ={true} /> } tip={"It is recommended to copy the Car ID from the GPS Track table to ensure accuracy when linking."} />

                                                <MasterDataRegInput input={
                                                    <Select 
                                                        value={SelectedVHType}
                                                        onChange={(data)=>{SetSelectedVHType(data)}} 
                                                        options={AvailVHTypeList} 
                                                        placeholder={"Select Vehicle Type"} 
                                                        isSearchable={true} 
                                                        styles={Custom_DD_Style} 
                                                        required ={true} /> } />


                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetPlateCode(e.target.value)}}
                                                    type="text" 
                                                    placeholder='License Plates'
                                                    required />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Link'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>

    )
}