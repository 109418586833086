import api from "../Util/axiosInstance";



export const QueryAllInventoryItems = async(data)=>
{
    try 
    {
        const response = await api.post('/api/inventory-item/inventory-item-query-all', data);
        const res = await response.data;
        return res;
    
    }catch(err) 
    {
        throw err;
    }
}
//inventory-item-query-all-by-branch
export const QueryAllInventoryItemsByBranch = async(data)=>
{
    try 
    {
        const response = await api.post('/api/inventory-item/inventory-item-query-all-by-branch', data);
        const res = await response.data;
        return res;
    
    }catch(err) 
    {
        throw err;
    }
}
export const QueryInventoryItemByID = async(data)=>
{
    try 
    {
        const response = await api.post('/api/inventory-item/inventory-item-query-by-id', data);
        const res = await response.data;
        return res;
    
    }catch(err) 
    {
        throw err;
    }
}

export const QueryInventoryUOMs = async()=>
{
    try 
    {
        const response = await api.post('/api/inventory-item/query-uom-all');
        const res = await response.data;
        return res;
    
    }catch(err) 
    {
        throw err;
    }
}