import api from "../Util/axiosInstance";




export const FetchOrderableBakeryProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/fetch-bakery-products-orderbale-for-x-branch', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBakeryGroupAll = async(token)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/query-all-bakery-group', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const CreateBakeryGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/create_bakery_group', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBakeryGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/update_bakery_group', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const PurgeBakeryGroupByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/purge_bakery_group_by_id', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////// BAKERY PRODUCTS NOT GROUP
export const CreateBakeryProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/create-bakery-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateBakeryProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/update-bakery-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const FetchBakeryProductAll = async(token)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/fetch-bakery-products-all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeBakeryProductByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/purge-bakery-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBakeryProductByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/fetch-bakery-products-by-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBakeryProductByRecipe = async(data)=>
{
    try  
    {
        const response = await api.post('api/bakery-cluster/fetch-bakery-products-by-recipe', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}