import classes from './ProcessBakeryProductTransfer.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import UIButton from '../../../Components/UIButton/UIButton'

import {CreateNewMerchGroup} from '../../../../SharedComp/API/CoreDataClusters/API_FE_Merchanise'

 

//lib
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { Checks } from '@phosphor-icons/react'
import { ReceiveAndFinishFinalProductTransfer } from '../../../../SharedComp/API/FinalBakeryTransfer/FinalBakeryTransfer'


export default function ProcessBakeryProductTransfer({OpenController, TargetData}) 
{
    const auth = useAuth();
    const notify = useToast();

    const [DataPackageList, SetDataPackageList] = useState([]);
    const [Loading, SetLoading] = useState(false);

    function CloseModal() { OpenController(false); }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("responce_package", TargetData.TWFP_ResponsePackage);
            fd.append("receiver_barnch", TargetData.TWFP_RequestOwnerWorkspace);
            fd.append("sender_branch", TargetData.TWFP_RequestRecieverWorkspace);
            fd.append("transaction_id", TargetData.TWFP_TransactionID);
            fd.append("received_by", auth.user.USR_UserAccountID);
            fd.append("target_request_id", TargetData.TWFP_ID);
           
            const main_res = await ReceiveAndFinishFinalProductTransfer(fd);

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }

    const ParseInputList = ()=>
    {
        SetDataPackageList(JSON.parse(TargetData.TWFP_ResponsePackage));
    }

    useEffect(()=>{
        if(TargetData)
        {
            ParseInputList();
        }
    }, [TargetData])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Receive <span className={`${data_win.data_create_name}`}>Ordered Final Product</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            {
                                                DataPackageList.map((item, idx)=>
                                                (
                                                    <li key={idx}>
                                                        <span>{JSON.parse(item.ProductItem).label}</span>
                                                        <span>{item.Quantity} pcs</span>
                                                        <span className={`${classes.align_item_center}`}><UIButton onClick={()=>{}} Icon={<Checks weight='bold'/>} label={"Approved Request"}/></span>
                                                    </li>
                                                ))
                                            }
                                            {/* <MasterDataRegInput
                                                input={
                                                    <input  
                                                    onChange={(e)=>{SetGroupName(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Group Name'
                                                    required />
                                                }
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <textarea  
                                                    onChange={(e)=>{SetGroupDescritpion(e.target.value)}}
                                                    type="text"
                                                    placeholder='Description'
                                                    />
                                                }
                                            /> */}

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Receive'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}