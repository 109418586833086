import classes from './BranchView.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import axios from 'axios';
import { Custom_DD_Style } from '../../../../SharedComp/Utility'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput';
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { faCirclePlus, faPlusCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import Select from 'react-select'
import NotificationPopUp from '../../../../SharedComp/NotificationPopUp/NotificationPopUp';

import CreateWarehouse from './Component/CreateWarehouse/CreateWarehouse';
import BranchCard from '../Card/BranchCard';
import { CubeFocus, Empty, SlackLogo, Warehouse } from '@phosphor-icons/react';
import UIButton from '../../../Components/UIButton/UIButton';
import { PencilSimple } from '@phosphor-icons/react/dist/ssr';
import UpdateBranchInfo from '../UpdateBranchInfo/UpdateBranchInfo';

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import { useDAControl } from '../../../../DAControlContext';
import { QueryWorkspaceByID, UpdateWorkspaceSuspendState } from '../../../../SharedComp/API/Workspace/API_FE_Workspace';
import { useAuth } from '../../../../AuthProvider';

import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { useToast } from '../../../../ToastContext';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { FetchWarehouseByBranch } from '../../../../SharedComp/API/Warehouse/API_FE_Warehouse';

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;
const mapStyle = { height: '15em', width: "100%" };
export default function BranchView({Close_CALLBACK, BranchData}) 
{

    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl();
    const mapRef = useRef();
    const mapMarkerRef = useRef();
    const [CurrentLoc, SetCurrentLoc] = useState([9.013578790239688, 38.756242350790195])
    const [LeafletReady, SetLeafletReady] = useState(false);

    const [InitStore, SetInitStore] = useState(false); 
    const [StoreList, SetStoreList] = useState([]);
    const [SlackCashGroup, SetSlackCashGroup] = useState(null);
    const [SlackOther, SetSlackOther] = useState(null);
    const [SateliteView, SetSateliteView] = useState(true);

    const [FetchingBranchInfo, SetFetchingBranchInfo] = useState(false);
    const [LocalBranchView, SetLocalBranchView] = useState(null);
    const [RefreshLocalbranch, SetRefreshLocalbranch] = useState(false);
    const [IsBranchActive, SetIsBranchActive] = useState(BranchData.BranchState === "Active");

    const [OpenBranchUpdateDiag, SetOpenBranchUpdateDiag] = useState(false);


      // Notifiction
      const [MsgPkg, SetMsgPkg] = useState({});
      const [OpenMyNoti, SetOpenMyNoti] = useState(false);
      const [RefeshList, SetRefeshList] = useState(false);

      useEffect(()=>
        {
            if (mapRef.current) {
                mapRef.current.setView([CurrentLoc[0], CurrentLoc[1]], mapRef.current.getZoom(), {
                    animate: true, 
                    pan: {
                        duration: 8,
                    }
                });
            }
        }, [CurrentLoc])
      const ParseSlackInfo = ()=>
      {
        if(BranchData.BranchSatelliteCoordinates !== "Not Set") 
        {
            const _arary_temp = BranchData.BranchSatelliteCoordinates.split(",").map(coord => parseFloat(coord.trim()));
           if(_arary_temp.length > 1) 
           {
               SetCurrentLoc(_arary_temp);
           }
        }

        if(BranchData.BranchNotificationConfig) 
        {
            const __slack_info = JSON.parse(BranchData.BranchNotificationConfig);
            SetSlackCashGroup(__slack_info.SlackCashCollectionTarget);
            SetSlackOther(__slack_info.SlackOtherTarget);
        }
      }
      function MsgCallback(res) 
      {
            SetInitStore(false);
          LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
      }
      function LocalMsgTrigger(msg, stat) 
      {
          if(stat === 1) 
          {
              SetRefeshList(!RefeshList);
          }
          SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
          SetOpenMyNoti(true);
      }

      const getBranchStores = async()=>
      {
        try 
        {
            const fd = new FormData();
            fd.append("ID", BranchData.BranchID);
            const main_res = await FetchWarehouseByBranch(fd);
            SetStoreList(main_res);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
      }
      useEffect(()=>{
            getBranchStores();
            ParseSlackInfo();

      }, [])

      const GetBranchDataLocal = async()=>
      {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", BranchData.BranchID);
            const main_res = await QueryWorkspaceByID(fd);
            SetLocalBranchView(main_res[0]);
            SetIsBranchActive(main_res[0].BranchState === "Active")
            SetFetchingBranchInfo(false);
        }catch(err) 
        {
            SetFetchingBranchInfo(false);
        }
      }
      useEffect(()=>{
        SetFetchingBranchInfo(true);
        GetBranchDataLocal();
      }, [RefreshLocalbranch])

      const UpdateSuspendState = async()=>
      {
        notify.ShowLoad("Processing your request....", 10);
        try 
        {
            const fd = new FormData();
            const __new_state = LocalBranchView.BranchState === "Active" ? "Suspend" : "Active";
            fd.append("new_state", __new_state);
            fd.append("target_branch", BranchData.BranchID);
            const main_res = await UpdateWorkspaceSuspendState(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshLocalbranch(!RefreshLocalbranch);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
      }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


        {OpenBranchUpdateDiag && <UpdateBranchInfo Close_CALLBACK={SetOpenBranchUpdateDiag} TargetData={BranchData} />}
        {InitStore && <CreateWarehouse Close_CALLBACK={SetInitStore} WorkspaceCore={BranchData} Res_Callback={MsgCallback}/>}


        <div className={`${data_win.data_main}`}>
        {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Establishment {BranchData.BranchName}</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            {FetchingBranchInfo && <LoadingPlaceholder/> }

            

            <div className={`${classes.branch_action_menu_wrapper}`}>
             {IsBranchActive && <UIButton Variant='Secondary' Icon={<PencilSimple/>} onClick={()=>{SetOpenBranchUpdateDiag(true)}} label={"Edit Branch Profile"}/>}
            {IsBranchActive &&   <UIButton Variant='Danger'  Icon={<Empty/>} onClick={()=>{
                    DAControl.Trigger('Suspend Branch',
                        `You are about to suspend the branch '${BranchData.BranchName}'. This action will freeze all stock in the warehouse, restrict users from logging into their accounts, and disable all interactions with this branch.`,
                        true,
                        8,
                        (data)=>{
                            if(data) 
                            {
                                UpdateSuspendState();
                            }
                        }
                    )
                }} label={"Suspend Branch"}/>}
            {!IsBranchActive &&   <UIButton Icon={<Empty/>} onClick={()=>{
                    DAControl.Trigger('Activate Branch',
                        `You are about to activate the branch '${BranchData.BranchName}'. This action will make the warehouse stock accessible, allow users to log into their accounts, and enable all interactions with this branch.`,
                        true,
                        8,
                        (data)=>{
                            if(data) 
                            {
                                UpdateSuspendState();
                            }
                        }
                    )
                }} label={"Activate Branch"}/>}
            </div>

            <div className={`${classes.data_body}`}>

                <div className={`${classes.head_wrapper}`}>

                    <div className={`${classes.warehouse_wrapper}`}>
                        <div className={`${classes.warehouse_list_wrapper}`}>
                        <span className={`${classes.slack_logo}`}><Warehouse size={30}/>  <span>Warehouses</span></span>

                            {
                                StoreList.map((item, idx)=>(
                                    <li style={{textTransform: 'capitalize'}} key={idx}>  
                                        <span className={`${classes.warehouse_name}`}>{item.WHName}</span>
                                        <span className={`${classes.warehouse_type}`}>{item.IsShared ? 'Shared' : 'Local'}</span>
                                        {/* <span className={`${classes.warehouse_buget}`}>$345,911.00</span> */}
                                    </li>
                                ))
                            }
                            <li onClick={()=>{SetInitStore(true)}} className={`${classes.warehouse_reg_wrap}`} style={{
                                fontSize: '1.0em',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }} >  
                                <FontAwesomeIcon icon={faCirclePlus}></FontAwesomeIcon>
                            </li>
                        </div>
                    </div>
                    <div className={`${classes.warehouse_wrapper}`}>
                        <div className={`${classes.warehouse_list_wrapper}`}>
                            <span className={`${classes.slack_logo}`}><SlackLogo size={30}/>  <span>Slack Integration</span></span>

                                <li>  
                                    <span className={`${classes.warehouse_name}`}>{SlackCashGroup ? '#' + SlackCashGroup : 'NO_SLACK_CHANNEL_PROVIDED_FOR_CASH_COLLECTION'}</span>
                                </li>
                                <li>  
                                    <span className={`${classes.warehouse_name}`}>{SlackOther ? '#' + SlackOther : 'NO_SLACK_CHANNEL_PROVIDED_FOR_OTHER_BRANCH_ACTIVITY'}</span>
                                </li>
                        </div>
                    </div>

                </div>


            </div>
            <div className={`${classes.map_layer_swicth}`}>
                <UIButton Variant='Secondary' Icon={<CubeFocus weight='fill' size={16}/>} label={"Satellite Layer"} onClick={()=>{SetSateliteView(true)}}/>
                <UIButton Variant='Secondary' Icon={<CubeFocus size={16}/>} label={"Base Layer"} onClick={()=>{SetSateliteView(false)}}/>
            </div>
            <div className={`${classes.map_route_view_wrapper}`}>
                
                            {CurrentLoc && <MapContainer 
                                    ref={mapRef}
                                    zoomControl={false} 
                                    attributionControl={false} 
                                    center={CurrentLoc} 
                                    zoom={15} 
                                    style={mapStyle}
                                    whenCreated={mapInstance => { mapRef.current = mapInstance; }}
                                    whenReady={()=>{SetLeafletReady(true)}}
                                    >
                                        {
                                            <>
                                        {SateliteView && <>    <TileLayer
                                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                                attribution='&copy; <a href="https://www.esri.com">Esri</a>'
                                            />
                                            <TileLayer
                                                    url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                                                    attribution='&copy; <a href="https://www.esri.com">Esri</a>'
                                                    opacity={1}
                                            /> </> }
                                        {
                                            !SateliteView && 
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                
                                            />
                                        }



                                                  
                                            <Marker position={[CurrentLoc[0], CurrentLoc[1]]}>
                                                <Popup>
                                                   JAK AND SONS PLC <br /> {BranchData.BranchName} Establishment 
                                                </Popup>
                                            </Marker>
                                                
                                            </>
                                        }
                            
                            </MapContainer> }
            </div>

        </div>
    </div>
    );
}