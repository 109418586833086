import { ArrowCircleDownRight, ArrowDown, HeadCircuit, SealQuestion, SquareHalf } from '@phosphor-icons/react'
import UIButton from '../../../Components/UIButton/UIButton'
import classes from './StockInventoryTransaction.module.css'
import { useState } from 'react'
import RegisterStock from '../../Component/RegisterStock/RegisterStock'
import DirectStockMovementRequest from '../DirectStockMovementRequest/DirectStockMovementRequest'
import { useAuth } from '../../../../AuthProvider'

import StockTransactionDetailWrapper from './StockTransactionDetailWrapper/StockTransactionDetailWrapper'


export default function StockInventoryTransaction() 
{
    const auth = useAuth();

    const [OpenReceivePurchasedStockModal, SetOpenReceivePurchasedStockModal] = useState(false);
    const [OpenDirectStockMovmentRequestModal, SetOpenDirectStockMovmentRequestModal] = useState(false);
    const [CurrentFilterMethod, SetCurrentFilterMethod] = useState("Default");
    const [DataRefreshFlag, SetDataRefreshFlag] = useState(new Date().getMilliseconds());




    return ( 
        <div className={`${classes.stock_in_out_transaction_master_wrapper}`}>

                {OpenReceivePurchasedStockModal && 
                <RegisterStock Refresh_CALLBACK={SetDataRefreshFlag} OpenController={SetOpenReceivePurchasedStockModal} /> }

                {OpenDirectStockMovmentRequestModal && 
                <DirectStockMovementRequest OpenController={SetOpenDirectStockMovmentRequestModal}/>}

            <div className={`${classes.stock_in_out_header_action_bar_wrapper}`}>
                <div className={`${classes.stock_inventory_action_left_wrapper}`}>
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ReceiveStock && <UIButton Variant='Secondary' label={"Receive Purchased Inventory"} Icon={<ArrowCircleDownRight weight='bold'/>} onClick={()=>{SetOpenReceivePurchasedStockModal(true)}} /> }
                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateDirectStock &&  <UIButton Variant='Secondary' label={"Request Direct Stock"} Icon={<SealQuestion weight='fill'/>} onClick={()=>{SetOpenDirectStockMovmentRequestModal(true)}} /> }
                </div>
                <div className={`${classes.stock_inventory_action_left_wrapper}`}>
                    {/* <UIButton label={"Smart Summerize"} Icon={<HeadCircuit weight='bold'/>} onClick={()=>{SetCurrentFilterMethod("AISUM")}} /> */}
                    <UIButton Variant='Secondary' label={"Summerize"} Icon={<SquareHalf weight='bold'/>} onClick={()=>{SetCurrentFilterMethod("SUM")}} />
                    <UIButton Variant='Secondary' label={"Group by Input & Out"} Icon={<SquareHalf weight='bold'/>} onClick={()=>{SetCurrentFilterMethod("GIO")}} />
                    <UIButton Variant='Secondary' label={"Sort By Latest"} Icon={<SquareHalf weight='bold'/>} onClick={()=>{SetCurrentFilterMethod("LATEST")}} />
                </div>

            </div>

            <div className={`${classes.stock_in_out_transaction_inner_wrapper}`}>

                <StockTransactionDetailWrapper DataRefresh={DataRefreshFlag} FilterMethod={CurrentFilterMethod}/>
                
            </div>

        </div>
    )
}