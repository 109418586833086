import { getBaseURL } from "./SharedComp/BaseUrlConfig";
import axios from 'axios'
import api from "./SharedComp/API/Util/axiosInstance";



// //////////////////////////////////// MASTER PRODUCT GROUP

export const FetchProductLineMasterGroup = async (id, token)=>
    {
        try 
        {
            const response = await fetch(getBaseURL() + 'product-line/fetch-prod-cat', {
                method: 'POST',
                headers: {
                   'Content-Type': 'application/json',
                },
                body: JSON.stringify({ID: id})
            });
            const res = await response.json();
            return res;
    
        }catch(err) 
        {
            throw err;
        }
}

export const UpdateProductLineMasterGroup = async (data, token) => {
    try 
    {
        const response = await api.post('api/product-line/update-prod-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeProductLineMasterGroup = async (data, token) => {
    try 
    {
        const response = await api.post('api/product-line/purge-prod-cat', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
        
    }catch(err) 
    {
        throw err;
    }
}


// //////////////////////////// YAM-MIX
export const PushYamMix = async(data, token) => {
    try 
    {
        const response = await api.post('api/master-data/add-yam-mix', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateYamMix = async(data, token) => {
    try 
    {
        const response = await api.post('api/master-data/update-yam-mix', data);
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const FetchYamMixID = async(id, token) => {
    try 
    {
        const response = await api.post('api/master-data/get-mix-id', {ID: id} );
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const FetchYamMixAll = async (token) =>{
    try 
    {
        const response = await axios.get(getBaseURL() + 'master-data/get-mix', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchYamMixOrderableBranch = async (id, token) =>{
    try 
    { 
        const response = await api.post('api/master-data/get-mix-orderable', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const FetchYamMixIngID = async(id, token) => {
    try 
    {
        const response = await api.post('api/master-data/get-mix-ing-id', {ID: id} );
        const res = await response.data;

        if(res.MyStateCode === -1 || res.MyStateCode === 0)
        {
            throw new Error(res.Msg);
        } else 
        {
            return res;
        }
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeYamMixID = async(id, token) => {
    try 
    {
        const response = await api.post('api/master-data/purg_prod_mix', {ID: id} );
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////////////// INVENTORY ITEM GROUP
export const FetchIVGroupAll = async(token)=>{
    try 
    {
        const response = await api.post('api/master-data/get-iv-type', {} );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchIVGroupEdibleState = async(state, token)=>{
    try 
    {
        const response = await api.post('api/master-data/get-iv-type-id', {IS_EDIBLE: state} );
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchIVGroupID = async(id, token)=>{
    try 
    {
        const response = await api.post('api/master-data/get-iv-type-id', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PushIVGroup = async(data)=>{
    try 
    {
        const response = await api.post('api/master-data/add-iv-type', data);
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateIVGroupID = async(data)=>{
    try 
    {
        const response = await api.post('api/master-data/update-iv-type', data);
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeIVGroupID = async(id, token)=>{
    try 
    {
        const response = await api.post('api/master-data/purge-iv-type-id', {ID: id} );
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

// //////////////////////////////////// INVENTORY ITEM
export const  FetchIVItemAll = async(token)=>{
    try 
    {
        const response = await api.post('api/master-data/get-iv-item', {} );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }

}
export const  FetchIVItemID = async(id, token)=>{
    try 
    {
        const response = await api.post('api/master-data/get-iv-item-id', {ID: id} );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const  FetchIVItemEdibaleState = async(state, token)=>{
}
export const  UpdateFetchIVItemID = async(data)=>{
    try 
    {
        const response = await api.post('api/master-data/update-iv-item-id', data);
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const  PurgeFetchIVItemID = async(id, token)=>{
    try 
    {
        const response = await api.post('api/master-data/purge-iv-item-id', {ID: id} );
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PushIVItem = async(data)=>{
    try 
    {
        const response = await api.post('api/master-data/add-iv-item', data);
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////////////////// INVENTORY STOCK
export const FetchInventoryStockID = async(id, token)=>{
    try 
    {
        const response = await api.post('api/inventory/get-inv-stock-id', {ID: id} );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchInventoryStockBranchID = async(branch_id, token)=>
{
    try 
    {
        const response = await api.post('api/inventory/get-inv-stock-branch', {ID: branch_id} );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PushInventoryStock = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory/add-inv-stock', data);
        const res = await response.data;

        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// ////////////////////////////////////// STOCK INVOICE

export const FetchInventoryInvoice = async(data, token) =>{
    try 
    {
        const response = await api.post('api/inventory/query-invoice-read', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchInventoryInvoiceUID = async(uid, token) =>{
    try 
    {
        const response = await api.post('api/inventory/query-invoice-read-uid', {UID: uid} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// ////////////////////////////////////////////// PO
export const FetchPOLinkedRawMatID = async(id, token)=>{
    try 
    {
        const response = await api.post('api/po/get-po-mix-mat-id', id );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ///////////////////////////////////////// Warehouse
export const FetchWarehouseItem = async(data)=>{
    try 
    { 
        const response = await api.post('api/warehouse/get-inv-stock-branch-warehouse', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchBranchWarehouse = async(branch_id, token)=>{
    try 
    { 
        const response = await api.post('api/warehouse/get-branch-warehouse', {ID: branch_id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////////////////////// BRANCH
export const FetchBranchID = async(id, token) =>
{

}
export const FetchBranchExcludedCurrent = async(id, token) =>
{
    try 
    { 
        const response = await api.post('api/workspace/get-workspace-exc', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

/////////////////////////////////////////// Stock Request
export const CreateStockTransferRequest = async(data)=>
{
    try 
    { 
        const response = await api.post('api/stock-transfer/create-transfer-request-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const TerminateStockTransferRequest = async(data)=>
{
    try 
    { 
        const response = await api.post('api/stock-transfer/terminate-transfer-request-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UnlockSuspendedStockTransferRequest = async(data)=>
{
    try 
    { 
        const response = await api.post('api/stock-transfer/unlock-suspended-transfer-request-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1)
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestDispatched = async(id, token)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-dispatched-request', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestIncoming = async(id, token)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-incoming-request', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestDispatchedDetail = async(id, token)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-dispatched-request-detail', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchTransferRequestPayload = async(id, token)=>
{ 
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-request-payload-v2', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CheckEligibleBalanceState = async(data)=>
{
    try 
    {
        const response = await api.post('api/stock-transfer/check-balance-eligible', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessStockRequest = async(data)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/process-stock-tr-request', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessRequestReceiveTransfer = async(data)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/process-request-receive-transfer', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchStockTransferRequestByID = async(id, token)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-stock-transfer-id', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchStockTransferRequestReadBackHash = async(id, token)=>{
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-stock-transfer-read-back-hash', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchRequestSenderUser = async(id, token) =>
{
    try 
    {
        const response = await api.post('api/stock-transfer/fetch-request-sender-user-id', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchRequestHandlerUser = async(id, token) =>
{
        try 
        {
            const response = await api.post('api/stock-transfer/fetch-request-handler-user-id', {ID: id} );
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}



// Production Order
export const FetchMixIngredientJoined = async(id, token)=>{
    try 
    {
        const response = await api.post('api/po/fetch-ingredient-joind', {MixID: id} );
        const res = await response.data;
        return res;
    }catch(err)
    {
        throw err;
    }
}
export const CreateProductionOrder = async(data)=>
{
    try 
    {
        const response = await api.post('api/po/process-po-reg', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// Purge this
export const FetchSavedProductionProductOrder = async(hash_id, token)=>{
    try 
    {
        const response = await api.post('api/po/fetch-ordered-product-item', {ID: hash_id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchFinalProductSaved = async(id, token)=>{
    try 
    {
        const response = await api.post('api/po/fetch-saved-final-prod', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchFinalProductByRecipe = async(id, token)=>{
    try 
    {
        const response = await api.post('api/po/fetch-final-prod-by-recipe', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMixSaved = async(id, token)=>{
    try 
    {
        const response = await api.post('api/po/fetch-saved-mix', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchProductionOrderReqMaterial = async(id, token)=>{
    try 
    {
        const response = await api.post('api/po/fetch-prod-order-mat', {ID: id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// Pendning
export const FetchProductionOrderBranchPending = async(branch_id, token)=>
{
    try 
    {
        const response = await api.post('api/po/fetch-production-order-branch-pending', {ID: branch_id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// Purge this 
export const FetchProductionOrderBranchStockedOut = async(branch_id, token)=>
{
    try 
    {
        const response = await api.post('api/po/fetch-production-order-branch-stocked-out', {ID: branch_id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// Purge this
export const ApprovePoRequest = async(data)=>{
    try 
    {
        const response = await api.post('api/po/approve-po-request', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
// Purge this
export const RejectPoRequest = async(data)=>{
    try 
    {
        const response = await api.post('api/po/reject-po-request', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const ProcessProductedPoStack = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-log/porcess-producted-po-stack', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        { 
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ProcessProductedPoStackV2 = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-log/porcess-producted-po-stack-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        { 
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
} 
export const CloseInDateProductionLog = async(data)=>
{
    try 
    {
        const response = await api.post('api/production-log/close-in-date-production-log-v2', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const RefreshProdcutionOrder = async(data)=>
{
    try  
    {
        const response = await api.post('api/production-log/refresh-production-order', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

