import classes from './SingleWarehouseEntryView.module.css'
import UIButton from '../../../../../../Components/UIButton/UIButton'
import { ArrowBendDoubleUpRight, DotsThree, Empty, PencilSimple, Warning } from '@phosphor-icons/react'
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useAuth } from '../../../../../../../AuthProvider';
import { useDAControl } from '../../../../../../../DAControlContext';
import { ForceInventoryStockPurge, UnmountInventoryAmountForce } from '../../../../../../../SharedComp/API/InventoryStock/API_FE_InventoryStock';
import { useToast } from '../../../../../../../ToastContext';

export default function SingleWarehouseEntryView({SelectItems, Data, DataIndex, EditInv_CALLBACK, IsChecked}) 
{

    const [Checked, SetChecked] = useState(false);
    const formatter = new Intl.NumberFormat('en-US');
    const [OpenOptionMenu, SetOpenOptionMenu] = useState(false);
    const ModalRef = useRef();
    const auth = useAuth();
    const notify = useToast();

    const DAControl = useDAControl();

    const HandleCheckMark = (value, id)=>
    {
        SetChecked(value);
        SelectItems(value, id);
    }

    useEffect(()=>
    {
        const handleClickOutside = (event) => {
            if (ModalRef.current && !ModalRef.current.contains(event.target)) {
                SetOpenOptionMenu(false);
            }
          };
      
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [])


    return (
        <div onClick={()=>{HandleCheckMark(!Checked, Data.ItemID)}} className={`${classes.single_warehouse_entry_view_master_wrapper}`}>
            <span style={{padding: '0px 10px 0px 1px'}}><input onChange={()=>{}} checked={IsChecked(Data.ItemID)} type='checkbox'/></span>
            <li className={`${classes.filter_item_list}`}>{DataIndex + 1}. {Data.IVItemName}</li>
            <li className={`${classes.filter_item_amount}`}>{formatter.format(Data.AvailQuantity)} {Data.UomDescription}</li>
            <li className={`${classes.filter_item_price_unit}`}>-</li>
            <li className={`${classes.inventory_critical_actions}`}>
                    <span onClick={()=>{SetOpenOptionMenu(!OpenOptionMenu)}}><DotsThree size={16} weight='bold'/></span>
            </li>
            
          {OpenOptionMenu &&   <div ref={ModalRef} className={`${classes.single_warehouse_entry_view_hidden_until}`}>
                
          {auth.GlobalConfig && auth.GlobalConfig?.GC_AllowStockToBeModified &&    <UIButton 
                    Variant='Secondary' 
                    Icon={<PencilSimple weight='bold'/>} 
                    label={"Modify Inventory"} 
                    onClick={()=>{
                        DAControl.Trigger("Edit Inventory Item", 
                        `You are about to edit inventory item '${Data.IVItemName}' This action it not recommended, since it will possibly corrupt the inventory report system.`,
                        true,
                        10,
                        (data)=>
                        {
                            if(data)
                            {
                                EditInv_CALLBACK(Data);
                            }
                        })
                    }} />}
                {auth.GlobalConfig && auth.GlobalConfig?.GC_AllowStockToBeUnmounted && <UIButton 
                    Variant='Danger' 
                    Icon={<Warning weight='fill'/>} 
                    label={"Unmount Inventory"}
                    onClick={()=>{
                        DAControl.Trigger("Unmount Inventory Item", 
                        `You are about to unmount inventory item '${Data.IVItemName}' This action will unmount the avalable inventory, in another work it will change it into 0.`,
                        true,
                        10,
                        async (data)=>
                        {
                            if(data)
                            {
                                notify.ShowLoad("Processing your request...", 10);
                                try 
                                {
                                    const fd = new FormData();
                                    fd.append("target_stock_id", Data.IvsID);
                                    const _res = await UnmountInventoryAmountForce(fd);
                                    notify.ShowSuccessUpdate(_res.Msg, 10);
                                }catch(err) 
                                {
                                    notify.ShowErrorUpdate(err.message, 10);
                                }
                            }
                        })
                    }} /> }
                   {auth.GlobalConfig && auth.GlobalConfig?.GC_AllowStockToBePurged && <UIButton 
                    Variant='Danger' 
                    Icon={<Warning weight='fill'/>} 
                    label={"Force Inventory Purge"}
                    onClick={()=>{
                        DAControl.Trigger("Force Purge Inventory Item", 
                        `You are about to remove inventory item '${Data.IVItemName}' This action will remove the record of this entry this might couse data corruption.`,
                        true,
                        10,
                        async (data)=>
                        {
                            if(data)
                            {
                                notify.ShowLoad("Processing your request...", 10);
                                try 
                                {
                                    const fd = new FormData();
                                    fd.append("target_stock_id", Data.IvsID);
                                    const _res = await ForceInventoryStockPurge(fd);
                                    notify.ShowSuccessUpdate(_res.Msg, 10);
                                }catch(err) 
                                {
                                    notify.ShowErrorUpdate(err.message, 10);
                                }
                            }
                        })
                    }} /> }
            </div>}

        </div>
    )
}