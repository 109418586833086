import classes from './ExperienceLetterDocument.module.css'
import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useAuth } from '../../../../../../../AuthProvider'
import PaperHeader from '../../../../../../../SharedComp/PaperHeader/PaperHeader';
import PaperHash from '../../../../../../../SharedComp/PaperHash/PaperHash';
import { XCircle } from '@phosphor-icons/react';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTargetEmployee } from '../../../SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import { ToEthiopianCalander } from '../../../../../../../SharedComp/Utility';
import { getBaseURL } from '../../../../../../../SharedComp/BaseUrlConfig';
import { toWords } from 'number-to-words';
import LetterHead from '../../../../../../../SharedComp/LetterHead/LetterHead';

function formatDuration(days) {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const remainingDays = days % 30;

    const final_year =  ` ${years} year${years !== 1 ? 's' : ''} `;
    const final_month = ` ${months} month${months !== 1 ? 's' : ''} `;
    const final_day =  ` ${remainingDays} day${remainingDays !== 1 ? 's' : ''} `
  
    var result = '';
    if(years > 0) 
    {
        result += final_year;
    }
    if(months > 0) 
    {
        result += final_month;
    }
    if(remainingDays > 0) 
    {
        result += final_day;
    }
  
    return result;
}
function CalcServiceDay(day) 
{
    const today = new Date();
    const emp_start_day = new Date(day);
    const timeDifference = today.getTime() - emp_start_day.getTime();
    return formatDuration(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
}
export default function ExperienceLetterDocument({Close_CALLBACK, Performance = false}) 
{
    const auth = useAuth();
    const print_ref = useRef();
    const sharedEmp = useTargetEmployee();
    const CurrentEmployeeSalary = sharedEmp.SalaryArray[sharedEmp.SalaryArray.length - 1].SalaryPoint;

    const PrintDoc = useReactToPrint({
        documentTitle: "Experience_Letter_Certificate" + "_" + sharedEmp.Core.JAKSBE_FullName,
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })


    return (
        <div className={`${data_win.main_data_creator_wrapper_emp}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Employee <span className={`${data_win.data_create_name}`}>Document Profile</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><XCircle weight='fill'/></button>
            </div>

        <div className={`${classes.data_preview_wrapper}`}>

            <div className={`${classes.inner_preview_data_wrapper}`}>
                <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                    {/* <PaperHeader/> */}
                    
                    <div className={`${classes.print_doc_non_repeat_content}`}>
                        <LetterHead/>
                        <PaperHash text={'HR official document'}/>
                        {/* <div className={`${classes.meta_data_wrapper}`}> 
                            <h2>Experience Letter Certificate</h2>
                            <h2></h2>
                        </div> */}

                        
                        
                        <div className={`${classes.main_paper_data_wrapper}`}>

                            <div className={`${classes.letter_header}`}>
                                <div className={`${classes.letter_issue_date_recipiant}`}>
                                    <li><span className={`${classes.employee_personal_info_label}`}>Ref: </span> <span className={`${classes.employee_personal_info_value}`}> J&S/HR/_________/______</span></li>
                                    <li><span className={`${classes.employee_personal_info_label}`}>Date: </span> <span className={`${classes.employee_personal_info_value}`}> {new Date().toDateString()}</span></li>
                                </div>

                                <div className={`${classes.letter_init_header}`}>
                                        <h2>To whom it may concern</h2>
                                </div>
                            </div>

                            <div className={`${classes.letter_body_xp_doc}`}>
                                <p>This is to certify that <strong>{sharedEmp.Core.JAKSBE_FullName}</strong> was employed with <strong>JAK AND SONS TRADING PLC</strong> In the following Job positions. </p> 

                                <li style={{marginLeft: '20px', padding: '3px'}}>From <strong>{new Date(sharedEmp.CompanyToEmployeeProfile.CTEP_StartDate).toDateString()}</strong> upto <strong>Now ({new Date().toDateString()})</strong> as an <strong>{sharedEmp.CompanyToEmployeeProfile.HRMJPT_Name}</strong> with monthly salary <strong>{(CurrentEmployeeSalary)?.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'ETB',
                                    })}  </strong> // <span style={{textTransform: 'capitalize'}}>{toWords(CurrentEmployeeSalary)}</span>
                                </li>
                                <br />
                                <p>Income tax and pension contribution are being deducted from this salary and paid to the concerned government offices.</p>
                                <br />
                              {Performance && <p> During their time with us, <strong>{sharedEmp.Core.JAKSBE_FullName.split(' ')[0]}</strong> demonstrated a high level of professionalism, dedication, and expertise. They consistently contributed to the success of the team and completed tasks with great efficiency.
                                <br /><br />
                                <strong>{sharedEmp.Core.JAKSBE_FullName.split(' ')[0]}</strong> displayed strong communication and interpersonal skills, working well with both colleagues and clients. They were proactive in addressing challenges and often took the initiative to suggest improvements that benefitted the team and the company as a whole.
                                <br /><br />We have been pleased with <strong>{sharedEmp.Core.JAKSBE_FullName.split(' ')[0]}</strong>'s performance, and they have been a valuable asset to our organization. We wish them the best in their future endeavors and are confident that they will continue to succeed in all their future professional pursuits.
                                <br />
                                </p>}

                                <p>
                                    Should you require any additional information, please feel free to contact us. <br />
                                    This certificate of experience does not serve as a termination letter. 
                                </p>
                                <br />
                                <p>
                                    Best regards
                                </p>
                            </div>
                            <div className={`${classes.letter_xp_footer}`}>
                                <li>{auth.user?.USR_FirstName} {auth.user?.USR_LastName}</li>
                                <li>{auth.CompanyToEmployeeProfile?.HRMJPT_Name}</li>
                            </div>
                           
                            
                        </div>

                        {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                        <div className={`${classes.final_note_po_stock_out}`}>
                            {/* <li>This document must have signatures to be considered valid.</li> */}
                        </div>

                    </div>
                </div>
            </div>



            </div> 


            <div className={`${classes.action_button_wrapper}`}>
                <button onClick={()=>{PrintDoc()}} className={`${classes.button_print}`}>Print Document</button>
                <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>
            </div>

            </div>
        </div>
    )
}