import api from "../Util/axiosInstance";




export const CreateHRPassDataReadyEntry = async(data)=>
{
    try 
    {
        const response = await api.post('api/payroll/create-hr-data-ready-payroll-data', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}