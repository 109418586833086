import classes from './WarehouseCard.module.css'


const WarehouseCard = ({Data, AnimDelay, HandleClick}) => {
    const style = {
        '--animation-delay': AnimDelay
    };

    return (
        <div className={`${classes.store_card_main_wrapper}`} style={style}>
                <div className={`${classes.store_card_header}`}>
                    <h2>{Data[0].BranchName}</h2>
                </div>

            <div className={`${classes.body_wrapper}`}>
                <div className={`${classes.store_card_main_wrap}`}>
                    {
                        Data.map((item, idx)=>(
                            <li onClick={(e)=>{HandleClick(item); }} key={idx}> 
                                <span className={`${classes.warehouse_name}`}>
                                    {item.WHName}
                                </span>
                                <span className={`${classes.warehouse_code}`}> {item.WHCode}</span>
                            </li>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}


export default WarehouseCard;