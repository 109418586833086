import { faBan, faPenNib, faPlus, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import classes from './ProductionMix.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import NotificationPopUp from '../../../SharedComp/NotificationPopUp/NotificationPopUp'
// Mix data

import MixData from './Component/MixData/MixData'
import MixDataEdit from './Component/MixDataEdit/MixDataEdit'

import axios from 'axios';
import { useDAControl } from '../../../DAControlContext'
import { useToast } from '../../../ToastContext'
import {PurgeYamMixID} from '../../../api'
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig'
import UIButton from '../../Components/UIButton/UIButton'
import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy'
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { useAuth } from '../../../AuthProvider'
import { QueryAllYamBakeryMix } from '../../../SharedComp/API/YamMix/API_FE_YamMixManage'

export default function ProductionMix() 
{ 
    const [ReloadQuery, SetReloadQuery] = useState(false);
    const [OpenMixData, SetOpenMixData] = useState(false);
    const [OpenMixDataEdit, SetOpenMixDataEdit] = useState(false);
    const [openNoti, SetOpenNoti] = useState(false);
    const [MsgPkg, SetMsgPkg] = useState({});
    const [DBMixData, SetDBMixData] = useState([]);
    const [DBMixDataOriginal, SetDBMixDataOriginal] = useState([]);
    const [MixEditIdx, SetMixEditIdx] = useState();

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();
    // Loading
    const [LoadingData, SetLoadingData] = useState(false);




    const FetchAllMix = async() => 
    {
        SetLoadingData(true);
        try 
        {
            const main_res = await QueryAllYamBakeryMix();
            SetDBMixData(main_res);
            SetDBMixDataOriginal(main_res);
            SetLoadingData(false);
        }catch(err) 
        {
            SetLoadingData(false);
        }
    }
    function HandleOpenMixData() 
    {
        SetOpenMixData(true);
    }

    function HandleMixEdit(id) 
    {
        SetMixEditIdx(id);
        SetOpenMixDataEdit(true);
    }

    function MsgTrigger(res)  
    {
        SetMsgPkg({
            Key: Math.random() * 1000,
            Msg: res.data.Msg,
            State:  res.data.MyStateCode
        })
        SetOpenNoti(true);
    }

    useEffect(()=>{
        FetchAllMix();
    }, [OpenMixData, OpenMixDataEdit, ReloadQuery])


    const FilyerChemeicalByName = async(_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < DBMixDataOriginal.length; i++) 
        {
            const check = DBMixDataOriginal[i].MixName.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(DBMixDataOriginal[i])
        }

        SetDBMixData(temp_list);
    }

    return (
        <div className={`${classes.production_mix_main_wrapper}`}>

            {openNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State}/>}
            {OpenMixData && <MixData OpenController={SetOpenMixData} MsgTrigger={MsgTrigger}/> }
            {OpenMixDataEdit && <MixDataEdit EditIdx={MixEditIdx} OpenController={SetOpenMixDataEdit} MsgTrigger={MsgTrigger}/> }

            <div className={`${classes.mix_box_wrapper}`}>
                <DataViewTableProxy 
                    IsEmpty={DBMixData.length === 0 && !LoadingData}
                    OnFilter={FilyerChemeicalByName}
                    OpenDataCreate={HandleOpenMixData}
                    HeaderName={
                        <div>
                            <h2>Master <b>Bakery Chemical </b> Manager</h2>
                        </div>
                    }

                    DataArray={
                        <div>

                            {
                                DBMixData.map((item, idx)=>(
                                    <li key={item.MixName + idx}>
                                        <span>{item.MixName}</span>
                                        <span className={`${classes.mix_ing_count}`}> {item.IngredientsCount} Ingredients </span> 
                                        {/* <span>{item.CMMG_Name}</span> */}
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger("Purge Product Recipe", 
                                                `You are about to purge the Production Mix '${item.MixName}'. This action will trigger severe consequences:
                                                Any active Product Item That are linked with this Recipe will be Hidden!This action is IRREVERSIBLE!`, 
                                                item.MID,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;
                    
                                                        notify.ShowLoad(`Purging Item ${item.MixName}.....`, 10);
                    
                                                        try 
                                                        {
                                                            const res = await PurgeYamMixID(data, auth.token);
                                                            notify.ShowSuccessUpdate(res.Msg, 10);
                                                            SetReloadQuery(!ReloadQuery);
                                                        }catch(err) 
                                                        {
                                                            notify.ShowErrorUpdate(err.message, 10);
                                                        }
                    
                    
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandleMixEdit(item.MID)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingData && 
                                <LoadingPlaceholder/>
                            }
                            
                        </div>
                    }
                />
            </div>

            
        </div>
    )
}