import api from "../Util/axiosInstance";





export const CreateEmployeeCredit = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/employee-operation-create-credit', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryEmployeeCreditByEmployeeID = async(data)=>
{
    try 
    {
        const response = await api.post('api/employee-operation/query-employee-operation-credit-by-employee-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}