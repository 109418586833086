import { io } from "socket.io-client";
import { getBaseURL } from "./SharedComp/BaseUrlConfig";



let socket = null;


export const getBaseSocket = () => 
{
    if (!socket) {
        socket = io(getBaseURL(), {
            autoConnect: true,
            reconnectionAttempts: 3, 
            reconnectionDelay: 5000, 
            reconnectionDelayMax: 30000,
            withCredentials: true
        });

        socket.on('connect', () => {
            
        }); 

        socket.on('disconnect', (reason) => {
            console.log('Socket disconnected. Reason:', reason);
        });

        socket.on('connect_error', (err) => {
            console.error('Connection error:', err.message);
        });
    }

    return socket;
}