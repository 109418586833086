import classes from './ProductionStackOrderGroupWrapper.module.css'
import ProductionStackOrderSingleCardWrapper from '../ProductionStackOrderSingleCardWrapper/ProductionStackOrderSingleCardWrapper'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {FetchSavedProductionProductOrder, RefreshProdcutionOrder} from '../../../../api'
import { useEffect } from 'react';
import { faBan, faCheckToSlot, faCircleChevronRight, faCirclePause } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'react-tooltip';

import ProductionStackProcessor from '../ProductionStackProcessor/ProductionStackProcessor'
import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { ToEthiopianCalander } from '../../../../SharedComp/Utility'
import { Warning, WarningDiamond } from '@phosphor-icons/react'
import UIButton from '../../../Components/UIButton/UIButton'
import { useDAControl } from '../../../../DAControlContext'
import { ReturnProductionOrderToStock } from '../../../../SharedComp/API/ProductionStack/API_FE_ProductionStack'
import {QueryMasterProcutionOrderByIdWithOwnerV2, QueryMasterProductionOrderRemainingBatchProductsByID, ReturnToInventoryPartialMasterProductionOrder} from '../../../../SharedComp/API/Production/API_MasterProductionV2'
export default function ProductionStackOrderGroupWrapper({DataPoint, DataRefresh_Callback}) 
{
    const [OrderProducts, SetOrderProducts] = useState([]);
    const [OpenPOProcessor, SetOpenPOProcessor] = useState(false);
    const [SelectedDataList, SetSelectedDataList] = useState(new Map());
    const [BatchMode, SetBatchMode] = useState(false);
    const [DataRefersh, SetDataRefersh] = useState(false);
    const [Loading, SetLoading] = useState(false);
    const [DateDiffCount, SetDateDiffCount] = useState(0);
    const DAControl = useDAControl();
    const [PurgingActiveOrder, SetPurgingActiveOrder] = useState(false);

    const [OrderOwner, SetOrderOwner] = useState();

    const notify = useToast();
    const auth = useAuth();

    const FetchOrderProducts = async()=>{
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_item_id", DataPoint.MPOM_ID);
            const main_res = await QueryMasterProductionOrderRemainingBatchProductsByID(fd);
            SetOrderProducts(main_res.OrderProductDetail);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }
    const SingleProductCallback = async(data, is_mix, batch_count, group_id, unique_id)=>
    {
        const __name = is_mix ? data.MixName : data.CMBP_Name;

        if(!BatchMode) 
        {
            const __new_map = new Map();
            __new_map.set(__name, {UniqueID: unique_id, GroupColID: group_id, IsMix: is_mix, Pkg: data, CycleCount: batch_count});
            SetSelectedDataList(__new_map);
            SetOpenPOProcessor(true);
        }
        const existing_data = new Map(SelectedDataList);

        if(existing_data.has(__name)) 
        {
            existing_data.delete(__name);
        }else 
        {
            existing_data.set(__name, {UniqueID: unique_id, GroupColID: group_id, IsMix: is_mix, Pkg: data, CycleCount: batch_count});
        }

        SetSelectedDataList(existing_data);
    }
    const ProcessBatchSelection = async()=>{
        SetOpenPOProcessor(true);
    }
    const ApplyBatchVoid = async(val)=>
    {
        SetBatchMode(val);
        if(!val) 
        {
            SetSelectedDataList(new Map());
        }
    }
    const CheckOrderPackageState = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("order_hash", DataPoint.PO_Hash);
            fd.append("branch_id", DataPoint.PO_SourceBranch);

            const res = await RefreshProdcutionOrder(fd);

            if(res.CleanHouse) 
            {
                // Global Parent Refresh
                if(res.MyStateCode === -1) 
                {
                    notify.ShowError(res.Msg);
                } else 
                {
                    notify.ShowSuccess(res.Msg);
                }
                DataRefresh_Callback(DataPoint.PO_Hash);
            } else if(res.CleanCount > 0) 
            {
                // Local Refresh
                SetDataRefersh(!DataRefersh);
            }

            if(res.MyStateCode === -1) 
            {
                notify.ShowError(res.Msg);
            }

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const CleanCloseCallback = async(val)=>
    {
        SetBatchMode(false);
        SetOpenPOProcessor(false);
        SetSelectedDataList(new Map());;
        // CheckOrderPackageState();
    }
    const RefershView = ()=>
    {
        CleanCloseCallback();
        SetDataRefersh(new Date().getMilliseconds());
    }

    const ReturnStockToInventory = async()=>
    {
        SetPurgingActiveOrder(true);
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("return_package_data", JSON.stringify(OrderProducts));
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("action_taken_by", auth.user.USR_UserAccountID);
            const main_res = await ReturnProductionOrderToStock(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetPurgingActiveOrder(false);
            DataRefresh_Callback();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetPurgingActiveOrder(false);
        }
    }

    const PerfromPartialInventoryReturn = async()=>
    {
        SetLoading(true);
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("item_list", JSON.stringify(Array.from(SelectedDataList.entries())));
            fd.append("order_col_id", DataPoint.MPOM_ID);
            fd.append("action_requested_by", auth.user.USR_UserAccountID);
            const main_res = await ReturnToInventoryPartialMasterProductionOrder(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            DataRefresh_Callback();
            SetLoading(false);
        }catch(err)  
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }
    }

    const CalculateDateDifference = ()=>
    {
        const start = new Date(DataPoint.MPOM_OrderDateTime);
        const end = new Date();
    
    
        const differenceInMilliseconds = Math.abs(end - start);
    
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    
        SetDateDiffCount(differenceInDays);
    }

    const FetchOrderOwner = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_item_id", DataPoint.MPOM_ID);
            const main_res = await QueryMasterProcutionOrderByIdWithOwnerV2(fd);
            if(main_res.length <= 0)
            {
                throw new Error("Cannot determine order owner!");
            }
            SetOrderOwner(main_res[0]);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    useEffect(()=>{
        FetchOrderOwner();
        FetchOrderProducts();
    }, [DataRefersh])
    
    useEffect(()=>{
        if(DataPoint) 
        {
            FetchOrderProducts();
            CalculateDateDifference();
            
        }
    }, [DataPoint])


    return (
        <div className={`${classes.prodcution_stack_order_group_wrapper_main}`}>
            <Tooltip className={`${classes.tool_quick}`} id="offical_doc" place="top" />
            {OpenPOProcessor && <ProductionStackProcessor MasterDataRefresh_Callback={DataRefresh_Callback} DataQuickRefresh={RefershView} Target={DataPoint} DataList={SelectedDataList} Close_CALLBACK={CleanCloseCallback}/>}

            <div className={`${classes.pro_stck_group_pkg_wrapper}`}>
                {
                    DataPoint && DataPoint.MPOM_OrderSuspendState !== "Open" && 
                    <div className={`${classes.group_block_interaction}`}>
                        <div className={`${classes.block_show_message}`}>
                            <h2>Action Required</h2>
                            <h6>If this process takes longer than expected, please reach out to your supervisor for assistance.</h6>
                            <li><Warning weight='fill'/></li>
                        </div>
                    </div>
                }
              {DataPoint &&  <div className={`${classes.order_info_header}`}>

                    <div>
                        <li><span>Order Initiated By: </span> <span className={`${classes.order_main_value_data}`}> {OrderOwner?.USR_FirstName} {OrderOwner?.USR_LastName}</span> </li>
                        <li><span>Shift Target: </span> <span className={`${classes.order_main_value_data}`}>{DataPoint.MPOM_Shift}</span> </li>
                    </div>
                   {!Loading && DateDiffCount > 1  && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ViewExpiredOrder && <div className={`${classes.expired_stock_order}`}>
                        <li><Warning weight='fill'/></li>
                    </div>}
                    <div>
                        <li><span>Ordered Date: </span> <span className={`${classes.order_main_value_data}`}>{ToEthiopianCalander(new Date(DataPoint.MPOM_OrderDateTime))}</span></li>
                        <li><span>Clock: </span> <span className={`${classes.order_main_value_data}`}>{new Date(DataPoint.MPOM_OrderDateTime).toLocaleTimeString()}</span></li>
                    </div>

                </div>}

                <div className={`${classes.production_stack_group_wrapper}`}>

                        <div className={`${classes.prodution_stock_single_card_list_wrapper}`}>

                            {!Loading &&
                                OrderProducts.map((item, idx)=>(
                                    <ProductionStackOrderSingleCardWrapper 
                                            key={idx + DataPoint.MPOM_OrderID + item.OP_ItemID + item.OP_BatchCount + item.OP_BatchRemain} 
                                            PackageList={SelectedDataList} 
                                            SingleCard={SingleProductCallback} 
                                            Target={item}
                                            OrderGroupID={DataPoint.MPOM_ID}
                                            />
                                ))
                            }

                        </div>
                        {Loading && <LoadingPlaceholder placeholder_height={10}/>}

                    
                </div>

                <div className={`${classes.production_stack_group_footer}`}>
                    <div className={`${classes.footer_wrap_inner}`}>
                        <button onClick={()=>{ApplyBatchVoid(!BatchMode)}}><span><FontAwesomeIcon icon={BatchMode ? faCirclePause : faCheckToSlot}></FontAwesomeIcon></span><span>{BatchMode ? "Void" : "Select"}</span></button>
                        {BatchMode && SelectedDataList.size > 0 &&  <button onClick={()=>{ProcessBatchSelection()}} className={`${classes.batch_process}`}><span><FontAwesomeIcon icon={faCircleChevronRight}></FontAwesomeIcon></span><span>Process</span></button>}
                        {BatchMode && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ReverseOrderToStock  && SelectedDataList.size > 0 &&  
                                    <button className={`${classes.batch_process}`} onClick={()=>{
                                        DAControl.Trigger(
                                            'Return Stock To Inventory',
                                            "The Selected Product flour including the chemical will be return to the inventory, this will create return request to the store manager. This order group will be suspended until you get response from store manager. after approval the remaining will be processed as usual.",
                                            true,
                                            5,
                                            (data)=>{
                                                if(data) 
                                                {
                                                    PerfromPartialInventoryReturn();
                                                }
                                            }
                                        )
                                    }} ><span><FontAwesomeIcon icon={faCircleChevronRight}></FontAwesomeIcon></span><span>Return Inventory</span></button>}
                    </div>
                    <div className={`${classes.action_taker}`}>
                        {/* {
                           !Loading && DateDiffCount > 0 && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ReverseOrderToStock && <UIButton  Variant={PurgingActiveOrder ? 'Disabled' : 'Danger'} Icon={<Warning weight='bold'/>} label={"Reverse Stock"} onClick={()=>{
                                DAControl.Trigger(
                                    'Return Stock to Inventory',
                                    "You are about to return the order to stock. This will result in the remaining batch being split into separate components and added to your inventory. If you wish to retain these items, you will need to place a new order",
                                    true,
                                    8,
                                    (data)=>{
                                        if(data) 
                                        {
                                            ReturnStockToInventory();
                                        }
                                    }
                                )
                            }}/>
                        } */}
                       {/* {OrderProducts.length === 0 && 
                                    <button  
                                        onClick={()=>{CheckOrderPackageState()}} 
                                        {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': "You shoudn't not see this button, If you are you have permission or things go south somewhere!"})} 
                                        className={`${classes.fail_save_purge}`}>
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span><span>Purge</span>
                                    </button>
                        } */}
                    </div>
                </div>
            </div>
            

            

        </div>
    )
}