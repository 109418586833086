import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './navbarStyle.module.css'
import { faAngleDown, faBars, faBell, faEye, faGear, faHouse, faHouseChimney, faHouseChimneyUser, faMessage } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import logo from '../../../asset/images/favicon.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DeviceInfo from 'react-native-device-info';
import { getBaseURL } from '../../BaseUrlConfig';

import { PrepareDropDownList } from '../../Utility';

import Select from 'react-select'
import { useAuth } from '../../../AuthProvider';

import {SetPrimaryColor, KeyColorSchemeBuffer, PaintColorSchemeBuffer} from '../../ColorScheme'

import RightSetting from './RightSetting/RightSetting';
import RightMessagePanal from './RightMessagePanal/RightMessagePanal';

import { AppWindow, Bell, CaretDown, ChatCenteredText, Desktop, Gear, GearSix, Monitor, Moon, SignOut, SquaresFour, Sun, UserCircle, UsersThree } from '@phosphor-icons/react';

import {QueryUnseenMessage} from '../../../SharedComp/API/Message/API_FE_Message'

import MasterSetting from './MasterSetting/MasterSetting';
import { useRef } from 'react';
import { Tooltip } from 'react-tooltip'

export default function NavBar()
{

    const auth = useAuth();
    const nav = useNavigate();
    
    const [OpenMasterSetting, SetOpenMasterSetting] = useState(false);
    const [SessionDuration, SetSessionDuration] = useState();
    const [ShowSignOutModal, SetShowSignOutModal] = useState(false);
    const [showUserOpt, setShowUserOpt] = useState(false);
    const [UnseenMessageList, SetUnseenMessageList] = useState([]);

    const [UsrLocation, SetUsrLocation] = useState({lat: 0.0, lon: 0.0});
    const [IpAddress, SetIpAddress] = useState('');
    const [BasOS, SetBasOS] = useState();
    const [DeviceName, SetDeviceName] = useState();

    const [OpenSideBar, SetOpenSideBar] = useState(false);
    const [OpenMessage, SetOpenMessage] = useState(false);

    const [DDate, SetDDate] = useState([]);

    const [UsrLocationError, SetUsrLocationError] = useState(null);
    const RightSettingRef = useRef();

    useEffect(()=>{
        DeviceInfo.getBaseOs().then((baseOs) => {
            SetBasOS(baseOs);
          });
        DeviceInfo.getDeviceName().then((deviceName) => {

                SetDeviceName(deviceName);
        });
        
        if(auth.IsOnline)
        {
            if(navigator.geolocation) 
                {
                    navigator.geolocation.getCurrentPosition(
                        (position)=>{
                            SetUsrLocation({
                                lat: position.coords.latitude,
                                lon: position.coords.longitude
                            });
                        },
                        (error)=>{
                            SetUsrLocationError(error.message);
                        }
                    )
                }
        }

      

    }, [])

    function GetSessionDuration() 
    {
        const sessionStartTime = localStorage.getItem('sessionStart');
        if (sessionStartTime) {
            const startTime = new Date(sessionStartTime);
            const currentTime = new Date();
            const sessionDurationInSeconds = Math.floor((currentTime - startTime) / 1000);

            const hours = Math.floor(sessionDurationInSeconds / 3600);
            const minutes = Math.floor((sessionDurationInSeconds % 3600) / 60);
            const seconds = sessionDurationInSeconds % 60;

            SetSessionDuration(`${hours}h ${minutes}m ${seconds}s`);
        }

        return "%%UNAUTHORIZED USER%%";
    }

    const FetchUnseenMessage = async ()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("user_id", auth.user.USR_UserAccountID);
            const main_res = await QueryUnseenMessage(fd);
            SetUnseenMessageList(main_res);
        }catch(err) 
        {

        }
    }

    function HandleMouseClick(e) 
    {
        if (RightSettingRef.current && !RightSettingRef.current.contains(e.target)) {
            SetOpenSideBar(false);
          }
    }

    useEffect(()=>{
        GetSessionDuration();
        FetchUnseenMessage();

        document.addEventListener('mousedown', HandleMouseClick);

        return () => {
            document.removeEventListener('mousedown', HandleMouseClick);
        }; 
    }, [])


    function UpdateSessionInfo(time) 
    {

        const fd = new FormData();
        fd.append('location', JSON.stringify(UsrLocation));
        fd.append('usrIP', IpAddress);
        fd.append('device', BasOS);
        fd.append('device_name', DeviceName);
        fd.append('session_duration', time);
        fd.append('usrID', auth.user.USR_UserAccountID);
        axios.post(getBaseURL() + 'api/update_session', fd)
        .then(res => {
        })
    }



   
    return (
        <div className={`${classes.navbar_main_wrapper}`}>
            <Tooltip className={`${classes.tool_quick}`} id="nav-bar-unimportaant-tip-no-one-care" place="bottom" />

           {OpenMasterSetting && <MasterSetting OpenController={SetOpenMasterSetting}/>} 
            <RightSetting PackageRef={RightSettingRef} Close_CALLBACK={SetOpenSideBar} IsOpen={OpenSideBar}/>
            <RightMessagePanal IsOpen={OpenMessage} Close_CALLBACK={SetOpenMessage} MessageList={UnseenMessageList} />

            <div className={`${classes.logo_menu_icon}`}>

                <div className={`${classes.logo_img}`}>
                    <img style={{filter: auth.DarkMode ? 'invert(0%)' : 'invert(100%)'}} src={logo}  />
                </div>

                <div className={`${classes.logo_txt}`}>
                    <h3>JAK AND SONS <span>[Beta v1.0.6]</span></h3>
                    <li>{auth.activeWorkShopName}</li>
                </div>
            </div>

           

            <div className={`${classes.right_side_cont}`}>
                <div className={`${classes.noti_seeting}`}>

                    <div className={`${classes.noti_wrapper}`}>
                        <button onClick={()=>{SetOpenMessage(!OpenMessage)}}>
                            {UnseenMessageList.length > 0 && <span className={`${classes.notify_dot}`}></span>}
                            <span><Bell weight='bold'/></span>
                        </button>
                        <button onClick={()=>{auth.DarkMode ? auth.QuickThemeLight() : auth.QuickThemeDark()}}>
                            <span> {auth.DarkMode ? <Sun weight='bold'/> : <Moon weight='bold'/>} </span>
                        </button>
                        <button style={{color: `${auth.DesktopXPMode ? 'var(--color-theme)' : ''}`}} onClick={()=>{auth.SetDesktopXPMode(!auth.DesktopXPMode)}}>
                            <span {...({'data-tooltip-id': 'nav-bar-unimportaant-tip-no-one-care', 'data-tooltip-content': `Switch To ${auth.DesktopXPMode ? 'Standard' : 'Desktop'} Experience`})}> 
                                <Monitor weight={`${auth.DesktopXPMode ? 'fill' : 'bold'}`}/> 
                            </span>
                        </button>

                    </div>
                    </div>

                    <div tabIndex={0} onBlur={()=>{SetShowSignOutModal(false)}} onClick={()=>{SetShowSignOutModal(true)}} className={`${classes.user_info}`}>
                        <div className={`${classes.user_pic}`}>
                            <img src={getBaseURL() + auth.EmployeeDocumentProfile?.EDOCP_ProfilePicture} alt="pic" />
                        </div>
                        <div className={`${classes.user_name}`}>
                            <li className={`${classes.name}`}>{auth.user?.USR_FirstName} {auth.user?.USR_LastName}</li>
                            <li {...({'data-tooltip-id': 'nav-bar-unimportaant-tip-no-one-care', 'data-tooltip-content': auth.CompanyToEmployeeProfile?.HRMJPT_Description})}  className={`${classes.job}`}>{auth.CompanyToEmployeeProfile?.HRMJPT_Name}</li>
                        </div>
                    <div className={`${classes.option_expose}`}>
                        <span><CaretDown weight='bold'/></span>
                    </div>

                        <div className={`${ShowSignOutModal ? classes.account_log_in_info : classes.account_log_in_info_hide}`}>

                            <div className={`${classes.user_detail_rep}`}>
                                <div className={`${classes.user_pic}`}>
                                    <img src={getBaseURL() + auth.EmployeeDocumentProfile?.EDOCP_ProfilePicture} alt="pic" />
                                </div>
                                <div className={`${classes.user_name}`}>
                                    <li className={`${classes.name}`}>{auth.user?.USR_FirstName} {auth.user?.USR_LastName}</li>
                                    <li className={`${classes.job}`}>{auth.user?.USR_Email}</li>
                                </div>
                            </div>

                            <button> <span className={`${classes.menu_icon}`}><UserCircle/> </span> <span> Profile </span> </button>
                            <button onClick={()=>{SetOpenSideBar(!OpenSideBar);}}> <span className={`${classes.menu_icon}`}><Gear/> </span> <span> Setting </span> </button>
                           {auth.user?.RoleID === 23 &&  <button onClick={()=>{SetOpenMasterSetting(!OpenMasterSetting);}}> <span className={`${classes.menu_icon}`}><GearSix/> </span> <span> System Configuration </span> </button>}
                            <button> <span className={`${classes.menu_icon}`}><ChatCenteredText/> </span> <span> Message </span> </button>
                            <button> <span className={`${classes.menu_icon}`}><UsersThree/> </span> <span> Support </span> </button>
                            <button onClick={(e)=>{auth.logOut()}}> <span className={`${classes.menu_icon}`}><SignOut/> </span> <span> Sign Out </span> </button>


                        </div>
                    </div>
            </div>
             

            

        </div>
    );
}