export const MasterMenu =  new Map([
    ['Control Panel', {
        ValidRouteGroup: false, 
        Icon: 'faChartLine',
        Config: new Map([
            ["/dashboard_master", 
                { HasAccess: false, Route: "/dashboard_master", Label: "Dashboard", Icon: 'faHashnode' }]
        ])
    }],
    ['Account Management', {
        ValidRouteGroup: true,
        Icon: 'faUserCheck',
        Config: new Map([
            ["/user-account", 
                { HasAccess: true, Route: "/user-account", Label: "Account Controls", Icon: 'faUsersRectangle' }],
            ["/user-role", 
                { HasAccess: true, Route: "/user-role", Label: "Access & Permissions", Icon: 'faUsersGear' }]
        ])
    }],


    ['Employee Manager', {
        ValidRouteGroup: false,
        Icon: 'faUsers',
        Config: new Map([
            ["/standard-employee", 
                { HasAccess: false, Route: "/standard-employee", Label: "Standard Employee", Icon: 'faUserTie' }],
            ["/cross-branch-employee", 
                { HasAccess: false, Route: "/cross-branch-employee", Label: "Cross-Branch Employee", Icon: 'faUserTag' }]
            // ["/employee-termination-archive", 
            //     { HasAccess: false, Route: "/employee-termination-archive", Label: "Termination Archive" }],
            // ["/employee-master-report", 
            //     { HasAccess: false, Route: "/employee-master-reportt", Label: "Master Report" }]
        ])
    }],


    ['Payroll Pipeline', {
        ValidRouteGroup: false,
        Icon: 'faDollarSign',
        Config: new Map([
            ["/master-payroll-accounting-pass", 
                { HasAccess: false, Route: "/master-accounting-hr-pass", Label: "Payroll Accouting Pass", Icon: 'faFileInvoiceDollar' }],
            ["/master-payroll-hr-pass", 
                { HasAccess: false, Route: "/master-payroll-hr-pass", Label: "Payroll HR Pass", Icon: 'faReceipt' }],
            ["/master-payroll-data-feed-pass", 
                { HasAccess: false, Route: "/master-payroll-data-feed-pass", Label: "Payroll Data feed", Icon: 'faFileInvoice' }],
            ["/payroll-scheduler", 
                { HasAccess: false, Route: "/payroll-scheduler", Label: "Payroll Scheduler", Icon: 'faStopwatch' }]
            // ["/master-payroll-archive", 
            //     { HasAccess: false, Route: "/master-payroll-archive", Label: "Payroll Archive" }],
        ])
    }],


    ['Device Integration', {
        ValidRouteGroup: false,
        Icon: 'faLink',
        Config: new Map([
            ["/whats-gps-vehicle-integration", 
                { HasAccess: false, Route: "/whats-gps-vehicle-integration", Label: "Vehicle", Icon: 'faLocationArrow' }]
        ])
    }],


    ['Stock Management', {
        ValidRouteGroup: false,
        Icon: 'faBoxesStacked',
        Config: new Map([
            ["/stock-in", 
                { HasAccess: false, Route: "/stock-in", Label: "Livestock", Icon: 'faLayerGroup' }],
            ["/inventory-stock-transaction", 
                { HasAccess: false, Route: "/inventory-stock-transaction", Label: "Inventory Stock Transaction", Icon: 'faArrowsTurnToDots'  }],
            ["/stock-warehouse", 
                { HasAccess: false, Route: "/stock-warehouse", Label: "Warehouse", Icon: 'faWarehouse' }],
            ["/stock-track-request", 
                { HasAccess: false, Route: "/stock-track-request", Label: "Track Request", Icon: 'faHandPointUp' }],
            ["/stock-out", 
                { HasAccess: false, Route: "/stock-out", Label: "Production Stock Request", Icon: 'faCodePullRequest' }],
            ["/manage-direct-stock-request", 
                { HasAccess: false, Route: "/manage-direct-stock-request", Label: "Manage Direct Stock Request", Icon: 'faBarsProgress'  }],
            ["/approved-stock-archive", 
                { HasAccess: false, Route: "/approved-stock-archive", Label: "Approved Stock Archive", Icon: 'faFolder'  }]
            // ["/manage-invoices", 
            //     { HasAccess: false, Route: "/manage-invoices", Label: "Manage Invoice" }],
            // ["/stock-audit-trails", 
            //     { HasAccess: false, Route: "/stock-audit-trails", Label: "Audit Trails" }]
        ])
    }],


    ['Pitty Cash Management', {
        ValidRouteGroup: false,
        Icon: 'faArrowDownUpAcrossLine',
        Config: new Map([
            ["/pitty-cash-finance-level", 
                { HasAccess: false, Route: "/pitty-cash-finance-level", Label: "Manage Petty Cash", Icon: 'faBuildingColumns' }],
            ["/pitty-cash-branch-level", 
                { HasAccess: false, Route: "/pitty-cash-branch-level", Label: "My Petty Cash", Icon: 'faMoneyBillTransfer' }]
        ])
    }],


    ['Production', {
        ValidRouteGroup: false,
        Icon: 'faGears',
        Config: new Map([
            ["/production-order", 
                { HasAccess: false, Route: "/production-order", Label: "Production Order", Icon: 'faFileCircleQuestion' }],
            ["/prod-stack", 
                { HasAccess: false, Route: "/prod-stack", Label: "Production Stack", Icon: 'faBoxesStacked' }],
            ["/stock-card", 
                { HasAccess: false, Route: "/stock-card", Label: "Production Log", Icon: 'faClipboardCheck' }],
            ["/production-transfer-archive", 
                { HasAccess: false, Route: "/production-transfer-archive", Label: "Production Transfer Archive", Icon: 'faBox' }]
        ])
    }],

    ['Reception', {
        ValidRouteGroup: false,
        Icon: 'faCircleUser',
        Config: new Map([
            ["/front-desk", 
                { HasAccess: false, Route: "/front-desk", Label: "Front Desk", Icon: 'faUniversalAccess' }],
            ["/sales-and-cash-summary-archive", 
                { HasAccess: false, Route: "/sales-and-cash-summary-archive", Label: "Sales & Cash Summary Archive", Icon: 'faMagnifyingGlassChart' }]
        ])
    }],


    ['Manage Orders', {
        ValidRouteGroup: false,
        Icon: 'faLayerGroup',
        Config: new Map([
            ["/kitchen-supply-order", 
                { HasAccess: false, Route: "/kitchen-supply-order", Label: "Kitchen Supply Order", Icon: 'faDiagramNext' }],
            ["/local-front-order", 
                { HasAccess: false, Route: "/local-front-order", Label: "Local Front Order", Icon: 'faUserGroup' }]
        ])
    }],

    ['Essentials Shelf', {
        ValidRouteGroup: false, 
        Icon: 'faCubesStacked',
        Config: new Map([
            ["/pastry-shop-shelfs", 
                { HasAccess: false, Route: "/pastry-shop-shelfs", Label: "Pastry Shelves", Icon: 'faSitemap' }],
            ["/merch-shop-shelfs", 
                { HasAccess: false, Route: "/merch-shop-shelfs", Label: "Merchandise Shelves", Icon: 'faSitemap' }],
            ["/manage-merch-invoice", 
                { HasAccess: false, Route: "/manage-merch-invoice", Label: "Manage Merchandise Invoice", Icon: 'faReceipt'}] 
        ])
    }],

    ['Kitchen', {
        ValidRouteGroup: false,
        Icon: 'faPizzaSlice',
        Config: new Map([
            ["/production-control-center", 
                { HasAccess: false, Route: "/production-control-center", Label: "Product Control Center", Icon: 'faSeedling' }],
            ["/chefs-stock-request", 
                { HasAccess: false, Route: "/chefs-stock-request", Label: "Chef's Stock Request", Icon: 'faQuestionCircle' }],
            ["/on-production-line", 
                { HasAccess: false, Route: "/on-production-line", Label: "Production Line", Icon: 'faCartFlatbed' }],
            ["/manage-workspace-order-distribution", 
                { HasAccess: false, Route: "/manage-workspace-order-distribution", Label: "Manage Distribution", Icon: 'faTruckRampBox' }],
            ["/distribution-archive", 
                { HasAccess: false, Route: "/distribution-archive", Label: "Distribution Archive", Icon: 'faFolder' }]
        ])
    }],

    ['Awaiting Dispatch', { 
        ValidRouteGroup: false, 
        Icon: 'faSignsPost',
        Config: new Map([
            ["/production-awaiting-dispatch", 
                { HasAccess: false, Route: "/production-awaiting-dispatch", Label: "Dispatch Active Order", Icon: 'faSignHanging' }]
        ])
    }],


    ['Chefs', {
        ValidRouteGroup: false,
        Icon: 'faUtensils',
        Config: new Map([
            ["/chefs_dashboard", 
                { HasAccess: false, Route: "/chefs_dashboard", Label: "Chefs Dashboard", Icon: 'faList' }],
            ["/chef-lineup", 
                { HasAccess: false, Route: "/chef-lineup", Label: "Chef Lineup", Icon: 'faPersonCircleQuestion' }]
            // ["/chefs_performance", 
            //     { HasAccess: false, Route: "/chefs_performance", Label: "Performance" }]
        ])
    }],


    // ['Social Hub', {
    //     ValidRouteGroup: true, 
    //     Icon: 'faHandshakeAngle',
    //     Config: new Map([
    //         ["/ask-discuss-talk", 
    //             { HasAccess: true, Route: "/ask-discuss-talk", Label: "Social Hub" }],
    //         ["/system-updates", 
    //             { HasAccess: true, Route: "/system-updates", Label: "System Updates" }],
    //     ])
    // }],


    ['Product Line', {
        ValidRouteGroup: false,
        Icon: 'faCogs',
        Config: new Map([
            ["/master-product-line", 
                { HasAccess: false, Route: "/master-product-line", Label: "Products Clusters", Icon: 'faCheckDouble' }],
            ["/production-master-data-entry", 
                { HasAccess: false, Route: "/production-master-data-entry", Label: "Manage Bakery Products", Icon: 'faBreadSlice' }],
            ["/inventory-merchandise", 
                { HasAccess: false, Route: "/inventory-merchandise", Label: "Manage Merchandise Products", Icon: 'faCube' }],
            ["/ck-production", 
                { HasAccess: false, Route: "/ck-production", Label: "Manage Pastry Products", Icon: 'faCakeCandles' }],
            ["/manage-hot-drink", 
                { HasAccess: false, Route: "/manage-hot-drink", Label: "Manage Beverage Products", Icon: 'faMugSaucer' }]
            
        ])
    }],

    ['Recipe Managment', {
        ValidRouteGroup: false,
        Icon: 'faFlaskVial',
        Config: new Map([
            ["/yam-mix-master-data-entry", 
                { HasAccess: false, Route: "/yam-mix-master-data-entry", Label: "Manage Bakery Recipe", Icon: 'faFlaskVial' }],
            ["/kitchen-variants-master-data-entry", 
                { HasAccess: false, Route: "/kitchen-variants-master-data-entry", Label: "Manage Pastry Recipe", Icon: 'faFlaskVial' }]
        ])
    }],


    ['JAK-YAM', {
        ValidRouteGroup: false,
        Icon: 'faBuildingColumns',
        Config: new Map([
            ["/company-branch-manager", 
                { HasAccess: false, Route: "/company-branch-manager", Label: "Branch Management", Icon: 'faBuildingWheat' }],
            ["/operation-master-data-cluster-I", 
                { HasAccess: false, Route: "/operation-master-data-cluster-I", Label: "Operation Data Cluster I", Icon: 'faFolder' }],
            ["/operation-master-data-cluster-II", 
                { HasAccess: false, Route: "/operation-master-data-cluster-II", Label: "Operation Data Cluster II", Icon: 'faFolder' }],
            ["/operation-master-data-cluster-III", 
                { HasAccess: false, Route: "/operation-master-data-cluster-III", Label: "Operation Data Cluster III", Icon: 'faFolder' }],
            ["/inventory-master-data-entry", 
                { HasAccess: false, Route: "/inventory-master-data-entry", Label: "Inventory /MD", Icon: 'faFolder' }],
            ["/vendors", 
                { HasAccess: false, Route: "/vendors", Label: "Merchandise Vendors", Icon: 'faFolder' }]
        ])
    }]]);