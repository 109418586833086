import classes from './SpecialOrderDetailView.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { useRef } from 'react'
import { useState } from 'react'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//lib
import { useAuth } from '../../../../../../AuthProvider'
import { useToast } from '../../../../../../ToastContext'

const human_readable_format = (val)=> 
{
    if(!val) { return ''; }
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
export default function SpecialOrderDetailView({OpenController, IsProdCard = false, DV_TargetData}) 
{

    const auth = useAuth();
    const notify = useToast();

    const CloseModal = () => {OpenController(false)};


    

    useEffect(()=>{
        if(DV_TargetData) 
        {
            if(!DV_TargetData.KPPO_SpecialOrderDetail) 
            {
                notify.ShowError("Invalid data parameter!");
                CloseModal();
            }
        }
    }, [DV_TargetData])


    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Special Order <span className={`${data_win.data_create_name}`}>{DV_TargetData.CKP_ItemName}</span> Preview
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form action="POST">
                                    <div className={`${classes.form_wrapper}`}> 

                                        <div className={`${classes.master_prod_type_config_wrapper}`}>

                                            <div className={`${classes.deatil_patch_wrapper}`}>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Order Received By</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.USR_FirstName} {DV_TargetData?.USR_LastName}</span></li>
                                                {/* <li><span className={`${classes.tag_so_detail_label}`}>Customer Name</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData?.PROCI_Name === '' ? "Unknown" : DV_TargetData?.PROCI_Name}</span></li> */}
                                                <li><span className={`${classes.tag_so_detail_label}`}>Cashier Note</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData.KPPO_SpecialOrderDetail?.SO_OperatorNote === ''? "Unknown" : DV_TargetData.KPPO_SpecialOrderDetail?.SO_OperatorNote}</span></li>
                                            </div>
                                            
                                            <div className={`${classes.deatil_patch_wrapper}`}>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Scripture</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData.KPPO_SpecialOrderDetail?.SO_Scripture === ''? "Unknown" : DV_TargetData.KPPO_SpecialOrderDetail?.SO_Scripture}</span></li>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Custom Product Color</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData.KPPO_SpecialOrderDetail?.SO_ColorChoice === ''? "Unknown" : DV_TargetData.KPPO_SpecialOrderDetail?.SO_ColorChoice}</span></li>
                                                <li><span className={`${classes.tag_so_detail_label}`}>Custom Kg Request</span> <span className={`${classes.tag_so_detail_value}`}>{DV_TargetData.KPPO_SpecialOrderDetail?.SO_FlourSpec === null ? "Unknown" : human_readable_format(DV_TargetData.KPPO_SpecialOrderDetail?.SO_FlourSpec)} KG</span></li>
                                            </div>
                                            

                                                
                                        </div>
                                            
                                    </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_save}`}>Okay</button>
                                </div>
                            </form>
                </div>
            </div>

        </div>
    )
}