import classes from './DesktopXP.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { 
    faArrowDownUpAcrossLine, 
    faBars, 
    faBoxOpen, 
    faBoxesStacked, 
    faBuildingColumns, 
    faCartFlatbed, 
    faChartLine, 
    faCircleHalfStroke, 
    faCircleUser, 
    faCogs, 
    faCube, 
    faCubesStacked, 
    faDollarSign, 
    faFlaskVial, 
    faGears, faHandshakeAngle, faLayerGroup, faLink, faPizzaSlice, faSignsPost, faUser, faUserCheck, faUserTie, faUsers, faUtensils, faWrench } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { useAuth } from '../../../AuthProvider';
import { CaretCircleDoubleLeft, CaretCircleDoubleRight, Funnel, House, SquaresFour } from '@phosphor-icons/react';
import MainDesktopIcon from './MainDesktopIcon/MainDesktopIcon';
import UIButton from '../../../pages/Components/UIButton/UIButton';
import { Navigate, useNavigate } from 'react-router-dom';

import { faHashnode } from "@fortawesome/free-brands-svg-icons";
import { 
    faArrowsTurnToDots, 
    faBarsProgress, 
    faBox, 
    faBreadSlice, 
    faBuildingWheat, 
    faCakeCandles, 
    faCheckDouble, 
    faClipboardCheck, 
    faCodePullRequest, 
    faDiagramNext, 
    faFileCircleQuestion,
    faFileInvoice, 
    faFileInvoiceDollar, 
    faFlask, 
    faFolder, 
    faHandPointUp,
    faList, 
    faLocationArrow, 
    faMagnifyingGlassChart, 
    faMoneyBillTransfer, 
    faMugSaucer, 
    faPersonCircleQuestion, 
    faQuestionCircle, 
    faReceipt, 
    faSeedling, 
    faSignHanging, 
    faSitemap, 
    faStopwatch, 
    faTruckRampBox, 
    faUniversalAccess, 
    faUserGroup, 
    faUsersGear, 
    faUsersRectangle, 
    faUserTag, 
    faWarehouse 
} from "@fortawesome/free-solid-svg-icons";


export default function DesktopXP({children}) 
{
     const [subMenuState, setSubMenuState] = useState(new Array(50).fill(false));
      const [CurrentUsageIndex, SetCurrentUsageIndex] = useState(0);
    
      const [activeMainMenu, setActiveMainMenu] = useState(-1);
      const [sidebarExpanded, setsidebarExpanded] = useState(false);
    
      const [RoutePackage, SetRoutePackage] = useState(new Map());

      const [SubRoot, SetSubRoot] = useState(false);
      const [ActiveInRouteList, SetActiveInRouteList] = useState(new Map());
      const [ChildViewRender, SetChildViewRender] = useState(false);
      const [AllAppBuffer, SetAllAppBuffer] = useState(new Map());
      const [AllAppBufferBackup, SetAllAppBufferBackup] = useState(new Map());
      const [ViewAllApp, SetViewAllApp] = useState(false);
    
      const auth = useAuth();
      const navigator = useNavigate();
    
      const IconMapShittyWay = useRef(new Map([
        ['faHashnode', faHashnode],
        ['faChartLine', faChartLine],
        ['faUserCheck', faUserCheck],
        ['faUsers', faUsers],
        ['faDollarSign', faDollarSign],
        ['faWrench', faWrench],
        ['faLink', faLink],
        ['faBoxesStacked', faBoxesStacked],
        ['faArrowDownUpAcrossLine', faArrowDownUpAcrossLine],
        ['faGears', faGears],
        ['faCircleUser', faCircleUser],
        ['faPizzaSlice', faPizzaSlice],
        ['faUtensils', faUtensils],
        ['faBoxOpen', faBoxOpen],
        ['faCogs', faCogs],
        ['faCubesStacked', faCubesStacked],
        ['faHandshakeAngle', faHandshakeAngle],
        ['faSignsPost', faSignsPost],
        ['faArrowsTurnToDots', faArrowsTurnToDots], 
        ['faBarsProgress', faBarsProgress],
        ['faBox', faBox], 
        ['faBreadSlice', faBreadSlice], 
        ['faBuildingColumns', faBuildingColumns], 
        ['faBuildingWheat', faBuildingWheat], 
        ['faCakeCandles', faCakeCandles], 
        ['faCartFlatbed', faCartFlatbed], 
        ['faCheckDouble', faCheckDouble], 
        ['faClipboardCheck', faClipboardCheck], 
        ['faCodePullRequest', faCodePullRequest], 
        ['faCube', faCube], 
        ['faDiagramNext', faDiagramNext], 
        ['faFileCircleQuestion', faFileCircleQuestion], 
        ['faFileInvoice', faFileInvoice], 
        ['faFileInvoiceDollar', faFileInvoiceDollar], 
        ['faFlask', faFlask], 
        ['faFlaskVial', faFlaskVial], 
        ['faFolder', faFolder], 
        ['faHandPointUp', faHandPointUp], 
        ['faLayerGroup', faLayerGroup], 
        ['faList', faList], 
        ['faLocationArrow', faLocationArrow], 
        ['faMagnifyingGlassChart', faMagnifyingGlassChart], 
        ['faMoneyBillTransfer', faMoneyBillTransfer], 
        ['faMugSaucer', faMugSaucer], 
        ['faPersonCircleQuestion', faPersonCircleQuestion], 
        ['faQuestionCircle', faQuestionCircle], 
        ['faReceipt', faReceipt], 
        ['faSeedling', faSeedling], 
        ['faSignHanging', faSignHanging], 
        ['faSitemap', faSitemap], 
        ['faStopwatch', faStopwatch], 
        ['faTruckRampBox', faTruckRampBox], 
        ['faUniversalAccess', faUniversalAccess], 
        ['faUserGroup', faUserGroup], 
        ['faUsersGear', faUsersGear], 
        ['faUsersRectangle', faUsersRectangle], 
        ['faUserTag', faUserTag], 
        ['faUserTie', faUserTie], 
        ['faWarehouse', faWarehouse]
    
      ]))
      
    
    
    
        const toggelSubMenu = (idx) => 
        {
            if(!sidebarExpanded) 
            {
                setsidebarExpanded(!sidebarExpanded);
            }
            
            localStorage.setItem("active_on_menu_idx", idx);
            setActiveMainMenu(idx);
            const some = subMenuState.fill(false);
            setSubMenuState(some);
            const updatedState = [...subMenuState];
            updatedState[idx] = !updatedState[idx];
            setSubMenuState(updatedState);
        }
    
        const ParseRouteInfo = async()=>
        {
            const reconstructedMap = new Map();
                
            Object.entries(JSON.parse(auth.user.UARI_PageList)).forEach(([groupName, groupData]) => {
                reconstructedMap.set(groupName, {
                    ValidRouteGroup: groupData.ValidRouteGroup,
                    Icon: groupData.Icon,
                    Config: new Map(Object.entries(groupData.Config))
                });
            });
    
            SetRoutePackage(reconstructedMap);

            const giantMap = new Map();

            for (const [key, value] of reconstructedMap.entries()) {
                // console.log(value.Config);

                for (const [innerKey, innerValue] of value.Config.entries()) {
                    giantMap.set(innerKey, innerValue);
                }
            }
            SetAllAppBufferBackup(giantMap);
        }
    
        useEffect(()=>{
            if(auth.user) 
            {
                const active_idx = localStorage.getItem("active_on_menu_idx");
                const side_bar_open = localStorage.getItem("is_side_bar_open");
    
                if(active_idx) { toggelSubMenu(active_idx); }
                // if(side_bar_open) {toggelSubMenu()}
    
    
                ParseRouteInfo();
            }
        }, [auth.user])

        const RootMenuDataCallback = (data)=>
        {
            SetActiveInRouteList(data);
            SetSubRoot(true);
            SetChildViewRender(false);
        }
        const SubRoute_CALLBACK = (data)=>
        {
            navigator(data);
            SetChildViewRender(true);
        }
        const backToHome = ()=>
        {
            SetSubRoot(false);
            SetChildViewRender(false);
            SetViewAllApp(false);
        }

        const PrepareAllAppForView = ()=>
        {
            const giantMap = new Map();

            for (const [key, value] of RoutePackage.entries()) {
                // console.log(value.Config);

                for (const [innerKey, innerValue] of value.Config.entries()) {
                    giantMap.set(innerKey, innerValue);
                }
            }
            SetAllAppBuffer(giantMap);
            SetViewAllApp(true);
            SetChildViewRender(false);
            SetSubRoot(false);
        }

        const FilterPackageByName = (key_input)=>
        {
            const _filter_map = new Map();
            let inputLower = key_input.toLowerCase();
            for(const[key, value] of AllAppBufferBackup.entries())
            {
                if(value.Label.toLowerCase().includes(inputLower)) 
                {
                    _filter_map.set(key, value);
                }
            }

            SetAllAppBuffer(_filter_map);
            SetViewAllApp(true);
            SetChildViewRender(false);
            SetSubRoot(false);
        }

        const generateAnimationDelay = (index) => {
            return `${index * 0.06}s`
          };


    return (
        <>
        {
            ChildViewRender ? 
                <div className={`${classes.element_x_route}`}>
                    {children}
                    <div onClick={()=>{backToHome()}} className={`${classes.desktop_mode_controller}`}>
                        <SquaresFour weight='fill'/>
                    </div>
               </div> : 
                <div className={`${classes.desktop_icon_view}`}>
                <div className={`${classes.classic_background_svg}`}></div>

        

                <div className={`${classes.search_module_bar}`}>
                    <div className={`${classes.stock_filter_wrapper}`}>
                        <div className={`${classes.workshop_filter_input_wrapper}`}>
                            <input onChange={(e)=>{FilterPackageByName(e.target.value)}} autoComplete='off' type="text" placeholder='Search Modules' /> 
                            <span><Funnel weight='fill'/></span>
                        </div>
                    <UIButton onClick={()=>{backToHome()}} label={"Home"} Icon={<SquaresFour weight='bold'/>}/>
                    <UIButton onClick={()=>{PrepareAllAppForView()}} label={"All App"} Icon={<SquaresFour weight='bold'/>}/>
                    </div>
                </div>
                <div className={`${classes.desktop_icon_view_inner_wrapper}`}>
                { !ViewAllApp && !ChildViewRender && !SubRoot &&
                    Array.from(RoutePackage.entries()).map(([group_name, group_data], idx)=>(

                        group_data.ValidRouteGroup && 
                        <MainDesktopIcon
                                AnimationDelay={generateAnimationDelay(idx)}
                                Data_CALLBACK={RootMenuDataCallback} 
                                DataPackage={group_data} 
                                Icon={IconMapShittyWay.current.get(group_data.Icon)} 
                                Label={group_name}/>
                    ))
                    
                }
                {!ViewAllApp && !ChildViewRender &&
                    SubRoot && 
                    Array.from(ActiveInRouteList.Config.entries()).map(([route, config], idx) => (
                        config.HasAccess &&  
                            <MainDesktopIcon 
                                AnimationDelay={generateAnimationDelay(idx)}
                                SubRoute_CALLBACK={SubRoute_CALLBACK}
                                Data_CALLBACK={RootMenuDataCallback} 
                                DataPackage={route} 
                                Root={false}
                                Icon={IconMapShittyWay.current.get(config.Icon)} 
                                Label={config.Label}/>
                    ))

                }
                {ViewAllApp &&
                    Array.from(AllAppBuffer.entries()).map(([route, config], idx) => (
                        config.HasAccess &&  
                            <MainDesktopIcon 
                                AnimationDelay={generateAnimationDelay(idx)}
                                SubRoute_CALLBACK={SubRoute_CALLBACK}
                                Data_CALLBACK={RootMenuDataCallback} 
                                DataPackage={route} 
                                Root={false}
                                Icon={IconMapShittyWay.current.get(config.Icon)} 
                                Label={config.Label}/>
                    ))

                }

            </div>

            

        </div>
        }
        </>
       
    )
}