import classes from './Vendors.module.css'
import VendorCard from './Component/VenderCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import RegisterVenderCard from './Component/RegisterVenderCard/RegisterVenderCard'
import { useState } from 'react'
import { useEffect } from 'react'

import { QueryVendorsOmni, PurgeVendorSingle } from '../../../SharedComp/API/VendorAPI/API_FE_Vendor'

import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'
import { useDAControl } from '../../../DAControlContext'

export default function Vendors() 
{
    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();

    const[OpenModal, SetOpenModel] = useState(false);
    const [RefreshView, SetRefreshView] = useState(false);



    const[VendorList, SetVendorList] = useState([]);


    const PurgeVendorByName = async(name)=> 
    {
        notify.ShowLoad("Processing your request....", 10);
        try 
        {
            const fd = new FormData();
            fd.append("purge_id", name);
            const main_res = await PurgeVendorSingle(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshView(!RefreshView);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }


    const QueryAllVenders = async()=>
    {
        try 
        {
            const main_res = await QueryVendorsOmni(auth.token);;
            SetVendorList(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to query venderos!");
        }
    }
    
    useEffect(()=>{

        QueryAllVenders();

    }, [OpenModal, RefreshView])


    return (
        <div className={`${classes.vender_main_wrapper}`}>

            {OpenModal && <RegisterVenderCard OpenController={SetOpenModel} />}



            <div className={`${classes.vender_header}`}>
                <h1>Vendors</h1>
            </div>
            
            <div className={`${classes.vender_main_inner_wrapper}`}>

               <div className={`${classes.vender_main_inner_list_wrapper}`}>

                    {
                        VendorList.map((item, idx)=>(
                                <VendorCard key={idx} ID={item.VID} ItemName={item.Vname} Tin={item.Vtin} Purge_CALLBACK={(ID, ItemName)=>{
                                    DAControl.Trigger(`Purge Vendor`,
                                    `You are about to Purge Vender '${ItemName}'.`,
                                    {_ID: ID, _ItemName: ItemName},
                                    6,
                                    (data)=>
                                        {
                                            if(data) 
                                            {
                                                PurgeVendorByName(data._ItemName);
                                            }
                                        }
                                    )
                                }} />
                        ))
                    }
                    
               </div>

            </div>

            <div className={`${classes.action_button}`}>
                <button onClick={()=>{SetOpenModel(true)}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></button>
            </div>

        </div>
    )
}