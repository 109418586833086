import classes from './ReceiveSTRequest.module.css'
import ProcessIRequestPackageInput from './ProcessIRequestPackageInput/ProcessIRequestPackageInput'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import {Custom_DD_Style, PrepareDropDownList} from '../../../../SharedComp/Utility'

import { FetchTransferRequestPayloadV2, ReceiveStockRequestV2 } from '../../../../SharedComp/API/InventoryStockTransfer/API_FE_InventoryStockTransfer'
import {FetchStockTransferRequestReadBackHash, ProcessRequestReceiveTransfer, FetchTransferRequestPayload} from '../../../../api'
import { useAuth } from '../../../../AuthProvider'
import { useToast } from '../../../../ToastContext'
import { useDAControl } from '../../../../DAControlContext'

export default function ReceiveSTRequest({OpenModal, TargetRequest, DataRefresh}) 
{

    const [Loading, SetLoading] = useState(false);

    const da_control = useDAControl();
    const auth = useAuth();
    const notify = useToast();
    const scrollRef = useRef(null);
    // Transfere Detail
    const [InventoryItemType, SetInventoryItemType] = useState(null);
    const [InventoryItemStore, SetInventoryItemStore] = useState(null);
    const [InventoryItemQuantity, SetInventoryItemQuantity] = useState(null);
    const [InventoryItemBrand, SetInventoryItemBrand] = useState(null);
    const [InventoryItemRequestHash, SetInventoryItemRequestHash] = useState(null);
    const [InventoryTransferApprovalState, SetInventoryTransferApprovalState] = useState(null);
    const [LockToSourceBranch, SetLockToSourceBranch] = useState(true);
    const [IncomingLoading, SetIncomingLoading] = useState(false);
    // Filter Branch List
    const [ValidBranchList, SetValidBranchList] = useState([]);
    
    const [RequestPayloadList, SetRequestPayloadList] = useState([]);
    const [RequestMeta, SetRequestMeta] = useState();
    const [StockItem, SetStockItem] = useState([]);

    const FetchRequestMeta = async()=>
    {
        try 
        {
            await FetchRequestPayload();
        }catch(err) 
        {

        }
    }
    const FetchRequestPayload = async()=>{
        SetIncomingLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_request_id", TargetRequest.ISTRV2_ID);
            const res = await FetchTransferRequestPayloadV2(fd);
            SetRequestPayloadList(res);

            SetInventoryItemType(null);
            SetInventoryItemStore(null);
            SetInventoryItemQuantity(null);
            SetInventoryItemBrand(null);
            SetInventoryItemRequestHash(null);
            SetInventoryTransferApprovalState(null);
            const existing = [];
            for(let i = 0; i < res.REQUEST_DETAIL_ARRAY.length; i++) 
            {
                const _focued_entry = res.REQUEST_DETAIL_ARRAY[i];
                const unique_idx = getRandomID();

                HandleIvTransferApprovalState(true, unique_idx);
                HandleIvnItemBrand(_focued_entry.SR_RequstedBrand ? _focued_entry.SR_RequstedBrand : '-', unique_idx);
                HandleItemQuantitySelection(_focued_entry.SR_RequstedQuantity, unique_idx);
                HandleIvItemSelection({value: _focued_entry.IvtID, label: _focued_entry.IVItemName}, unique_idx);
                HandleIvItemRequestHash(getRandomID(), unique_idx);
                existing.push(
                    <ProcessIRequestPackageInput 
                    I_TransferState={_focued_entry.SR_ApprovedQuantity === 0 ? "Rejected" : "Approved"}
                    I_Quantity={_focued_entry.SR_RequstedQuantity}
                    I_Brand={_focued_entry.SR_RequstedBrand ? _focued_entry.SR_RequstedBrand : '-'}
                    I_Inv_Item={{value: _focued_entry.IvtID, label: _focued_entry.IVItemName}}
                        HandleInvItemSelect={HandleIvItemSelection} 
                        HandleInvItemStore={HandleIvItemStore}
                        HandlInvItemQuantity={HandleItemQuantitySelection} 
                        HandleIvnItemBrand={HandleIvnItemBrand}
                        HandleTransferState={HandleIvTransferApprovalState}
                        idx={unique_idx}/>
                );
            }
                SetStockItem(existing);
                SetIncomingLoading(false);
        }catch(err) 
        {
            notify.ShowError("Faild To Fetch Request Payload, Please try again in a moment");
            SetIncomingLoading(false);
        }
    }

    function CheckValidTransfereCount() 
    {
        let data_count = 0;
        let valid_count = 0;
        for (const key in InventoryTransferApprovalState)  
        {
            data_count++;
            if(InventoryTransferApprovalState[key])
            {
                valid_count++;
            }
        }
        return valid_count;
    }

    function HandleIvItemSelection(data, idx) 
    {
        SetInventoryItemType(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemRequestHash(data, idx) 
    {
        SetInventoryItemRequestHash(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvItemStore(data, idx) 
    {
        SetInventoryItemStore(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleItemQuantitySelection(data, idx) 
    {
        SetInventoryItemQuantity(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvTransferApprovalState(data, idx) 
    {
        SetInventoryTransferApprovalState(prev => ({
            ...prev,
            [idx]: data
        }))
    }
    function HandleIvnItemBrand(data, idx) 
    {
        SetInventoryItemBrand(prev => ({
            ...prev,
            [idx]: data
        }))
    }

    function getRandomID() 
    {
        return Math.floor(Math.random() * 100000000);
    }

    function CloseModal() 
    {
        OpenModal();
    }
    const HandleSubmition = async(e, declined = false)=> 
    {
        if(!e)
        {
            return;
        }
        notify.ShowLoad("Processing Request...", 10);
        SetLoading(true);
        try 
        {
        
            const fd = new FormData();
            fd.append("receiver_user", auth.user.USR_UserAccountID);
            fd.append("receiver_workspace", auth.activeWorkShop);
            fd.append("request_payload_ID", TargetRequest.ISTRV2_ID);
            fd.append("request_item_hash", JSON.stringify(InventoryItemRequestHash));
            fd.append("request_payload_IVItem", JSON.stringify(InventoryItemType));
            fd.append("request_payload_IVItemQuantity", JSON.stringify(InventoryItemQuantity));
            fd.append("request_payload_IVItemBrand", JSON.stringify(InventoryItemBrand));
            fd.append("request_payload_store", JSON.stringify(InventoryItemStore));
            fd.append("request_payload_approval_state", JSON.stringify(InventoryTransferApprovalState));

            const res = await ReceiveStockRequestV2(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
            DataRefresh(new Date().getUTCMilliseconds());
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }
    }
    function HandleDecline(val) 
    {
        if(!val) 
        {
            return;
        }
        HandleSubmition("sup", true);
    }
    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
    useEffect(()=>{
        FetchRequestMeta();
    }, [])
    
    useEffect(()=>{
        scrollToBottom();
    }, [StockItem])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main} ${classes.data_main}`} >
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Processing <span className={`${data_win.data_create_name}`}>Stock Request</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={(e)=>{
                            e.preventDefault();
                            HandleSubmition(e, false);
                          
                        }} method='POST'>
                            <div className={`${classes.form_wrapper}`}> 
                                    
                                    <div className={`${classes.input_list_main_wrapper}`}>
                                        <div  className={`${classes.input_list_wrapper}`}>
                                            
                                                <div className={`${classes.input_header_single_wrapper_st_req_header}`}>
                                                    <h2>Warehouse Stock Transfer #{RequestMeta?.IVSTRID}</h2>
                                                </div>
                                        </div>
                                    </div>

                                    <div className={`${classes.po_mat_list_wrapper}`}>
                                        <div className={`${classes.po_mat_list}`}>
                                                {StockItem.map((item) => ( 
                                                    <div 
                                                        className={`${classes.input_fild_shild}`} 
                                                        key={item.props.idx}>{item}
                                                        <div ref={scrollRef}/>
                                                    </div>
                                                ))}
                                        </div>
                                   </div>                        
                                        
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button 
                                    className={`${data_win.form_action_button_save}`}>
                                        {Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Accept'}
                                </button>
                                <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>




            </div>
        </div>

    </div>
    )
}