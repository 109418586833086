import classes from './InspectOriginalOrder.module.css'
import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//lib
import { useAuth } from '../../../../../../../AuthProvider'
import { useToast } from '../../../../../../../ToastContext'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { ToEthiopianCalander } from '../../../../../../../SharedComp/Utility'

export default function InspectOriginalOrder({TargetData, OpenController}) 
{

    const auth = useAuth();
    const notify = useToast();


    function CloseModal() 
    {
        OpenController(false);
    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Preview <span className={`${data_win.data_create_name}`}>Order Package</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                <div className={`${classes.form_wrapper}`}> 

                    <div className={`${classes.master_prod_type_config_wrapper}`}>
                            <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                <li>
                                    <span>Order Created By: <strong>{TargetData.USR_FirstName} {TargetData.USR_LastName}</strong></span>
                                </li>
                                <li>
                                    <span>Order Created Date: <strong>{ToEthiopianCalander(new Date(TargetData.DOMD_OrderedDate))}</strong></span>
                                </li>
                                <li>
                                    <span>Order Expected Date: <strong>{ToEthiopianCalander(new Date(TargetData.DOMD_ExpectedDate))}</strong></span>
                                </li>

                            </div>

                            <div className={`${classes.master_product_type_inner_config_wrapper} ${classes.master_product_type_inner_config_wrapper_content_list}`}>

                                  {
                                    TargetData.DOMD_Payload.map((item, idx)=>(
                                        <li key={idx}>
                                            <span>{item.DO_UUID}</span>
                                            <span><strong>{item.CKP_ItemName}</strong></span>
                                            <span>{item.DO_OrderCount} Pcs</span>
                                        </li>
                                    ))
                                  }
                                  

                            </div>

                            
                        </div>
                            
                    </div>
                    {/* <div className={`${data_win.form_action_button_wrapper}`}>
                        <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                        <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                    </div> */}
            </div>
        </div>

    </div>
    )
}