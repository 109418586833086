import classes from '../PayrollConfig/PayrollConfig.module.css'
import '../../../../../index.css'

export default function InteractionConfig({UpdateReq, ConfigData}) 
{

    return (
        <div className={`${classes.credit_system_wrapper}`}>
        <div className={`${classes.part_of_option}`}>
            <ul>UI Scale</ul>
            <div className={`${classes.setting_items_config}`}>
                
                
            </div>  
        </div>
    </div>
    )
}