import classes from './ProductionStackProcessor.module.css'
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { useState } from 'react'

import MasterDataRegInput from '../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'

import {FetchFinalProductByRecipe, ProcessProductedPoStack, ProcessProductedPoStackV2} from '../../../../api'
import { FetchBakeryProductByRecipe } from '../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery'

export default function ProductionStackProcessor({Close_CALLBACK, DataList, Target, DataQuickRefresh, MasterDataRefresh_Callback}) 
{

    const [FProductList, SetFProductList] = useState([]);
    const [MixProductList, SetMixProductList] = useState([]);
    const [Loading, SetLoading] = useState(false);

    const auth = useAuth();
    const notify = useToast();

    const [ProductionTable, SetProductionTable] = useState(new Map());

    const [MixFProductList, SetMixFProductList] = useState(new Map());
    // Create Iteratable Array for UI
    const [MixFProdArray, SetMixFProdArray] = useState([]);

    const SetProductionBatch = async(key, val, mix_name = null)=>{

        const __existing = new Map(ProductionTable);

        if(__existing.has(key)) 
        {
            // get the data
            const _current = __existing.get(key);
            // the max Batch count
            const _search_key = mix_name ? mix_name: key;
            const _raw = DataList.get(_search_key);
            if(val > _raw.CycleCount) 
            {
                notify.ShowWarn(`Whoa there! The max batch you can handle for '${_search_key}' is '${_raw.CycleCount}'. Let's not bite off more than we can chew here!`);
                return;
            }
            _current.Batch = val;
            SetProductionTable(__existing);
            return;
        } else 
        {
            notify.ShowError("Something Want wrong please try again! Refresh the page!") 
        }

    }
    const ProcessMixProducts = async(mix_list, _prod_table)=>
    {
        try 
        {
            const __prod_table = new Map(_prod_table);

            const __mix_data_table = new Map();
            for(let  i = 0; i < mix_list.length; i++) 
            {
                const fd = new FormData();
                fd.append("targte_id", mix_list[i].MID);
                const res = await FetchBakeryProductByRecipe(fd);

                if(res.length > 0) 
                {
                    __mix_data_table.set(mix_list[i].MixName, res);

                    for(let j = 0; j < res.length; j++) 
                    {
                        __prod_table.set(res[j].CMBP_Name, {UID: mix_list[i].UID, Name: res[j].CMBP_Name, ID: res[j].CMBPID, Batch: 1, ProductQuantity: '', IsMix: true, RootName: mix_list[i].MixName});
                    }
                }

            }
            
            // console.log(__prod_table)
            SetProductionTable(__prod_table);
            SetMixFProductList(__mix_data_table);
            SetMixFProdArray(Array.from(__mix_data_table.entries()));
            SetLoading(false);

        }catch(err)
        {

        }
    }
    const SetProductionAmount = async(key, val)=>{

        const __existing = new Map(ProductionTable);

        if(__existing.has(key)) 
        {
            // get the data
            const _current = __existing.get(key);
            if((val / _current.Batch) > 600) 
            {
                notify.ShowError("Hold your horses—600 products per batch is the magic number! Let’s keep it classy and not get carried away. Mindfulness is key, my friend!");
                return;
            }
            _current.ProductQuantity = val;
            SetProductionTable(__existing);
            return;
        } else 
        {
            notify.ShowError("Something Want wrong please try again! Refresh the page!") 
        }

    }
    useEffect(()=>{
        // SetLoading(true);
        const __prod_list = [];
        const __mix_list = [];


        // Product Tbale
        const __product_table = new Map();

        for(const [key, value] of DataList.entries())
        {
            if(value.IsMix) 
            {
                __mix_list.push({...value.Pkg, ...{UID: value.UniqueID}});
            }else 
            {
                __prod_list.push(value.Pkg);
                __product_table.set(key, {UID: value.UniqueID, Name: key, ID: value.Pkg.CMBPID, Batch: 1, ProductQuantity: '', IsMix: false, RootName: null});
            }
        }

        // console.log(__product_table);
        SetProductionTable(__product_table);
        SetFProductList(__prod_list);
        SetMixProductList(__mix_list);
        ProcessMixProducts(__mix_list, __product_table);

    }, []);

    const HandleSubmition = async(e)=>
    {
        e.preventDefault();
        notify.ShowLoad("Processing...", 10);

        try 
        {
            const fd = new FormData();
            fd.append("main_data", JSON.stringify(Array.from(ProductionTable.entries())));
            fd.append("reference_data", JSON.stringify(Array.from(DataList.entries())));
            fd.append("po_hash", Target.MPOM_OrderID);
            fd.append("po_shift", Target.MPOM_Shift);
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("user_id", auth.user.USR_UserAccountID);
            fd.append("order_col_id", Target.MPOM_ID);

            const res = await ProcessProductedPoStackV2(fd);

            notify.ShowSuccessUpdate(res.Msg, 10);
            Close_CALLBACK(true);
            DataQuickRefresh();
            if(res.UpdateState) 
            {
                MasterDataRefresh_Callback();
            }
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }


    }

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Production Processing</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>
                <div className={`${classes.data_header}`}>
                    <h2>Production Stack</h2>
                </div>

                {!Loading && <div className={`${classes.po_stack_body}`}>

                    <form onSubmit={HandleSubmition} method="post">
                    
                    <div className={`${classes.batch_data_wrapper}`}>
                        {
                            FProductList.map((item, idx)=>(
                                <div key={idx} className={`${classes.input_wrapper_batch_process}`}>

                                        <div className={`${classes.single_data_wrapper}`}>
                                            <MasterDataRegInput 
                                            input={<input 
                                                value={item.CMBP_Name} 
                                                // onChange={(e)=>{HandleProductQantity(e.target.value);}} 
                                                name='product_quantity' 
                                                type="text"  
                                                title={item.CMBP_Name} 
                                                autoComplete='off' 
                                                readOnly
                                                />} 
                                            />
                                        </div>
                                        <div className={`${classes.single_data_wrapper}`}>
                                            <MasterDataRegInput 
                                                input={<input  
                                                    onChange={(e)=>{SetProductionBatch(item.CMBP_Name, e.target.value)}} 
                                                    value={ProductionTable.get(item.CMBP_Name)?.Batch}
                                                    name='bratch_count' 
                                                    type="number"
                                                    min={1}  
                                                    title={""} 
                                                    autoComplete='off'  
                                                    placeholder='Number Of Batch'
                                                    required
                                                    />} 
                                                    tip={"The default Batch count 1. You can change it, just be cautious"}
                                                />
                                        </div>
                                        <div className={`${classes.single_data_wrapper}`}>
                                            <MasterDataRegInput 
                                                input={<input  
                                                    onChange={(e)=>{SetProductionAmount(item.CMBP_Name, e.target.value)}} 
                                                    name='product_quantity' 
                                                    type="number"
                                                    value={ProductionTable.get(item.CMBP_Name)?.ProductQuantity}
                                                    min={0}  
                                                    title={""} 
                                                    autoComplete='off' 
                                                    placeholder={"Number of " + item.CMBP_Name}
                                                    required
                                                    />} 
                                                    tip={item.CMBP_Name + " Quantity"}
                                                />
                                        </div>
                                    
                                    
                                </div>
                            ))
                        }

                        {
                            MixFProdArray.map((item_mix, idx_mix)=>(
                                <div key={idx_mix} className={`${classes.input_wrapper_batch_process_mix}`}>

                                    <div className={`${classes.data_per_mix_header}`}>
                                        <li>{item_mix[0]}</li>
                                    </div>

                                    {
                                        item_mix[1].map((prod_item, prod_idx)=>(

                                        <div key={prod_idx} className={`${classes.input_wrapper_batch_process_expanded_mix_list_wrapper}`}>

                                            <div className={`${classes.single_mix_data_wrapper}`}>
                                                <MasterDataRegInput 
                                                input={<input 
                                                    value={prod_item.CMBP_Name} 
                                                    // onChange={(e)=>{HandleProductQantity(e.target.value);}} 
                                                    name='product_quantity' 
                                                    type="text"  
                                                    title={prod_item.CMBP_Name} 
                                                    autoComplete='off' 
                                                    readOnly
                                                    />} 
                                                />
                                            </div>
                                            <div className={`${classes.single_mix_data_wrapper}`}>
                                                <MasterDataRegInput 
                                                    input={<input  
                                                        onChange={(e)=>{SetProductionBatch(prod_item.CMBP_Name, e.target.value, item_mix[0])}} 
                                                        value={ProductionTable.get(prod_item.CMBP_Name)?.Batch}
                                                        name='bratch_count' 
                                                        type="number"
                                                        min={1}  
                                                        title={""} 
                                                        autoComplete='off'  
                                                        placeholder='Number Of Batch'
                                                        required
                                                        />} 
                                                        tip={"The default Batch count 1. You can change it! but great power comes with great responsibility! .... So mistakes will be normalized!"}
                                                    />
                                            </div>
                                            <div className={`${classes.single_mix_data_wrapper}`}>
                                                <MasterDataRegInput 
                                                    input={<input  
                                                        onChange={(e)=>{SetProductionAmount(prod_item.CMBP_Name, e.target.value)}} 
                                                        name='product_quantity' 
                                                        type="number"
                                                        value={ProductionTable.get(prod_item.CMBP_Name)?.ProductQuantity}
                                                        min={0}  
                                                        title={""} 
                                                        autoComplete='off' 
                                                        placeholder={"Number of " + prod_item.CMBP_Name}
                                                        required
                                                        />} 
                                                        tip={prod_item.CMBP_Name + " Quantity"}
                                                    />
                                            </div>
                                        
                                        
                                    </div>

                                        ))
                                    }

                                </div>
                            ))
                        }
                    </div>

                    <div className={`${classes.action_button_wrapper}`}>

<button onClick={()=>{}} className={`${classes.button_approve}`}>Finish</button>
<button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

</div>
                    </form>
                </div> }


               

            </div>

        </div>
    )
}