import api from "../Util/axiosInstance";




//////////////////////////////////////// MASTER EMPLOYEE BENEFIT

export const CreateNewEmployeeBenfitType = async(data)=>
{
        try  
        {
            const response = await api.post('api/operation-data-cluster-gamma/create-hr-master-employee-benefit-type', data);
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateEmployeeBenfitType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-gamma/update-hr-master-employee-benefit-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeEmployeeBenfitType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-gamma/purge-hr-master-employee-benefit-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterEmployeeBenfit = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-gamma/query-all-hr-master-employee-benefit-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}



//////////////////////////////////////// MASTER VEHICLE Vehicle

export const CreateNewVehicleType = async(data)=>
{
        try  
        {
            const response = await api.post('api/operation-data-cluster-gamma/create-hr-master-vehicle-type', data);
            const res = await response.data;
            if(res.MyStateCode !== 1) 
            {
                throw new Error(res.Msg);
            }
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateVehicleType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-gamma/update-hr-master-vehicle-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeVehicleType = async(data)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-gamma/purge-hr-master-vehicle-type', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllMasterVehicle = async(token)=>
{
    try  
    {
        const response = await api.post('api/operation-data-cluster-gamma/query-all-hr-master-vehicle-type', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}