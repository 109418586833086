import api from "../Util/axiosInstance";





export const CreateNewGlobalConfig = async(data)=>
{
    try 
    {
        const response = await api.post('api/global-config/create-new-gloabal-config', data);
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
} 
export const UpdateGlobalConfig = async(data)=>
{
    try 
    {
        const response = await api.post('api/global-config/update-gloabal-config', data);
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryGlobalObjectConfig = async(data)=>
{
    try 
    {
        const response = await api.post('api/global-config/query-gloabal-config', data);
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}