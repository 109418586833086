import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './EditInventoryGroup.module.css'
import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import {faSpinner, faTimesCircle, faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef, useState,useEffect } from 'react'
import axios from 'axios';
import {mapToObject, objectToMap} from '../../../../../SharedComp/Utility'

import { UpdateIVGroupID, FetchIVGroupID} from '../../../../../api'
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { FetchWorkspaceBranchAll } from '../../../../../SharedComp/API/Workspace/API_FE_Workspace'

export default function EditInventoryGroup({OpenModal, TargetID}) 
{
    const formRef = useRef(); 

    const auth = useAuth();
    const notify = useToast();

    // If the server is requested but not answerd yet..
    const [registring, SetRegistring] = useState(false);

    const [WorkspaceList, SetWorkspaceList] = useState([]);
    const [WorkspaceChecked, SetWorkspaceChecked] = useState(new Map());
    const [ToggelListTrue, SetToggelListTrue] = useState(false);

    // data
    const [TypeName, SetTypeName] = useState();
    const [TypeRemark, SetTypeRemark] = useState();
    const [IsEdible, SetIsEdible] = useState(false);

    // Target Data
    const [TargetData, SetTargetData] = useState();


    const handleToggle = (branchID) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("Active", !update_state_inner.get("Active"));

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecMaxBalance = (branchID, max_balance) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("MaxBalance", max_balance);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const handleBranchSpecMinBalance = (branchID, min_balance) => {
        if(!WorkspaceChecked.has(branchID))
            return;
        SetWorkspaceChecked(prevState => {
            const updatedState = new Map(prevState);

            const update_state_inner = new Map(updatedState.get(branchID));
            update_state_inner.set("MinBalance", min_balance);

            updatedState.set(branchID, update_state_inner);
            return updatedState;
        });
    };
    const selectAll = (val) => {

        SetToggelListTrue(val);

        let updated_list = new Map();
        for(const [mp_key, mp_val] of WorkspaceChecked.entries()) 
        {
            const updated = mp_val;
            updated.set("Active", val);
            updated_list.set(mp_key, updated);
        }
        SetWorkspaceChecked(updated_list);    
    };
    const QueryWorkspace = async () => {

        try {
            const res = await FetchWorkspaceBranchAll(auth.token);

            // for now prepare void list
            let init_check_list = new Map();
            for(let  i = 0; i < res.length; i++) 
            {
                if(!init_check_list.has(res[i].BranchID)) 
                    init_check_list.set(res[i].BranchID, new Map([
                        ["Active", false],
                        ["MinBalance", null],
                        ["MaxBalance", null]
                      ])); 
            }
            SetWorkspaceChecked(init_check_list);

            SetWorkspaceList(res);
        } catch (err) 
        {
            throw new Error(err);
        }
    }

    const QueryTargetData = async()=>{
        try 
        {
            const res = await FetchIVGroupID(TargetID);
            SetTargetData(res[0]);

            SetTypeName(res[0].IVItemTypeName);
            SetTypeRemark(res[0].IVItemTypeDescription);
            SetIsEdible(res[0].IVItemTypeEdibleState);
            
            const p_res = objectToMap(JSON.parse(res[0].IVItemTypeActiveWorkspace));
            SetWorkspaceChecked(p_res);

        }catch(err) 
        {
            notify.ShowError("Failed to Fetch Target Data! " + err.message);
        }
    }


    // Clear the form when ever the user click dismiss of close the window
    function CloseModal() 
    {
        formRef.current.reset();
        OpenModal(false);
    }

    // Handle submition
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        SetRegistring(true);
        notify.ShowLoad("Processing your request...", 10);

        setTimeout( async () => {

            const fd = new FormData();
            fd.append("goods_type", TypeName);
            fd.append("goods_remark", TypeRemark);
            fd.append("is_edible", IsEdible);
            fd.append("workspace_info", JSON.stringify(mapToObject(WorkspaceChecked)))
            fd.append('target_id', TargetID);

            try 
            {
               const res = await UpdateIVGroupID(fd);
               notify.ShowSuccessUpdate(res.Msg, 10);
               SetRegistring(false);
               CloseModal();
            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
                SetRegistring(false);
            }

            }, 3000)
    }

    useEffect(()=>{
        QueryWorkspace();
    }, []);
    
    useEffect(()=>{
        if(WorkspaceList.length > 0)
            QueryTargetData();

    }, [WorkspaceList])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main} ${classes.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Edit <span className={`${data_win.data_create_name}`}> Inventory Type</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body} ${classes.data_body}`}>
                        


                            <form onSubmit={HandleSubmition} ref={formRef} action="POST">
                                <div className={`${classes.form_wrapper}`}> 
                                    <div  className={`${classes.input_list_wrapper}`}>
                                        <MasterDataRegInput input={<input value={TypeName} onChange={(e)=>{SetTypeName(e.target.value)}} name='goods_type' type="text" placeholder='Type Name' title='' autoComplete='off' required />} />
                                        <MasterDataRegInput input={<input value={TypeRemark} onChange={(e)=>{SetTypeRemark(e.target.value)}} name='goods_remark' type="text" placeholder='Remark'  autoComplete='off' />} />
                                        <div onClick={()=>{SetIsEdible(!IsEdible)}} className={`${classes.edible_state_input_wrapper}`}>
                                            <input 
                                                checked={IsEdible}
                                                onChange={(e)=>{SetIsEdible(e.target.checked)}}
                                                type="checkbox" 
                                                />
                                            <span>Edible Group</span>
                                        </div>   
                                 </div>

                                    <div className={`${classes.master_product_type_inner_avail_workspace_list}`}>
                                    <div className={`${classes.master_product_type_inner_avail_workspace_list_header}`}>
                                        <h1>Accessibility</h1>
                                        <button type='button' onClick={()=>{selectAll(!ToggelListTrue)}}><FontAwesomeIcon icon={ToggelListTrue ? faObjectUngroup : faObjectGroup}/></button>
                                    </div>
                                    <div className={`${classes.master_prod_avail_list}`}>
                                        {
                                            WorkspaceList.map((item, idx)=>(
                                                <div key={idx}  className={`${classes.master_prod_avail_branch}`}>
                                                    
                                                    <div onClick={() => handleToggle(item.BranchID)} className={`${classes.workspace_tick_and_name_wrapper}`}>

                                                        <input 
                                                            onClick={() => handleToggle(item.BranchID)}
                                                            checked={WorkspaceChecked.get(item.BranchID)?.get("Active")} 
                                                            name={item.BranchName} 
                                                            type="checkbox"
                                                            onChange={() => handleToggle(item.BranchID)}
                                                            />
                                                        <li>{item.BranchName}</li>
                                                        
                                                    </div>
                                                    
                                                    <div className={`${classes.workspace_min_max_value_per_type_wrapper}`}>
                                                        <input  
                                                            step='any'
                                                            min={0}
                                                            value={WorkspaceChecked.get(item.BranchID)?.get("MinBalance")} 
                                                            onChange={(e)=>{handleBranchSpecMinBalance(item.BranchID, e.target.value)}} 
                                                            type="text" 
                                                            placeholder="Min Balance" />
                                                        <input 
                                                            step='any'
                                                            min={0}
                                                            value={WorkspaceChecked.get(item.BranchID)?.get("MaxBalance")} 
                                                            onChange={(e)=>{handleBranchSpecMaxBalance(item.BranchID, e.target.value)}} 
                                                            type="text" 
                                                            placeholder="Max Balance" />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                </div>
                                
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={registring} className={`${data_win.form_action_button_save}`}>{registring ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={registring}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>




                </div>
            </div>

        </div>
    )
}