import classes from './ReceiveOrder.module.css'
import {QueryMasterProdCatValidToPreorder} from '../../../../../SharedComp/API/DeskView/API_FE_DeskView'
import { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'

import { CreateMasterPreOrder } from '../../../../../SharedComp/API/DeskView/API_FE_DeskView';

import Draggable from 'react-draggable';

import UIButton from '../../../../Components/UIButton/UIButton';

import OrderProductCard from '../OrderProductCard/OrderProductCard';

import {QueryCKProductKitchenID, FetchWorkspaceProductionCenterOnly} from '../../../../../SharedComp/API/CK/AP_CenteraKitchen'
import { FetchBakeryProductAll } from '../../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';
import { Broom, CheckCircle, House, PhoneCall, PushPin, PushPinSlash, ShoppingCart, Trash, TrashSimple, XCircle } from '@phosphor-icons/react';

import PastryOrderDetail from './Component/PastryOrderDetail/PastryOrderDetail';
import OtherOrderDetail from './Component/OtherOrderDetail/OtherOrderDetail';
import CustomerDetail from './Component/CustomerDetail/CustomerDetail';
import { useDAControl } from '../../../../../DAControlContext';
// import PeerToPeer from './Component/PeerToPeer/PeerToPeer';


export default function ReceiveOrder()
{

    const [IsCartPinned, SetIsCartPinned] = useState(true);
    const [ViewCart, SetViewCart] = useState(false);
    const [ValidGroupList, SetValidGroupList] = useState([]);
    const [ActiveGroupID, SetActiveGroupID] = useState();
    // const [EstablishComm, SetEstablishComm] = useState(false);

    const [KicthenChoice, SetKicthenChoice] = useState();
    const [ProductionCenterList, SetProductionCenterList] = useState([]);
    const [ProductList, SetProductList] = useState([]);
    const [FilteredList, SetFilteredList] = useState([]);

    const [OpenCustomerDetail, SetOpenCustomerDetail] = useState(false);

    // Bakery
    const [BakeryProductList, SetBakeryProductList] = useState([]);

    // Pastry
    const [TargetPastryProduct, SetTargetPastryProduct] = useState(null);
    const [TargetOtherProduct, SetTargetOtherProduct] = useState(null);

    // Open Dialog
    const [OpenPastryOrderDetailOrder, SetOpenPastryOrderDetailOrder] = useState(false);
    const [OpenOtherOrderDetailOrder, SetOpenOtherOrderDetailOrder] = useState(false);

    const [CurrentActiveDataList, SetCurrentActiveDataList] = useState([]);
    const [CurrentActiveDataListOriginal, SetCurrentActiveDataListOriginal] = useState([]);
    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();

    const [ProductLevelView, SetProductLevelView] = useState(false);

    
    // Orders Queue
    const [OrderQueues, SetOrderQueues] = useState([]);

    const QueryValidGroup = async()=>
    {
        try 
        {
            const main_res = await QueryMasterProdCatValidToPreorder(auth.token);
            SetValidGroupList(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Valid Production Group List: " + err.message);
        }
    }

    const QueryBakeryProductList = async()=>
    {
        try 
        {
            const main_res = await FetchBakeryProductAll(auth.token);
            SetBakeryProductList(main_res);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Bakery Product List: " + err.message);
        }
    }



    // This guy triggered when the card is clicked at group level
    const HandleGroupCardClick = async(target_data) =>
    {
        SetActiveGroupID(target_data.ProdCatID);
        if(target_data.ProdCatID === 0) 
        {
            SetProductLevelView(true);
            SetCurrentActiveDataList(BakeryProductList);
            SetCurrentActiveDataListOriginal(BakeryProductList);
        } else if(target_data.ProdCatID === 1)
        {
            SetProductLevelView(true);
            SetCurrentActiveDataList(FilteredList);
            SetCurrentActiveDataListOriginal(FilteredList);
        }
    }
    // Thiss guy triggered when the card clicked at the product level
    const HandleProductCardClick = async(target_data, group_id)=>
    {

        if(ActiveGroupID === 0) 
        {
            SetTargetOtherProduct(target_data);
            SetOpenOtherOrderDetailOrder(true);

        } else if(ActiveGroupID === 1)
        {
            SetTargetPastryProduct(target_data);
            SetOpenPastryOrderDetailOrder(true);
        }
    }
    const FetchDataProductDataList = async() =>
    {
        try 
        {
            const res = await QueryCKProductKitchenID(KicthenChoice, auth.token);
            SetProductList(res);
            SetFilteredList(res);

        }catch(err) 
        {
            console.log("Unable to fetch product data-list");
        }
    }
    const SelectKitchen = async (_id) =>
    {
        SetKicthenChoice(_id); 
    } 
    const FetchProductionCenter = async () =>
    {
        try 
        {
            const res = await FetchWorkspaceProductionCenterOnly(auth.token);
            SetProductionCenterList(res);
            if(res.length > 0) 
            {
                SelectKitchen(res[0].BranchID);
            }
        } catch(err) 
        {

        }
    }

    const HandleProductSelectionCallback = async(data)=>
    {
        // Find if the same name exist
        SetOrderQueues((prevOrderQueues) => {
            if (!prevOrderQueues.some(item => item._ProductPreviewName === data._ProductPreviewName)) {
              return [...prevOrderQueues, data];
            }
            notify.ShowWarn("This item has already been added to your cart.");
            return prevOrderQueues;
          });
    }

    const PurgeCartItem = async(targetName)=>
    {
        SetOrderQueues((prevOrderQueues) =>
            prevOrderQueues.filter(item => item._ProductPreviewName !== targetName)
          );
    }
    const PurgeAllCartItem = async()=>
    {
        SetOrderQueues([]);
        SetViewCart(false);
        notify.ShowWarn("Cart cleared!");
    }

    useEffect(()=>
    {
        QueryValidGroup();
        QueryBakeryProductList();
        FetchProductionCenter();

    }, [])

    useEffect(()=>{

        if(KicthenChoice) 
        {
            FetchDataProductDataList();
        }
    }, [KicthenChoice])

    const FilterItems = async(filter_key)=>
    {
        // We only filter if we are in product view
        if(ProductLevelView) 
        {
            const valid_list = [];
            for(let i = 0; i < CurrentActiveDataListOriginal.length; i++) 
            {
                const _key_name = CurrentActiveDataListOriginal[i][Object.keys(CurrentActiveDataListOriginal[i]).find((key) => key.includes("Name"))];
                if(_key_name.toLowerCase().includes(filter_key.toLowerCase())) 
                {
                    valid_list.push(CurrentActiveDataListOriginal[i]);
                }   
            }

            SetCurrentActiveDataList(valid_list);
        }
    }

    const SubmitionCallback = async(customer_data)=> 
    {

        notify.ShowLoad("Processing your request...", 10);

        try 
        {

            const fd = new FormData();
            fd.append("order_package", JSON.stringify(OrderQueues));
            fd.append("order_customer", JSON.stringify(customer_data));
            fd.append("target_kitchen", KicthenChoice);
            fd.append("source_workspace", auth.activeWorkShop);
            fd.append("source_worksapce_name", auth.activeWorkShopName);

            const main_res = await CreateMasterPreOrder(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetOrderQueues([]);
            SetViewCart(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    const ProcessCartQueue = async()=>
    {
        if(OrderQueues.length === 0)
        {
            notify.ShowError("Cart is empty!");
            return;
        }

        SetOpenCustomerDetail(true);
    }




    return (
        <div className={`${classes.desk_view_card_wrapper}`}>

            {OpenPastryOrderDetailOrder && <PastryOrderDetail ProductGroupID={ActiveGroupID} AddPastry_Callback={HandleProductSelectionCallback} TargetData={TargetPastryProduct} OpenController={SetOpenPastryOrderDetailOrder}/>}
            {OpenOtherOrderDetailOrder && <OtherOrderDetail ProductGroupID={ActiveGroupID} AddPastry_Callback={HandleProductSelectionCallback} TargetData={TargetOtherProduct} OpenController={SetOpenOtherOrderDetailOrder}/>}

            {OpenCustomerDetail && <CustomerDetail  Submition_Callback={SubmitionCallback} TargetData={OrderQueues} OpenController={SetOpenCustomerDetail}/>}

           {ViewCart &&  <Draggable disabled={IsCartPinned}>
                <div className={`${classes.side_order_cart}`}>
                    <div className={`${classes.header_side_cart}`}>
                        <h2><span onClick={()=>{SetIsCartPinned(!IsCartPinned)}}>{IsCartPinned ? <PushPinSlash weight='fill' /> : <PushPin weight='fill' />}</span> Pre-Order Queue ({OrderQueues.length})  </h2>
                        <button onClick={()=>{SetViewCart(false)}}><span><XCircle weight='fill'/></span></button>
                    </div>

                    <div className={`${classes.pre_order_cart_body_list}`}>
                        {
                            OrderQueues.map((item, idx)=>(
                                <li key={idx}>
                                    <span className={`${classes.name_space}`}>{item._ProductPreviewName}</span>
                                    <span>{item._ProductPrice}</span>
                                    <span>{item._ProductQuantity}</span>
                                    <span> <UIButton onClick={()=>{PurgeCartItem(item._ProductPreviewName)}} label={"Purge"} Variant='Danger' Icon={<TrashSimple weight='fill'/>}/> </span>
                                </li>
                            ))
                        }
                    </div>

                    <div className={`${classes.action_button_wrapper}`}>
                        <UIButton onClick={()=>{ProcessCartQueue()}} label={"Submit Order"} Variant='Primary' Icon={<CheckCircle weight='fill'/>}/> 
                        <UIButton 
                            label={"Void"} 
                            Variant='Danger' 
                            Icon={<Broom weight='bold'/>}
                            onClick={()=>{
                                DAControl.Trigger(`Void Cart`,
                                    `You are about clear all item from the cart..`,
                                    true,
                                    5,
                                    (data)=>
                                    {
                                        if(data)
                                        {
                                            PurgeAllCartItem();
                                        }
                                    }
                                )
                            }}
                            /> 
                    </div>
                </div>
            </Draggable>}

            {/* {EstablishComm && <PeerToPeer OpenController={SetEstablishComm} />} */}

            <div className={`${classes.desk_view_receive_order_inner_nav_wrapper}`}>

                <div className={`${classes.desk_view_receive_order_nav}`}>

                    <UIButton onClick={()=>{SetProductLevelView(false)}} label={"Home"} Variant='Secondary' Icon={<House weight='bold'/>}/> 
                    <UIButton CountNew={(OrderQueues.length === 0 || ViewCart) ? null : OrderQueues.length} onClick={()=>{SetViewCart(!ViewCart)}} label={"Orders"} Variant='Primary' Icon={<ShoppingCart weight='bold'/>}/>
                    {/* <UIButton onClick={()=>{SetEstablishComm(true)}} label={"Connect"} Variant='Primary' Icon={<PhoneCall weight='bold'/>}/> */}
                    {/* <UIButton label={"Void Order"} Variant='Danger' Icon={<Broom weight='bold'/>}/> */}
                    
                    <div className={`${classes.filter_in_domain}`}>
                        <input onChange={(e)=>{FilterItems(e.target.value)}} type="text" placeholder='Search' />
                    </div>

                </div>
            </div>

            <div className={`${classes.desk_view_list_wrapper}`}>
                { ProductLevelView ?
                    CurrentActiveDataList.map((item, idx) => (
                        <OrderProductCard key={idx + item[Object.keys(item).find((key) => key.includes("Name"))]}  CardName={item[Object.keys(item).find((key) => key.includes("Name"))]} CardPriceTag={item[Object.keys(item).find((key) => key.includes("UnitPrice"))]} Data_CALLBACK={HandleProductCardClick}  TargetData={item}/>
                    ))
                    :
                    ValidGroupList.map((item, idx)=>(
                        <OrderProductCard key={idx} CardName={item.ProdCatName}  Data_CALLBACK={HandleGroupCardClick} isGroup={true} TargetData={item}/>
                    ))
                }
            </div>
            
            
        </div>
    )
}