import classes from './EmpCredit.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faCircleNotch, faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig';

import CreateNewCredit from './CreateNewCredit/CreateNewCredit';
import { QueryEmployeeCreditByEmployeeID } from '../../../../../SharedComp/API/EmployeeOperation/API_FE_EmployeeCredit';
import { useTargetEmployee } from '../SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import { useAuth } from '../../../../../AuthProvider';

import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo';
import UIButton from '../../../../Components/UIButton/UIButton';
import { ArrowDownLeft, CheckCircle, Checks, File, SpinnerGap } from '@phosphor-icons/react';
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility';


const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function EmpCredit({}) 
{
    const [OpenCreditCreator, SetOpenCreditCreator] = useState(false);
    const [AllCredit, SetAllCredit] = useState([]);
    
    const sharedData = useTargetEmployee();
    const auth = useAuth();

    const GetAllCredit = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("employee_id", sharedData.Core.JAKSBE_SyntheticID);
            const main_res = await QueryEmployeeCreditByEmployeeID(fd);
            SetAllCredit(main_res);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{
        GetAllCredit();
    }, [OpenCreditCreator])

 
    return (
        <div className={`${classes.emp_credit_main_wrapper}`}>

            {OpenCreditCreator && <CreateNewCredit OpenController={SetOpenCreditCreator}/>}

            <div className={`${classes.emp_action_adder}`}>
                <button onClick={() => {SetOpenCreditCreator(true)}}>Credit Cash</button>
            </div>
            
            <div className={`${classes.amp_credit_info}`}>

                <div className={`${classes.list_wrapper_inner}`}>
                    {
                        AllCredit.map((item, idx)=>(
                            <li key={item.EMP_CREDIT_Hash}>
                                <span>{ToEthiopianCalander(new Date(item.EMP_CREDIT_ApprovedDate))}</span>
                                <span>{currency_format(item.EMP_CREDIT_Amount)}</span>
                                <span className={`${classes.action_indicator}`}>
                                        <UIButton Icon={<ArrowDownLeft weight='bold'/>} Variant='Disabled' label={"Show Transaction"} onClick={()=>{}}/>
                                        <UIButton Icon={<Checks weight='bold'/>} label={"Active"} onClick={()=>{}}/>
                                </span>
                            </li>
                        ))
                    }
                </div>
                
                  
            </div>

        </div>
    );
}

