import api from "../Util/axiosInstance";





export const QueryProductionCenterWorkShop = async(token)=>
{
    try  
    {
        const response = await api.post('api/kitchen/query-production-center-workshop', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const AssignChefToWorkspace = async(data)=>
{
    try  
    {
        const response = await api.post('api/kitchen/assign-chef-for-active-kicthen', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAssignedChef = async(data)=>
{
    try  
    {
        const response = await api.post('api/kitchen/query-assigned-chefs', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeAssignedChef = async(data)=>
{
    try  
    {
        const response = await api.post('api/kitchen/purge-assigned-chefs', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ShipProducedCkProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/kitchen/ship-ck-products', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
