import classes from './loginPageStyle.module.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeLowVision, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import logo from '../../asset/images/favicon.png'

import { useAuth } from '../../AuthProvider';
import { useEffect } from 'react';

import { PaintColorSchemeBuffer } from '../../SharedComp/ColorScheme';
import DeviceInfo from 'react-native-device-info';

export default function Login() 
{

    const [log_values, setLogValues] = useState({
        username: '',
        password: ''
    })

    const [showKey, SetShowKey] = useState(false);
    const auth = useAuth();

    const handleFieldInput = (e) => 
    {
        setLogValues(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    

    useEffect(()=>{
        if(auth)
        {
            auth.SetSurfaceColor(PaintColorSchemeBuffer[1].Value)
        }
    }, [auth.user])

    const handleLogInSubmit = async (event) => 
    {
        event.preventDefault();

    
        // DeviceInfo.getBaseOs().then((baseOs) => {
        //     console.log(baseOs);
        //   });
        // DeviceInfo.getDeviceName().then((deviceName) => {

        //     console.log(deviceName);
        // });

        auth.loginAction(log_values, new Date().getSeconds());
    }
    return (
        <div className={`${classes.login_main_wrapper}`}>

            <div className={`${classes.form_wrapper} ${auth.loading ? classes.form_wrapper_loading : ''}`}>
                <div className={`${classes.company_info}`}>  
                        <div className={`${classes.comp_name}`}>
                            <div className={`${classes.head_stick_logo}`}>
                                <img src={logo} alt="" />
                            </div>
                            {/* <div className={`${classes.head_stick_logo}`}>
                                <h2>JAK AND SONS</h2>
                            </div> */}
                        </div>
                </div>
                <form onSubmit={handleLogInSubmit} method="post">

                    <div className={`${classes.input_field_wrapper}`}>
                        <div className={`${classes.input_icon}`}>
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                        </div>
                        <div className={`${classes.input_value}`}>
                            <input  onChange={(e)=>{handleFieldInput(e)}} name='username' type="text" placeholder='Employee ID / User ID' required />
                        </div>
                    </div>

                    <div className={`${classes.input_field_wrapper_key}`}>
                        <div className={`${classes.left_side_input_wrapper}`}>
                            <div className={`${classes.input_icon}`}>
                                <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                            </div>
                            <div className={`${classes.input_value}`}>
                                <input onChange={(e)=>{handleFieldInput(e)}} name='password' type={showKey ? 'text' : 'password'} placeholder='********' required />
                    
                            </div>
                        </div>

                        <div className={`${classes.show_pass_key}`}>
                            <span onClick={(e)=>{SetShowKey(!showKey)}}>
                                <FontAwesomeIcon icon={showKey ? faEyeLowVision : faEye}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>

                    <div className={`${classes.action_buttons_wrapper}`}>
                        <div className={`${classes.forget_pass}`}>
                    
                        </div>
                        <div className={`${classes.action_button_wrapper}`}>
                            <button disabled={auth.loading}>{auth.loading ? "Verifying..." : 'sign in'} </button>
                        </div>
                    </div>

                </form>
            </div>

                        
        </div>
        
    ); 
}