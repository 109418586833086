import classes from './LoadingPlaceholder.module.css'
import DotsGif from '../CssGif/DotsGif'
import { useState } from 'react';
import { useEffect } from 'react';

export default function LoadingPlaceholder({preview_text = "Retrieving Package Data", placeholder_height=10}) 
{
    const jokes = [
        "Why did the loading screen break up with the user? Because it needed space to load its feelings!",
        "Loading screens are like bad dates... You don’t know when they’ll end, but you're stuck there pretending everything’s fine.",
        "I love when a loading screen says 'Almost Done'... It's like that one friend who keeps saying they'll be ready in five minutes for the next hour.",
        "Why did the computer bring a ladder to the loading screen? It wanted to reach the next level!",
        "I like to think of loading screens as motivational speeches. 'You can do it, you’re almost there, just one more second!'",
        "I’m starting to believe that loading screens are like my life... Full of progress but never quite finished.",
        "The best part of a loading screen is the part where it lies to you. 'Just a few seconds left!'... 10 minutes later... still loading.",
        "Why do loading screens make terrible comedians? Because they always leave you hanging for way too long!",
        "A loading screen walks into a bar… Bartender says, 'Just one second, I’ll be right with you!'",
        "I think loading screens are secretly the universe’s way of teaching patience. Or maybe they’re just messing with us. Who knows?"
      ];

      const [currentJoke, setCurrentJoke] = useState(preview_text);

      useEffect(() => {
        const intervalId = setInterval(() => {
          const randomJoke = jokes[Math.floor(Math.random() * jokes.length)];
          setCurrentJoke(randomJoke);
        }, 5000); 
    
        return () => clearInterval(intervalId); 
      }, []);


    return (
        <div style={{height: `${placeholder_height}em`}} className={`${classes.loading_gif_wrapper}`}>
            <div className={`${classes.loading_gif_placeholder_text}`}>{currentJoke}</div> <DotsGif/>
        </div>
    )
}