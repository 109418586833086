import classes from './TrackSupplyOrder.module.css'
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'
import UIButton from '../../../../Components/UIButton/UIButton'
import { Camera, CheckCircle, Key, Scan, SmileyWink } from '@phosphor-icons/react'

import { useState } from 'react'
import { useToast } from '../../../../../ToastContext'
import { useAuth } from '../../../../../AuthProvider'
import { useEffect } from 'react'

import { QueryOrdersGroupByBranchID } from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import SingleEntryGroup from './SingleEntryGroup/SingleEntryGroup'
import { ArrowCircleDown } from '@phosphor-icons/react/dist/ssr'
import QRCodeReader from '../../../../../SharedComp/QRCodeReader/QRCodeReader'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import ReceiveOrderedItem from './ReceiveOrderedItem/ReceiveOrderedItem'
import { useRef } from 'react'

export default function TrackSupplyOrder({targetKicthen, FilterSpecial = false}) 
{
    const [DataCopy, SetDataCopy] = useState([]);

    const notify = useToast();
    const auth = useAuth();
    const [AtDayOf, SetAtDayOf] = useState(new Date());

    const [ShowReceiver, SetShowReceiver] = useState(false);
    const [UseKeyCode, SetUseKeyCode] = useState(false);

    const [PreviewReadyItems, SetPreviewReadyItems] = useState(false);
    const [OpenQRCodeReader, SetOpenQRCodeReader] = useState(false);
    const [TargetDataItem, SetTargetDataItem] = useState();
    const [ShipmentCode, SetShipmentCode] = useState(null);
    const ReqKeyInputWrapperRef = useRef();
    const [DatCallbackRefresh, SetDatCallbackRefresh] = useState(Date.now());


    const CloseDatPreview = ()=>
    {
        SetOpenQRCodeReader(false);
        SetUseKeyCode(false);
        SetShowReceiver(false);
        SetPreviewReadyItems(false);
    }
    const OnQrCodeScan = async(data)=>
    {
        if(data) 
        {
            SetOpenQRCodeReader(false);
            SetPreviewReadyItems(true);
            SetShipmentCode(data.text);
        }
    }

    function HandleMouseClick(e) 
    {
        if (ReqKeyInputWrapperRef.current && !ReqKeyInputWrapperRef.current.contains(e.target)) {
            SetShowReceiver(false);
            SetUseKeyCode(false);
          }
    }
    const FetchOrderGroupByBranch = async()=>
    {
        try 
        {
            const fd = new FormData();

            fd.append("branch_id", auth.activeWorkShop);
            fd.append('day_of', AtDayOf);
            fd.append("target_branch", targetKicthen);

            const main_res = await QueryOrdersGroupByBranchID(fd);
            SetDataCopy(main_res);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const OnQrFailToRead = async(err) =>
    {
        SetOpenQRCodeReader(false);
        console.log(err);
    }
    useEffect(()=>{
        FetchOrderGroupByBranch();

        document.addEventListener('mousedown', HandleMouseClick);
        return () => {
        document.removeEventListener('mousedown', HandleMouseClick);
        };

    }, [auth.activeWorkShop, DatCallbackRefresh]);




    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>

            {PreviewReadyItems && <ReceiveOrderedItem  ShipmentCode={ShipmentCode} Refresh_CALLBACK={SetDatCallbackRefresh} OpenController={SetPreviewReadyItems} /> }
            {OpenQRCodeReader && <QRCodeReader onQRRead={OnQrCodeScan} OnError={OnQrFailToRead} Open={OpenQRCodeReader} OpenController={CloseDatPreview}/>}

            <div className={`${classes.filter_workshop_by_name}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{SetAtDayOf(e.target.value)}} autoComplete='off' type="date" /> 
                        <span>{ToEthiopianCalander(new Date(AtDayOf))}</span>
                    </div>
                {
                auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ReceiveAuthorizeOrder && 
                    <UIButton 
                        Variant='Secondary' 
                        Icon={<ArrowCircleDown weight='bold'/>} 
                        onClick={()=>{SetShowReceiver(!ShowReceiver)}} 
                        label={"Receive Incoming Package"} /> 
                }

            {ShowReceiver &&   
                <div ref={ReqKeyInputWrapperRef} className={`${classes.receive_order_methods}`}>

                    <UIButton 
                        Variant='Primary' 
                        onClick={()=>{SetUseKeyCode(!UseKeyCode); SetTargetDataItem({})}} 
                        Icon={<Key weight='fill'/>} 
                        label={"Enter Key"}/>

                    <UIButton 
                        Variant='Primary' 
                        onClick={()=>{SetOpenQRCodeReader(true); SetTargetDataItem({})}} 
                        Icon={<Camera weight='fill'/>} 
                        label={"Scan Key"}/> 

                    {/* Receive through key */}

                    {
                        UseKeyCode &&   
                        <div className={`${classes.enter_key_order_group}`}>
                            <div className={`${classes.input_key_wrapper}`}>
                                <input onChange={(e)=>{SetShipmentCode(e.target.value)}} type="text" placeholder='Please Enter Key..' />
                            </div>
                            <div className={`${classes.enter_key_order_group_action_button}`}>
                                <UIButton Variant='Secondary' Icon={<CheckCircle weight='fill'/>} onClick={()=>{SetPreviewReadyItems(true);}} label={"Validate Code"}/>
                            </div>
                        </div>
                    }

                </div>
            }
            </div>
            
            <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.active_order_main_list_wrapper}`}>
                    <li className={`${classes.header_list}`}>
                        <span>Ordered By</span>
                        <span>Ordered Date</span>
                        <span>Expected</span>
                        <span>Order State</span>
                    </li>

                    <div className={`${classes.list_data_wrapper}`}>
                        {
                            DataCopy.map((item, idx)=>(
                                <SingleEntryGroup key={item.PROCI_Name + idx} item={item}/>
                            ))
                        }
                        {
                            DataCopy.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                <ul><SmileyWink fontSize={30}/></ul>
                                <ul>No TRACK RECORD</ul>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}