import classes from './OrderSubGroupDaily.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckDouble, faCircleStop, faCodeBranch, faCopy, faInfoCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import {FetchTargetOrderGroupSubProducts, RejectOrderSubProduct} from '../../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed';
import CountdownTimer from '../../../../../SharedComp/CountDown/CountDownTimer';
import { useState } from 'react';
import { useToast } from '../../../../../ToastContext';
import { useDAControl } from '../../../../../DAControlContext';
import { useAuth } from '../../../../../AuthProvider';
import { Star } from '@phosphor-icons/react';
import { useRef } from 'react';
import { useEffect } from 'react';

function getFormattedDate(dateValue) {
    return new Date(dateValue).toLocaleDateString('en-US', {
      weekday: 'long', 
      month: 'short',  
      day: '2-digit'
    //   year: 'numeric'  
    });
  }
function getFormattedTime(dateValue) {
    return new Date(dateValue).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true 
    });
}
function isDateInThePast(inputDate) {
    const now = new Date();
    const providedDate = new Date(inputDate);
    return now < providedDate;
}

export default function OrderSubGroupDaily({SingleItemDispatch, IsItemSelected, SelectItem,  ItemGroupBase, UpdateView}) 
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();
    const TimeModalRef = useRef(null);

    const [ValidOrderTime, SetValidOrderTime] = useState(isDateInThePast(ItemGroupBase.DOMD_ExpectedDate));
    const [OpenOrderTimeInfo, SetOpenOrderTimeInfo] = useState(false);
    const [MyButtonPosition, SetMyButtonPosition] = useState();


    const HandleTimeModalInfoOpen = (val)=>
    {
        if(!OpenOrderTimeInfo && TimeModalRef.current) 
        {
            TimeModalRef.current.focus();
        }
        SetOpenOrderTimeInfo(val);
    }
    const handleMouseMove = (event) => 
    {
        SetMyButtonPosition({
            x: event.clientX,
            y: event.clientY,
            });
    };

    const HandleRejectOrder = async(_product_id, rej_aount)=>
    {
    
        notify.ShowLoad("Processing your request.....", 10);
        try 
        {
            const fd = new FormData();
            fd.append("entry_id", ItemGroupBase.KCOI_ID);
            fd.append("package_id", ItemGroupBase.DOMD_PackageUUID);
            fd.append("Product_id", _product_id);
            fd.append("reject_amount", rej_aount);
            fd.append("user_id", auth.user.USR_UserAccountID);

            const main_res = await RejectOrderSubProduct(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            UpdateView(new Date().getMilliseconds());

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }



    return (
        <div className={`${classes.order_group_list_singular}`}>
                <div className={`${classes.group_header}`}>
                    <h2>Daily Order</h2>
                    <button onClick={()=>{HandleTimeModalInfoOpen(!OpenOrderTimeInfo);}}><FontAwesomeIcon icon={OpenOrderTimeInfo ? faTimesCircle : faInfoCircle}></FontAwesomeIcon></button>
                    <div 
                    ref={TimeModalRef} 
                    tabIndex={0} 
                    onBlur={()=>{SetOpenOrderTimeInfo(false)}} 
                    className={`${OpenOrderTimeInfo ? classes.order_info_critical : classes.order_info_critical_hide}`}>
                        <li>Ordered Date: <TimeElapsed Time={ItemGroupBase.DOMD_OrderedDate}/> </li>
                        <li>Expected Date: {getFormattedDate(ItemGroupBase.DOMD_ExpectedDate)} @ {getFormattedTime(ItemGroupBase.DOMD_ExpectedDate)}</li>
                        <li>Available Time: <CountdownTimer targetTime={ItemGroupBase.DOMD_ExpectedDate} CountSec={true}/></li>
                        <li>Order Status: <span className={`${ValidOrderTime ? classes.order_validity : classes.order_invalidity}`}>{ValidOrderTime ? "In Effect" : "No Longer Active"}</span></li>
                    </div>
                </div>
                <div className={`${classes.sub_group_list_data}`}>
                    {
                        ItemGroupBase?.DOMD_Payload.map((item, idx)=>(
                            <li key={item.DO_UUID + idx} className={`${IsItemSelected(item.DO_UUID) ? classes.selected_group_prod : ''}`} onClick={()=>{SelectItem(item, ItemGroupBase.DOMD_PackageUUID,ItemGroupBase.KCOI_ID, "Daily")}}>
                                <span>{item.CKP_ItemName}</span>
                                <span className={`${classes.quantity_data_wrapper}`}>
                                    <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Ordered Quantity'})} className={`${classes.total_amount_wrapper}`}><FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon> {item.DO_OrderCount} </span>
                                    <span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Dispatched Amount'})}><FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon> {item.DO_DispatchedCount} </span>
                                    {<span {...({'data-tooltip-id': 'offical_doc', 'data-tooltip-content': 'Rejected Amount'})} className={`${classes.rejected_amount_wrapper}`}><FontAwesomeIcon icon={faBan}></FontAwesomeIcon> {item.DO_RejectedCount} </span>}
                                </span>
                            <span className={`${classes.action_button_wrapper_group}`}>
                                   {<button disabled={((item.DO_DispatchedCount + item.DO_RejectedCount) >= item.DO_OrderCount)} onClick={()=>{
                                            DAControl.Trigger(`Reject Order Productt`, 
                                            `You are about to dismiss the remaining products which is [ -${item.DO_OrderCount - item.DO_DispatchedCount}- ] the dispatched amount won't be affected.`,
                                            true,
                                            5, (val)=>{
                                                if(val !== null) 
                                                {
                                                    HandleRejectOrder(item.DO_UUID, item.DO_OrderCount - item.DO_DispatchedCount);
                                                }
                                            }   
                                        );}}
                                    className={`${classes.reject_order_item} ${((item.DO_DispatchedCount + item.DO_RejectedCount) >= item.DO_OrderCount) ? classes.button_disabled : ''}`}  >
                                        <span>
                                            <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span> <span>Reject</span>
                                        </span>
                                    </button>}
                                    <button 
                                        disabled={((item.DO_DispatchedCount + item.DO_RejectedCount) >= item.DO_OrderCount)} 
                                        className={`${((item.DO_DispatchedCount + item.DO_RejectedCount) >= item.DO_OrderCount) ? classes.button_disabled : ''}`} 
                                        onMouseMove={(e)=>{handleMouseMove(e)}}  
                                        onClick={()=>{SingleItemDispatch(item, ItemGroupBase.DOMD_PackageUUID, ItemGroupBase.KCOI_ID, "Daily",  MyButtonPosition);}}
                                        >
                                        <span>
                                            <span><FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon></span> <span>Dispatch</span>
                                        </span>
                                    </button>
                                </span> 
                            </li>
                        ))
                    }
                </div>
            </div>
    )
}