import api from "../Util/axiosInstance";





export const QueryMasterProdCatValidToPreorder = async(token)=>
{
    try  
    {
        const response = await api.post('api/desk_view/get_ck_prod_type_all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryActiveOrderByBranch = async(data)=>
{
    try  
    {
        const response = await api.post('api/desk_view/query-order-active-order', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QuerySpecialOrderByBranchByDate = async(data)=>
{
    try  
    {
        const response = await api.post('api/desk_view/query-special-order-by-branch-date', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const QueryActivePendingPayments = async(data)=>
{
    try  
    {
        const response = await api.post('api/desk_view/query-active-pending-payment', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryActiveOrderBakeryByCustomerID = async(data)=>
{
    try  
    {
        const response = await api.post('api/desk_view/query-order-bakery-by-customer-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryActiveOrderPastryByCustomerID = async(data)=>
    {
        try  
        {
            const response = await api.post('api/desk_view/query-order-pastry-by-customer-id', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const CreateMasterPreOrder = async(data)=>
{
    try  
    {
        const response = await api.post('api/desk_view/create-pre-order-master', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const WrapUpPreorder = async(data)=>
{
    try  
    {
        const response = await api.post('api/desk_view/wrap-up-pre-order-cashier', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}