import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseURL } from "./SharedComp/BaseUrlConfig";
// import { Howl } from 'howler';
import error_log_sound from './asset/audio/error-login.mp3'

import { useLocation } from "react-router-dom";

import {VerifyWhatsgpsAccount, FetchWhatsgpsCarInfo, VerifyWhatsgpsCars} from './SharedComp/API/GPS/API_FE_WhatsGPS'
import {CreateUserLoginRequest, CreateSignOutRequest, AuthClient} from './SharedComp/API/UserAccess/API_FE_UserAuth'

import { ToastWrapper, toast } from "./SharedComp/ToastWrapper/ToastWrapper";
import { useGlobalNotification } from "./GlobalNotification";

import {MixColor, KeyColorSchemeBuffer, PaintColorSchemeBuffer} from './SharedComp/ColorScheme'
import { useToast } from "./ToastContext";
import { QueryBaseEmployeeBySyntID } from "./SharedComp/API/BaseEmployee/API_FE_BaseEmployee";
import { QueryGlobalObjectConfig } from "./SharedComp/API/GlobalConfig/PI_FE_GlobalConfig";
import OfflinePage from "./pages/Offline/Offline";
import { getBaseSocket } from "./SocketBase";


const AuthContext = createContext();


const AuthProvider = ({children})=> {
    
    const socket = getBaseSocket();
    const location = useLocation();
    const [LastRoute, SetLastRoute] = useState(location.pathname);
    const [CheckingUserAuth, SetCheckingUserAuth] = useState(true);

    const [DarkMode, SetDarkMode] = useState(false);
    const [user, setUser] = useState(null);
    const [employeeData, setEmployeeData] = useState(null);
    const [SalaryArray, SetSalaryArray] = useState([]);
    const [CompanyToEmployeeProfile, SetCompanyToEmployeeProfile] = useState(null);
    const [EmployeeDocumentProfile, SetEmployeeDocumentProfile] = useState();

    const [loading, setLoading] = useState(false);
    const [serverRes, setServerRes] = useState('##');
    const [IsOnline, SetIsOnline] = useState(window.navigator.onLine);
    const navigate = useNavigate();

    const [userAccessWorkspaceIDList, setUserAccessWorkspaceIDList] = useState([]);
    const [userAccessworkshopList, setUserAccessworkshopList] = useState([]);
    const [activeWorkShop, setActiveworkshop] = useState(0);
    const [activeWorkShopName, setActiveWorkShopName] = useState('');
    const CarInfoIntervalRef = useRef(null);

    const [GlobalConfig, SetGlobalConfig] = useState({})
    const [UserSpecialAccessList, SetUserSpecialAccessList] = useState({});


    const [ActiveAccentColor, SetActiveAccentColor] = useState(KeyColorSchemeBuffer[0].Value);
    const [ActiveSurfaceColor, SetActiveSurfaceColor] = useState(PaintColorSchemeBuffer[0].Value);

    const Gnotify = useGlobalNotification();
    const notify = useToast();

    const [DesktopXPMode, SetDesktopXPMode] = useState(false);


    const ToastHeader = useRef(new Map());
    const GetToastID = (__id) =>
    {
        const _rand = parseInt(Math.ceil((Math.random() * 1000)));
        ToastHeader.current.set(__id, _rand);
        return _rand;
    }
    const FlushToastID = (__id) =>
    {
        const _flush_id = parseInt(ToastHeader.current.get(__id));
        ToastHeader.current.delete(__id);
        return _flush_id;
    }


    const setThemeVariables = async(themeConfig) => {
        Object.keys(themeConfig).forEach((key) => {
            document.documentElement.style.setProperty(key, themeConfig[key]);
        });
    };
    const SetSurfaceColor = async(surface_color)=>
    {
        if(surface_color['--background-dark_plus'] === '#ffffff' || surface_color['--background-dark_plus'] === '#b3b3b3') 
        {
            SetDarkMode(false);
        } else 
        {
            SetDarkMode(true);
        }
        
        SetActiveSurfaceColor(surface_color);
        await setThemeVariables(MixColor(surface_color, ActiveAccentColor));
    }
    const SetAccentColor = async(accent_color)=>
    {
        SetActiveAccentColor(accent_color);
        await setThemeVariables(MixColor(ActiveSurfaceColor, accent_color))
    }
    const SetAccentColorEXT = async(accent_color, surface_color)=>
        {
            SetActiveAccentColor(accent_color);
            await setThemeVariables(MixColor(surface_color, accent_color))
    }



    // const error_sound = new Howl({
    //     src: [error_log_sound]
    // })

    const WatchCarInfo = async (gps_token, car_id) => {
        try {
            const res = await FetchWhatsgpsCarInfo(car_id, gps_token);

            if(res.data)
            {
                if(res.data[0].status === '5') 
                {
                    Gnotify.Disturb(`Vehicle Motion Detected`, `Your vehicle [${res.data[0].carId}] is now moving. Time of detection: ${new Date().toLocaleTimeString()}`);

                    clearInterval(CarInfoIntervalRef.current);
                    CarInfoIntervalRef.current = null; // Reset the ref after clearing
                }
                
            }
    
        } catch (err) {
            // console.log(err);
        }
    };

    // useEffect(()=>{
    //     const _gps_token = localStorage.getItem('GPS-TOKEN');
    //     const _active_car = localStorage.getItem('GPS-ACTIVE-CAR');
    //     if(_gps_token && _active_car) 
    //         {
    //             CarInfoIntervalRef.current = setInterval(async () => {
    //                 WatchCarInfo(_gps_token, _active_car, 'my_token');
                    
    //             }, 8000);
    //         }

    //         return () => {
    //             if (CarInfoIntervalRef.current) {
    //                 clearInterval(CarInfoIntervalRef.current);
    //                 CarInfoIntervalRef.current = null; // Reset the ref after clearing
    //             }
    //         }
    // }, []);

    const parseData = (data) =>
    {
        const __userAccessWorkspaceIDList = [];
        const __userAccessworkshopList = [];

        
        for (const [key, value] of Object.entries(data)) {

            if (value !== -1) {
                __userAccessWorkspaceIDList.push(value);
                __userAccessworkshopList.push({ label: key, value: value });
            }
        }
        if(__userAccessworkshopList.length > 0) 
        {
            const _starter = __userAccessworkshopList[0];

            const _visit_workspace = localStorage.getItem('workspace_visit');
            if(_visit_workspace) 
            {
                const _json_p = JSON.parse(_visit_workspace);
                SwitchWorkspace(_json_p.ID, _json_p.Label);
            } else 
            {
                SwitchWorkspace(parseInt(_starter.value), _starter.label);
            }
        }

        setUserAccessWorkspaceIDList(__userAccessWorkspaceIDList);
        setUserAccessworkshopList(__userAccessworkshopList);
    }
    
    const HandleWhatGPSAPI = async() =>
    {
        if(!IsOnline) 
        {
            return;
        }
        try 
        {
            const AccountGPSRes = await VerifyWhatsgpsAccount();
            if(AccountGPSRes.data) 
            {
                const CarRes = await VerifyWhatsgpsCars(AccountGPSRes.data.token);
            }
        }catch(err) 
        {
            console.log(err);
        }
    }
    const QueryEmployeeData = async (data) =>{

        try 
        {

            // Prep WhatsGPS Data
            
            if(data.Type === "Classic") 
                return;

            const fd = new FormData();
            fd.append('target_id', data.USR_EmployeeID);
            const main_res = await QueryBaseEmployeeBySyntID(fd);
            setEmployeeData(main_res[0]);
            const _parse_context = main_res[0].JAKSBE_EmployeeContextProfile;

            SetSalaryArray(_parse_context.CTE_Profile.CTEP_GrossSalary);
            SetCompanyToEmployeeProfile(_parse_context.CTE_Profile);
            SetEmployeeDocumentProfile(_parse_context.EDOC_Profile);

            const _gps_ress = await HandleWhatGPSAPI();

        }catch(err) 
        {
            console.log(err.message);
            notify.ShowError(err.message);
        }

    }
    const GetGlobalConfig = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", 1);
            const main_res = await QueryGlobalObjectConfig(fd); 
            if(main_res.length > 0) 
            {
                SetGlobalConfig(JSON.parse(main_res[0].CONFIGGLOBAL_Config));
            }
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const SerializeUserData = async(clientX)=>
    {
        const _user_config = JSON.parse(clientX.USR_UserConfig);

        // Set User Theme
        SetSurfaceColor(_user_config.USR_UIConfig.PaintColor);
        SetAccentColorEXT(String(_user_config.USR_UIConfig.KeyColor), _user_config.USR_UIConfig.PaintColor);

        // Query Employee Info if the user is standard
        QueryEmployeeData(clientX);

        UpdateUIScale(parseFloat(_user_config.USR_UIConfig.UIScaleFactor));


        // Parse the user Workspace Access
        // This will help us to know to which wrokspace this user has access to
        parseData(_user_config.USR_WorkspacePrivilege);

        // Store the user data this will be usefull if any child component want to get know
        // who the user is
        setUser(clientX);
        
        SetUserSpecialAccessList(clientX.UARI_SpecialAccessList ? JSON.parse(clientX.UARI_SpecialAccessList) : null);
    }

    const UpdateGlobalConfig = async()=>
    {
        GetGlobalConfig();
    }

   

    const handleOnline = () => {
        
        notify.ShowSuccess("You're now back online.");
        SetIsOnline(true);
    }
    const handleOffline = () => {
        // navigate('/offline');
        SetIsOnline(false);
    }

    // hearbeat
    useEffect(()=>{

        if(!user) { return; }


        socket.emit("on-login", { UserID: user.USR_UserAccountID });

        const interval = setInterval(()=> {
            socket.emit("heartbeat"); 
        }, 10000);

        return ()=>{
            clearInterval(interval);
            socket.emit("on-logout", { UserID: user.USR_UserAccountID });
        }

    }, [user])

    useEffect(()=>{


        socket.on('force-terminate-user-session', (data)=>{
            if(user) 
            {
                if(parseInt(data.UserID) === user.USR_UserAccountID) 
                {
                    setUser(null);
                    localStorage.clear();
                    navigate('/force-termination');
                }
            }
            
        })

        return () => {
            socket.off('force-terminate-user-session'); // Remove listener
        };

    }, [user])

    const UpdateUIScale = async(scaleValue)=>
    {
        const baseScales = {
            min: 0.6, 
            mid: 0.7, 
            lrg: 1.0  
          };
        
          document.documentElement.style.setProperty("--default-font-scale-min", `${(baseScales.min * scaleValue).toFixed(2)}em`);
          document.documentElement.style.setProperty("--default-font-scale-mid", `${(baseScales.mid * scaleValue).toFixed(2)}em`);
          document.documentElement.style.setProperty("--default-font-scale-lrg", `${(baseScales.lrg * scaleValue).toFixed(2)}em`);
    }

    const ReAuthUser = async()=>
    {
        setLoading(true);
        SetCheckingUserAuth(true);
        try 
        {
            const main_res = await AuthClient();
            await GetGlobalConfig();
            SerializeUserData(main_res.AuthorizedUser);
        }catch(err)
        {
            
        } finally 
        {
            setLoading(false);
            SetCheckingUserAuth(false);
        }
    }

    
    useEffect(() => {
        
        ReAuthUser();

        // Attach event listener
        window.addEventListener("online", handleOnline)
        window.addEventListener("offline", handleOffline)

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("online", handleOnline)
            window.removeEventListener("offline", handleOffline)
        };


    }, []);

    const getPublicIP = async()=>
    {
        let _public_ip = "##";
        try 
        {
            await fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                _public_ip = data.ip;
            })
        }catch(err) 
        {
            return _public_ip;
        }    
    }
    async function getGeolocation() {
        try {
          // Try to get geolocation if permission is granted
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          
          return position;  
        } catch (error) {
          return "##";
        }
      }
    const loginAction = async (data, time_stamp) => {

        notify.ShowLoad("Validating Your Credentials...", GetToastID(time_stamp));
        
        setServerRes("Validating...");
        
        try 
        {
            setLoading(true);

            const _location = "##";//await getGeolocation();
            const _public_ip = "##";// await getPublicIP();
            const fd = new FormData();
            fd.append("username", data.username);
            fd.append("password", data.password); 
            fd.append("public_ip", _public_ip);
            fd.append("location_ac", JSON.stringify(_location))
            const main_res = await CreateUserLoginRequest(fd);
            setServerRes(main_res.Msg);
            
            notify.ShowSuccessUpdate(main_res.Msg, FlushToastID(time_stamp));
            
            SerializeUserData(main_res.user);

            // and finally go to the prepared page
            navigate(main_res.user.UARI_LandingPageRoute);
            socket.emit('on-login', {UserID: main_res.user.USR_UserAccountID})
            notify.Close();
            setLoading(false);

        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, FlushToastID(time_stamp));
            console.log(err.message);
            // error_sound.play();
            setLoading(false);
        }

    }

    const logOut = async() => {
        notify.ShowLoad("Signing out...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("user_data", JSON.stringify(user))
            const main_res = await CreateSignOutRequest(fd);
        }catch(err)
        {
            notify.ShowError(err.message)
        }
        
        if(user) 
        {
            socket.emit('on-logout', {UserID: user.USR_UserAccountID}); 
        }
        setLoading(true);
        setUser(null);
        SetSurfaceColor(PaintColorSchemeBuffer[1].Value);
        localStorage.clear();
        navigate("/login");
        // window.location.reload();
        setLoading(false);
        notify.ShowSuccessUpdate("Session Closed", 10);
    }

    const QuickThemeLight = () =>
    {
        SetDarkMode(false);
        SetSurfaceColor(PaintColorSchemeBuffer[2].Value);
    }
    const QuickThemeDark= (idx = 1) =>
    {
        SetDarkMode(true);
        SetSurfaceColor(PaintColorSchemeBuffer[idx].Value);
    }

    const SwitchWorkspace = (branch_id, branch_label)=>
    {
        setActiveWorkShopName(branch_label);
        setActiveworkshop(branch_id);

        localStorage.setItem('workspace_visit', JSON.stringify({ID: branch_id, Label: branch_label}));
    }

    const HandleFastWorkspaceSwitch = (event)=>
    {
        
        if(!event.key)
            return;
        const _click_key = event.key.toLowerCase();
        let _target_data = null;
        
        for(let i = 0; i < userAccessworkshopList.length; i++) 
        {
            const _letter = userAccessworkshopList[i].label[0].toLowerCase();
            if(_letter === _click_key) 
            {
                _target_data = userAccessworkshopList[i];
                break;
            }
        } 

        if (event.altKey && 
            (event.key === 'q' || event.key === 'Q')) {
            event.preventDefault(); 
            logOut();
        }else if (event.ctrlKey && event.shiftKey && _target_data) {
            event.preventDefault(); 
            SwitchWorkspace(_target_data.value, _target_data.label); 
            notify.ShowWarn(`Workspace Switched to ${_target_data.label}`)
        }else if(event.altKey && event.key.toLowerCase() === 'd') 
        {
            event.preventDefault(); 
            SetDesktopXPMode((prevMode) => {
                SetDesktopXPMode(prevMode);
                return !prevMode; 
              });
        }else if(event.altKey && event.key.toLowerCase() === 'l') 
        {
            event.preventDefault(); 
            SetDarkMode((prevMode) => {
                if (prevMode) {
                  QuickThemeLight();
                } else {
                  QuickThemeDark();
                }
                return !prevMode; 
              });
        }
    }
    
        useEffect(()=>{      
            window.addEventListener("keydown", HandleFastWorkspaceSwitch);

            return () => {
              window.removeEventListener("keydown", HandleFastWorkspaceSwitch);
            };
        }, [user])

    return (
        <AuthContext.Provider value={
            {
                DarkMode,
                QuickThemeLight,
                QuickThemeDark,
                user, 
                UserSpecialAccessList,
                loading, 
                CheckingUserAuth,
                serverRes, 
                userAccessWorkspaceIDList, 
                userAccessworkshopList, 
                activeWorkShop,
                SwitchWorkspace,
                loginAction, 
                logOut,
                activeWorkShopName,
                setActiveWorkShopName,
                employeeData,
                CompanyToEmployeeProfile,
                EmployeeDocumentProfile,
                IsOnline,
                SetAccentColor,
                SetSurfaceColor,
                GlobalConfig,
                UpdateGlobalConfig,
                UpdateUIScale,
                DesktopXPMode,
                SetDesktopXPMode
            }}>
            {IsOnline ? null : <OfflinePage />}
            <div style={{ display: IsOnline ? 'block' : 'none' }}>
                {children}
            </div>
        </AuthContext.Provider>
    )
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
}