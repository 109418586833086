import classes from './ViewMappedItem.module.css'
import data_win from '../../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { useRef, useState } from 'react';
import { useAuth } from '../../../../../../AuthProvider';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import {FetchWarehouseItem} from '../../../../../../api'
import { useEffect } from 'react';
import UIButton from '../../../../../Components/UIButton/UIButton';
import { Article, ChartLineUp, CheckSquare, Info } from '@phosphor-icons/react';

import MasterDataRegInput from '../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import {ACTIVE_REPORT_ETHIOPIAN_YEAR, Custom_DD_Style, ETHIOPIAN_MONTHS_DROPDOWN, PrepareDropDownList, ToGregorianCalendar, mapToObject} from '../../../../../../SharedComp/Utility'
import Select from 'react-select';
import { useToast } from '../../../../../../ToastContext';
import { QueryDateRangeInventoryData } from '../../../../../../SharedComp/API/InventoryStock/API_FE_InventoryStock';

import MasterWarehouseReportPreview from '../MasterWarehouseReportPreview/MasterWarehouseReportPreview';
import SingleWarehouseEntryView from './SingleWarehouseEntryView/SingleWarehouseEntryView';
import ModifyInventoryItem from './ModifyInventoryItem/ModifyInventoryItem';
import LoadingPlaceholder from '../../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';

const ViewMappedItem = ({CloseModal, TargetData}) => {

    const formRef = useRef();
    const auth = useAuth();
    const notify = useToast();

    const [Loading, SetLoading] = useState(false);

    const [WarehouseItemList, SetWarehouseItemList] = useState([]);
    const [InventoryItemViewList, SetInventoryItemViewList] = useState([]);

    const [validReportReadItems, SetvalidReportReadItems] = useState([]);

    // Filter Data
    const [Ryear, SetRyear] = useState(null);
    const [ReportTargetMonth, SetReportTargetMonth] = useState(null);
    const [REndMonth, SetREndMonth] = useState(null);

    // Preview
    const [OpenPreviewReport, SetOpenPreviewReport] = useState(false);
    const [ReviewDataPackage, SetReviewDataPackage] = useState([]);
    const [OpenReportPReviewActionButtons, SetOpenReportPReviewActionButtons] = useState(false);
    const GenerationActionButtonWrapperRef = useRef();
    const [SummeryFlag, SetSummeryFlag] = useState('');

    // Edit Inventory
    const [OpenEditInventoryModal, SetOpenEditInventoryModal] = useState(false);
    const [EditItemDataPackage, SetEditItemDataPackage] = useState(null);

    const [BatchSelect, SetBatchSelect] = useState(false);


    const FetchLinkedItem = async()=>{
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("BranchID", TargetData.BranchID);
            fd.append("StoreID", TargetData.WhID);
            const res = await FetchWarehouseItem(fd);
            SetWarehouseItemList(res);
            SetInventoryItemViewList(res);
            SetLoading(false);
            setTimeout(() => {
            }, 5000);
        }catch(err) 
        {
            SetLoading(false);
        }
    }

    useEffect(()=>{
        FetchLinkedItem();
    }, [])

    const FilterIvItemByName = async(text)=>{

        const temp_list = [];
        for(let i = 0; i < WarehouseItemList.length; i++) 
        {
            const check = WarehouseItemList[i].IVItemName.toLowerCase();
            if(check.includes(text.toLowerCase()))
                temp_list.push(WarehouseItemList[i])
        }

        SetInventoryItemViewList(temp_list);
    }

    const QueryReportData = async(_target_start_date, _target_end_date)=>
    {
        notify.ShowLoad("Preparing your report data...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("StartDate", _target_start_date);
            fd.append("EndDate", _target_end_date);
            fd.append("report_ready_items", JSON.stringify(validReportReadItems));
            fd.append("target_branch", TargetData.BranchID);
            const res = await QueryDateRangeInventoryData(fd);

            if(res.length <= 0) 
            {
                notify.ShowErrorUpdate("Query result returned empty data set.", 10);
                return;
            }

            SetReviewDataPackage(res);
            SetOpenPreviewReport(true);
            notify.ShowSuccessUpdate("Preparing view....", 10);
        }catch(err) 
        {
            notify.ShowSuccessUpdate(err.message, 10);
        }
    }

    const PrepareDetailReport = async(summery_flag)=>
    {
        SetSummeryFlag(summery_flag);
        if(validReportReadItems.length <= 0) 
        {
            notify.ShowError("Please select atleast one item to create report");
            return;
        }

        if(!ReportTargetMonth || !Ryear) 
        {
            notify.ShowError("Unable to prepare report: Incomplete Information");
            return;
        }

        const _target_start_date = ToGregorianCalendar(Ryear, ReportTargetMonth, 1);
       

        let _last_day_of_month = 30;

        // If the the month the is the 13th which paguma 
        // We need to deal with the stupid ethiopian calander system
        if(ReportTargetMonth === 13) 
        {
            // So if it is the 13th month the last day of the month / day count is 5 UNLESS 
            // It is leep year

            // So to begin with let's assume IT IS NOT
            let _unit = 0;

            // If it is one we increament 1 meaning the month date count will be 6
            if(Ryear - 1 % 4 === 3)  { _unit = 1; }
            _last_day_of_month = 5 + _unit; // Here
        }
       
        const _target_end_date = ToGregorianCalendar(Ryear, ReportTargetMonth, _last_day_of_month);
        // Now we have the start and end date of the filter which is super cool and we can start filter the data
        await QueryReportData(_target_start_date, _target_end_date);

        
    }

    const ReportValidItemsCallback = (value, item_id)=>
    {
        
        SetvalidReportReadItems((prev)=>
        {
            const __exist = [...prev];

            const _idx = __exist.findIndex((target)=>{ return target === item_id; });

            if(_idx >= 0) 
            {
                if(!value) 
                {
                    __exist.splice(_idx, 1);
                }
            } else 
            {
                __exist.push(item_id);
            }

            return __exist;
        })
    }

    const EditInventoryCallback = (data)=>
    {
        SetEditItemDataPackage(data);
        SetOpenEditInventoryModal(true);
    }
    const IsCheckedCallback = (id)=>
    {
        const _idx = validReportReadItems.findIndex((item)=>{return item === id});

        return _idx >= 0;
    }
    const SelectAllItem = ()=>
    {
        if(InventoryItemViewList.length === validReportReadItems.length) 
        {
            SetvalidReportReadItems([]);
            return;
        }
        const _valid_list = [];
        for(let i = 0; i < InventoryItemViewList.length; i++)
        {
            _valid_list.push(InventoryItemViewList[i].ItemID)
        }
        SetvalidReportReadItems(_valid_list);

    }

    useEffect(()=>
    {
        const handleClickOutside = (event) => {
            if (GenerationActionButtonWrapperRef.current && !GenerationActionButtonWrapperRef.current.contains(event.target)) {
                SetOpenReportPReviewActionButtons(false);
            }
          };
      
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            {OpenPreviewReport && <MasterWarehouseReportPreview CoreBranchData={TargetData} ReportFlag={SummeryFlag} DataSet={ReviewDataPackage} OpenModal={SetOpenPreviewReport}/>}
            {OpenEditInventoryModal && <ModifyInventoryItem TargetData={EditItemDataPackage} OpenController={SetOpenEditInventoryModal}/>}

            <div className={`${data_win.data_main} ${classes.data_main}`} >
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                    Warehouse Items <span className={`${data_win.data_create_name}`}> - {TargetData.BranchName} @ {TargetData.WHName}</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body} ${classes.data_body}`}>

                    <div className={`${classes.header_wrapper_list}`}>
                        <div className={`${classes.filter_workshop_by_name}`}>
                            <div className={`${classes.workshop_filter_input_wrapper}`}>
                                <input onChange={(e)=>{FilterIvItemByName(e.target.value)}} type="text" placeholder='Search Inventory' />
                            </div>
                            <UIButton onClick={()=>{SelectAllItem()}} Icon={<CheckSquare weight='bold'/>} Variant='Secondary' label={"Toggel Selection"}/>
                        </div>
                    </div>

                    <div className={`${classes.filter_list_wrapper}`}>

                        <div className={`${classes.filter_paper_list_wrapper}`}>
                            <div className={`${classes.filter_inner_list_wrapper}`}>
                                {!Loading &&
                                    InventoryItemViewList.map((item, idx)=>(
                                        <SingleWarehouseEntryView 
                                            IsChecked={IsCheckedCallback} 
                                            SelectItems={ReportValidItemsCallback} 
                                            EditInv_CALLBACK={EditInventoryCallback} 
                                            DataIndex={idx} 
                                            Data={item}
                                        />
                                    ))
                                }
                                {!Loading &&
                                    InventoryItemViewList.length === 0 && <div className={`${classes.empty_not}`}>
                                            there are no item, in ({TargetData.BranchName} - {TargetData.WHName}).
                                    </div>      
                                }
                                {
                                    Loading && 
                                    <LoadingPlaceholder preview_text='Loading Store Items'/>
                                }
                                    
                                    

                            </div>
                            
                            <div className={`${classes.report_prep_config_wrapper}`}>
                                <div className={`${classes.report_generate_header_wrapper}`}>
                                    <h2>Report Configuration</h2>
                                </div>

                                <div className={`${classes.report_generae_body_master_wrapper}`}>

                                    <div className={`${classes.report_generae_body_inner_master_wrapper}`}>

                                        <div className={`${classes.report_generate_time_frame_selection}`}>
                                            <div className={`${classes.report_generate_time_frame_selection_inner_header}`}>
                                                <h3>Select Year-Month</h3>
                                                <span onClick={()=>{SetOpenReportPReviewActionButtons(!OpenReportPReviewActionButtons)}} className={`${classes.generate_init_spa}`}><Article weight='fill'/> Generate Report</span>
                                           {OpenReportPReviewActionButtons && <div ref={GenerationActionButtonWrapperRef} className={`${classes.report_generate_time_frame_selection_report_button_action}`}>
                                                <UIButton Variant='Secondary' Icon={<ChartLineUp weight='bold'/>} onClick={()=>{PrepareDetailReport("DETAIL_SUMMERY")}}  label={"Prepare Detail Report"}/>
                                                <UIButton Variant='Secondary' Icon={<ChartLineUp weight='bold'/>} onClick={()=>{PrepareDetailReport("DAY_SUMMERY")}}  label={"Daily Summerized Report"}/>
                                                <UIButton Variant='Secondary' Icon={<ChartLineUp weight='bold'/>} onClick={()=>{PrepareDetailReport("WEEK_SUMMERY")}}  label={"Weekly Summerized Report"}/>
                                                <UIButton Variant='Secondary' Icon={<ChartLineUp weight='bold'/>} onClick={()=>{PrepareDetailReport("MONTH_SUMMERY")}}  label={"Monthly Summerized Report"}/>
                                            </div> }
                                        </div>
                                            <div className={`${classes.report_generate_time_frame_selection_inner}`}>
                                                <div className={`${classes.inner_report_gn_single_input_wrap}`}>
                                                    <MasterDataRegInput 
                                                        input={
                                                            <Select
                                                                onChange={(data)=>{SetRyear(data.value)}}
                                                                name={"monthly_report"}
                                                                options={ACTIVE_REPORT_ETHIOPIAN_YEAR}
                                                                placeholder={"Year"}
                                                                isSearchable={true}
                                                                styles={Custom_DD_Style}
                                                                required ={true}
                                                            />
                                                            
                                                        }  />
                                                </div>
                                                <div className={`${classes.inner_report_gn_single_input_wrap}`}>
                                                    <MasterDataRegInput 
                                                        input={
                                                            <Select
                                                                onChange={(data)=>{SetReportTargetMonth(data.value)}}
                                                                name={"monthly_report"}
                                                                options={ETHIOPIAN_MONTHS_DROPDOWN}
                                                                placeholder={"Start Month"}
                                                                isSearchable={true}
                                                                styles={Custom_DD_Style}
                                                                required ={true}
                                                            />
                                                            
                                                        }  />
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                     
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default ViewMappedItem;