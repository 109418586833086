import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faIdCard, faPenNib, faTimesCircle, faUser, faUserCog, faUserTie } from '@fortawesome/free-solid-svg-icons';
import classes from './EmployeeView.module.css'
import { useEffect, useState } from 'react';
import EmpCredit from './Credit/EmpCredit';
import EmpPenality from './Penality/EmpPenality'
import EmpAttendy from './Attendance/EmpAttendy'
import EmpAllowance from './Allowance/EmpAllowance'
import EmpBenefit from './Benefit/EmpBenefit'
import EmpPto from './PTO/EmpPto'
import DZ from './DZ/Dz';
import data_win from '../../../../SharedComp/CommonStyle/DataCreator.module.css'
import Flag from 'react-world-flags';
import EmpStatus from './Status/EmpStatus';
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';

import { useTargetEmployee } from './SingleEmployeeContextProvider/SingleEmployeeContextProvider';
import EditableInputPreviewComponent from './EditableInputPreviewComponent/EditableInputPreviewComponent';
import { useAuth } from '../../../../AuthProvider';
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
export default function EmployeeView({RefreshDataView, EmpViewController}) 
{
    const auth = useAuth();
    const [MiniTabBar, SetMiniTabBar] = useState([true, false, false, false, false, false, false, false]);
    const EmpData = useTargetEmployee();

    function MakeActiveTabBar(idx) 
    {
        const tab_bar_count = 8;
        let temp_bar = [];
        for(var i = 0; i < tab_bar_count; i++)
        {
            if(idx == i)
            {
                temp_bar.push(true);
            } else 
            {
                temp_bar.push(false);
            }
        }

        SetMiniTabBar(temp_bar);
    }

    return (
            <div className={`${data_win.main_data_creator_wrapper} ${classes.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>
                    <div className={`${classes.employee_view_header}`}>
                        <div className={`${classes.employee_view_header_inner}`}>
                            <h2>Profile</h2>
                        </div>
                        <div className={`${classes.employee_view_header_inner}`}>
                            <button onClick={() => {EmpViewController(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                        </div>
                    </div>

                    {!EmpData.Loading && 
                    <div className={`${classes.employee_detail_body}`}>

                            <div className={`${classes.employee_detail_right}`}>

                                <img className={`${classes.employee_pic}`} src={getBaseURL() + EmpData.Core.JAKSBE_EmployeeContextProfile?.EDOC_Profile.EDOCP_ProfilePicture} alt="name stuff" />

                                <div className={`${classes.employee_respon}`}>
                                    <Flag style={{ width: 35, height: 25}} code={EmpData.Core.JAKSBE_Nationality}/>
                                    <li className={`${classes.emp_name_big}`}> <FontAwesomeIcon className={`${classes.employee_resp_icon}`}  icon={faUserTie}></FontAwesomeIcon>{EmpData.Core.JAKSBE_FullName} </li>
                                    <li> <FontAwesomeIcon className={`${classes.employee_resp_icon}`}  icon={faUserCog}></FontAwesomeIcon>{EmpData.Core.JAKSBE_EmployeeContextProfile?.CTE_Profile?.HRMJPT_Name}</li>
                                </div>


                                <div className={`${classes.employee_personal_info}`}>
                                    <EditableInputPreviewComponent Label={"Name"} PreviewValue={EmpData.Core.JAKSBE_FullName} DataType='text'/>
                                    <EditableInputPreviewComponent Label={"Phone"} PreviewValue={EmpData.Core.JAKSBE_PhoneNumber} DataType='text'/>
                                    <EditableInputPreviewComponent Label={"Email"} PreviewValue={EmpData.Core.JAKSBE_Email} DataType='email'/>
                                    <EditableInputPreviewComponent Label={"Address"} PreviewValue={EmpData.Core.JAKSBE_Address} DataType='text'/>
                                    <EditableInputPreviewComponent Label={"Age"} PreviewValue={EmpData.Core.JAKSBE_Age} DataType='text'/>

                                </div>
                                
                            </div>

                            
                            <div className={`${classes.employee_detail_left}`}>

                                {/* Create mini tab bar */}

                                <div className={`${classes.emp_detail_tab_bar}`}>
                                        <li><button className={`${ MiniTabBar[0] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(0)}}>Employee</button></li>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectEmployeeCreditLine && <li><button className={`${ MiniTabBar[1] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(1)}}>Credit Line</button></li> }
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectEmployeePenalty && <li><button className={`${ MiniTabBar[2] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(2)}}>Penality</button></li> }
                                        {/* <li><button className={`${ MiniTabBar[3] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(3)}}>Attendance</button></li> */}
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectEmployeeAllowance && <li><button className={`${ MiniTabBar[4] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(4)}}>Allowance</button></li> }
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_InspectEmployeeBenefit &&  <li><button className={`${ MiniTabBar[5] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(5)}}>Benefit</button></li> }
                                        {/* <li><button className={`${ MiniTabBar[6] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(6)}}>Paid Time Off (PTO)</button></li> */}
                                        {/* <li><button className={`${ MiniTabBar[7] ? classes.active_tab_bar_but : ''}`} onClick={() => {MakeActiveTabBar(7)}}>Termination & Resign</button></li> */}
                                </div>

                                {/*  Emp Conditional rendering stuff */}
                                

                                {MiniTabBar[0] && <EmpStatus RefershBranchData={RefreshDataView} ViewOpenController={EmpViewController} />}
                                {MiniTabBar[1] && <EmpCredit />}
                                {MiniTabBar[2] && <EmpPenality />}
                                {MiniTabBar[3] && <EmpAttendy />}
                                {MiniTabBar[4] && <EmpAllowance />}
                                {MiniTabBar[5] && <EmpBenefit />}
                                {MiniTabBar[6] && <EmpPto/>}
                                {/* {MiniTabBar[7] && <DZ />} */}

                            </div>

                    </div>
                    }
                    {EmpData.Loading && 
                        <LoadingPlaceholder preview_text='Loading Employee Profile'/>
                    }



                </div>      
            </div>
    );
}