import { useState } from 'react'
import classes from './ArchiveBranchGroup.module.css'
import { useEffect } from 'react';
import { useToast } from '../../../../ToastContext';

import {FetchBranchPreorderHistory} from '../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'

import { Files, Folders, StackMinus } from '@phosphor-icons/react';
import TimeAgo from '../../../../SharedComp/TimeElapsed/TimeAgo';
import UIButton from '../../../Components/UIButton/UIButton';

import ShippingDialog from '../../ManageDistribution/Component/ShippingDialog/ShippingDialog';
import { useAuth } from '../../../../AuthProvider';
import { ToEthiopianCalander } from '../../../../SharedComp/Utility';


export default function ArchiveBranchGroup({TargetData}) 
{

    const auth = useAuth();
    const [GData, SetGData] = useState();
    const [DistorDataList, SetDistorDataList] = useState([]);
    const [SubProductList, SetSubProductList] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);
    const [OpenShippingDialog, SetOpenShippingDialog] = useState(false);
    const [ActiveViewShipmentCollection, SetActiveViewShipmentCollection] = useState([]);
    const [DetailShipmentView, SetDetailShipmentView] = useState(false);
    const [TargetGroupData, SetTargetGroupData] = useState([]);

    const notify = useToast();

    const ExpandDailyShipmentDetail = async(list = null)=>
    {
        if(!list) 
        {
            SetDetailShipmentView(false);
            SetActiveViewShipmentCollection([]);
        } else 
        {
            SetDetailShipmentView(true);
            SetActiveViewShipmentCollection(list);
        }
        
    }

    const PreviewPrintableDoc = (target_batch)=>
    {
        SetOpenShippingDialog(true);
        SetTargetGroupData(target_batch);
    }

    const FetchDistributionData = async()=>
    {
        try 
        {

            const fd = new FormData();
            fd.append("processor_workshop_id", TargetData.BranchID);
            fd.append("base_workspace_id", auth.activeWorkShop);

            // Here Fetch resolved Production order
            const main_res = await FetchBranchPreorderHistory(fd);
            SetDistorDataList(main_res);

        }catch(err) 
        {
            console.log("Falied to Fetch Distribution Data: " + err.message);
            notify.ShowError(err.message);

        }
    }


    useEffect(()=>{

        FetchDistributionData();

    }, [])



    return (
        <div className={`${classes.archive_branch_group_main_wrapper}`}>

             {OpenShippingDialog && 
                    <ShippingDialog 
                            Preview={true}
                            UpdateViewToggel={RefreshView} 
                            UpdateView={SetRefreshView} 
                            DataList={TargetGroupData} 
                            BranchData={TargetData}
                            Close_CALLBACK={SetOpenShippingDialog}/>
                            }

            <div className={`${classes.archive_branch_group_header}`}>
                <h3 style={{cursor: 'pointer'}} onClick={()=>{ExpandDailyShipmentDetail(null)}}> <span className={`${classes.distro_icon_header}`}><Folders weight='fill'/></span> {TargetData.BranchName} <span className={`${classes.distro_common_text}`}>Archive</span></h3>
            </div>

            <div className={`${classes.data_list_wrapper}`}>

                {!DetailShipmentView &&
                    DistorDataList.map((item, idx)=>(
                        <li key={idx} onClick={()=>{ExpandDailyShipmentDetail(item.DailyShippmentList)}}>
                            <span>{ToEthiopianCalander(new Date(item.ShipmentDate))}</span>
                            <span className={`${classes.remain_time_to_purge}`}>{item.ShipmentCount} Shipment</span>
                        </li>
                    ))
                }
                {DetailShipmentView &&
                     ActiveViewShipmentCollection.map((item, idx)=>(
                        <li style={{background: `var(--background-dark_minus)`, border: `2px solid var(--background-dark_plus)`}} key={item.KSOBATCH_ShipUUID + idx} onClick={()=>{}}>
                            <span>{idx + 1}) {item.KSOBATCH_ShipUUID}</span>
                            <span>{item.KSOBATCH_ShippedByStringName}</span>
                            <span>{new Date(item.KSOBATCH_ShipmentDate).toLocaleTimeString()}</span>
                            <span><UIButton onClick={()=>{PreviewPrintableDoc(item.KSOBATCH_PackageList)}} Variant='Secondary' label={"View Doc"} Icon={<Files weight='bold'/>}/></span>
                        </li>
                    ))
                }
                {
                    DistorDataList.length === 0 && 
                    <div className={`${classes.empty_his}`}>
                        <div className={`${classes.empty_his_icon}`}>
                            <StackMinus weight='bold'/>
                        </div>
                        <div className={`${classes.empty_hist_text}`}>
                            <h2>no Distribution History</h2>
                        </div>
                    </div>
                }
            </div>  

        </div>
    )
}