import { ToEthiopianCalander } from '../Utility'
import classes from './PaperFooter.module.css'


export default function PaperFooter({Name, Role, Phone})
{
    return (
        <div className={`${classes.paper_footer_note_wrapper}`}>


            <div className={`${classes.owner_verify}`}>
                <li className={`${classes.owner_name}`}>{Name}</li>
                <li>{Role}</li>
                <li>{ToEthiopianCalander(new Date())}</li>
            </div>

        </div>
    )
}