import { faCheckCircle, faFilter, faPlus, faTriangleExclamation, faUserSecret, faUserTie } from '@fortawesome/free-solid-svg-icons';
import classes from './UserManagment.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import data_win from '../../SharedComp/CommonStyle/DataCreator.module.css'
import ClassicUserCreate from './Component/ClassicUserCreate/ClassicUserCreate';
import StandardUserCreate from './Component/StandardUserCreate/StandardUserCreate';
import ManageUser from './Component/View/ManageUser';
import { useEffect, useState } from 'react';

import NotificationPopUp from '../../SharedComp/NotificationPopUp/NotificationPopUp';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';

import { formatDistanceToNow } from 'date-fns';
import { Tooltip } from 'react-tooltip'
import UIButton from '../Components/UIButton/UIButton';
import { Checks, Empty, PencilCircle, UserCirclePlus, Warning, WifiHigh, WifiSlash } from '@phosphor-icons/react';
import { useAuth } from '../../AuthProvider';
import { getBaseSocket } from '../../SocketBase';
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { useToast } from '../../ToastContext';
import { QueryAllUserWithAccount, TerminateAllUserSessions } from '../../SharedComp/API/UserAccess/API_FE_UserManage';
import { useDAControl } from '../../DAControlContext';
import EmptyProxyDataView from '../../SharedComp/DataViewTableProxy/EmptyDataViewTable';



const getRelativeTime = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
};

export default function UserManagment() 
{
    
    const DAControl = useDAControl();
    const socket = getBaseSocket();
    const auth = useAuth();
    const notify = useToast();

    const [Loading, SetLoading] = useState(false);
    const [OpenClassicUserCreate, SetOpenClassicUserCreate] = useState(false);
    const [OpenStandardUserCreate, SetOpenStandardUserCreate] = useState(false);
    const [OpenManageUser, SetOpenManageUser] = useState(false);

    const [ActiveUser, SetActiveUser] = useState();


    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);


    const [UserList, SetUserList] = useState([]);
    const [UserListOrigin, SetUserListOrigin] = useState([]);


    function SelectUser(item) 
    {
        SetActiveUser(item);
        SetOpenManageUser(true);
    }


    function MsgCallback(res) 
    {
        SetOpenClassicUserCreate(false);
        SetOpenStandardUserCreate(false);
        SetOpenManageUser(false);

        LocalMsgTrigger(res.data.Msg, res.data.MyStateCode);
    }

    const SortUserByName = (data_array)=>
    {
        data_array.sort((a, b)=>
        {
            const nameA = a.USR_FirstName.toLowerCase();
            const nameB = b.USR_FirstName.toLowerCase();
            
            if (nameA < nameB) return -1; 
            if (nameA > nameB) return 1;
                
            return 0;                     
        });

        return data_array;
    }
    function UpdateList()
    {
        SetRefeshList(new Date().getMilliseconds());
    }
 
    function LocalMsgTrigger(msg, stat) 
    {
        if(stat === 1) 
        {
            SetRefeshList(!RefeshList);
        }
        SetMsgPkg({Key : Math.random() * 10000, Msg: msg, State: stat})
        SetOpenMyNoti(true); 
    }

    const handleUserUpdate = async() =>
    {
        // SetRefeshList(new Date().getMilliseconds());
        try 
        {
            const main_res = await QueryAllUserWithAccount();

            FilterInitial(main_res);
            SetUserListOrigin(main_res);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }

    const fetchUsers = async () =>{
        SetLoading(true);
        try 
        {
            const main_res = await QueryAllUserWithAccount();

            FilterInitial(main_res);
            SetUserListOrigin(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
            notify.ShowError(err.message);
        }
    }

    const TerminateOmniSession = async()=>
    {
        notify.ShowLoad("Processing you request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("master_user", auth.user.USR_UserAccountID);
            const main_res = await TerminateAllUserSessions(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            UpdateList();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message,10);
        }
    }

    useEffect(() => {
        const handleOnline = (data) => handleUserUpdate();
        const handleOffline = (data) => handleUserUpdate();

        socket.on("user-back-online", handleOnline);
        socket.on("user-back-offline", handleOffline);

        return () => {
            socket.off("user-back-online", handleOnline);
            socket.off("user-back-offline", handleOffline);
        };
    }, []);

    useEffect(()=>{
        fetchUsers();
    }, [RefeshList, auth.activeWorkShop, auth.user])


    // Utility functions
    const FilterInitial = (data_array)=>
    {
        // Here we sort and show only online users
        const _valid_list = [];
        for(let i = 0; i < data_array.length; i++) 
        {
            const _user_config = JSON.parse(data_array[i].USR_UserConfig);
            if(_user_config.USR_IsOnline) 
            {
                _valid_list.push(data_array[i]);
            }
           
        }

        SortUserByName(_valid_list);
        SetUserList(_valid_list);
    }
    const CollectOfflineUser = () =>
    {
        const _valid_list = [];
        for(let i = 0; i < UserListOrigin.length; i++) 
        {
            const _user_config = JSON.parse(UserListOrigin[i].USR_UserConfig);
            if(!_user_config.USR_IsOnline && UserListOrigin[i].USR_AccountState === "Active") 
            {
                _valid_list.push(UserListOrigin[i]);
            }
        }
        SortUserByName(_valid_list);
        SetUserList(_valid_list);
    }
    const CollectInactiveUser = () =>
    {
        const _valid_list = [];
        for(let i = 0; i < UserListOrigin.length; i++) 
        {
            if(UserListOrigin[i].USR_AccountState !== "Active") 
            {
                _valid_list.push(UserListOrigin[i]);
            }
        }
        SortUserByName(_valid_list);
        SetUserList(_valid_list);
    }
    const ShowAllActiveUsers = ()=>
    {
        const _valid_list = [];

        for(let i = 0; i < UserListOrigin.length; i++) 
        {
            if(UserListOrigin[i].USR_AccountState === "Active")
            {
                _valid_list.push(UserListOrigin[i]);
            }
        }

        SortUserByName(_valid_list);
        SetUserList(_valid_list);
    }
    const FilterByName = (key)=>
    {
        const _valid_list = [];
        for(let i = 0; i < UserListOrigin.length; i++) 
        {
            if(UserListOrigin[i].USR_FirstName.toLowerCase().includes(key.toLowerCase())) 
            {
                _valid_list.push(UserListOrigin[i]);
            }
        }
        SortUserByName(_valid_list);
        SetUserList(_valid_list);
    }



    return (
       <div className={`${classes.user_managment_main_wrapper}`}>
        <Tooltip className={`${data_win.tool_quick}`} id="usr_account_sign_in_info" place="top" />
        {OpenMyNoti && <NotificationPopUp key={MsgPkg.Key} msg={MsgPkg.Msg} state={MsgPkg.State} />}

        {OpenClassicUserCreate      && <ClassicUserCreate Close_CALLBACK={SetOpenClassicUserCreate} MsgTrigger_CALLBACK={MsgCallback} /> }
        {OpenStandardUserCreate     && <StandardUserCreate Close_CALLBACK={SetOpenStandardUserCreate} MsgTrigger_CALLBACK={MsgCallback} /> }
        {OpenManageUser             && <ManageUser RefCallBack={UpdateList} UsrData={ActiveUser} Close_CALLBACK={SetOpenManageUser} MsgTrigger_CALLBACK={MsgCallback} /> }


        <div className={`${classes.user_data_wrapper}`}>

            <div className={`${classes.user_box_wrapper}`}>

                <div className={`${classes.user_header_wrapper}`}>
                   

                    <div className={`${classes.filter_header_wrapper}`}>
                        <div className={`${classes.filter_action_button_wrapper}`}>
                            <UIButton Variant='Secondary' Icon={<Checks weight='bold'/>} label={"Active Users"} onClick={()=>{ShowAllActiveUsers()}}/>
                            <UIButton Variant='Secondary' Icon={<WifiHigh weight='bold'/>} label={"Online Users"} onClick={()=>{FilterInitial(UserListOrigin)}}/>
                            <UIButton Variant='Secondary' Icon={<WifiSlash weight='bold'/>} label={"Offline Users"} onClick={()=>{CollectOfflineUser()}}/>
                            <UIButton Variant='Danger' Icon={<Empty weight='bold'/>} label={`Deactivated Users`} onClick={()=>{CollectInactiveUser()}}/>
                        </div>

                        <div className={`${classes.filter_input_wrapper}`}>
                            <input onChange={(e)=>{FilterByName(e.target.value)}} type="text" placeholder='Filter Name' autoComplete='off' />
                        </div>
                    </div>

                </div>

                <div className={`${classes.user_list_wrapper}`}>

                    { !Loading &&
                        UserList.map((item, idx)=>(
                            <li key={idx}>
                                <span className={`${classes.user_name}`}>{item.USR_FirstName} {item.USR_LastName} </span>
                                <span className={`${item.USR_AccountState ===  'Active'? classes.active : classes.inactive}`}> <span>{item.USR_AccountState ===  'Active'? "Active" : "Deactivated"}</span> </span>
                                <span className={`${classes.user_role}`}>{item.USR_EmployeeID}</span>
                                <span className={`${classes.user_role}`}>{item.USR_Email}</span>
                               {/* {!item.IsActive && <span className={`${classes.user_last_time}`}>{'Active ' + getRelativeTime(item.LastTime)}</span> } */}
                                {/* <span className={`${item.IsActive ? classes.online_state : classes.offline_state}`}>
                                    <span {...({'data-tooltip-id': 'usr_account_sign_in_info', 'data-tooltip-content': item.IsActive ? 'Online' : 'Active ' + getRelativeTime(item.LastTime)})}>{item.IsActive ? <WifiHigh weight='bold'/> : <WifiSlash weight='bold'/>}</span> 
                                </span> */}
     
                                <UIButton onClick={(e)=>{SelectUser(item)}} Variant='Secondary' label={"Manage"} Icon={<PencilCircle/>}/>
                            </li>
                        ))
                    }
                    {
                        Loading && 
                        <LoadingPlaceholder/>
                    }
                    {!Loading &&
                        UserList.length <= 0 && 
                        <EmptyProxyDataView text='Empty'/>
                    }
                    
                </div>
                <div className={`${classes.reg_action_button_wrapper}`}>
                    
                     {/* {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateUser && <button onClick={(e)=>{SetOpenClassicUserCreate(true)}}><FontAwesomeIcon icon={faUserSecret}></FontAwesomeIcon> Classic User</button> } */}
                     {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateUser &&  <UIButton Variant='Secondary' Icon={<UserCirclePlus weight='fill'/>} onClick={()=>{SetOpenStandardUserCreate(true)}} label={"Create Standard User"}/> }
                     {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_CreateUser && <UIButton Variant='Danger' Icon={<Warning weight='fill'/>} label={`Terminate All Sessions`} onClick={()=>{
                        DAControl.Trigger('Terminate All User Sessions',
                        "Now this action will terminate all user sessions, meaning everyone will be automatically signed out, This might couse sudden operation stop for some users.",
                        true,
                        10,
                        (data)=>
                        {
                            if(data){
                                TerminateOmniSession();
                            }
                        })
                     }}/> }
                    </div>
            </div>
        </div>

       </div>
    );
}