import api from "../Util/axiosInstance";




export const TerminateUserSession = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/force-terminate-user-session', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const TerminateAllUserSessions = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/force-terminate-all-session', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}

export const UpdateUserKeyColorChoice = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/update-user-keycolor-choice', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateUserUIScaleChoice = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/update-user-uiscale-choice', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateUserPaintColorChoice = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/update-user-paintcolo-choice', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryUserByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/query_usr', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllUserWithAccount = async()=>
{
    try  
    {
        const response = await api.post('api/user-account/query_usr_omni', {});
        const res = await response.data;


        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryPreorderCustomerByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/pastry_customer_by_id', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}

// User Managment
export const UpdateUserAccountState = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/update_usr_state', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateUserRole = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/update_usr_role', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateUserPassword = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/update_usr_access_passkey', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateUserWorkspaceAccessControl = async(data)=>
{
    try  
    {
        const response = await api.post('api/user-account/update_usr_access_workspace', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// 
export const QueryAllUserRoles = async() =>
{
    try  
    {
        const response = await api.post('api/auth/account-access/query_usr_roles');
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}