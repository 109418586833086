import classes from './CompanyBranchStyle.module.css'
import BranchCard from './Component/Card/BranchCard';
import BranchCreator from './Component/Create/BranchCreator';
import { useState } from 'react';
import UIButton from '../Components/UIButton/UIButton';
import { useEffect } from 'react';
import BranchView from './Component/View/BranchView';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';
import { CirclesThreePlus } from '@phosphor-icons/react';
import { useToast } from '../../ToastContext';
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { FetchWorkspaceBranchAll } from '../../SharedComp/API/Workspace/API_FE_Workspace';
import { useAuth } from '../../AuthProvider';


export default function CompanyBranch() 
{
    const auth = useAuth();
    const notify = useToast();
    const [OpenDetailView, SetOpenDetailView] = useState(false);
    const [SiteList, SetSiteList] = useState([]);
    const [FocuseSite, SetFocuseSite] = useState();
    const [OpenBranchCreator, setOpenBranchCreator] = useState(false);
    const [Loading, SetLoading] = useState(false);


      function CreateQuickSitePreview(siteData) 
      {
        SetFocuseSite(siteData);
        SetOpenDetailView(true);
      }

      const FetchBranchList = async() =>
      {
        SetLoading(true);
        try 
        {
            const main_res = await FetchWorkspaceBranchAll(auth.token);
            SetSiteList(main_res);
            SetLoading(false);
        }catch(err)
        {
            notify.ShowError(err.message);
            SetLoading(false);
        }
      }



    useEffect(() => 
    {
        FetchBranchList();
    }, []) 

    return (
        <div className={`${classes.company_branch_main_wrapper}`}>

            {OpenBranchCreator && <BranchCreator Close_CALLBACK={setOpenBranchCreator}/>} 
            {OpenDetailView &&  <BranchView Close_CALLBACK={SetOpenDetailView} BranchData={FocuseSite} />} 

           <div className={`${classes.branch_body_wrapper}`}>


           <div className={`${classes.action_button_wrapper}`}>
                    <UIButton Variant='Secondary' Icon={<CirclesThreePlus/>} onClick={()=>{setOpenBranchCreator(true)}} label={"Create New Workspace"}/>
                </div>

                <div className={`${classes.esta_wrapper}`}>


                        <div className={`${classes.production_center_inner_list_wrapper}`}>
                            {!Loading && SiteList.map((item, index) => (
                                <BranchCard key={index} Data_CALLBK={CreateQuickSitePreview} BranchData={item}  />
                            ))}
                            {
                                Loading &&
                                <LoadingPlaceholder/>
                            }
                        </div> 


                </div>

               
           </div>

        </div>
    );
}