import classes from './Layout.module.css'
import NavBar from "../SharedComp/Navigation/TopMenu/Navbar";
import MainMenu from "../SharedComp/Navigation/SideMenu/MainMenu";
import { useAuth } from '../AuthProvider';
import { useEffect } from 'react';
import DesktopXP from '../SharedComp/Navigation/DesktopXP/DesktopXP';

const Layout = ({ children }) => {

    const auth = useAuth();

    useEffect(()=>{
        
    }, [auth.user])

    return (
        <div className={`${classes.root_layout_wrapper}`}>
            <div className={`${classes.top_nav_bar}`}>

                <NavBar />

            </div>
            <div className={`${classes.elment_wrapper}`}>
              {auth.DesktopXPMode ?   <div className={`${classes.desktop_icon_view}`}>
                    <DesktopXP children={children}/>
                </div>
              :  
                <>
                    <div className={`${classes.element_x_menu}`}>
                        {auth.user?.UARI_IsClient === 0 &&  <MainMenu />} 
                    </div> 
                    <div className={`${classes.element_x_route}`}>
                        {children}
                    </div>
                </>
                }                
            </div>
        </div>
    )
}

export default Layout;