import classes from './LocalFrontOrder.module.css'
import TimeAgo from '../../../../SharedComp/TimeElapsed/TimeAgo'
import UIButton from '../../../Components/UIButton/UIButton'
import { Camera, CheckCircle, Key, List, Scan, SealCheck, SmileyWink, SpinnerGap, Tray } from '@phosphor-icons/react'

import { useState } from 'react'
import { useToast } from '../../../../ToastContext'
import { useAuth } from '../../../../AuthProvider'
import { useEffect } from 'react'

import { QueryOrdersGroupByBranchID } from '../../../../SharedComp/API/CK/AP_CenteraKitchenOrder'
import SingleEntryGroup from '../../KitchenSupplyOrder/Component/TrackSupplyOrder/SingleEntryGroup/SingleEntryGroup'
import { ArrowCircleDown } from '@phosphor-icons/react/dist/ssr'
import QRCodeReader from '../../../../SharedComp/QRCodeReader/QRCodeReader'
import { ToEthiopianCalander } from '../../../../SharedComp/Utility'
import { useRef } from 'react'
import { QueryActiveOrderByBranch, QuerySpecialOrderByBranchByDate } from '../../../../SharedComp/API/DeskView/API_FE_DeskView'

export default function LocalFrontOrder() 
{
    const [DataCopy, SetDataCopy] = useState([]);
    
        const notify = useToast();
        const auth = useAuth();
        const [AtDayOf, SetAtDayOf] = useState(new Date());
        const [Loading, SetLoading] = useState(true);
    

        useEffect(()=>{
            GetActiveOrders();
        
        }, [auth.activeWorkShop, AtDayOf]);

        const GetActiveOrders = async()=>
        {
            try 
            {
                const fd = new FormData();
                fd.append("workspace_id", auth.activeWorkShop);
                fd.append("target_date", AtDayOf);
                const main_res = await QuerySpecialOrderByBranchByDate(fd);
                SetDataCopy(main_res);
                SetLoading(false);
            }catch(err) 
            {
                SetLoading(false);
            }
        }



    return (
        <div className={`${classes.active_order_list_master_wrapper}`}>
        
        
                    <div className={`${classes.filter_workshop_by_name}`}>
                        <div className={`${classes.workshop_filter_input_wrapper}`}>
                            <input onChange={(e)=>{SetAtDayOf(e.target.value)}} autoComplete='off' type="date" /> 
                            <span>{ToEthiopianCalander(new Date(AtDayOf))}</span>
                        </div>
                    </div>
                    
                    <div className={`${classes.list_box_wrapper}`}>
        
                        <div className={`${classes.active_order_main_list_wrapper}`}>
                            <li className={`${classes.header_list}`}>
                                <span>Customer Name</span>
                                <span>Cashier</span>
                                <span>Ordered Date</span>
                                <span>Expected Date</span>
                                <span>State</span>
                                <span>Action</span>
                            </li>
        
                            <div className={`${classes.list_data_wrapper}`}>
                                 {!Loading &&
                                    DataCopy.map((item, idx)=>(
                                        <li key={idx + item.Customer.POCV_FullName}>
                                            <span style={{textTransform: 'capitalize'}}>{item.Customer.POCV_FullName}</span>
                                            <span>{item.Cashier.USR_FirstName} {item.Cashier.USR_LastName}</span>
                                            <span>{ToEthiopianCalander(new Date(item.OrderDetail.PREO_OrderDetail.OrderIntiailizedDate))}</span>
                                            <span>{ToEthiopianCalander(new Date(item.OrderDetail.PREO_OrderDetail.ProductExpectedDate))}</span>
                                            <span className={`${classes.action_button_wrapper}`}>
                                                <UIButton 
                                                    onClick={()=>{}} 
                                                    Variant={item.OrderDetail.PREO_State === "Complete" ? "Primary" : "Secondary"} 
                                                    label={item.OrderDetail.PREO_State} 
                                                    Icon={item.OrderDetail.PREO_State === "Complete" ? <SealCheck weight='bold'/> : <SpinnerGap weight='bold'/> } />
                                            </span>
                                            
                                            <span className={`${classes.action_button_wrapper}`}>
                                                {/* <UIButton Variant='Secondary' onClick={()=>{}} Icon={<List weight='fill'/>} label={"Inspect Order"}/> */}
                                                --
                                            </span>
                                        </li>
                                    ))
                                }
                                {
                                    DataCopy.length === 0 && <div className={`${classes.empty_box_wrapper}`}>
                                        <ul><Tray fontSize={30}/></ul>
                                        <ul>No RECORD</ul>
                                    </div>
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
    )
}