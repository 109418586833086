import api from "../Util/axiosInstance";



export const QueryInventoryTransactionByDateExpanded = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/query-inventory-stock-transaction-by-day-expanded', data);
        const res = await response.data;
        return res;
    
    }catch(err) 
    {
        throw err;
    }
}
export const AISmartSummerizeStockTransaction = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/ai-smart-summerize-data-by-id', data);
        const res = await response.data;
        return res;
    
    }catch(err) 
    {
        throw err;
    }
}
export const SingularItemInvoiceData = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/query-singular-item-invoice-data-entry', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryDateRangeInventoryData = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/query-date-ranged-inventory-report-data', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateInventoryAmountForce = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/update-inventory-amount-force', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UnmountInventoryAmountForce = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/unmount-inventory-amount-force', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ForceInventoryStockPurge = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/force-inventory-stock-purge', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryInventoryStockByBranch = async(data)=>
{
    try 
    {
        const response = await api.post('api/inventory-stock/get-inv-stock-branch', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}