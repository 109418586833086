import classes from './PayrollDataFeedPass.module.css'
import { useEffect } from 'react'
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';
import { useState } from 'react';
import { ToEthiopianCalanderRaw } from '../../../SharedComp/Utility';
import SingleWorkshopBundlePreview from './SingleWorkshopBundlePreview/SingleWorkshopBundlePreview';
import { MagnifyingGlass, Tray } from '@phosphor-icons/react';
import { QueryPayrollScheduleByDate } from '../../../SharedComp/API/Payroll/API_FE_PayrollSchedule';
import { useAuth } from '../../../AuthProvider';


const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0'); 
    return `${year}-${month}-${day}`;
 };
export default function PayrollDataFeedPass() 
{

    const auth = useAuth();
    const [BranchOriginal, SetBranchOriginal] = useState([]);
    const [BranchPreviewList, SetBranchPreviewList] = useState([]);
    const [RefreshView, SetRefreshView] = useState(false);
    


    const GetAvailBranchForPayrollDataFeed = async()=>
    {
        try 
        {
            const fd = new FormData();
             const _target_date = formatDate(new Date());
            const _date_split = _target_date.split('-');
            const ec_date_raw = ToEthiopianCalanderRaw(new Date(_date_split[0], parseInt(_date_split[1]) - 1, _date_split[2]));
            fd.append("target_year", ec_date_raw[0]);
            fd.append("target_month", ec_date_raw[1]);
            const main_res = await QueryPayrollScheduleByDate(fd);
            SetBranchOriginal(main_res);
            SetBranchPreviewList(main_res);
        }catch(err) 
        {

        }
    }

    useEffect(()=>{

        GetAvailBranchForPayrollDataFeed();

    }, [RefreshView])

    const TriggerRefresh = ()=>
    {
        SetRefreshView(!RefreshView);
    }

    return (
        <div className={`${classes.hr_data_feed_pass_master_wrapper}`}>

            

            <div className={`${classes.hr_data_feed_pass_workspace_wrapper_upper_layer}`}>
                {
                    BranchPreviewList.map((item, idx)=>(
                        <SingleWorkshopBundlePreview UpdateViewBack={TriggerRefresh} key={item.BranchName + idx} WorkspaceData={item}/>
                    ))
                }
                {
                        BranchPreviewList.length <= 0 && 
                        <div className={`${classes.empty_data_hr_data_feed_ready}`}>
                            <span><Tray weight='bold'/></span>
                            <li>There is no available data ready for processing</li>
                        </div>
                    }
            </div>


        </div>
    )
}