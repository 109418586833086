import classes from './ReleaseCash.module.css'

import { Custom_DD_Style, PrepareDropDownList } from '../../../../../SharedComp/Utility'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef } from 'react'

import Select from 'react-select'
import axios from 'axios';
import { useEffect } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import { useAuth } from '../../../../../AuthProvider'
import { ApproveCashReleasePettyCashV2 } from '../../../../../SharedComp/API/PettyCash/API_FE_PettyCash'
import { useToast } from '../../../../../ToastContext'
import { QueryAllMasterUtilityExpense } from '../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_II'
 
export default function ReleaseCash({Close_CALLBACK, UpdateView}) 
{

    const [Loading, SetLoading] = useState(false);
    const auth = useAuth();
    const notify = useToast();

    const [MRC, SetMRC] = useState('-');
    const [TIN, SetTIN] = useState('-');
    const [Receipt, SetReceipt] = useState('-');
    const [Cat, SetCat] = useState();
    const [Amount, SetAmount] = useState();
    const [Reason, SetReason]= useState('-');

    const [UtilExpenseCatList, SetUtilExpenseCatList] = useState([]);

    const QueryMasterUtilityExpenseCat = async()=>
    {
        try 
        {
            const main_res = await QueryAllMasterUtilityExpense(auth.token);
            PrepareDropDownList(main_res, SetUtilExpenseCatList);
        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const HandleSubmition = async(e)=>
    {
        e.preventDefault();

        SetLoading(true);
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();

            fd.append("MRC", MRC);
            fd.append("TIN", TIN);
            fd.append("Receipt", Receipt);
            fd.append("Cat", Cat);
            fd.append("Amount", Amount);
            fd.append("Reason", Reason);
            fd.append("branch_id", auth.activeWorkShop);
            fd.append("user_id", auth.user.USR_UserAccountID);
    
            const main_res = await ApproveCashReleasePettyCashV2(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetLoading(false);
            UpdateView();
            Close_CALLBACK(false);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }
    }

    useEffect(()=>{
        QueryMasterUtilityExpenseCat();
    }, [auth.activeWorkShop, auth.user])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>


        <div className={`${data_win.data_main}`}>

            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Release Cash</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>

            <div className={`${classes.data_body}`}>

                    <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 
                                    <div  className={`${classes.input_list_wrapper}`}>
                                        
                                         <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetMRC(e.target.value)}}  name='mrc' type="text" placeholder='MRC' title='' autoComplete='off' />}/>
                                        </div>
                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetReceipt(e.target.value)}}  name='rn' type="text" placeholder='Receipt Number' title='' autoComplete='off'/>} />
                                        </div>
                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input onChange={(e)=>{SetTIN(e.target.value)}} name='stin' type="text" placeholder='TIN Number' title='' autoComplete='off' />} />
                                        </div>

                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput 
                                                input={
                                                    <Select
                                                        onChange={(data)=>{SetCat(data.value)}}
                                                        name={"dd_data"}
                                                        options={UtilExpenseCatList}
                                                        placeholder={"Category..."}
                                                        isSearchable={true}
                                                        styles={Custom_DD_Style}
                                                        required ={true}
                                                    />
                                                
                                            } tip={"Select Category"}  forceInput={true}/>
                                        </div>

                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<input min={1} step="any" onChange={(e)=>{SetAmount(e.target.value)}} name='expence' type="number" placeholder='Amount' title='' autoComplete='off' required />} />
                                        </div>

                                        <div className={`${classes.input_wrap}`}>
                                            <MasterDataRegInput input={<textarea onChange={(e)=>{SetReason(e.target.value)}}  name='reason' type="text" placeholder='Detail Reason' autoComplete='off'  />} />
                                        </div>
                                        

                                        

                                    </div>
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Add'}</button>
                                <button disabled={Loading}  onClick={()=>{Close_CALLBACK(false)}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>

            </div>

        </div>

    </div>
    )
}