import classes from './SignableStockReturnDocumentPreview.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { useReactToPrint } from 'react-to-print'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'

import { useEffect, useRef } from 'react'
import { useState } from 'react'


import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'


import { useToast } from '../../../../../ToastContext';

import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import { ApproveRTOByIdV2, QueryMasterStockReturnWithHandlerByID } from '../../../../../SharedComp/API/Production/API_MasterProductionV2'


export default function SignableStockReturnDocumentPreview({Preview = false, Close_CALLBACK, DataRefersh, TargetData}) 
{
    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();


    const [OrderHandler, SetOrderHandler] = useState();
    const [IncomingLoading, SetIncomingLoading] = useState(false);

    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_PO-Requested-Stock" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })
    const handleApproval = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("user_id", auth.user.USR_UserAccountID);
            fd.append("target_order_id", TargetData?.CoreData.MPOSRH_ID);

            const res = await ApproveRTOByIdV2(fd);

            if(res.MyStateCode !== 1)
            {
                throw new Error(res.Msg);
            }

            notify.ShowSuccess(res.Msg);
            DataRefersh();
            Close_CALLBACK(false);

        }catch(err) 
        {
            notify.ShowError(err.message);
        }
    }
    const QueryDocApprover = async()=>
    {
        SetIncomingLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_item_id", TargetData.CoreData.MPOSRH_ID);
            const main_res = await QueryMasterStockReturnWithHandlerByID(fd);
            if(main_res.length > 0) 
            {
                SetOrderHandler(main_res[0]);
            }
            SetIncomingLoading(false);
        }catch(err) 
        {
            SetIncomingLoading(false);
        }
    }

    useEffect(()=>{

        if(TargetData && Preview) 
        {
            QueryDocApprover();
        }

    }, [TargetData])

    
    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>Return To Origin</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

               {!IncomingLoading && <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        
                        <div className={`${classes.print_doc_non_repeat_content}`}>

                            <PaperHash text={Preview ? "copy not original" : null}/>
                            <div className={`${classes.meta_data_wrapper}`}>
                                <h2>[#] {auth.activeWorkShopName} </h2>
                                <h2>[#] Inventory Return To Origin</h2>
                            </div>

                            <div className={`${classes.transcation_owner_wrapper}`}>
                                <div className={`${classes.transcation_owner}`}>

                                    <div className={`${classes.transcation_init}`}>
                                        <h2>Request Info</h2>
                                        <li><span>Stock Return Init By</span> <span style={{fontWeight: 'bold'}}>{TargetData?.CoreData.USR_FirstName} {TargetData?.CoreData.USR_LastName}</span></li>
                                        <li><span>Date</span>  <span style={{fontWeight: 'bold'}}>{ToEthiopianCalander(new Date(TargetData?.CoreData.MPOSRH_RequestDateTime))}</span></li>
                                        <li><span>Clock</span> <span style={{fontWeight: 'bold'}}>{TargetData?.CoreData ? new Date(TargetData?.CoreData.MPOSRH_RequestDateTime).toLocaleTimeString() : "--"}</span></li>
                                    
                                    </div>

                                </div>
                            </div>
``
                            <div className={`${classes.entry_detail}`}>
                                <li><span className={`${classes.entry_deatil_tag}`}>Process ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetData?.CoreData.MPOSRH_TransactionID}</span></li>
                                <li><span className={`${classes.entry_deatil_tag}`}>Print Date</span> <span className={`${classes.entry_deatil_val}`}>{ToEthiopianCalander(new Date())}</span></li>
                            </div>  
                            
                            
                            <div className={`${classes.main_paper_data_wrapper}`}>

                                <div className={`${classes.paper_list_wrapper}`}>

                                <div className={`${classes.single_order_data_view_master_wrapper}`}>
                                        <div className={`${classes.packge_data_wrapper}`}>

                                            <div className={`${classes.main_prod_info_wrapper}`}>
                                                <li><span className={`${classes.prod_name_PO}`}>Summery</span></li>
                                            </div>

                                            <div className={`${classes.ing_list_data_wrapper}`}>
                                                <div className={`${classes.data_marging_wrapper}`}>
                                                    {
                                                        TargetData.DataMergedDetail.map((item, idx)=>(
                                                            <li key={item.IVItemName + idx}>
                                                                <span>[RTO] - {item.IVItemName}</span>
                                                                <span>{(item.RT_Quantity).toFixed(2)} {item.UomDescription}</span>
                                                            </li>
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>

                            <div className={`${classes.special_footer_local_wrapper}`}>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Digital Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>{Preview && OrderHandler ? OrderHandler.USR_FirstName + ' ' + OrderHandler.USR_LastName :  auth.employeeData?.JAKSBE_FullName}</li>
                                    {Preview && OrderHandler && <li className={`${classes.sign_manual}`}>{ToEthiopianCalander(new Date(OrderHandler.MPOSRH_RequestHandleDateTime))} @ {new Date(OrderHandler.MPOSRH_RequestHandleDateTime).toLocaleTimeString()}</li>}    
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>


                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Physical Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                            </div>

                            {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                            <div className={`${classes.final_note_po_stock_out}`}>
                                <li>This document must have all signatures to be considered valid.</li>
                            </div>

                        </div>
                    </div>
                </div>



                </div>}
                {
                    IncomingLoading &&
                    <LoadingPlaceholder preview_text='Preparing Document'/>
                }


                <div className={`${classes.action_button_wrapper}`}>

                 {Preview &&    <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>}
                  {!Preview && !IncomingLoading && <button onClick={()=>{
                                        DAControl.Trigger(`Approve RTO`, 
                                        `This action will affect Inventory Balance. This means the inventory amount listed inside the summery field will be added to your store.`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                handleApproval();
                                            }
                                        }   
                                    );}} className={`${classes.button_approve}`}>Approve</button> }
                    <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                </div>

            </div>
        </div>
    )
}