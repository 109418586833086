import api from "../Util/axiosInstance";




export const CreateNewPayrollSchedule = async(data)=>
{
    try 
    {
        const response = await api.post('api/payroll/create-new-payroll-schedule-man', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const PurgePayrollSchedule = async(data)=>
{
    try 
    {
        const response = await api.post('api/payroll/purge-payroll-schedule-man', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryPayrollScheduleByBranchByDate = async(data)=>
{
    try 
    {
        const response = await api.post('api/payroll/query-payroll-schedule-by-date-branch', data);
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryPayrollScheduleByDate = async(data)=>
{
    try 
    {
        const response = await api.post('api/payroll/query-payroll-schedule-by-date', data);
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}
export const QueryPayrollScheduleAllResolved = async(token)=>
{
    try 
    {
        const response = await api.post('api/payroll/query-payroll-schedule-all-resolved', {} );
        const res = await response.data;
        return res;

    }catch(err) 
    {
        throw err;
    }
}