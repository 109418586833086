import api from "../Util/axiosInstance";




export const QueryProdTypeAll = async(token)=>
{
    try  
    {
        const response = await api.post('api/ck/get_ck_prod_type_all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryProdTypeID = async(__id, token)=>
    {
        try  
        {
            const response = await api.post('api/ck/get_ck_prod_type_id', {ID: __id} );
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const PurgeProdTypeID = async(__id, token)=>
    {
        try  
        {
            const response = await api.post('api/ck/purge_ck_prod_type_id', {ID: __id} );
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const QueryCKProductALL = async(token)=>
{
    try  
    {
        const response = await api.post('api/ck/get_ck_prod_item_all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryCKProductKitchenID = async(__id, token)=>
{
    try  
    {
        const response = await api.post('api/ck/get_ck_prod_item_kitchen_id', {ID: __id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateCKProductAvailState = async(data)=>
{
    try  
    {
        const response = await api.post('api/ck/update_ck_prod_avail_state', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryCKProductID = async(__id, token)=>
    {
        try  
        {
            const response = await api.post('api/ck/get_ck_prod_item_id', {ID: __id} );
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
    }
export const CreateCKProductType_API_EP = async(data)=>
{
    try  
    {
        const response = await api.post('api/ck/reg_ck_prod_type', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateCKProductType_API_EP = async(data)=>
    {
        try  
        {
            const response = await api.post('api/ck/update_ck_prod_type', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const CreateCKProduct_API_ED = async(data)=>
    {
        try  
        {
            const response = await api.post('api/ck/reg_ck_prod', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const UpdateCKProduct_API_ED = async(data)=>
    {
        try  
        {
            const response = await api.post('api/ck/update_ck_prod', data);
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}
export const PurgeCKProduct_API_ED = async(__id, token)=>
    {
        try  
        {
            const response = await api.post('api/ck/purge_ck_prod_id', {ID: __id} );
            const res = await response.data;
            return res;
        }catch(err) 
        {
            throw err;
        }
}

export const FetchWorkspaceProductionCenterOnly = async(token)=>
{
    try 
    {
        const response = await api.post('api/ck/fetch_workshop_production_center_only', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}