import { ChefHat } from '@phosphor-icons/react'
import classes from './ChefViewCard.module.css'

import { useDAControl } from '../../../../DAControlContext';
import { useToast } from '../../../../ToastContext';
import {PurgeAssignedChef} from '../../../../SharedComp/API/Kicthen/API_FE_Kicthen'
import { getBaseURL } from '../../../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../../../AuthProvider';

export default function ChefViewCard({ChefData, UpdateView}) 
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const HandlePurge = async()=>
    {
        notify.ShowLoad("Processing your request....", 10);
        try
        {
            const fd = new FormData();
            fd.append("emp_id", ChefData.USR_EmployeeID);

            const main_res = await PurgeAssignedChef(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            UpdateView();

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }

    }


    return (
        <div className={`${classes.chef_card_view_wrapper}`}
        onDoubleClick={()=>{
            DAControl.Trigger(
                `Purge Assign Chef`,
                `You are about Purge Assigned Chef ${ChefData.USR_FirstName}. after this action the chef will not receive order form the dipstacher... so with this in mind `,
                true,
                8,
                (data)=>{
                    if(data) 
                    {
                        HandlePurge();
                    }
                }
            )
        }}
        >
            <div className={`${classes.chaef_image_wrapper}`}>
                <img src={getBaseURL() + ChefData.EDOCP_ProfilePicture} alt="" />
            </div>
            <div className={`${classes.chef_name_and_info}`}>
                <li className={`${classes.chef_name}`}> <ChefHat/> {ChefData.USR_FirstName} {ChefData.USR_LastName}</li>
                <li className={`${classes.chef_email}`}>{ChefData.JAKSBE_Email}</li>
                <li className={`${classes.chef_phone}`}>{ChefData.JAKSBE_PhoneNumber}</li>
            </div>
        </div>
    ) 
}