import classes from './IncomingInventoryRequestSingleEntryWrapper.module.css'
import TransferStateIndicator from '../../TransferStateIndicator/TransferStateIndicator'
import { CirclesThreePlus } from '@phosphor-icons/react'
import { ToEthiopianCalander } from '../../../../../../SharedComp/Utility'
import TimeAgo from '../../../../../../SharedComp/TimeElapsed/TimeAgo'
import { useState } from 'react'


export default function IncomingInventoryRequestSingleEntryWrapper({TargetItem, HandleDataPreview, DocViewCallback, ShipProduct}) 
{

    const [OpenOption, SetOpenOption] = useState(false);


    return (
        <div  tabIndex={1} className={`${classes.transaction_list_inner_single}`} key={TargetItem.ISTRV2_TransactionID}>
            <li style={{fontWeight: 'bold'}}>[FROM] {TargetItem.BranchName}</li>
            <li>{TargetItem.USR_FirstName} {TargetItem.USR_LastName}</li>
            <li>{ToEthiopianCalander(new Date(TargetItem.ISTRV2_RequestInitDate))} / {<TimeAgo show_suffix={true} date={new Date(TargetItem.ISTRV2_RequestInitDate)}/>}</li>
            <li className={`${classes.center_align_span}`}>
                    <TransferStateIndicator label={TargetItem.ISTRV2_RequestState ===  "Received" ? "Sent" : TargetItem.ISTRV2_RequestState}/>
                    <CirclesThreePlus onClick={()=>{SetOpenOption(!OpenOption)}}  weight='bold' size={16}/>
            </li>

            {OpenOption && <div tabIndex={0} onBlur={()=>{SetOpenOption(false)}} className={`${classes.single_entry_action_take_incoming_stock_request}`}>
               {TargetItem.ISTRV2_RequestState === "Pending" &&  <li onClick={()=>{HandleDataPreview(TargetItem)}}>View Request</li>}
                {TargetItem.ISTRV2_RequestState === "Processing" && <li onClick={()=>{ShipProduct(TargetItem.ISTRV2_ID)}}>Ship Inventory</li> }
                {TargetItem.ISTRV2_RequestState !== "Pending" && <li onClick={()=>{DocViewCallback(TargetItem.ISTRV2_ID)}}>Transaction Document</li>}
            </div> }

        </div>
    )
}