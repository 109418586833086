import classes from './PittyCashBranchAnalysis.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea, faChartLine, faCheckCircle, faRotateBack, faTimesCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import ApChartMini from '../../../../../SharedComp/ApexChartMiniWrapper/ApChartMini'
import DotsGif from '../../../../../SharedComp/CssGif/DotsGif'
import ReactApexChart from 'react-apexcharts'
import Chart from 'react-apexcharts'
import { useEffect, useState } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import PittyCashAuditView from './PittyCashAuditView/PittyCashAuditView'
import NotificationPopUp from '../../../../../SharedComp/NotificationPopUp/NotificationPopUp'
import { useAuth } from '../../../../../AuthProvider'
import UIButton from '../../../../Components/UIButton/UIButton'
import { ChartLine, Empty, Money, Paperclip, Warning } from '@phosphor-icons/react'
import ReceiptOfFunds from '../../../PittyCashBranch/Component/ReceiptOfFunds/ReceiptOfFunds'
import { QueryPettyCashTransactionByBranchID, QueryPettyCashTransactionDetailByID, SuspendPettyCashActivity } from '../../../../../SharedComp/API/PettyCash/API_FE_PettyCash'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import { useDAControl } from '../../../../../DAControlContext'
import { useToast } from '../../../../../ToastContext'
import { FetchWorkspaceBranchAll, FetchWorkspaceBranchByID, QueryWorkspaceByID } from '../../../../../SharedComp/API/Workspace/API_FE_Workspace'


function GetRandomValue()
{
   return Math.floor(Math.random() * 50000)
}
const getCssVariable = (variableName) =>
  getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
export default function PittyCashBranchAnalysis({Close_CALLBACK, Item, RefershData}) 
{

  const auth = useAuth();
  const DAControl = useDAControl();
  const notify = useToast();

  const colorTheme = getCssVariable('--color-theme');
  const [localStateBranch, setLocalStateBranch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [depositList, setDepositList] = useState([]);
  const [chartData, setChartdata] = useState();
  const [openPittyCashAudit, setOpenPittyCashAudit] = useState(false);
  const [activeBook, setActiveBook] = useState(false);
  const [ActiveDeposit, SetActiveDeposit] = useState(null);

  // Notifiction
  const [RefeshList, SetRefeshList] = useState(false);
  const [OpenBudgetAllocationModal, SetOpenBudgetAllocationModal] = useState(false);

      const series = [
        {
          name: 'Total Allocation',
          data: chartData,
        },
      ];


     const chart_option = {
        
        chart: {
          background: 'var(--background-dark_minus)', // Set the background color
      },
      theme: !auth.DarkMode ? { 
        monochrome: {
          enabled: true,
          color: colorTheme,
          shadeTo: 'light',
          shadeIntensity: 0.65
        } 
      } : {},
        xaxis: {
            type: 'category',
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set x-axis text color
              },
            },
          },
          yaxis: {
            show: true, // Hide y-axis
            labels: {
              style: {
                colors: 'var(--text_light_white)', // Set y-axis text color
              },
            },
          },
          grid: {
            show: false, // Hide grid lines
            
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return val.toLocaleString('en-US', {
                style: 'currency',
                currency: 'ETB',
                });
            },
            style: {
                fontSize: '10px', // Adjust the font size here
            },
          },
          tooltip: {
            theme: auth.DarkMode ? "dark" : 'light', // Set the theme to dark
          },
        colors: ['var(--color-theme)'], // Set the text color
      };
      const processGraphData = (res) => {
        // Initialize an empty object to store the graph data
        const graphData = {};
      
        // Loop through the fetched data
        res.forEach(item => {
          const name = item.HRMUET_Name;
          const initialValue = item.PTYCSH_Amount;
      
          // Check if the name already exists in the graphData
          if (graphData[name]) {
            // If it exists, add the amount to the existing value
            graphData[name] += initialValue;
          } else {
            // If it doesn't exist, create a new entry with the amount
            graphData[name] = initialValue;
          }
        });
      
        return graphData;
      };
      const fetchChartSpesificData = async (data) => {
        try {
          const fd = new FormData();
          fd.append("target_id", data.PCMM_ID);
          const main_res = await QueryPettyCashTransactionDetailByID(fd);

            setChartdata(
                Object.entries(processGraphData(main_res.Transaction)).map(([key, value]) => ({
                  x: key,
                  y: value,
                }))
            );
        }catch(err) 
        {

        }
      }

      const fetchDeposit = async () => {
        try 
        {

          const fd = new FormData();
          fd.append("branch_id", Item.BranchID);
          const main_res = await QueryPettyCashTransactionByBranchID(fd);
          setDepositList(main_res); 
          for(let i = 0; i < main_res.length; i++) 
          {
            if(main_res[i].PCMM_IsActive === "Yes")
            {
              SetActiveDeposit(main_res[i]);
              await fetchChartSpesificData(main_res[i]);
              break;
            }
          }
        }catch(err) 
        {

        }
      }
      const fetchLocalBranch = async () => {
        try 
        {
          const fd = new FormData();
          fd.append("target_id", Item.BranchID);

          const main_res = await QueryWorkspaceByID(fd);

          setLocalStateBranch(main_res[0]);
          fetchDeposit();
          setLoading(false);

        }catch(err) 
        {
          throw new Error("Error Fetching Workspace PittyCash Detail Data" + err);
        }
      }
      const handleCashLogViewOpen = (data) => {
        setOpenPittyCashAudit(true);
      }
      const ApproveOperation = (data) => {

        setActiveBook(data); 
        handleCashLogViewOpen(data);
      }

      const SuspendPettyCashInteraction = async(id)=>
      {
        if(!ActiveDeposit) 
        {
          notify.ShowError("There is no active allocated budget to suspend!");
          return;
        }
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
          const fd = new FormData();
          fd.append("target_id", ActiveDeposit.PCMM_ID);
          fd.append("should_suspend", ActiveDeposit.PCMM_SuspendTransaction === "Yes" ? "No" : "Yes");
          const main_res = await SuspendPettyCashActivity(fd);
          notify.ShowSuccessUpdate(main_res.Msg, 10);
          RefershData(new Date().getMilliseconds());
          Close_CALLBACK(false);
        }catch(err) 
        {
          notify.ShowErrorUpdate(err.message, 10);
        }
      }

      useEffect(()=>{

        setLoading(true);
        fetchLocalBranch();
      }, [RefeshList]);

      
// item.PCMM_IsActive === "No" && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ApprovePettyCashBudget ? check avail
    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
        {OpenBudgetAllocationModal && <ReceiptOfFunds branchID={localStateBranch.BranchID} Close_CALLBACK={SetOpenBudgetAllocationModal} RefershCallbackVal={SetRefeshList} />}
        {openPittyCashAudit && <PittyCashAuditView LocalStateBranch={localStateBranch} Item={activeBook} Close_CALLBACK={setOpenPittyCashAudit}/>}

        <div className={`${data_win.data_main} ${classes.data_main}`}>


            <div className={`${data_win.data_main_header}`}>
                <h3>
                    JAK <span className={`${data_win.data_create_name}`}>Pitty Cash Analysis</span>
                </h3>
                <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
            </div>
            { !loading ? <div className={`${classes.data_body}`}>


            <div className={`${classes.action_button_list_for_branch_spec}`}>
                   <UIButton Variant='Secondary' label={"Allocate New Budget"} Icon={<Money weight='fill'/>} onClick={()=>{SetOpenBudgetAllocationModal(true)}}/>
                 {ActiveDeposit &&   <UIButton Variant={ActiveDeposit.PCMM_SuspendTransaction === "Yes" ? 'Primary' : 'Danger'} label={ActiveDeposit.PCMM_SuspendTransaction === "No" ? "Suspend Petty Cash Activity" : "Unlock Petty Cash Activity"} Icon={<Empty weight='fill'/>} onClick={()=>{
                    DAControl.Trigger(
                      "Suspend Petty Cash Activity",
                      `You are about to susped pitty cash activity for branch ${localStateBranch.BranchName}, This will block interaction.`,
                      true,
                      6,
                      (data)=>
                      {
                        if(data)
                        {
                          SuspendPettyCashInteraction()
                        }
                      }
                      )
                   }}/>}
                </div>
              <div className={`${classes.inner_body_wrapper}`}>
                             
                <div className={`${classes.distro_graph_wrapper}`}>

                    <div className={`${classes.header_graph}`}>
                        <h2>Visual Analysis - {localStateBranch.BranchName}</h2>
                    </div>

                    <Chart 
                        options={chart_option}
                        series={series}
                        type="area"
                        height={150}
                    />
                </div>

                <div className={`${classes.pitty_cash_audit_list_wrapper}`}>
                   

                    <div className={`${classes.list_main}`}>
                        
                        <div className={`${classes.pitty_cash_audit_list_inner_wrapper}`}>
                          {
                            depositList.map((item, idx)=>(
                              <li key={idx}>
                                  <span className={`${classes.pit_date}`}>{ToEthiopianCalander(new  Date(item.PCMM_InitialAllocationDateTime))}</span>
                                  <span>

                                    <UIButton 
                                      label={item.PCMM_IsActive === "Yes" ? "Budget Open" : "Budget Closed"}
                                      Variant={item.PCMM_IsActive === "Yes" ? "Primary" : "Secondary"}
                                      onClick={()=>{}}
                                    />
                                      
                                  </span>

                                  <span className={`${classes.pit_amount}`}>
                                     {item.PCMM_IsActive === "Yes" && 
                                       "(TI) " + Intl.NumberFormat('en', {currency: 'ETB', style: 'currency'}).format(item.PCMM_TransferredIn) + " -- " + "(I) " + Intl.NumberFormat('en', {currency: 'ETB', style: 'currency'}).format(item.PCMM_InitialAllocationAmount) + " -- (C) " }
                                       {Intl.NumberFormat('en', {currency: 'ETB', style: 'currency'}).format(
                                            item.PCMM_IsActive === "Yes" ? item.PCMM_RemainBudget : item.PCMM_InitialAllocationAmount
                                      )}
                                  </span>
                                  
                                  <span className={`${classes.center_span_align}`}> 

                                    
                                    {
                                      item.PCMM_IsActive === "No" && item.PCMM_IsAudited === "No" && auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ApprovePettyCashBudget ? 
                                      <UIButton onClick={()=>{ApproveOperation(item)}} Variant='Danger' Icon={<Warning weight='fill'/>} label={"Require Audit"}/> : item.PCMM_IsActive === "Yes" && item.PCMM_IsAudited === "No" ? ' ' :
                                      <UIButton onClick={()=>{ApproveOperation(item)}} Icon={<Paperclip weight='bold'/>} label={"Preview Document"}/>
                                    }
                                    <UIButton onClick={()=>{fetchChartSpesificData(item)}} Icon={<ChartLine weight='bold'/>} label={"Graph It"}/>
                          
                                  </span>
                              </li>

                            ))
                          }
                          

                            

                        </div>

                    </div>

                </div>

              </div> 
            </div>
            : 
            <div className={`${classes.loading_placeholder}`}>
                  <DotsGif/>
             </div> }

        </div>

    </div>
    )
}