import { useEffect, useState } from 'react'
import classes from './MiniPittyCashBranchCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../../AuthProvider';
import { QueryPettyCashTransactionByBranchID } from '../../../../SharedComp/API/PettyCash/API_FE_PettyCash';
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import UIButton from '../../../Components/UIButton/UIButton';
import { Checks, Warning } from '@phosphor-icons/react';
import { ToEthiopianCalander } from '../../../../SharedComp/Utility';

const CurrencyFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ETB',
    });
  
    return formatter.format(value);
  };
export default function MiniPittyCashBranchCard({Item, OpenAnalysis}) 
{
    const auth = useAuth();
    const [HelperText, SetHelperText] = useState('');
    const [hasAction, setHasAction] = useState(false);
    const [ReqActionCount, SetReqActionCount] = useState(0);
    const [Loading, SetLoading] = useState(false);
    const [ActiveLog, SetActiveLog] = useState(null);
    const [LastTransaction, SetLastTransaction] = useState(null);



    const QueryPettyCashLogList = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("branch_id", Item.BranchID);
            const main_res = await QueryPettyCashTransactionByBranchID(fd);

            let _unaudited_count = 0;
            let _active_log = null;
            for(let i = 0; i < main_res.length; i++) 
            {
                if(main_res[i].PCMM_IsActive === "No" && main_res[i].PCMM_IsAudited === "No")
                {
                    _unaudited_count++;
                }
                if(main_res[i].PCMM_IsActive === "Yes") 
                {
                    _active_log = main_res[i];
                }
            }
            if(_unaudited_count > 0) 
            {
                setHasAction(true);
                SetReqActionCount(_unaudited_count);
            }

            if(_active_log) 
            {
                SetActiveLog(_active_log);
                const _transaction_log = JSON.parse(_active_log.PCMM_TransactionLog).Log;

                if(_transaction_log.length > 0) 
                {
                    SetLastTransaction(_transaction_log[_transaction_log.length - 1]);
                }
            }

            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }

    useEffect(()=>{
        QueryPettyCashLogList();
    }, [])



    return (
        <div onClick={()=>{
            if(auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PreviewBranchPettyCashUtilization)
            {
                OpenAnalysis(Item)
            }
            }} className={`${classes.mini_card_main_wrapper}`}>
                    <div className={`${classes.mini_card_header}`}>
                        <h2>
                            {Item.BranchName}
                        </h2>
                        <li className={`${hasAction ? classes.noti_wrapper : classes.noti_wrapper_hide}`}  onMouseOver={()=>{SetHelperText("Action required")}} onMouseLeave={()=>{SetHelperText('')}}>
                            {ReqActionCount} {HelperText}
                        </li>
                    </div>
                {!Loading && 

                    <div className={`${classes.mini_card_body_wrapper}`}>
                        <li><span>Current Budget:</span>  <span className={`${classes.card_min_val}`}>{ActiveLog ? CurrencyFormatter(ActiveLog?.PCMM_RemainBudget) : "Unknown"}</span></li>
                        <li><span>Activity State:</span>  <span className={`${classes.card_min_val}`}>{ActiveLog ? ActiveLog.PCMM_SuspendTransaction === "Yes" ? <UIButton Variant='Danger' Icon={<Warning weight='fill'/>} label={"Suspended"} onClick={()=>{}}/> : <UIButton Icon={<Checks weight='bold'/>} label={"Operational"} onClick={()=>{}}/> : 'Unknown'}</span></li>
                        <li><span>Recent Interaction (Date):</span> <span className={`${classes.card_min_val}`}>{LastTransaction ? ToEthiopianCalander(new Date(LastTransaction?.PTYCSH_DateTime)) : "Unknown"}</span></li>
                        <li><span>Recent Interaction (Clock):</span> <span className={`${classes.card_min_val}`}>{LastTransaction ? new Date(LastTransaction?.PTYCSH_DateTime).toLocaleTimeString() : "Unknown"}</span></li>
                    </div>
                }
                {
                    Loading && 
                    <LoadingPlaceholder placeholder_height={2}/>
                }
            
        </div>
    )
}