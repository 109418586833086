import classes from './ProductionStockOrderProcessorV2.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { useReactToPrint } from 'react-to-print'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'

import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { nanoid } from 'nanoid'

import {SingleProductPackageOrderTemplate} from '../../../../ProductionOrder/Component/InitProductionOrder/ProductOrderUtils'

import PaperHeader from '../../../../../SharedComp/PaperHeader/PaperHeader'
import PaperHash from '../../../../../SharedComp/PaperHash/PaperHash'

import {FetchSavedProductionProductOrder, ApprovePoRequest} from '../../../../../api'
import {FetchSavedProductionOrderConfig, FetchProductionOrderOWner} from '../../../../../SharedComp/API/Production/API_ProductionOrder'

import SingleOrderDataViewWrapper from '../Component/SingleOrderDataViewWrapper/SingleOrderDataViewWrapper'
import GrandTotalDataViewWrapper from '../Component/GrandTotalOrderDataViewWrapper/GrandTotalOrderDataViewWrapper'
import { useToast } from '../../../../../ToastContext'

import { FetchProductionOrderReqMaterial, FetchMixIngredientJoined, FetchMixSaved } from '../../../../../api'
import { FetchBakeryProductByID } from '../../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery';

import ChemiclaCollectionPreviewDoc from './ChemiclaCollectionPreviewDoc/ChemiclaCollectionPreviewDoc'
import { ToEthiopianCalander, ToEthiopianCalanderRaw } from '../../../../../SharedComp/Utility'
import { nextMonday } from 'date-fns'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { ApproveMasterProductionOrderStockRequestV2, QueryMasterProductionOrderDetailByID, QueryMasterProductionOrderForRequestHandlerByID, QueryMasterProductionOrderForRequestOwnerByID, QueryPendingStockReturnRequestDetail } from '../../../../../SharedComp/API/Production/API_MasterProductionV2'


export default function ProductionStockOrderProcessorV2({Preview = false, Close_CALLBACK, DataRefersh, TargetData}) 
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const [OrderOwner, SetOrderOwner] = useState();
    const [OrderHandler, SetOrderHandler] = useState();

    const [Loading, SetLoading] = useState(false);
    const [DataOrigin, SetDataOrigin] = useState(null);


    // After made inside debate we decided that to query the main data here
    const QueryTargetData = async()=>
    {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_order_id", TargetData.MPOM_ID);
            const main_res = await QueryMasterProductionOrderDetailByID(fd);
            await FetchOrderOwner(TargetData.MPOM_ID);
            if(Preview)
            {
                await FetchOrderHandler(TargetData.MPOM_ID)
            }
            if(main_res) 
            {
                SetDataOrigin(main_res);
            }
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
        }
    }



    const FetchOrderOwner = async(_target_id)=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_item_id", _target_id);
            const res = await QueryMasterProductionOrderForRequestOwnerByID(fd);
            SetOrderOwner(res[0]);
        }catch(err) 
        {
            notify.ShowError("Failed to Query Request Ownder: " + err.message);
        }
    }
    const FetchOrderHandler = async(_target_id)=>
        {
            try 
            {
                const fd = new FormData();
                fd.append("target_item_id", _target_id);
                const res = await QueryMasterProductionOrderForRequestHandlerByID(fd);
                SetOrderHandler(res[0]);
            }catch(err) 
            {
                notify.ShowError("Failed to Query Request Ownder: " + err.message);
            }
        }
    const print_ref = useRef();
    const PrintDoc = useReactToPrint({
        documentTitle: auth.activeWorkShopName + "_PO-Requested-Stock" + "_" + new Date().toDateString(),
        content: () => print_ref.current,
        onAfterPrint: ()=>{
            
        }
    })
    const handleApproval = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("source_branch", auth.activeWorkShop);
            fd.append("user_id", auth.user.USR_UserAccountID);
            fd.append("target_order_id", TargetData.MPOM_ID);

            const res = await ApproveMasterProductionOrderStockRequestV2(fd);
            // const res = await ApprovePoRequest(fd); 
             
            
            if(res.MyStateCode !== 1)
            {
                throw new Error(res.Msg);
            }

            notify.ShowSuccess(res.Msg);
            DataRefersh();
            Close_CALLBACK(false);

        }catch(err) 
        {
            notify.ShowError(<div dangerouslySetInnerHTML={{ __html: err.message }} />);
        }
    }
    
    useEffect(()=>{
        if(TargetData) 
        {
            QueryTargetData();
        }
    }, [auth.user, TargetData])
    


    



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>
            <div className={`${data_win.data_main} ${classes.data_main}`}>

                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        JAK <span className={`${data_win.data_create_name}`}>PO Stock Process V2</span>
                    </h3>
                    <button onClick={()=>{Close_CALLBACK(false)}}><FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon></button>
                </div>

               {!Loading && <div className={`${classes.data_preview_wrapper}`}>

                <div className={`${classes.inner_preview_data_wrapper}`}>
                    <div ref={print_ref} className={`${classes.paper_wrapper_dash}`}>
                        <PaperHeader/>
                        
                        <div className={`${classes.print_doc_non_repeat_content}`}>

                            <PaperHash/>
                            <div className={`${classes.meta_data_wrapper}`}>
                                <h2>[#] {auth.activeWorkShopName} </h2>
                                <h2>[#] PO Stock Processing</h2>
                            </div>

                            <div className={`${classes.transcation_owner_wrapper}`}>
                                <div className={`${classes.transcation_owner}`}>

                                    <div className={`${classes.transcation_init}`}>
                                        <h2>Stock Request Info</h2>
                                        <li><span>Request Owner</span> <span style={{fontWeight: 'bold'}}>{OrderOwner?.USR_FirstName} {OrderOwner?.USR_LastName}</span></li>
                                        <li><span>Target Shift</span> <span style={{fontWeight: 'bold'}}>{OrderOwner?.MPOM_Shift}</span></li>
                                        <li><span>Ordered Date</span>  <span style={{fontWeight: 'bold'}}>{OrderOwner?.MPOM_OrderedBy && ToEthiopianCalander(new Date(OrderOwner.MPOM_OrderDateTime))}</span></li>
                                        <li><span>Clock</span> <span style={{fontWeight: 'bold'}}>{OrderOwner ? new Date(OrderOwner.MPOM_OrderDateTime).toLocaleTimeString() : "--"}</span></li>
                                    
                                    </div>

                                </div>
                            </div>

                            <div className={`${classes.entry_detail}`}>
                                <li><span className={`${classes.entry_deatil_tag}`}>Process ID</span> <span className={`${classes.entry_deatil_val}`}>{TargetData.MPOM_OrderID}</span></li>
                            </div>  
                            
                            
                            <div className={`${classes.main_paper_data_wrapper}`}>

                                <div className={`${classes.paper_list_wrapper}`}>
                                 {DataOrigin && <ChemiclaCollectionPreviewDoc ChemicalPreviewPayload={DataOrigin?.OrderProductDetail}/>}   
                                 {DataOrigin && <GrandTotalDataViewWrapper DataList={DataOrigin?.InventoryUtilizationSummery} />}  
                                </div>
                                
                            </div>

                            <div className={`${classes.special_footer_local_wrapper}`}>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Digital Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>{Preview ? OrderHandler?.USR_FirstName + ' ' + OrderHandler?.USR_LastName : `${auth.user?.USR_FirstName} ${auth.user?.USR_LastName}` }</li>
                               {Preview && OrderHandler && <li className={`${classes.print_date}`}>{ToEthiopianCalander(new Date(OrderOwner.MPOM_ApprovedDateTime)) + ' @ ' + new Date(OrderHandler.MPOM_ApprovedDateTime).toLocaleTimeString()}</li>}     
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Physical Authoriziation By</h2>
                                    <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                                <div className={`${classes.footer_user_wrap}`}>
                                    <h2>Received By</h2>
                                    <li className={`${classes.name_manual}`}>Full Name: __________________</li>
                                    <li className={`${classes.sign_manual}`}>Signature: ______________________</li>
                                </div>

                            </div>

                            {/* <PaperFooter Name={auth.employeeData?.FullName} Role={auth.employeeData?.DepName} Phone={auth.employeeData?.PhoneNumber}/> */}
                            <div className={`${classes.final_note_po_stock_out}`}>
                                <li>This document must have all signatures to be considered valid.</li>
                            </div>

                        </div>
                    </div>
                </div>



                </div>}
                {
                    Loading &&
                    <LoadingPlaceholder preview_text='Preparing Document'/>
                }


                <div className={`${classes.action_button_wrapper}`}>

                 {Preview &&  <button onClick={(e)=>{PrintDoc()}} className={`${classes.button_print}`}>Print</button>}
                  {!Preview && !Loading && <button onClick={()=>{
                                        DAControl.Trigger(`Approve Stock Out`, 
                                        `This action will affect Inventory Balance. In other word the "Grand Total" Amount you see will be deducted form your store.`,
                                        true,
                                        5, (val)=>{
                                            if(val !== null) 
                                            {
                                                handleApproval();
                                            }
                                        }   
                                    );}} className={`${classes.button_approve}`}>Approve</button> }
                    <button onClick={()=>{Close_CALLBACK(false)}} className={`${classes.button_dismiss}`}>Close</button>

                </div>

            </div>
        </div>
    )
}