import classes from '../DispatchedInventoryRequestBundle/DispatchedInventoryRequestBundle.module.css'
import { ArrowFatDown, ArrowFatUp, Calendar, CaretCircleDoubleDown, CirclesThreePlus, DotsThreeCircle, DotsThreeCircleVertical, List } from '@phosphor-icons/react'
import UIButton from '../../../../Components/UIButton/UIButton'
import { useRef, useState } from 'react'
import { useAuth } from '../../../../../AuthProvider'
import { useEffect } from 'react'
import LoadingPlaceholder from '../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import { ProcessStockRequestShipingState, QueryAllDispatchedTransaction, QueryAllIncomingTransaction } from '../../../../../SharedComp/API/InventoryStockTransfer/API_FE_InventoryStockTransfer'
import { ToEthiopianCalander } from '../../../../../SharedComp/Utility'
import TimeAgo from '../../../../../SharedComp/TimeElapsed/TimeAgo'
import TransferStateIndicator from '../TransferStateIndicator/TransferStateIndicator'
import IncomingInventoryRequestSingleEntryWrapper from './IncomingInventoryRequestSingleEntryWrapper/IncomingInventoryRequestSingleEntryWrapper'
import { useToast } from '../../../../../ToastContext'
export default function IncomingInventoryRequestBundle({HandleView, RefreshView, HandleTransactionDoc}) 
{
        const notify = useToast();
        const auth = useAuth();
        const [ShowMinFilterDiag, SetShowMinFilterDiag] = useState(false);
        const [DataBuffer, SetDataBuffer] = useState([]);
        const [DataBufferOrigin, SetDataBufferOrigin] = useState([]);
        const [Loading, SetLoading] = useState(false);
        const [LocalRefresh, SetLocalRefresh] = useState(null);
        const PopupMiniModalRef = useRef();
    
        const FetchAllDispacthedBranchRelatedTransaction = async()=>
        {
            SetLoading(true);
            try 
            {
                const fd = new FormData();
                fd.append("target_branch", auth.activeWorkShop);
                const main_res = await QueryAllIncomingTransaction(fd);
                const _sorted_data = SortByDate(main_res);
                FilterByToDate(_sorted_data);
                SetDataBufferOrigin(main_res);
                SetLoading(false);
            }catch(err) 
            {
                SetLoading(false);
            }
        }
        const UpdateShipingState = async(request_id)=>
        {
            notify.ShowLoad("Processing your request...", 10);
            try 
            {
                const fd = new FormData();
                fd.append("target_request_id", request_id);
                const main_res = await ProcessStockRequestShipingState(fd);
                notify.ShowSuccessUpdate(main_res.Msg, 10);
                SetLocalRefresh(new Date().getMilliseconds());
            }catch(err) 
            {
                notify.ShowErrorUpdate(err.message, 10);
            }
        }
        const DataViewTrigger = (data)=>
        {
            HandleView(data);
        }
        const TransactionPaperView = (data)=>
        {
            HandleTransactionDoc(data);
        }
        useEffect(()=>{
            FetchAllDispacthedBranchRelatedTransaction();
        }, [auth.activeWorkShop, RefreshView, LocalRefresh]) 

        useEffect(()=>
        {
            const handleClickOutside = (event) => {
                if (PopupMiniModalRef.current && !PopupMiniModalRef.current.contains(event.target)) {
                    SetShowMinFilterDiag(false);
                }
                };
            
                document.addEventListener('mousedown', handleClickOutside);
                return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                };
        }, [])

        const SortByDate = (data_array)=>
        {
            data_array.sort((a, b)=> new Date(b.ISTRV2_RequestInitDate) - new Date(a.ISTRV2_RequestInitDate));
            return data_array;
        }
        const FilterByKeyState = (key_state, data_array)=>
        {
            const _temp_buffer = [];
            for(let i = 0; i < data_array.length; i++) 
            {
                if(data_array[i].ISTRV2_RequestState === key_state)
                {
                    _temp_buffer.push(data_array[i]);
                }
            }
            SetDataBuffer(_temp_buffer);
        }
        const FilterByToDate = (data_array)=>
        {
            const _temp_buffer = [];
            for(let i = 0; i < data_array.length; i++) 
            {
                const _base_date = new Date(data_array[i].ISTRV2_RequestInitDate);
                const _today  = new Date();

                if(
                    _base_date.getFullYear() === _today.getFullYear() &&
                    _base_date.getMonth() === _today.getMonth() && 
                    _base_date.getDate() === _today.getDate()
                )
                {
                    _temp_buffer.push(data_array[i]);
                }
            }

            SetDataBuffer(_temp_buffer);
        }

    return (
        <div className={`${classes.dispatched_iniventory_request_bundle_master_wrapper}`}>
        <div tabIndex={0} className={`${classes.dispatched_iniventory_request_bundle_wrapper_header}`}>
            <h2 style={{display: 'inline-flex'}}><span onClick={()=>{SetShowMinFilterDiag(!ShowMinFilterDiag)}} style={{padding: '0px 4px', cursor: 'pointer'}}><CaretCircleDoubleDown  weight='bold' size={17}/></span> Incoming Request </h2>
            <span><ArrowFatDown weight='fill'/></span>

            {ShowMinFilterDiag && <div ref={PopupMiniModalRef} className={`${classes.dispatched_iniventory_request_bundle_filter_drop_down_list}`}>
                    <li onClick={()=>{FilterByToDate(DataBufferOrigin);SetShowMinFilterDiag(false)}}>Today Transaction</li>
                    <li onClick={()=>{SetDataBuffer(DataBufferOrigin);SetShowMinFilterDiag(false)}}>Show All Transaction</li>
                    <hr />
                    <li  onClick={()=>{FilterByKeyState("Received", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Completed Transaction</li>
                    <li onClick={()=>{FilterByKeyState("Pending", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Pending Transaction</li>
                    <li onClick={()=>{FilterByKeyState("Processing", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Processing Transaction</li>
                    <li  onClick={()=>{FilterByKeyState("Shipped", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Underway Transaction</li>
                    <li  onClick={()=>{FilterByKeyState("Rejected", DataBufferOrigin);SetShowMinFilterDiag(false)}}>Rejected Transaction</li>
            </div> }
        </div>

        <div className={`${classes.transaction_list_wrapper}`}>
            {
                DataBuffer.map((item, idx)=>(
                    <IncomingInventoryRequestSingleEntryWrapper 
                        key={idx} 
                        HandleDataPreview={DataViewTrigger} 
                        TargetItem={item}
                        DocViewCallback={TransactionPaperView}
                        ShipProduct={UpdateShipingState}
                    />
                ))
            }
            {!Loading &&
                DataBuffer.length === 0 && 
                <div className={`${classes.empty_stock_transaction_history}`}>
                    <div>
                        <li>Empty <sub>/ˈɛm(p)ti/</sub></li>
                        <li><sub>adjective</sub></li>
                        <li>1.containing nothing; not filled or occupied.</li>
                    </div>
                </div>
            }
            {
                Loading && <LoadingPlaceholder preview_text='Loading Transactions' />
            }
        </div> 
    </div>
    )
}