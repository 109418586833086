import classes from './PittyCashBranch.module.css'
import Chart from 'react-apexcharts'

import ReleaseCash from './Component/ReleaseCash/ReleaseCash';
import ReceiptOfFunds from './Component/ReceiptOfFunds/ReceiptOfFunds';

import { useEffect, useState } from 'react';
import { getBaseURL } from '../../../SharedComp/BaseUrlConfig';
import { useAuth } from '../../../AuthProvider';
import DotsGif from '../../../SharedComp/CssGif/DotsGif';

import BudgetAllocationHistoryLocalView from './BudgetAllocationHistoryLocalView/BudgetAllocationHistoryLocalView';
import BudgetCashUtilizationGraphView from './BudgetCashUtilizationGraphView/BudgetCashUtilizationGraphView';
import BudgetExpenditureLocalView from './BudgetExpenditureLocalView/BudgetExpenditureLocalView';

export default function PittyCashBranchLevel() 
{

    const auth = useAuth();
    const [OpenReleaseCash, SetOpenReleaseCash] = useState(false);
    const [OpenReceiptFunds, SetOpenReceiptFunds] = useState(false);
    const [loadingExpense, setLoadingExpense] = useState(false);

    // Notifiction
    const [MsgPkg, SetMsgPkg] = useState({});
    const [OpenMyNoti, SetOpenMyNoti] = useState(false);
    const [RefeshList, SetRefeshList] = useState(false);
    

    // Expense List
    const [ExpenseList, SetExpenseList] = useState([]);
    const [GrandTotalExpense, SetGrandTotalExpense] = useState(0);

    // Deposit List
    const [DepositList, SetDepositList] = useState([]);

    // Active open book
    const [ActiveBook, SetActiveBook] = useState(null);
    const [UtilizedAmount, SetUtilizedAmount] = useState(0.01);
    const [AvailableAmount, SetAvailableAmount] = useState(0.01);

    const [DataPreviewOriginal, SetDataPreviewOriginal] = useState(null);
    const [DetailTransactionEntry, SetDetailTransactionEntry] = useState([]);
    const [RefershDataViewPoint, SetRefershDataViewPoint] = useState(new Date().getMilliseconds());


    const UpdateDataPreviewBuffer = (data)=>
    {
        SetDataPreviewOriginal(data);
        SetUtilizedAmount(data.Core.PCMM_InitialAllocationAmount - data.Core.PCMM_RemainBudget);
        SetAvailableAmount(data.Core.PCMM_RemainBudget);
        SetDetailTransactionEntry(data.Transaction);
    }

    useEffect(()=>{

    }, [auth.user, DataPreviewOriginal])

    const RefreshDataView = ()=>
    {
        SetRefershDataViewPoint(new Date().getMilliseconds());
    }







     const PieChartData = [AvailableAmount, UtilizedAmount];

    return (
        <div className={`${classes.pitty_cash_branch_level_master_wrapper}`}>

            <div className={`${classes.pitty_cash_branch_level_inner_wrapper}`}>

                {OpenReleaseCash && <ReleaseCash Close_CALLBACK={SetOpenReleaseCash} UpdateView={RefreshDataView} />}
                {OpenReceiptFunds && <ReceiptOfFunds Close_CALLBACK={SetOpenReceiptFunds} />}

                <div className={`${classes.pitty_cash_bl_inner_wrapper}`}>

                        <div className={`${classes.pitty_cash_bl_allocation_View_wrapper}`}>

                            <BudgetCashUtilizationGraphView GraphData={PieChartData}/>
                            <BudgetAllocationHistoryLocalView  RefershData={RefershDataViewPoint} DataPreviewCallback={UpdateDataPreviewBuffer} />

                        </div>

                        <div className={`${classes.pitty_cash_transaction_main_wrapper}`}>

                            <BudgetExpenditureLocalView ExpList={DetailTransactionEntry} ReleaseCash_Callback={SetOpenReleaseCash} />
                           
                        </div>
                </div>

            </div>
        </div>
    )
}