import classes from './MasterBankType.module.css'

import DataViewTableProxy from '../../../../SharedComp/DataViewTableProxy/DataViewTableProxy'
import { useEffect, useState } from 'react'

import MBT_Create from './MBT_Create/MBT_Create'
import MBT_Edit from './MBT_Edit/MBT_Edit'

import { useToast } from '../../../../ToastContext'
import { PurgeBankType, QueryAllMasterDataBank } from '../../../../SharedComp/API/MDCluster/API_FE_MDCluster_I'
import { useAuth } from '../../../../AuthProvider'
import { faBan, faPenNib } from '@fortawesome/free-solid-svg-icons'
import { useDAControl } from '../../../../DAControlContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingPlaceholder from '../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'

export default function MasterBankType() 
{

    const notify = useToast();
    const auth = useAuth();
    const DAControl = useDAControl();

    const [RefreshView, SetRefreshView] = useState(false);
    const [OpenMasterBankCreate, SetOpenMasterBankCreate] = useState(false);
    const [OpenMasterBankUpdate, SetOpenMasterBankUpdate] = useState(false);
    const [TargetEditData, SetTargetEditData] = useState(null);
    const [DataList, SetDataList] = useState([]);
    const [DataListCopy, SetDataListCopy] = useState([]);
    const [Loading, SetLoading] = useState(false);

    const FetchBanks = async()=>
    {
        SetLoading(true);
        try 
        {
            const main_res = await QueryAllMasterDataBank(auth.token);
            SetDataList(main_res);
            SetDataListCopy(main_res);
            SetLoading(false);
        }catch(err) 
        {
            SetLoading(false);
            notify.ShowError("Failed to fetch Banks!");
        }
    }
    const HandleUpdateView = ()=>
    {
        SetRefreshView(!RefreshView);
    }
    useEffect(()=>{
        FetchBanks();
    }, [RefreshView])

    const FilterByName = (key_name)=>
    {
        const valid_list = [];
        for(let i = 0; i < DataList.length; i++) 
        {
            if(DataList[i].HRMBNKT_Name.toLowerCase().includes(key_name.toLowerCase())) 
            {
                valid_list.push(DataList[i]);
            }
        }
        SetDataListCopy(valid_list);
    }



    return (
        <div className={`${classes.data_pro_table_wrapper}`}>

            {OpenMasterBankCreate && <MBT_Create UpdateView={HandleUpdateView} OpenController={SetOpenMasterBankCreate}/>}
            {OpenMasterBankUpdate && <MBT_Edit UpdateView={HandleUpdateView} OpenController={SetOpenMasterBankUpdate} TargetData={TargetEditData}/>}



            <div className={`${classes.md_inner_data_table_wrap}`}>
                        <DataViewTableProxy 
                            IsEmpty={DataList.length === 0}
                            OnFilter={FilterByName}
                            OpenDataCreate={SetOpenMasterBankCreate}
                            HeaderName={
                                <div>
                                    <h2><b>Master Bank</b> Type</h2>
                                </div>
                            }
                            DataArray={
                                <div>
                                    {!Loading &&
                                        DataListCopy.map((item, idx)=>(
                                            <li key={item.HRMBNKT_Name + idx}>
                                                <span>{item.HRMBNKT_Name}</span>
                                                <span>{item.HRMBNKT_Code}</span>
                                                <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                                {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData && <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                                    onClick={()=>{DAControl.Trigger(`Purge Master Bank type`, 
                                                        `You are about to purge Master Bank Type '${item.HRMBNKT_Name}'.
                                                        This action is IRREVERSIBLE!`, 
                                                        item.HRMBNKT_ID,
                                                        10,
                                                        async (data)=>{
                                                            if(!data)
                                                                return;

                                                                try 
                                                                {
                                                                    const fd = new FormData();
                                                                    fd.append('target_id', data);
                                                                    const main_res = await PurgeBankType(fd,auth.token);
                                                                    notify.ShowSuccess(main_res.Msg);
                                                                    SetRefreshView(!RefreshView);
                                                                } catch(err) {
                                                                   notify.ShowError(err.message);
                                                                }

                                                        })}}
                                                    >
                                                        <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                        <span>Purge</span>
                                                    </button>}
                                                    {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{SetTargetEditData(item); SetOpenMasterBankUpdate(true)}}>
                                                        <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                        <span>Manage</span>
                                                    </button>}
                                                </span>
                                            </li>
                                        ))
                                    }
                                    {
                                        Loading &&
                                        <LoadingPlaceholder/>
                                    }
                                    
                                </div>
                            }
                        />
            </div>
        </div>
    )
}