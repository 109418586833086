import classes from './StockManagerStyle.module.css'
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

// Internal Component
import StockManagerCard from './Component/Card/StockManagerCard';
import StockView from './Component/StockView/StockView';
import { useAuth } from '../../AuthProvider';
import { getBaseURL } from '../../SharedComp/BaseUrlConfig';
import { getBaseSocket } from '../../SocketBase';
import { FileXls, Funnel } from '@phosphor-icons/react';
import UIButton from '../Components/UIButton/UIButton';
import { useToast } from '../../ToastContext';
import LoadingPlaceholder from '../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { QueryInventoryStockByBranch } from '../../SharedComp/API/InventoryStock/API_FE_InventoryStock';

const generateAnimationDelay = (index) => `${index * 0.06}s`;
export default function StockManager() 
{
    
    const notify = useToast();
    const socket = getBaseSocket();
    const [viewDataPkg, SetViewDataPkg] = useState();
    const [OpenStockView, SetOpenStockView] = useState(false);
    const [MasterInvt, SetMasterInvt] = useState([]);
    const [MasterInvtOriginal, SetMasterInvtOriginal] = useState([]);
    const [stockData, setStockData] = useState([]);
    const [Loading, SetLoading] = useState(false);

    const auth = useAuth();

    function CreateDataList(data) 
    {

        const _temp_map = new Map();
        for(const [key, item] of data.entries())
        {
            const _sub_array = []
            for(let i = 0; i < item.length; i++) 
            {
                if(item[i].AvailQuantity > 0) 
                {
                    _sub_array.push(item[i]);
                }
            }

            if(item.length > 1) 
            {
                if(!(auth.GlobalConfig && auth.GlobalConfig?.GC_SuppressStockIssueNotification))  
                {
                    notify.ShowWarn(`We have detected that the inventory item (${item[0].IVItemName}) is present in multiple stores. This may cause issues during the automatic auditing process. To resolve this, please unmount the inventory from the current store and assign it to the appropriate one. Alternatively, you may contact your system administrator for assistance.`)
                }
            }

            if(auth.GlobalConfig && auth.GlobalConfig?.GC_HideInventoryIfBalanceIsZero) 
            {
                if(_sub_array.length > 0) 
                {
                    _temp_map.set(key, item);
                }
            } else 
            {
                _temp_map.set(key, item);
            }
        }

        SetMasterInvt(Array.from(_temp_map, ([ItemID, items]) => ({
            ItemID,
            items
          })));
          SetMasterInvtOriginal(
            Array.from(_temp_map, ([ItemID, items]) => ({
                ItemID,
                items
              }))
          );
    }

    function SocketDynamicUpdate(data) 
    {
        FetchStockData();
    }

   const PrepDataToView = async() => {
        SetLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("workspace_id", auth.activeWorkShop);
            const main_res = await QueryInventoryStockByBranch(fd);
            setStockData(main_res);
            const itemMap = new Map();
            main_res.forEach(item => {
                if(!itemMap.has(item.ItemID)) 
                {
                    itemMap.set(item.ItemID, []);
                }
                itemMap.get(item.ItemID).push(item);
            })

            CreateDataList(itemMap);
            SetLoading(false);

        }catch(err) 
        {
            notify.ShowError(err.message);
            SetLoading(false);
        }
   }

    function FetchStockData() 
    {
        PrepDataToView();
    }

    useEffect(()=>{
        // console.log(MasterInvt); 
    }, [MasterInvt])
 
    useEffect(() => {
        FetchStockData(); 

        socket.on("HANDLE_PO_PROCESS", SocketDynamicUpdate);
        return ()=>{
            socket.off("HANDLE_PO_PROCESS", SocketDynamicUpdate)
        }


    }, [socket, auth.activeWorkShop]);


    const FilterStockItemByName = (key_filter)=>
    {
        const valid_list = [];
        // console.log(MasterInvtOriginal);
        for(let i = 0; i < MasterInvtOriginal.length; i++) 
        {
            const __exist_name = MasterInvtOriginal[i].items[0].IVItemName;
            if(__exist_name.toLowerCase().includes(key_filter.toLowerCase())) 
            {
                valid_list.push(MasterInvtOriginal[i]);
            }
        }
        SetMasterInvt(valid_list);
    }


    return (
        <div className={`${classes.main_stock_manager_wrapper}`}>


            {OpenStockView && 
                <StockView 
                    OpenController={SetOpenStockView} 
                    TargetID={viewDataPkg.IvsID}
                    DataItemID={viewDataPkg.ItemID}
                    StockName={viewDataPkg.ItemName}
                /> }
            

            <div className={`${classes.stock_manager_header_wrapper}`}>

                <div className={`${classes.stock_filter_wrapper}`}>
                    <div className={`${classes.workshop_filter_input_wrapper}`}>
                        <input onChange={(e)=>{FilterStockItemByName(e.target.value)}} autoComplete='off' type="text" placeholder='Search' /> 
                        <span><Funnel weight='fill'/></span>
                    </div>
                </div>
                {/* <div className={`${classes.stock_filter_wrapper_action_buttons}`}>
                    <UIButton onClick={()=>{}} Variant='Secondary' Icon={<FileXls weight='bold'/>} label={"XLS"}/>
                </div> */}

           </div>


            <div className={`${classes.sorck_manager_card_list_main_wrapper}`}>
                 <div className={`${classes.sock_card_list_upper_wrapper}`}>
                    <div className={`${classes.stock_manager_card_List_wrapper}`}>
                        <div className={`${classes.stock_manager_card_list}`}>

                            {!Loading && MasterInvt?.map(({ ItemID, items }, idx) => (
                                <StockManagerCard   
                                    key={ItemID} 
                                    OpenViewController={SetOpenStockView} 
                                    AnimDelay={generateAnimationDelay(idx + 1)} 
                                    Data={items} 
                                    setViewData={SetViewDataPkg} />
                            ))}
                            {
                                Loading && 
                                <LoadingPlaceholder preview_text='Fetching Your Inventory'/>
                            }
                            
                        </div> 
                    </div>
                 </div>
            </div>

        </div>
    );
}