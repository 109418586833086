import classes from './DirectStockOrderBranchBundle.module.css'
import TimeAgo from '../../../SharedComp/TimeElapsed/TimeAgo'
import { CheckCircle, CheckSquare, CircleNotch, Paperclip, Tray, Warning } from '@phosphor-icons/react'
import { FetchProductionOrderBranchStockedOut } from '../../../api'
import { useEffect } from 'react'
import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'
import UIButton from '../../Components/UIButton/UIButton'
import { ToEthiopianCalander } from '../../../SharedComp/Utility'
import { useState } from 'react'
import { QueryDirectStockRequestGroupByBranch, RejectDirectStockRequestRequest } from '../../../SharedComp/API/DirectStockRequest/API_FE_DirectStockRequest'
import { useAuth } from '../../../AuthProvider'
import DirectStockOrderApprovalDocument from '../DirectStockOrderApprovalDocument/DirectStockOrderApprovalDocument'
import { useToast } from '../../../ToastContext'
import { useDAControl } from '../../../DAControlContext'

export default function DirectStockOrderBranchBundle({WorkspaceData}) 
{
    const notify = useToast();
    const DAControl = useDAControl();
    const [DataPackage, SetDataPackage] = useState([]);
    const [PendingRequestArray, SetPendingRequestArray] = useState([]);
    const [FinalizedRequestArray, SetFinalizedRequestArray] = useState([]);

    const [ChoicenArrayDataSet, SetChoicenArrayDataSet] = useState([]);

    const [IncomingLoading, SetIncomingLoading] = useState(false);
    const auth = useAuth();
    const [OpenApprovalDocument, SetOpenApprovalDocument] = useState(false);
    const [PreviewRequesstTarget, SetPreviewRequesstTarget] = useState();
    const [DataViewRefersh, SetDataViewRefersh] = useState(false);
    const [SelectedDate, SetSelectedDate] = useState(new Date());


    const [IsPreviewMode, SetIsPreviewMode] = useState( false);
    const [OpenPreview, SetOpenPreview] = useState(false);

    const FormalizeDataEntry = (data)=>
    {
        const __pending_entry = [];
        const __finlized_entry = [];


        for(let i = 0; i < data.length; i++) 
        {
            if(data[i].DSRG_ResolveState === "No")
            {
                __pending_entry.push(data[i]);
            } else 
            {
                __finlized_entry.push(data[i]);
            }
        }
        SetPendingRequestArray(__pending_entry);
        SetChoicenArrayDataSet(__pending_entry); // Default show pending

        SetFinalizedRequestArray(__finlized_entry);
    }
    const QueryActiveProductionOrders = async()=>{
        SetIncomingLoading(true);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", WorkspaceData.BranchID);
            const res = await QueryDirectStockRequestGroupByBranch(fd);
            SetDataPackage(res);
            FormalizeDataEntry(res);
            SetIncomingLoading(false);
        }catch(err) 
        {
            SetIncomingLoading(false);
        }
    }
    const SortByResolveDate = (data_array)=>
    {
        data_array.sort((a, b)=> new Date(b.DSRG_ResolveDateTime) - new Date(a.DSRG_ResolveDateTime));

        SetChoicenArrayDataSet(data_array)
        return data_array;
    }
    useEffect(()=>
    {
        QueryActiveProductionOrders();
    }, [DataViewRefersh])

    const OpenDataPreview = async(data)=>
    {
        SetPreviewRequesstTarget(data);
        SetOpenApprovalDocument(true);
        SetIsPreviewMode(false);
    }
    const OpenDataJustForPreview = async(data)=>
    {
        SetPreviewRequesstTarget(data);
        SetOpenApprovalDocument(true);
        SetIsPreviewMode(true);
    }
    const RefreshData = ()=>
    {
        SetDataViewRefersh(!DataViewRefersh);
    }
    const RejectDirectStockRequest = async(target_item)=>
    {
        notify.ShowLoad("Processing your request....", 10);
        try 
        {
            const fd = new FormData();
            fd.append("group_id", target_item.DSRG_ID);

            const main_res = await RejectDirectStockRequestRequest(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            RefreshData();
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }
    const FilterByDate = (_new_date)=>
    {
        const _valid_entry = [];

        for(let i = 0; i < DataPackage.length; i++) 
        {
            const _ex_date = new Date(DataPackage[i].DSRG_ResolveDateTime);
            const _crt_date = new Date(_new_date);
            if(
                _ex_date.getFullYear() === _crt_date.getFullYear() &&
                _ex_date.getMonth() === _crt_date.getMonth() && 
                _ex_date.getDate() === _crt_date.getDate()
            ) 
            {
                _valid_entry.push(DataPackage[i]);
            }
        }
        SetChoicenArrayDataSet(_valid_entry);
    }

    return (
        <div className={`${classes.order_detail_man_wrapper}`}>

            {OpenApprovalDocument && <DirectStockOrderApprovalDocument Preview={IsPreviewMode}   DataRefersh={RefreshData} Close_CALLBACK={SetOpenApprovalDocument} TargetData={PreviewRequesstTarget}/>}            

                    <div className={`${classes.filter_workshop_by_name}`}>
                        <div className={`${classes.workshop_filter_input_wrapper}`}>
                            <li> Direct Stock Request - {WorkspaceData.BranchName}</li>
                        </div> 
                        <div className={`${classes.workshop_filter_input_wrapper_date_choice}`}>
                            <li>{ToEthiopianCalander(SelectedDate) }</li>
                            <input onChange={(e)=>{SetSelectedDate(new Date(e.target.value)); FilterByDate(new Date(e.target.value))}} type="date" />
                        </div>
                        <div className={`${classes.workshop_filter_input_wrapper}`}>
                            <UIButton Variant='Secondary' Icon={<CircleNotch weight='bold'/>} label={"Show Pending"} onClick={()=>{SetChoicenArrayDataSet(PendingRequestArray)}}/>
                            <UIButton Variant='Secondary' Icon={<CheckCircle weight='bold'/>} label={"Show Completed"} onClick={()=>{SortByResolveDate(FinalizedRequestArray);}}/>
                            <UIButton Variant='Secondary' Icon={<CheckSquare weight='bold'/>} label={"Show All"} onClick={()=>{SetChoicenArrayDataSet(DataPackage)}}/>
                        </div>

                        
                </div>

                <div className={`${classes.list_box_wrapper}`}>
                    <div className={`${classes.active_order_main_list_wrapper}`}>
                        <li className={`${classes.header_list}`}>
                            <span>Request Date</span>
                            <span>Approved Date</span>
                            <span>Requested By</span>
                            <span>Reason</span>
                            <span>Action</span>
                        </li>
                        <div className={`${classes.list_data_wrapper}`}>
                            { !IncomingLoading &&
                                ChoicenArrayDataSet.map((item, idx)=>(
                                    <li key={idx}>
                                        <span>{ToEthiopianCalander(new Date(item.DSRG_RequestDateTime))} @ {new Date(item.DSRG_RequestDateTime).toLocaleTimeString()}</span>
                                        <span>{item.DSRG_ResolveDateTime ? <span>{ToEthiopianCalander(new Date(item.DSRG_ResolveDateTime))} // <TimeAgo show_suffix={true} date={new Date(item.DSRG_ResolveDateTime)}/></span>  : '-'}</span>
                                        <span>{item.USR_FirstName} {item.USR_LastName}</span>
                                        <span>{item.DSRG_RequestReason}</span>
                                        <span style={{display: 'flex', justifyContent: 'center'}}>
                                             {item.DSRG_ResolveState === "No" ?    
                                                <>
                                                    <UIButton label={"Show Request"} Icon={<CheckCircle weight='bold'/>} onClick={()=>{OpenDataPreview(item)}}/>
                                                </>
                                                : <UIButton label={"Preview Document"} Icon={<Paperclip weight='bold'/>} onClick={()=>{OpenDataJustForPreview(item)}}/>}
                                        </span>
                                    
                                    </li>
                                ))
                            }
                            {IncomingLoading && <LoadingPlaceholder/>}
                            {
                                !IncomingLoading &&
                                ChoicenArrayDataSet.length === 0 && 
                                <div className={`${classes.empty_box_wrapper}`}>
                                    <ul><Tray fontSize={30}/></ul>
                                    <ul>No Data</ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
    )
}