import classes from './StockRequestGroup.module.css'
import { useAuth } from '../../../../../AuthProvider'
import { useDAControl } from '../../../../../DAControlContext'
import { useToast } from '../../../../../ToastContext'
import { Tooltip } from 'react-tooltip'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
import TimeElapsed from '../../../../../SharedComp/TimeElapsed/TimeElapsed'

import UIButton from '../../../../Components/UIButton/UIButton'
import { CheckCircle, Warning } from '@phosphor-icons/react'

import { AcceptChefsStockRequestItem} from '../../../../../SharedComp/API/CK/AP_CenteralKicthenChef'
import { useEffect, useState } from 'react'
import { getBaseURL } from '../../../../../SharedComp/BaseUrlConfig'
import { QueryBaseEmployeeBySyntID } from '../../../../../SharedComp/API/BaseEmployee/API_FE_BaseEmployee'

import ApproveChefStockRequestDocPreview from '../ApproveChefStockRequestDocPreview/ApproveChefStockRequestDocPreview'


export default function StockRequestGroup({_key_key, TargetData, DataRefreshV1, DR_Order = false})
{

    const auth = useAuth();
    const DAControl = useDAControl();
    const notify = useToast();

    const [OpenApproveDocPreview, SetOpenApproveDocPreview] = useState(false);
    const [TargetChef, SetTargetChef] = useState(null);
    const [CanBeAccepted, SetCanBeAccepted] = useState(true);
    const [DataViewRefresh, SetDataViewRefresh] = useState(new Date());

    // Common DataSet
    const [InventoryPackageList, SetInventoryPackageList] = useState(DR_Order ? TargetData.RequestPackage : TargetData.InventoryList);


    const QueryTargetChefInfo = async()=>
    {
        try 
        {
            const fd = new FormData();
            fd.append("target_id", DR_Order ? TargetData.USR_EmployeeID : TargetData.KPSR_ChefID);
            const main_res = await QueryBaseEmployeeBySyntID(fd);

            SetTargetChef(main_res[0]);
        }catch(err) 
        {
            
        }
    }

    useEffect(()=>{

        QueryTargetChefInfo();

    }, [])


    return (
        <div key={_key_key} className={`${classes.main_order_group_wrapper}`}>
            <Tooltip className={`${data_win.tool_quick}`} id="offical_doc" place="top" />

            {OpenApproveDocPreview && <ApproveChefStockRequestDocPreview DR_request={DR_Order} DataRefresh={DataRefreshV1} RequestOwner={TargetChef} TargetData={TargetData} InventoryList={InventoryPackageList} OpenController={SetOpenApproveDocPreview} />}
            
            
            <div className={`${classes.main_order_group_header_wrapper}`}>
                <h3>Stock Request <sub>{<TimeElapsed Time={DR_Order ? TargetData.RequestTime : TargetData.KPSR_Date} />}</sub></h3>

                <div className={`${classes.right_size_header_action_wrapper}`}>
                    {/* <UIButton Icon={<TrashSimple weight='bold' />} label={"Reject"} Variant='Danger'/> */}

                 {CanBeAccepted && <UIButton onClick={()=>{SetOpenApproveDocPreview(true);}} Icon={<CheckCircle weight='bold' />} label={"Preview Request"} />}
                {!CanBeAccepted && <UIButton Variant='Secondary' label={"Approval restricted"} Icon={<Warning weight='fill'/>} onClick={()=>{notify.ShowWarn("Acceptance is blocked due to insufficient inventory. Please Notify Inventory Manager, or discuss with the chef to adjust the requested amount.")}}/>}
                </div>
            </div>

            <div className={`${classes.request_body_wrapper}`}>

                <div className={`${classes.order_info_wrapper}`}>

                    <div className={`${classes.order_owner_wrapper}`}>

                        <div className={`${classes.chef_pp_warpper}`}>
                            <img src={getBaseURL() + TargetChef?.JAKSBE_EmployeeContextProfile.EDOC_Profile.EDOCP_ProfilePicture} alt="" />

                        </div>

                        <div className={`${classes.owner_name_and_stuff}`}>
                            <li>{TargetChef?.JAKSBE_FullName}</li>
                        </div>

                    </div> 
                    <div className={`${classes.order_prod_list_wrapper}`}>

                        {
                            InventoryPackageList.map((item, idx)=>(
                                    <li  key={item.IVItemName}>
                                        <span>{item.IVItemName}</span>
                                        <span className={`${classes.chef_stock_request}`}>{item.RequestAmount} {item.UomDescription} </span>
                                    </li>
                            ))
                        }
                        
                        
                    </div>


                </div>

            </div>

        </div>
    )
}