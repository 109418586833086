import classes from './BaverageCluster.module.css'

import DataViewTableProxy from '../../../SharedComp/DataViewTableProxy/DataViewTableProxy'

import {FetchBeverageGroupAll, PurgeBeverageGroupByID,PurgeBeverageProductByID, FetchBeverageProductAll} from '../../../SharedComp/API/CoreDataClusters/API_FE_Baverage'

// Group
import CreateBaverageGroup from './Component/BaverageGroup/CreateBaverageGroup/CreateBaverageGroup'
import EditBaverageGroup from './Component/BaverageGroup/EditBaverageGroup/EditBaverageGroup'

// Product
import CreateBaverageProduct from './Component/BaverageProduct/CreateBaverageProduct/CreateBaverageProduct'
import EditBaverageProduct from './Component/BaverageProduct/EditBaverageProduct/EditBaverageProduct'

import { useEffect, useState } from 'react'
import { useAuth } from '../../../AuthProvider'
import { useToast } from '../../../ToastContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPenNib } from '@fortawesome/free-solid-svg-icons'
import { useDAControl } from '../../../DAControlContext'
import { Empty } from '@phosphor-icons/react'

import LoadingPlaceholder from '../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder'


const currency_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const human_readable_format = (val)=> 
{
    return val.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export default function BaverageCluster()
{

    const auth = useAuth();
    const notify = useToast();
    const DAControl = useDAControl();

    // Group
    const [OpenCreateBeverageGroup, SetOpenCreateBeverageGroup] = useState(false);
    const [OpenEditBeverageGroup, SetOpenEditBeverageGroup] = useState(false);
    const [BeverageGroupTargetEditData, SetBeverageGroupTargetEditData] = useState(null);
    const [BaverageGroupListOriginal, SetBaverageGroupListOriginal] = useState([]);
    const [BaverageGroupList, SetBaverageGroupList] = useState([]);
    const [RefreshBaverageGroupView, SetRefreshBaverageGroupView] = useState(false);


    // Product
    const [OpenCreateBaverageProduct, SetOpenCreateBaverageProduct] = useState(false);
    const [OpenEditBaverageProduct, SetOpenEditBaverageProduct] = useState(false);
    const [BaverageProductTargetProductForEdit, SetBaverageProductTargetProductForEdit] = useState(null);
    const [BaverageProductListOriginal, SetBaverageProductListOriginal] = useState([]);
    const [BaverageProductList, SetBaverageProductList] = useState([]);
    const [RefreshBaverageProductView, SetRefreshBaverageProductView] = useState(false);

    // Loading
    const [LoadingBavGroup, SetLoadingBavGroup] = useState(false);
    const [LoadingBavItems, SetLoadingBavItems] = useState(false);


    const FetchBaverageGroups = async()=>
    {
        SetLoadingBavGroup(true);
        try 
        {

            const main_res = await FetchBeverageGroupAll(auth.token);
            SetBaverageGroupListOriginal(main_res);
            SetBaverageGroupList(main_res);
            SetLoadingBavGroup(false);
        }catch(err) 
        {
            SetLoadingBavGroup(false);
            notify.ShowError(err.message);
        }
    }
    const FetchBaverageProductList = async()=>
    {
        SetLoadingBavItems(true);
        try 
        {

            const main_res = await FetchBeverageProductAll(auth.token);
            SetBaverageProductListOriginal(main_res);
            SetBaverageProductList(main_res);
            SetLoadingBavItems(false);
        }catch(err) 
        {
            SetLoadingBavItems(false);
            notify.ShowError(err.message);
        }
    }

    // Purge
    const HandleBaverageGroupPurge = async(id)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", id);

            const main_res = await PurgeBeverageGroupByID(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshBaverageGroupView(!RefreshBaverageGroupView);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }
    const HandleBavergaeProductPurge = async(id)=>
    {
        notify.ShowLoad("Processing your request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append("target_id", id);

            const main_res = await PurgeBeverageProductByID(fd);
            notify.ShowSuccessUpdate(main_res.Msg, 10);
            SetRefreshBaverageProductView(!RefreshBaverageProductView);
        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }
    }

    // Manage
    const HandeleBeverageManage = async(data)=>
    {
        SetBeverageGroupTargetEditData(data);
        SetOpenEditBeverageGroup(true);
    }
    const HandleBaverageProductmanage = async(data)=>
    {
        SetBaverageProductTargetProductForEdit(data);
        SetOpenEditBaverageProduct(true);
    }

    const FilterBaverageGroupByName = async(_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < BaverageGroupListOriginal.length; i++) 
        {
            const check = BaverageGroupListOriginal[i].CMBVG_Name.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(BaverageGroupListOriginal[i])
        }

        SetBaverageGroupList(temp_list);
    }
    const FilterBaverageProductByName = async(_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < BaverageProductListOriginal.length; i++) 
        {
            const check = BaverageProductListOriginal[i].CMBVP_Name.toLowerCase();
            if(check.includes(_key.toLowerCase()))
                temp_list.push(BaverageProductListOriginal[i])
        }

        SetBaverageProductList(temp_list);
    }
    const FilterBaverageProductByGroup = async(group_key)=>
    {
        const temp_list = [];
        for(let i = 0; i < BaverageProductListOriginal.length; i++) 
        {
            if(BaverageProductListOriginal[i].CMBVP_ParentID === group_key)
                temp_list.push(BaverageProductListOriginal[i])
        }

        SetBaverageProductList(temp_list);
    }

    useEffect(()=>
    {
        FetchBaverageGroups();
    }, [OpenCreateBeverageGroup, RefreshBaverageGroupView, OpenEditBeverageGroup])

    useEffect(()=>
    {
        FetchBaverageProductList();
    }, [OpenCreateBaverageProduct, RefreshBaverageProductView, OpenEditBaverageProduct])


    return (
        <div className={`${classes.baverage_cluster_main_wrapper}`}>

{/* Group */}
            {OpenCreateBeverageGroup && <CreateBaverageGroup OpenController={SetOpenCreateBeverageGroup}/>}
            {OpenEditBeverageGroup &&  <EditBaverageGroup OpenController={SetOpenEditBeverageGroup} TargetData={BeverageGroupTargetEditData} /> }

{/* Product */}
            {OpenCreateBaverageProduct && <CreateBaverageProduct OpenController={SetOpenCreateBaverageProduct}/>}
            {OpenEditBaverageProduct &&  <EditBaverageProduct OpenController={SetOpenEditBaverageProduct} TargetID={BaverageProductTargetProductForEdit?.CMBVPID}/>}

            <div className={`${classes.data_wrapper_upper}`}>

            <DataViewTableProxy 
                    IsEmpty={BaverageGroupList.length === 0 && !LoadingBavGroup}
                    OnFilter={FilterBaverageGroupByName}
                    HeaderName={ <div> <h2>Master <b>Baverage</b> Group</h2> </div>}
                    OpenDataCreate={SetOpenCreateBeverageGroup}

                    DataArray={
                        <div>

                            {
                                BaverageGroupList.map((item, idx)=>(
                                    <li onDoubleClick={()=>{FilterBaverageProductByGroup(item.CMBVGID)}} key={item.CMBVG_Name + idx}>
                                        <span>{item.CMBVG_Name}</span>
                                        <span>{item.CMBVG_Description}</span>
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData &&  <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger(`Purge Baverage Group`, 
                                                `You are about to purge Baverage Products Group '${item.CMBVG_Name}'. (MAKE SURE THERE ARE NO LINKED SUB-PRODUCT TO THIS GROUP)
                                                This action is IRREVERSIBLE!`, 
                                                item.CMBVGID,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;
                                                     await HandleBaverageGroupPurge(item.CMBVGID);
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData && <button onClick={()=>{HandeleBeverageManage(item)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingBavGroup && 
                                <LoadingPlaceholder/>
                            }
                            
                        </div>
                    }
                />

                <DataViewTableProxy 
                    IsEmpty={BaverageProductList.length === 0 && !LoadingBavItems}
                    OnFilter={FilterBaverageProductByName}
                    OpenDataCreate={SetOpenCreateBaverageProduct}
                    HeaderName={<div><h2>Master <b>Baverage</b> Product</h2> </div>}

                    DataArray={
                        <div>

                            {
                                BaverageProductList.map((item, idx)=>(
                                    <li onDoubleClick={()=>{}} key={item.CMBVP_Name + idx}>
                                        <span>{item.CMBVP_Name}</span>
                                        <span><span className={`${classes.cash_view_value}`}>{currency_format(item.CMBVP_Price)}</span></span>
                                        <span>{item.CMBVG_Name}</span>
                                        <span style={{textAlign: 'right'}} className={`${classes.action_button_wrapper}`}>
                                        {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_PurgeOperationalData &&    <button style={{color: `var(--primary_text_color)`, background: `var(--red-color)`, borderColor: `var(--red-color)`}}
                                            onClick={()=>{DAControl.Trigger(`Purge Baverage Product`, 
                                                `You are about to purge Baverage Products  '${item.CMBVP_Name}'.
                                                This action is IRREVERSIBLE!`, 
                                                item.CMBVPID,
                                                10,
                                                async (data)=>{
                                                    if(!data)
                                                        return;

                                                    
                                                    await HandleBavergaeProductPurge(item.CMBVPID);
                                                })}}
                                            >
                                                <span><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></span>
                                                <span>Purge</span>
                                            </button>}
                                            {auth.UserSpecialAccessList && auth.UserSpecialAccessList?.LC_ManageOperationalData &&  <button onClick={()=>{HandleBaverageProductmanage(item)}}>
                                                <span><FontAwesomeIcon icon={faPenNib}></FontAwesomeIcon></span>
                                                <span>Manage</span>
                                            </button>}
                                        </span>
                                    </li>
                                ))
                            }
                            {
                                LoadingBavItems && 
                                <LoadingPlaceholder/>
                            }
                        
                            
                        </div>
                    }
                />

            </div>

        </div>
    )
}