import api from "../Util/axiosInstance";




export const CreateKitchenSupplyOrder = async(data)=>
{
    try  
    {
        const response = await api.post('api/ck/orders/create_kitchen_supply_order', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err; 
    }
}

export const CreateOrderDispatch = async(data)=>
{
    try  
    {
        const response = await api.post('api/ck/orders/dispatch_order_to_chef', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const RejectOrderSubProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/ck/orders/reject-order-product', data);
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err; 
    }
}

export const FetchTargetOrderGroup = async (branch_id, token)=>
{
    try  
    {
        const response = await api.post('api/ck/orders/fetch_kitchen_supply_order_group', {ID: branch_id} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const FetchBranchPreorderHistory = async (data, token)=>
{
    try  
    {
        const response = await api.post('api/ck/orders/fetch_ck_order_group_history', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err; 
    }
}

export const FetchTargetOrderGroupSubProducts = async (data, token)=>
{
    try  
    {
        const response = await api.post('api/ck/orders/fetch_kitchen_supply_order_group_sub_product', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err; 
    }
}

export const QueryShippingReadyProducts = async (data, token)=>
{
    try  
    {
        const response = await api.post('api/ck/orders/query_shipping_ready_products', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err; 
    }
}
export const QueryCustomerWithCasherInfo = async(data)=>
{
    try  
    { 
        const response = await api.post('api/ck/orders/query-customer-with-casher-info', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryOrdersGroupByBranchID = async(data)=>
{
    try  
    { 
        const response = await api.post('api/ck/orders/query-orders-by-group-id', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryProductReadyItemByGroupID = async(data)=>
{
    try  
    { 
        const response = await api.post('api/ck/orders/query-product-ready-item-by-group-id', data);
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}