import api from "../Util/axiosInstance";





export const CreateNewMerchGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/create_merch_group', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateMerchGroup = async(data)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/update_merch_group', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMerchGroupAll = async(token)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/query-all-merch-group', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeMerchGroupByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/purge_merch_group_by_id', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}

// ////////////////////////// MERCH PRODUCTS NOT GROUP
export const CreateMerchProducts = async(data)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/create-merch-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const UpdateMerchProduct = async(data)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/update-merch-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMerchProductAll = async(token)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/fetch-merch-products-all', {} );
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const PurgeMerchProductByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/purge-merch-product', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchMerchProductByID = async(data)=>
{
    try  
    {
        const response = await api.post('api/merch-cluster/fetch-merch-products-by-id', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}