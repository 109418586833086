import classes from './EditBakeryProductGroup.module.css'

import data_win from './../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useRef } from 'react'
import { useState } from 'react'
import { faSpinner, faTimesCircle, faObjectUngroup, faObjectGroup } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import Select from 'react-select';

import {UpdateBakeryGroup} from '../../../../../SharedComp/API/CoreDataClusters/API_FE_Bakery'

 

//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'

export default function EditBakeryProductGroup({OpenController, TargetData})
{

    const auth = useAuth();
    const notify = useToast();

    const [GroupName, SetGroupName] = useState();
    const [GroupDescritpion, SetGroupDescritpion] = useState();
    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("group_name", GroupName);
            fd.append("group_description", GroupDescritpion);
            fd.append("user_id", auth.user.USR_UserAccountID);
            fd.append("target_id", TargetData.CMBGID);

            const res = await UpdateBakeryGroup(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }


    useEffect(() => 
    {
        if(TargetData) 
        {
            SetGroupName(TargetData.CMBG_Name);
            SetGroupDescritpion(TargetData.CMBG_Description);
        }

    }, [TargetData])



    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

            <div className={`${data_win.data_main}`}>
                <div className={`${data_win.data_main_header}`}>
                    <h3>
                        Update <span className={`${data_win.data_create_name}`}>Bakery Group</span>
                    </h3>
                    <button onClick={()=>{CloseModal()}}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                    </button>
                </div>

                <div className={`${data_win.data_body}`}>
                        
                

                            <form onSubmit={HandleSubmition} action="POST">
                                <div className={`${classes.form_wrapper}`}> 

                                <div className={`${classes.master_prod_type_config_wrapper}`}>
                                        <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                                <MasterDataRegInput
                                                    input={
                                                        <input  
                                                        value={GroupName}
                                                        onChange={(e)=>{SetGroupName(e.target.value)}}
                                                        type="text" 
                                                        placeholder='Group Name'
                                                        required />
                                                    }
                                                />
                                                <MasterDataRegInput
                                                    input={
                                                        <textarea  
                                                        value={GroupDescritpion}
                                                        onChange={(e)=>{SetGroupDescritpion(e.target.value)}}
                                                        type="text"
                                                        placeholder='Description'
                                                        />
                                                    }
                                                />

                                        </div>

                                        
                                    </div>
                                        
                                </div>
                                <div className={`${data_win.form_action_button_wrapper}`}>
                                    <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                    <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                                </div>
                            </form>
                </div>
            </div>

        </div>
    )
}