import api from "../Util/axiosInstance";




export const FetchWarehouseByBranch = async(data)=>
{
    try 
    {
        const response = await api.post('api/warehouse/get-branch-warehouse', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const FetchWarehouseAll = async(data)=>
{
    try 
    {
        const response = await api.post('api/warehouse/get-branch-store-omni', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
