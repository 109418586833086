import classes from './MPT_Edit.module.css'
import data_win from '../../../../../SharedComp/CommonStyle/DataCreator.module.css'
// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


//lib
import { useAuth } from '../../../../../AuthProvider'
import { useToast } from '../../../../../ToastContext'
import { UpdatePenalityType } from '../../../../../SharedComp/API/MDCluster/API_FE_MDCluster_II'

export default function MPT_Edit({UpdateView, OpenController, TargetData})
{
    const auth = useAuth();
    const notify = useToast();

    const [PenalityName, SetPenalityName] = useState(TargetData.HRMPT_Name);
    const [PenalityDesc, SetPenalityDesc] = useState(TargetData.HRMPT_Description);
    const [Loading, SetLoading] = useState(false);

    function CloseModal() 
    {
        OpenController(false);
    }
    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        SetLoading(true);

        try 
        {
            const fd = new FormData();
            fd.append("penality_name", PenalityName);
            fd.append("penality_desc", PenalityDesc);
            fd.append("target_id", TargetData.HRMPT_ID);

            const res = await UpdatePenalityType(fd);
            notify.ShowSuccessUpdate(res.Msg, 10);
            UpdateView();
            SetLoading(false);
            CloseModal();
        } catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
            SetLoading(false);
        }

    }


    return(
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Update <span className={`${data_win.data_create_name}`}>Penality Type</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>

                                            <MasterDataRegInput
                                                input={
                                                    <input  
                                                    value={PenalityName}
                                                    onChange={(e)=>{SetPenalityName(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Penality Name'
                                                    required />
                                                }
                                            />
                                            <MasterDataRegInput
                                                 input={
                                                    <input  
                                                    value={PenalityDesc}
                                                    onChange={(e)=>{SetPenalityDesc(e.target.value)}}
                                                    type="text" 
                                                    placeholder='Description'
                                                    required 
                                                    />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button disabled={Loading} className={`${data_win.form_action_button_save}`}>{Loading ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Update'}</button>
                                <button disabled={Loading}  onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>)
}