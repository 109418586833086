import api from "../Util/axiosInstance";



export const CreateDirectStockRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/direct-stock-request/create-direct-stock-request',  data );
        const res = await response.data;

        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryDirectStockRequestGroupByBranch = async(data)=>
{
    try 
    {
        const response = await api.post('api/direct-stock-request/query-direct-stock-request-group-by-branch',  data );
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryDirectStockRequestItemsByGroup = async(data)=>
{
    try 
    {
        const response = await api.post('api/direct-stock-request/query-direct-stock-request-item-by-group',  data );
        const res = await response.data;
        
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const ApproveDirectStockRequestItemsByGroup = async(data)=>
{
    try 
    {
        const response = await api.post('api/direct-stock-request/approve-direct-stock-request',  data );
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryDirectStockRequestWithApproverItemsByID = async(data)=>
{
    try 
    {
        const response = await api.post('api/direct-stock-request/query-direct-stock-request-with-approver-by-id',  data );
        const res = await response.data;

        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const RejectDirectStockRequestRequest = async(data)=>
{
    try 
    {
        const response = await api.post('api/direct-stock-request/reject-direct-stock-request',  data );
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg);
        }
        return res;
    }catch(err) 
    {
        throw err;
    }
}