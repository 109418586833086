import api from "../Util/axiosInstance";




export const CreateNewEmployeeCashPenality = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/employee-operation-create-cash-penality', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const CreateNewEmployeeNonCashPenality = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/employee-operation-create-non-cash-penality', data);
        const res = await response.data;
        if(res.MyStateCode !== 1) 
        {
            throw new Error(res.Msg)
        };
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryAllEmployeeCashPenality = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/query-employee-operation-cash-penality', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}
export const QueryEmployeeAllNonCashPenality = async(data)=>
{
    try  
    {
        const response = await api.post('api/employee-operation/query-employee-operation-all-non-cash-penality', data);
        const res = await response.data;
        return res;
    }catch(err) 
    {
        throw err;
    }
}