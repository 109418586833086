import classes from './PastryDataPackageRC.module.css'
import { FetchPastryProductAll } from '../../../../../../../SharedComp/API/CoreDataClusters/API_FE_Pastry';
import { useAuth } from '../../../../../../../AuthProvider';
import { useToast } from '../../../../../../../ToastContext';
import { useEffect, useState } from 'react';
import { ArrowBendUpRight, Lock, LockOpen, Warning } from '@phosphor-icons/react';
import UIButton from '../../../../../../Components/UIButton/UIButton';
import {QueryCKProductKitchenID, FetchWorkspaceProductionCenterOnly} from '../../../../../../../SharedComp/API/CK/AP_CenteraKitchen'
import { QueryPastryShelvesProductByBranch } from '../../../../../../../SharedComp/API/ShopShelf/API_FE_PastryShelves';


export default function PastryDataPackageRC({Interaction, PastryDataPackage, SetPastryDataPackage}) 
{

    const auth = useAuth();
    const notify = useToast();

    // ///////////////////////////// Pastry START 
    const [KicthenChoice, SetKicthenChoice] = useState();
    const [ProductionCenterList, SetProductionCenterList] = useState([]);
    const [LockPastryInteraction, SetLockPastryInteraction] = useState(false);
    const [PastryProductList, SetPastryProductList] = useState([]);
    const [PastryProductListOriginal, SetPastryProductListOriginal] = useState([]);

    const InitPastryDataPackage = async(data)=>
    {
        const _init_list = new Map();
        for(let i = 0; i < data.length; i++) 
        {
            _init_list.set(data[i].CKP_ItemName,
                {
                    Name: data[i].CKP_ItemName,
                    ProductID: data[i].CKPID,
                    Price: data[i].CKP_UnitPrice,
                    Damage: '',
                    Unsold: '',
                    Available: data[i].SPP_Quantity
                }
            )
        }

        SetPastryDataPackage(new Map(_init_list));
    }
    const UpdatePastryUnsoldAmount = async(name, amount)=>
    {
        SetPastryDataPackage((prevList) => {
            const newList = new Map(prevList);
            if (newList.has(name)) {
                newList.set(name, { ...newList.get(name), Unsold: amount });  
            }
            return newList; 
        });
    }
    const UpdatePastryDamageAmount = async(name, amount)=>
    {
        SetPastryDataPackage((prevList) => {
            const newList = new Map(prevList); 
            if (newList.has(name)) {
                newList.set(name, { ...newList.get(name), Damage: amount });  
            }
            return newList;  
        });
    }
    const HandlePastryFilter = async(_key)=>
        {
            const valid_list = [];
    
            for(let  i = 0; i < PastryProductListOriginal.length; i++) 
            {
                const _seach_val = PastryProductListOriginal[i].CKP_ItemName;
                if(_seach_val.toLowerCase().includes(_key.toLowerCase())) 
                {
                    valid_list.push(PastryProductListOriginal[i]);
                }
            }
    
            SortAndSetBakertList(valid_list);
    }
    const SortAndSetBakertList = async(data)=>
    {
        const sortedProducts = data.sort((a, b) => {
            if (a.CKP_ItemName < b.CKP_ItemName) return -1; 
            if (a.CKP_ItemName > b.CKP_ItemName) return 1; 
            return 0;                         
        });

        SetPastryProductList(sortedProducts);
    }
    const QueryPastryProductList = async(id)=>
    {
        try 
            {
                const fd = new FormData();
                fd.append("branch_id", auth.activeWorkShop)
                const res = await QueryPastryShelvesProductByBranch(fd);
                // const res = await QueryCKProductKitchenID(id, auth.token);
                InitPastryDataPackage(res);
                SortAndSetBakertList(res);
                SetPastryProductListOriginal(res);
    
            }catch(err) 
            {
                notify.ShowError("Failed to Query Pastry Product List: " + err.message);
            }
    }
    const SelectKitchen = async (_id) =>
    {
        SetKicthenChoice(_id); 
    } 
    const FetchProductionCenter = async () =>
    {
        try 
        {
            const res = await FetchWorkspaceProductionCenterOnly(auth.token);
            SetProductionCenterList(res);
            if(res.length > 0) 
            {
                SelectKitchen(res[0].BranchID);
                QueryPastryProductList(res[0].BranchID);
            }
        } catch(err) 
        {

        }
    }

    // /////////////////////////////// Pastry END
    

    useEffect(()=>{
        FetchProductionCenter();
    }, [])


    return (
        <div className={`${classes.list_box_wrapper}`}>

                <div className={`${classes.heder_type_main_wrapper}`}>
                    <h3>Pastry</h3>
                    <div className={`${classes.group_filter_and_action}`}>
                        <input onChange={(e)=>{HandlePastryFilter(e.target.value)}} type="text" placeholder='Filter' autoComplete='off' />
                    </div>
                </div>

                <div className={`${classes.list_content_section_wrapper}`}>
                    <div style={{pointerEvents: LockPastryInteraction ? 'none' : '', filter: LockPastryInteraction ? 'brightness(50%)' : 'brightness(100%)'  }} className={`${classes.items_list_wrapper}`}>
                        {
                            PastryProductList.map((item, idx)=>(
                                <div key={item.CKP_ItemName} className={`${classes.list_single_item}`}>
                                    <div className={`${classes.produc_singluar_name} ${classes.data_inner_wrapper}`}>
                                        {item.CKP_ItemName} <span>#{item.SPP_Quantity} Pcs</span>
                                    </div>
                                    <div className={`${classes.data_left_side_input_wrapper}`}>
                                        <div className={`${classes.product_input_data_wrapper} ${classes.data_inner_wrapper}`}>
                                            <li className={`${classes.damage_prod_report}`}> <span><Warning weight='fill'/></span> Damage</li>
                                            <input 
                                                value={PastryDataPackage.get(item.CKP_ItemName)?.Damage || ''}
                                                onChange={(e) => UpdatePastryDamageAmount(item.CKP_ItemName, e.target.value)}
                                                type="number" 
                                                name={item.CKP_ItemName + '_'+'damage'}
                                                min={1} />
                                        </div>
                                        <div className={`${classes.product_input_data_wrapper} ${classes.data_inner_wrapper}`}>
                                            <li className={`${classes.unsold_prod_report}`}> <span><ArrowBendUpRight weight='fill'/></span> Unsold</li>
                                            <input
                                                key={item.CKP_ItemName + "_unsold"}
                                                value={PastryDataPackage.get(item.CKP_ItemName)?.Unsold || ''}
                                                onChange={(e) => UpdatePastryUnsoldAmount(item.CKP_ItemName, e.target.value)}
                                                name={item.CKP_ItemName + '_'+'unsold'}
                                                type="number" 
                                                min={1} />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={`${classes.bottom_action_wrapper}`}>
                    <UIButton 
                        Variant={LockPastryInteraction ? "Danger" : "Primary"} 
                        Icon={LockPastryInteraction ? <Lock weight='fill'/> : <LockOpen weight='fill' />} 
                        label={LockPastryInteraction ? "Unlock Interaction" : "Lock Interaction"} 
                        onClick={()=>{
                            SetLockPastryInteraction(!LockPastryInteraction);
                            Interaction(!LockPastryInteraction);
                            }}/>
                </div>
            </div>
    )
}