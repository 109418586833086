import axios from "axios";
import { getBaseURL } from "../../BaseUrlConfig";

const api = axios.create({
    baseURL: getBaseURL(),
    headers: { "Content-Type": "application/json" },
    withCredentials:true
});


api.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.response) 
        {
            const _status_code = error.response.status;

            if(_status_code === 401) 
            {
                if (window.location.pathname !== "/unauthorized") {
                    window.location.href = "/unauthorized"; 
                }
            }
            if(_status_code === 403) 
            {
                if (window.location.pathname !== "/login") {
                    window.location.href = "/login"; 
                }
            }
        }
        

        return Promise.reject(error);
    }
);

export default api;