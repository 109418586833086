import { useEffect, useState } from 'react';
import { ToEthiopianCalander } from '../../../../../../../SharedComp/Utility';
import classes from './ReportTransactionPreviewRC.module.css'
import LoadingPlaceholder from '../../../../../../../SharedComp/LoadingPlaceholder/LoadingPlaceholder';
import { format } from 'date-fns-tz';
export default function ReportTransactionPreviewRC({CloseDate, DataArray, SummeryMethod="DETAIL_SUMMERY"}) 
{
    const formatter = new Intl.NumberFormat('en-US');
    const [MainDataArray, SetMainDataArray] = useState([]);

    const SortDataByDate = (data_array)=>
    {
        data_array.sort((a, b)=>  new Date(a.INVOICELOG_DateOfEntry) - new Date(b.INVOICELOG_DateOfEntry));
        return data_array;
    }
    
    const SummerizeList = (data_array)=>
    {
        switch(SummeryMethod)
        {
            case "DETAIL_SUMMERY": SortDataByDate(data_array); SetMainDataArray(data_array); break;
            case "DAY_SUMMERY": SummerizeByDay(data_array); break;
            case "WEEK_SUMMERY": SummarizeByWeek(data_array); break;
            case "MONTH_SUMMERY": SummarizeByMonth(data_array); break;
            default: SortDataByDate(data_array); SetMainDataArray(data_array); break;
        }
    }

    const SummerizeByDay = (data_array) => {
        
        const __input_summery = new Map();
        const _output_summery = new Map();
        for(let i = 0; i < data_array.length; i++) 
        {
            const _date = format(new Date(data_array[i].INVOICELOG_DateOfEntry), 'yyyy-MM-dd', { timeZone: 'Africa/Nairobi' });
            const _flag = data_array[i].INVOICELOG_Flag;

            if(_flag === "Input")
            {
                if(!__input_summery.has(_date)) 
                {
                    __input_summery.set(_date, {
                        IVItemName: data_array[i].IVItemName,
                        INVOICELOG_DateOfEntry: data_array[i].INVOICELOG_DateOfEntry,
                        UomDescription: data_array[i].UomDescription,
                        INVOICELOG_Quantity: parseFloat(data_array[i].INVOICELOG_Quantity),
                        INVOICELOG_Flag: "Input",
                        INVOICELOG_Balance: parseFloat(data_array[i].INVOICELOG_Balance)
                    });
                } else 
                {
                    let _crt_value = __input_summery.get(_date);
                    _crt_value.INVOICELOG_Quantity += parseFloat(data_array[i].INVOICELOG_Quantity);
                    __input_summery.set(_date, _crt_value);
                } 
            } else 
            {
                if(!_output_summery.has(_date)) 
                {
                    _output_summery.set(_date, {
                        IVItemName: data_array[i].IVItemName,
                        INVOICELOG_DateOfEntry: data_array[i].INVOICELOG_DateOfEntry,
                        UomDescription: data_array[i].UomDescription,
                        INVOICELOG_Quantity: parseFloat(data_array[i].INVOICELOG_Quantity),
                        INVOICELOG_Flag: "Output",
                        INVOICELOG_Balance: parseFloat(data_array[i].INVOICELOG_Balance)
                    });
                } else 
                {
                    let _crt_value = _output_summery.get(_date);
                    _crt_value.INVOICELOG_Quantity += parseFloat(data_array[i].INVOICELOG_Quantity);
                    _output_summery.set(_date, _crt_value);
                } 
            }
        }

        const __array = [...Array.from(_output_summery.values()), ...Array.from(__input_summery.values())];

        SortDataByDate(__array);;
        SetMainDataArray(__array);
    };
    const SummarizeByWeek = (data_array) => {
        const inputSummary = new Map();
        const outputSummary = new Map();
        
        for (let i = 0; i < data_array.length; i++) {
            const date = new Date(data_array[i].INVOICELOG_DateOfEntry);
            const weekNumber = getWeekNumber(date);
            const yearWeek = `${date.getFullYear()}-W${weekNumber}`;
            const flag = data_array[i].INVOICELOG_Flag;
    
            if (flag === "Input") {
                if (!inputSummary.has(yearWeek)) {
                    inputSummary.set(yearWeek, {
                        IVItemName: data_array[i].IVItemName,
                        INVOICELOG_Week: yearWeek,
                        UomDescription: data_array[i].UomDescription,
                        INVOICELOG_Quantity: parseFloat(data_array[i].INVOICELOG_Quantity),
                        INVOICELOG_Flag: "Input",
                        INVOICELOG_Balance: parseFloat(data_array[i].INVOICELOG_Balance)
                    });
                } else {
                    let currentValue = inputSummary.get(yearWeek);
                    currentValue.INVOICELOG_Quantity += parseFloat(data_array[i].INVOICELOG_Quantity);
                    inputSummary.set(yearWeek, currentValue);
                }
            } else {
                if (!outputSummary.has(yearWeek)) {
                    outputSummary.set(yearWeek, {
                        IVItemName: data_array[i].IVItemName,
                        INVOICELOG_Week: yearWeek,
                        UomDescription: data_array[i].UomDescription,
                        INVOICELOG_Quantity: parseFloat(data_array[i].INVOICELOG_Quantity),
                        INVOICELOG_Flag: "Output",
                        INVOICELOG_Balance: parseFloat(data_array[i].INVOICELOG_Balance)
                    });
                } else {
                    let currentValue = outputSummary.get(yearWeek);
                    currentValue.INVOICELOG_Quantity += parseFloat(data_array[i].INVOICELOG_Quantity);
                    outputSummary.set(yearWeek, currentValue);
                }
            }
        }
        
        const resultArray = [...Array.from(outputSummary.values()), ...Array.from(inputSummary.values())];
        SortDataByDate(resultArray);
        SetMainDataArray(resultArray);
    };
    const getWeekNumber = (date) => {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDays = Math.floor((date - startOfYear) / (24 * 60 * 60 * 1000));
        return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7);
    };
    const SummarizeByMonth = (data_array) => {
        const inputSummary = new Map();
        const outputSummary = new Map();
        
        for (let i = 0; i < data_array.length; i++) {
            const date = new Date(data_array[i].INVOICELOG_DateOfEntry);
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            const flag = data_array[i].INVOICELOG_Flag;
    
            if (flag === "Input") {
                if (!inputSummary.has(monthYear)) {
                    inputSummary.set(monthYear, {
                        IVItemName: data_array[i].IVItemName,
                        INVOICELOG_Month: monthYear,
                        UomDescription: data_array[i].UomDescription,
                        INVOICELOG_Quantity: parseFloat(data_array[i].INVOICELOG_Quantity),
                        INVOICELOG_Flag: "Input",
                        INVOICELOG_Balance: parseFloat(data_array[i].INVOICELOG_Balance)
                    });
                } else {
                    let currentValue = inputSummary.get(monthYear);
                    currentValue.INVOICELOG_Quantity += parseFloat(data_array[i].INVOICELOG_Quantity);
                    inputSummary.set(monthYear, currentValue);
                }
            } else {
                if (!outputSummary.has(monthYear)) {
                    outputSummary.set(monthYear, {
                        IVItemName: data_array[i].IVItemName,
                        INVOICELOG_Month: monthYear,
                        UomDescription: data_array[i].UomDescription,
                        INVOICELOG_Quantity: parseFloat(data_array[i].INVOICELOG_Quantity),
                        INVOICELOG_Flag: "Output",
                        INVOICELOG_Balance: parseFloat(data_array[i].INVOICELOG_Balance)
                    });
                } else {
                    let currentValue = outputSummary.get(monthYear);
                    currentValue.INVOICELOG_Quantity += parseFloat(data_array[i].INVOICELOG_Quantity);
                    outputSummary.set(monthYear, currentValue);
                }
            }
        }
        
        const resultArray = [...Array.from(outputSummary.values()), ...Array.from(inputSummary.values())];
        SortDataByDate(resultArray);
        SetMainDataArray(resultArray);
    };

    useEffect(()=>{
        if(DataArray.length > 0) 
        {
            SummerizeList(DataArray);
        }

    }, [DataArray])



    return (
        <div className={`${classes.report_transaction_review_master_wrapper}`}>
           {MainDataArray.length > 0 && <div className={`${classes.inventorty_data_list_wraooer}`}>
                <div className={`${classes.inventory_header_not_type}`}>
                    {
                        MainDataArray.length > 0 && 
                        <h2>{MainDataArray[0].IVItemName}</h2>
                    }
                </div>
                <div className={`${classes.entry_wrapper_left_monthly_data_list}`}>
                    <div className={`${classes.montly_inventory_data_list}`}>
                        { SummeryMethod === "DETAIL_SUMMERY" &&
                            MainDataArray.map((item, idx)=>(
                                <li key={idx}>
                                    <span style={{flex: 1}}>{ToEthiopianCalander(new Date(item.INVOICELOG_DateOfEntry))}</span>
                                    <span style={{flex: 1}}>{item.USR_FirstName} {item.USR_LastName}</span>
                                    <span style={{flex: 1}}>{item.INVOICELOG_Method}</span>
                                    <span style={{flex: 1}}>{item.INVOICELOG_UnitPrice ? "ETB " + formatter.format(item.INVOICELOG_UnitPrice) : 'Unknown'}</span>
                                    <span style={{flex: 1}}>{item.INVOICELOG_Flag === "Output" ? <span className={`${classes.stock_utilization_deduct}`}>-({formatter.format(item.INVOICELOG_Quantity)} {item.UomDescription})</span> : <span style={{color: `#000000`, fontWeight: 'bold', marginLeft: '50px'}}>{formatter.format(item.INVOICELOG_Quantity) + " " + item.UomDescription}</span> } </span>
                                    <span style={{flex: 1}}>{formatter.format(item.INVOICELOG_Balance)} {item.UomDescription}</span>
                                </li>
                            ))
                        }
                        { SummeryMethod === "DAY_SUMMERY" &&
                            MainDataArray.map((item, idx)=>(
                                <li key={idx}>
                                    <span style={{flex: 1}}>{ToEthiopianCalander(new Date(item.INVOICELOG_DateOfEntry))}</span>
                                    <span style={{flex: 1}}>{item.INVOICELOG_Flag === "Output" ? <span className={`${classes.stock_utilization_deduct}`}>-({formatter.format(item.INVOICELOG_Quantity)} {item.UomDescription})</span> : <span style={{color: `#000000`, fontWeight: 'bold', marginLeft: '50px'}}>{formatter.format(item.INVOICELOG_Quantity) + " " + item.UomDescription}</span> } </span>
                                    <span style={{flex: 1}}>{formatter.format(item.INVOICELOG_Balance)} {item.UomDescription}</span>
                                </li>
                            ))
                        }
                        { SummeryMethod === "WEEK_SUMMERY" &&
                            MainDataArray.map((item, idx)=>(
                                <li key={idx}>
                                    <span style={{flex: 1}}>{item.INVOICELOG_Week}</span>
                                    <span style={{flex: 1}}>{item.INVOICELOG_Flag === "Output" ? <span className={`${classes.stock_utilization_deduct}`}>-({formatter.format(item.INVOICELOG_Quantity)} {item.UomDescription})</span> : <span style={{color: `#000000`, fontWeight: 'bold', marginLeft: '50px'}}>{formatter.format(item.INVOICELOG_Quantity) + " " + item.UomDescription}</span> } </span>
                                    <span style={{flex: 1}}>{formatter.format(item.INVOICELOG_Balance)} {item.UomDescription}</span>
                                </li>
                            ))
                        }
                        { SummeryMethod === "MONTH_SUMMERY" &&
                            MainDataArray.map((item, idx)=>(
                                <li key={idx}>
                                    <span style={{flex: 1}}>{item.INVOICELOG_Month}</span>
                                    <span style={{flex: 1}}>{item.INVOICELOG_Flag === "Output" ? <span className={`${classes.stock_utilization_deduct}`}>-({formatter.format(item.INVOICELOG_Quantity)} {item.UomDescription})</span> : <span style={{color: `#000000`, fontWeight: 'bold', marginLeft: '50px'}}>{formatter.format(item.INVOICELOG_Quantity) + " " + item.UomDescription}</span> } </span>
                                    <span style={{flex: 1}}>{formatter.format(item.INVOICELOG_Balance)} {item.UomDescription}</span>
                                </li>
                            ))
                        }
                        <strong>
                            <li style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                alignContent: 'center',
                                gap: '10px',
                                margin: '5px'}}>
                                <span>{CloseDate}</span>
                                <span>Balance</span>
                                <span>{formatter.format(MainDataArray[MainDataArray.length-1].INVOICELOG_Balance)} {MainDataArray[MainDataArray.length-1].UomDescription}</span>
                            </li>
                        </strong>
                    </div>
                </div>
            </div>}
        </div>
    )
}