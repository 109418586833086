import classes from './ShiftClosingReport.module.css'

import data_win from '../../../../../../../SharedComp/CommonStyle/DataCreator.module.css'

// Comp
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MasterDataRegInput from '../../../../../../../SharedComp/CommonStyle/MasterDataRegInput'
import { useState } from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

import { ProcessShiftClose } from '../../../../../../../SharedComp/API/DeskView/API_FE_CloseShift'


//lib
import { useAuth } from '../../../../../../../AuthProvider'
import { useToast } from '../../../../../../../ToastContext'

export default function ShiftClosingReport({OpenController, BakeryPackage, PastryPackage, MerchPackage, BaveragePackage}) 
{

    const auth = useAuth();
    const notify = useToast();

    const [FloatingCash, SetFloatingCash] = useState('');
    const [CashOnHand, SetCashOnHand] = useState('');
    const [CashierNote, SetCashierNote] = useState('');
    const [PettyCashExp, SetPettyCashExp] = useState('');


    function CloseModal() 
    {
        OpenController(false);
    }

    const HandleSubmition = async(e)=> 
    {
        e.preventDefault();
        notify.ShowLoad("Processing Your Request...", 10);
        try 
        {
            const fd = new FormData();
            fd.append('branch_id', auth.activeWorkShop);
            fd.append('cashier_id', auth.user.USR_UserAccountID);
            fd.append("cash_on_hand", CashOnHand);
            fd.append("cash_float", FloatingCash);
            fd.append("cashier_note", CashierNote);
            fd.append("petty_cash", PettyCashExp);
            fd.append("bakery_package", JSON.stringify(Array.from(BakeryPackage.entries())));
            fd.append("pastry_package", JSON.stringify(Array.from(PastryPackage.entries())));
            fd.append("merch_package", JSON.stringify(Array.from(MerchPackage.entries())));
            fd.append("bavergae_package", JSON.stringify(Array.from(BaveragePackage.entries())));

            const main_res = await ProcessShiftClose(fd);

            notify.ShowSuccessUpdate(main_res.Msg, 10);
            // CloseModal();

        }catch(err) 
        {
            notify.ShowErrorUpdate(err.message, 10);
        }

    }

    useEffect(()=>{

       

    }, [])

    return (
        <div className={`${data_win.main_data_creator_wrapper}`}>

        <div className={`${data_win.data_main}`}>
            <div className={`${data_win.data_main_header}`}>
                <h3>
                    Conclude <span className={`${data_win.data_create_name}`}>Shift Report</span>
                </h3>
                <button onClick={()=>{CloseModal()}}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                </button>
            </div>

            <div className={`${data_win.data_body}`}>
                    
            

                        <form onSubmit={HandleSubmition} action="POST">
                            <div className={`${classes.form_wrapper}`}> 

                            <div className={`${classes.master_prod_type_config_wrapper}`}>
                                    <div className={`${classes.master_product_type_inner_config_wrapper}`}>
                                            
                                           
                                            <MasterDataRegInput
                                                input={
                                                    <input
                                                        value={CashOnHand}
                                                        onChange={(e)=>{SetCashOnHand(e.target.value)}}
                                                        type="number"
                                                        min={1}
                                                        placeholder='Cash On Hand' 
                                                        required
                                                />
                                                }
                                            />
                                            <MasterDataRegInput
                                                input={
                                                    <input
                                                        value={PettyCashExp}
                                                        onChange={(e)=>{SetPettyCashExp(e.target.value)}}
                                                        type="number"
                                                        min={0}
                                                        placeholder='Petty Cash Expense' 
                                                        required
                                                />
                                                }
                                            />
                                             <MasterDataRegInput
                                                    input={
                                                        <input
                                                            value={FloatingCash}
                                                            onChange={(e)=>{SetFloatingCash(e.target.value)}}
                                                            type="number"
                                                            min={0}
                                                            placeholder='Floating Cash' 
                                                            required
                                                    />
                                                    }
                                                />

                                            <MasterDataRegInput
                                                input={
                                                    <textarea  
                                                        value={CashierNote}
                                                        onChange={(e)=>{SetCashierNote(e.target.value)}}
                                                        type="text"
                                                        placeholder='Your note'
                                                    />
                                                }
                                            />

                                    </div>

                                    
                                </div>
                                    
                            </div>
                            <div className={`${data_win.form_action_button_wrapper}`}>
                                <button className={`${data_win.form_action_button_save}`}>Finish</button>
                                <button onClick={()=>{CloseModal()}} className={`${data_win.form_action_button_dismiss}`} type='button'>Dismiss</button>
                            </div>
                        </form>
            </div>
        </div>

    </div>
    )
}